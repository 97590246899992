/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_ACTUATOR, CHANGED, DEL_ACTUATOR } from '../../../../redux/constants/idriConstants';
import Select from 'react-select';
import scaleDecimal from '../../../../support_func/scaleDecimal';
import roundNearest from '../../../../support_func/roundNearest';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import scrollToElement from '../../../../support_func/scrollToElement';
import selectStyles from '../../../../support_func/selectStyles';

const Actuador = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idriData = useSelector((state) => state.idriData);
  const sensorsList = useSelector((state) => state.sensorsList);
  const actuatorsList = useSelector((state) => state.actuatorsList);
  const changed = useSelector((state) => state.changed);
  const idri = useSelector((state) => state.idri);
  const key = props.pos;

  //update an actuator in redux store
  const updateActuator = (obj, i, oldNoutput) => {
    var actuator = actuatorsList[i ? i : key] || {};
    actuator = { ...actuator, [Object.keys(obj)]: Object.values(obj)[0] };
    //console.log(obj[Object.keys(obj)[0]]);
    // console.log(oldNoutput);
    // console.log(actuator);
    if (Object.keys(obj)[0] === 'lfminput' && actuator.flow && (actuator.category == 0 || actuator.linked_fm)) {
      actuator.flow = parseFloat(actuator.flow).toFixed(
        scaleDecimal(sensorScale(Object.values(obj)[0]))
      );
    }
    if (Object.keys(obj)[0] === 'linked_fm' && obj[Object.keys(obj)[0]] && actuator.flow && actuator.lfminput) {
      actuator.flow = parseFloat(actuator.flow).toFixed(
        scaleDecimal(sensorScale(actuator.lfminput))
      );
    }

    dispatch({
      type: ADD_ACTUATOR,
      payload: { ...actuatorsList, [i ? i : key]: actuator },
    });
    //actuator change checking
    changed.config === false &&
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: true },
      });

    // let isMaster =actuator.master
    if (Object.keys(obj)[0] === 'noutput' && actuator.master) {
      const clone = structuredClone(actuatorsList);
      Object.entries(clone).map(([k, v]) => {
        if (v.associated_masters.includes(oldNoutput)) {
          v.associated_masters[v.associated_masters.indexOf(oldNoutput)] = obj.noutput;

          // console.log(v.associated_masters);
        }
      });
      let a = clone[i ? i : key] || {};
      a.noutput = obj.noutput;
      dispatch({
        type: ADD_ACTUATOR,
        payload: clone,
      });

      // actuator = { ...actuator, [Object.keys(obj)]: Object.values(obj)[0] };
    }
    //  else if (Object.keys(obj)[0] === 'noutput' && !actuator.master) {
    //   dispatch({
    //     type: ADD_ACTUATOR,
    //     payload: { ...actuatorsList, [i ? i : key]: actuator },
    //   });
    //   //actuator change checking
    //   changed.config === false &&
    //     dispatch({
    //       type: CHANGED,
    //       payload: { ...changed, config: true },
    //     });
    // }
  };

  // removing an actuator from a redux store
  const deleteActuator = () => {
    delete actuatorsList[key];
    dispatch({
      type: DEL_ACTUATOR,
      payload: { ...actuatorsList },
    });
    if (!changed.config || changed.config === false) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: true },
      });
    }
  };

  //output option disable when some actuator is selected
  useEffect(() => {
    for (let index = 1; index <= document.getElementsByClassName(`salida${key}`).length; index++) {
      document.getElementById(`out_${index}_${key}`).disabled = false;
    }
    actuatorsList &&
      Object.keys(actuatorsList).forEach(
        (i) =>
          actuatorsList[i].noutput &&
          (document.getElementById(`out_${actuatorsList[i].noutput}_${key}`).disabled = true)
      );
  }, [actuatorsList]);

  //submit behavior
  const submit = (e) => {
    e.preventDefault();
  };

  //counters check
  const contadores = [];
  idriData.kindsensors &&
    Object.entries(idriData.kindsensors).map(
      ([key, value]) => value.kind_code === 3 && contadores.push(key)
    );
  //solenoid check
  // const solenoid = [];
  // Object.entries(idriData.kindactuators).map(
  //   ([key, value]) => value.kind === 'solenoide' && solenoid.push(key)
  // );

  var list = [];
  const aso = actuatorsList && Object.values(actuatorsList).map((val) => val.master && val.noutput);
  aso.forEach((element) => {
    actuatorsList[key].noutput !== element &&
      typeof element === 'number' &&
      list.push({ value: element, label: 'S' + element });
  });

  const handleSelectChange = (value) => {
    updateActuator({
      associated_masters: value.map((element) => element.value),
    });
  };

  useEffect(() => {
    changed.config &&
      updateActuator({
        associated_masters: [],
      });
  }, [actuatorsList[key].master]);

  useEffect(() => {
    changed.config && actuatorsList[key].flow && actuatorsList[key].flow > 0
      ? actuatorsList[key].category === 0 && updateActuator({ linked_fm: true })
      : changed.config && updateActuator({ linked_fm: false });
  }, [actuatorsList[key].flow]);

  // useEffect(() => {
  //   let asocQty = [];
  //   let duplicates = false;
  //   let flow = [];
  //   let cat = [];
  //   // eslint-disable-next-line array-callback-return
  //   actuatorsList &&
  //     Object.values(actuatorsList).map((a) => {
  //       a.lfminput && asocQty.push(a.lfminput);
  //     });
  //   actuatorsList &&
  //     Object.values(actuatorsList).map(
  //       (a) => a.flow && flow.push(parseFloat(a.flow) > 0 ? true : false)
  //     );
  //   actuatorsList &&
  //     Object.values(actuatorsList).map((a) =>
  //       a.category && cat.push(a.category) > 0 ? true : false
  //     );

  //   // let catCheck = [];
  //   // actuatorsList &&
  //   //   Object.values(actuatorsList).map(
  //   //     (a) => (a.category === 0 || a.category === 1) && catCheck.push(a.category)
  //   //   );

  //   // actuatorsList &&
  //   //   catCheck.every((val, i, arr) => val === arr[0]) &&
  //   //   Object.values(actuatorsList).map(() => cat.push(true));

  //   // console.log(asocQty);
  //   // console.log(flow);
  //   // console.log(cat);

  //   asocQty.map(
  //     (asoc) =>
  //       // eslint-disable-next-line array-callback-return
  //       actuatorsList &&
  //       Object.values(actuatorsList).map((a) => {
  //         if (a.lfminput === asoc) {
  //           if (cat.length > 1 && flow.some((v) => v === true)) {
  //             duplicates = asocQty.some((e, i, arr) => arr.indexOf(e) !== i);
  //             // console.log(duplicates);

  //             dispatch({
  //               type: CHANGED,
  //               payload: { ...changed, fluidFreeFlow: duplicates },
  //             });
  //           } else {
  //             changed.fluidFreeFlow &&
  //               dispatch({
  //                 type: CHANGED,
  //                 payload: { ...changed, fluidFreeFlow: false },
  //               });
  //           }
  //         }
  //       })
  //   );
  // }, [
  //   actuatorsList[key].flow,
  //   actuatorsList[key].lfminput,
  //   actuatorsList[key].category,
  // ]);

  const flowCheck = () => {
    return (
      sensorsList &&
      Object.values(sensorsList).map((sensor) => {
        if (contadores.includes(String(sensor.kind))) {
          let sensInput = sensor.ninput;
          let totalLibres = 0;
          let totalNeutros = 0;
          let nLibres = 0;
          let nNeutros = 0;
          let flowLibre = false;
          let flowNeutro = false;

          actuatorsList &&
            Object.values(actuatorsList).map((actuator) => {
              if (actuator && actuator.lfminput && actuator.lfminput === sensInput) {
                if (actuator.category === 0) {
                  totalLibres++;
                  if (actuator.flow && parseFloat(actuator.flow) > 0) {
                    flowLibre = true;
                    nLibres++;
                  }
                }
                if (actuator.category === 1) {
                  totalNeutros++;
                  if (actuator.flow && parseFloat(actuator.flow) > 0) {
                    flowNeutro = true;
                    nNeutros++;
                  }
                }
              }
            });
          // console.log('---- ' + sensor.ninput + ' ----');
          // console.log('nLibres caudal definido ' + nLibres);
          // console.log('nNeutros caudal definido ' + nNeutros);
          // console.log('flowLibre ' + flowLibre);
          // console.log('flowNeutro ' + flowNeutro);
          // console.log('totalLibres ' + totalLibres);
          // console.log('totalNeutros ' + totalNeutros);
          // console.log('----------');
          if (
            actuatorsList[key].lfminput === sensor.ninput &&
            actuatorsList[key].category === 0 &&
            nLibres !== 0 &&
            flowLibre &&
            totalLibres > 1
          ) {
            return true;
            // } else if (
            //   actuatorsList[key].lfminput === sensor.ninput &&
            //   actuatorsList[key].category === 1
            //   //  && nNeutros !== 0 &&
            //   // flowNeutro
            //   // && totalNeutros > 1
            // ) {
            //   return true;
          } else return false;
        }
      })
    );
  };

  const aMaster = (aId, aState, pos) => {
    if (!aState && window.confirm(t('changeMaster')) === true) {
      updateActuator({ master: aState });
      let actuators = actuatorsList;
      actuators[pos].master = aState;
      Object.keys(actuators).forEach((i) => {
        if (
          !actuators[i].master &&
          actuators[i].associated_masters &&
          actuators[i].associated_masters.length > 0
        ) {
          let arrNew = actuators[i].associated_masters.filter(function (e) {
            return e !== aId;
          });
          actuators[i].associated_masters = arrNew;
        }
      });
      dispatch({
        type: ADD_ACTUATOR,
        payload: actuators,
      });
    } else if (aState) {
      let actuators = actuatorsList;
      // actuators[key].linked_fm = false;
      dispatch({
        type: ADD_ACTUATOR,
        payload: actuators,
      });
      updateActuator({ master: aState });
    }
  };

  const checkAsoc = (lfminput) => {
    return (
      sensorsList &&
      Object.values(sensorsList).map((sensor) => {
        if (sensor.ninput === lfminput) {
          return sensor.kind;
        }
      })
    ).find((value) => {
      return value;
    });
  };
  const sensorScale = (lfminput) => {
    return (
      sensorsList &&
      Object.values(sensorsList).map((sensor) => {
        if (sensor.ninput === lfminput) {
          return sensor.scale
            ? sensor.scale
            : idriData.kindsensors[
                Object.values(sensorsList)
                  .map((s) => {
                    if (s.ninput === lfminput) {
                      return s.kind;
                    }
                  })
                  .find((value) => {
                    return value;
                  })
              ]?.default_scale;
        }
      })
    ).find((value) => {
      return value;
    });
  };

  const valueDecCheck = (value, lfminput) => {
    let strValue = String(value).split('.');
    if (strValue[1] && strValue[1].length > scaleDecimal(sensorScale(lfminput))) {
      return parseFloat(value).toFixed(scaleDecimal(sensorScale(lfminput)));
    } else {
      return value;
    }
  };

  useEffect(() => {
    let arr = [];
    let kind = actuatorsList[key].kind;
    actuatorsList[key].associated_masters &&
      actuatorsList[key].associated_masters.map((am) => {
        Object.values(actuatorsList).map((a) => {
          if (a.noutput === am && a.kind === 3) {
            arr.push(a.kind);
          }
        });
      });
    if (
      changed.config &&
      actuatorsList[key].associated_masters &&
      actuatorsList[key].associated_masters.length > 0 &&
      arr.includes(3)
    ) {
      actuatorsList[key].associated_masters.map((am) => {
        Object.values(actuatorsList).map((a) => {
          if (a.noutput === am && a.kind === 3) {
            if (kind === 4) {
              window.alert(`${t('masterActive')} S${a.noutput}.`);
            } else if (kind === 5 || kind === 6) {
              window.alert(`${t('masterIs')} S${a.noutput} ${t('masterFluid')}`);
            }
          }
        });
      });
    }
  }, [actuatorsList[key].kind, actuatorsList[key].associated_masters]);

  // console.log(actuatorsList[key]);

  useEffect(() => {
    textLineAnimation();
  });
  return (
    <Card key={props.key} className='p-2 cardBlue'>
      <Form
        id={`salidas_list_${key}`}
        onSubmit={(e) => {
          submit(e);
        }}
      >
        <Row>
          <Form.Control
            disabled
            className='visually-hidden'
            value={actuatorsList[key].id ? actuatorsList[key].id : ''}
          ></Form.Control>
          <Col xs={12} md={3}>
            <div className='animated text-truncate'>
              <strong>{t('salida')}</strong>
            </div>
            <Form.Select
              required
              value={actuatorsList[key].noutput ? actuatorsList[key].noutput : ''}
              onChange={(e) => {
                updateActuator(
                  { noutput: parseInt(e.target.value) },
                  null,
                  actuatorsList[key].noutput
                );
                scrollToElement(`salidas_list_${key}`, true, 'center', 300);
              }}
            >
              {Array.apply(null, Array(idriInitData.v_idri < 3 ? 9 : 81)).map((x, i) => {
                return (
                  <>
                    {i === 0 && (
                      <option key={i} value='' disabled>
                        {t('selectSalida')}...
                      </option>
                    )}
                    {i > 0 && (
                      <option
                        key={i}
                        id={`out_${i}_${key}`}
                        className={`salida${key}`}
                        selected={actuatorsList[key].ninput && actuatorsList[key].noutput === i}
                        value={i}
                      >
                        S{i}
                      </option>
                    )}
                  </>
                );
              })}
            </Form.Select>
          </Col>
          {actuatorsList[key].noutput && (
            <>
              <Col xs={12} md={3}>
                <div className='animated text-truncate'>
                  <strong>{t('nombreActuador')}</strong>
                </div>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={actuatorsList[key].name ? actuatorsList[key].name : ''}
                  onChange={(e) => updateActuator({ name: e.target.value })}
                />
              </Col>

              <Col xs={12} md={3}>
                <div className='animated text-truncate'>
                  <strong>{t('marca')}</strong>
                </div>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={actuatorsList[key].brand ? actuatorsList[key].brand : ''}
                  onChange={(e) => updateActuator({ brand: e.target.value })}
                />
              </Col>

              <Col xs={12} md={3}>
                <div className='animated text-truncate'>
                  <strong>{t('modelo')}</strong>
                </div>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={actuatorsList[key].model ? actuatorsList[key].model : ''}
                  onChange={(e) => updateActuator({ model: e.target.value })}
                />
              </Col>

              <Col xs={12} md={6}>
                <div className='animated text-truncate'>
                  <strong>{t('tipoActuador')}</strong>
                </div>
                <Form.Select
                  required
                  disabled={actuatorsList[key].id}
                  value={actuatorsList[key].kind ? actuatorsList[key].kind : ''}
                  className='actuador_tipo'
                  name='actuatorType'
                  onChange={(e) => {
                    updateActuator({
                      kind: parseInt(e.target.value),
                    });
                    scrollToElement(`salidas_list_${key}`, true, 'center', 300);
                  }}
                >
                  <option value='' disabled>
                    {t('selectTipoActuador')}...
                  </option>
                  {idriData.kindactuators &&
                    Object.entries(idriData.kindactuators).map(([i, value]) => (
                      <option key={i} value={i} selected={actuatorsList[key].kind === i}>
                        {value.kind +
                          ' - ' +
                          value.power_int +
                          ' - ' +
                          value.related_to +
                          ' - ' +
                          (value.normal_state ? t('abierto') : t('cerrado'))}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {idriData.kindactuators[actuatorsList[key].kind] &&
                idriData.kindactuators[actuatorsList[key].kind].related_to === 'fluid' && (
                  <>
                    <Col xs={12} md={3}>
                      <div className='animated text-truncate'>
                        <strong>{t('fluidType')}</strong>
                      </div>
                      <Form.Select
                        required
                        value={
                          actuatorsList[key].category || actuatorsList[key].category === 0
                            ? actuatorsList[key].category
                            : actuatorsList[key].category
                            ? actuatorsList[key].category
                            : ''
                        }
                        onChange={(e) => updateActuator({ category: parseInt(e.target.value) })}
                      >
                        <option key={''} value='' disabled>
                          {t('selectTipoSolenoide')}...
                        </option>
                        <option key={1} value={1} selected={actuatorsList[key].category === 1}>
                          {t('neutro')}
                        </option>
                        <option key={0} value={0} selected={actuatorsList[key].category === 0}>
                          {t('libre')}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className='animated text-truncate'>
                        <strong>{t('contadorAsociado')}</strong>
                      </div>
                      <Form.Select
                        required
                        id={`asoc_${key}`}
                        value={actuatorsList[key].lfminput ? actuatorsList[key].lfminput : ''}
                        onChange={(e) => {
                          updateActuator({ lfminput: parseInt(e.target.value) });
                          // asocOnChange(actuatorsList[key].lfminput);
                        }}
                      >
                        <option value='' selected={actuatorsList[key].lfminput === ''} disabled>
                          {t('contadorAsociado')}...
                        </option>
                        {sensorsList &&
                          Object.entries(sensorsList).map(
                            ([i, val]) =>
                              contadores.includes(String(val.kind)) &&
                              document.getElementById(`mainIn_${i}`) &&
                              parseInt(document.getElementById(`mainIn_${i}`).value) ===
                                val.ninput && (
                                <option key={val.ninput} value={val.ninput}>
                                  E{val.ninput}
                                </option>
                              )
                          )}
                      </Form.Select>
                    </Col>
                  </>
                )}
              {parseFloat(idriInitData.v_idri) >= 3 && (
                <>
                  {idriData.kindactuators[actuatorsList[key].kind] &&
                    idriData.kindactuators[actuatorsList[key].kind].related_to === 'fluid' &&
                    actuatorsList[key].lfminput &&
                    actuatorsList[key].lfminput > 0 && (
                      <>
                        <Col xs={12} md={3}>
                          <div className='animated text-truncate'>
                            <strong>
                              {t('caudal')}{' '}
                              {idriData.kindsensors[
                                parseInt(checkAsoc(actuatorsList[key].lfminput))
                              ]
                                ? '(' +
                                  idriData.kindsensors[
                                    parseInt(checkAsoc(actuatorsList[key].lfminput))
                                  ].magnitude +
                                  '/h' +
                                  ')'
                                : null}
                            </strong>
                          </div>

                          <Form.Control
                            required={flowCheck().includes(true)}
                            type='number'
                            min={(actuatorsList[key].category === 1 && !actuatorsList[key].linked_fm) ? 
                              0.001 : sensorScale(actuatorsList[key].lfminput)}
                            step={(actuatorsList[key].category === 1 && !actuatorsList[key].linked_fm) ? 
                              0.001 : sensorScale(actuatorsList[key].lfminput)}
                            value={
                              actuatorsList[key].flow ? parseFloat(actuatorsList[key].flow) : ''
                            }
                            onChange={(e) => {
                              (actuatorsList[key].category === 1 && !actuatorsList[key].linked_fm) ?
                              updateActuator({
                                flow: parseFloat(e.target.value)
                              })
                              :
                              updateActuator({
                                flow: parseFloat(
                                  valueDecCheck(e.target.value, actuatorsList[key].lfminput)
                                ),
                              })
                            }}
                            onBlur={() => {
                              (actuatorsList[key].category === 1 && !actuatorsList[key].linked_fm) ?
                              updateActuator({
                                flow: parseFloat(
                                  roundNearest(
                                    actuatorsList[key].flow,
                                    0.001
                                  )
                                ),
                              })
                              :
                              updateActuator({
                                flow: parseFloat(
                                  roundNearest(
                                    actuatorsList[key].flow,
                                    sensorScale(actuatorsList[key].lfminput)
                                  )
                                ),
                              })
                            }}
                          />
                        </Col>
                        {actuatorsList[key].flow > 0 && actuatorsList[key].category === 1 && (
                          <Col xs={12} md={3} className='text-center'>
                            <div className='animated text-truncate'>
                              <strong>{t('vinculado')}</strong>
                            </div>
                            <Form.Check
                              className='mt-2'
                              type='checkbox'
                              checked={actuatorsList[key].linked_fm}
                              onChange={(e) => {
                                updateActuator({ linked_fm: e.target.checked });
                              }}
                            />
                          </Col>
                        )}
                      </>
                    )}
                  {actuatorsList[key].kind && (
                    <>
                      <Col xs={12} md={3} className='text-center'>
                        <div className='animated text-truncate'>
                          <strong>{t('master')}</strong>
                        </div>
                        <Form.Check
                          className='mt-2'
                          type='checkbox'
                          checked={actuatorsList[key].master ? actuatorsList[key].master : false}
                          onChange={(e) => {
                            aMaster(actuatorsList[key].noutput, e.target.checked, key);
                          }}
                        />
                      </Col>
                      {actuatorsList[key].master && (
                        <Col xs={12} md={3}>
                          <div className='animated text-truncate'>
                            <strong>{t('stateChange')}</strong>
                          </div>
                          <Form.Control
                            required
                            type='number'
                            min={10}
                            max={300}
                            value={
                              actuatorsList[key].gap_master
                                ? actuatorsList[key].gap_master
                                : updateActuator({
                                    gap_master: 10,
                                  })
                            }
                            onChange={(e) => {
                              updateActuator({
                                gap_master: parseInt(e.target.value),
                              });
                            }}
                            onBlur={() => {
                              actuatorsList[key].gap_master &&
                                actuatorsList[key].gap_master < 10 &&
                                updateActuator({
                                  gap_master: 10,
                                });
                            }}
                          />
                        </Col>
                      )}

                      {!actuatorsList[key].master && (
                        <Col xs={12} md={6} lg={3}>
                          <div className='animated text-truncate'>
                            <strong>{t('masterAsoc')}</strong>
                          </div>
                          <Select
                            menuShouldScrollIntoView
                            isMulti={!actuatorsList[key].master}
                            styles={selectStyles}
                            value={
                              actuatorsList[key].associated_masters &&
                              actuatorsList[key].associated_masters.map((element) => {
                                return { value: element, label: 'S' + element };
                              })
                            }
                            onChange={handleSelectChange}
                            options={list}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Row>
        <Col className='text-end mt-2'>
          <Button
            className='d-none'
            variant='outline-success'
            size='sm'
            type='submit'
            id={`submitBtnAct_${key}`}
          >
            V
          </Button>
          <Button
            variant='outline-danger'
            size='sm'
            onClick={() =>
              idri && idri.discharged && actuatorsList[key].id
                ? window.confirm(t('seguroDelAct'))
                  ? deleteActuator()
                  : null
                : deleteActuator()
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Form>
    </Card>
  );
};

export default Actuador;
