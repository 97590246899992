/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import axios from 'axios';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGED, IDRI } from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import ActuatorsControl from './ActuatorsControl';
import SensorsControl from './SensorsControl';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';

const TestInstalation = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const dispatch = useDispatch();
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idri = useSelector((state) => state.idri);
  const changed = useSelector((state) => state.changed);

  const [runAsync, setRunAsync] = useState(false);
  const [autoUse, setAutoUse] = useState(false);
  const [act, setAct] = useState({});

  const extraGap = 6;

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 2000);
    }
  };
  //get async response from device
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          if (res.data.found === true && res.data.response.result === true) {
            getIdri();

            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            // setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdri(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              getIdri(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            getIdri(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            // setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  //get device data
  const getIdri = (errorAsync) => {
    setIsPending(true);
    setError(null);
    setRunAsync(false);
    Object.keys(idri).length !== 0 &&
      dispatch({
        type: IDRI,
        payload: {},
      });
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${idriInitData.ididri}&ghatorserial=${idriInitData.idriserial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          errorAsync && setError(errorAsync);

          localStorage.setItem('stage', [
            'testInst',
            idriInitData.ghatorid,
            idriInitData.ghatorserial,
          ]);
          dispatch({
            type: IDRI,
            payload: res.data.ghator,
          });
          // setLocalTime(new Date());
          if (changed.test && changed.test === true) {
            dispatch({
              type: CHANGED,
              payload: { ...changed, test: false },
            });
          }
          // setLastUpdate(moment().format('L') + ' ' + moment().format('LTS'));
          setIsPending(false);
          // setAct(
          //   res.data.idri.actuators.map((actuator) => ({
          //     idactuator: actuator.idactuator,
          //     status: actuator.status,
          //     duration: '',
          //   }))
          // );
          parseFloat(idriInitData.ghatorv) >= 3
            ? setAct(
                res.data.ghator.ghatorserial
                  ? res.data.ghator.actuators.map((actuator) => ({
                      idactuator: actuator.idactuator,
                      is_master: actuator.is_master,
                      status: actuator.status,
                      duration: { h: 0, m: 0, s: 0 },
                      volumeAsoc: '',
                      flow: '',
                      permanent:
                        actuator.status === 0
                          ? false
                          : (actuator.status === 1 &&
                              actuator.status_change_time === null &&
                              Object.keys(actuator.fluid).length > 0 &&
                              actuator.fluid.status_change_afmv_remain === null &&
                              actuator.fluid.status_change_afv_remain === null) ||
                            (actuator.status === 1 &&
                              actuator.status_change_time === null &&
                              Object.keys(actuator.fluid).length === 0)
                          ? true
                          : false,
                    }))
                  : []
              )
            : setAct(
                res.data.ghator.ghatorserial
                  ? res.data.ghator.actuators.map((actuator) => ({
                      idactuator: actuator.idactuator,
                      status: actuator.status,
                      minutes: '',
                    }))
                  : []
              );
          setAutoUse(res.data.ghator.master_en);

          //store data in redux storage
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  // console.log(act);

  //send changed actuators state to server
  const postOperations = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {};
    parseFloat(idriInitData.v_idri) < 3
      ? (body = {
          ghatorid: parseInt(idriInitData.ididri),
          ghatorserial: idriInitData.idriserial,
          actuators: act.map((a) =>
            Object.values(idri.actuators).find((obj) => {
              return obj.idactuator === a.idactuator && obj;
            }).status !== a.status ||
            Object.values(idri.actuators).find((obj) => {
              return obj.idactuator === a.idactuator && obj;
            }).status === 1
              ? {
                  idactuator: a.idactuator,
                  status: a.status,
                  minutes: a.duration && a.duration !== null ? a.duration : '',
                }
              : null
          ),
        })
      : (body = {
          ghatorid: parseInt(idriInitData.ididri),
          ghatorserial: idriInitData.idriserial,
          // master_en: autoUse,
          actuators: act.map((a) =>
            (idri.master_en && a.is_master) ||
            (a.status === 1 &&
              !a.permanent &&
              a.volumeAsoc === '' &&
              a.flow === '' &&
              parseInt(a.duration.h) * 60 * 60 +
                parseInt(a.duration.m) * 60 +
                parseInt(a.duration.s) ===
                0)
              ? null
              : (Object.values(idri.actuators).find((obj) => {
                  return obj.idactuator === a.idactuator && obj;
                }).status !== a.status ||
                  Object.values(idri.actuators).find((obj) => {
                    return obj.idactuator === a.idactuator && obj;
                  }).status === 1) &&
                (a.permanent ||
                  parseInt(a.duration.h) * 60 * 60 +
                    parseInt(a.duration.m) * 60 +
                    parseInt(a.duration.s) !==
                    0 ||
                  a.volumeAsoc !== 0 ||
                  a.flow !== 0)
              ? {
                  idactuator: a.idactuator,
                  status: a.status,
                  seconds: a.permanent
                    ? ''
                    : a.duration &&
                      parseInt(a.duration.h) * 60 * 60 +
                        parseInt(a.duration.m) * 60 +
                        parseInt(a.duration.s) !==
                        0
                    ? parseInt(a.duration.h) * 60 * 60 +
                      parseInt(a.duration.m) * 60 +
                      parseInt(a.duration.s)
                    : '',
                  afmv: a.permanent ? '' : a.volumeAsoc && a.volumeAsoc !== 0 ? a.volumeAsoc : '',
                  afv: a.permanent ? '' : a.flow && a.flow !== 0 ? a.flow : '',
                }
              : null
          ),
        });
    console.log(body);
    if (
      parseFloat(idri.ghatorv) < 3 &&
      body.actuators
        .map((e) => {
          if (e !== null && e.status === 1 && e.minutes === '') {
            return true;
          } else {
            return false;
          }
        })
        .every((e) => e === true)
    ) {
      setIsPending(false);
      setError(t('noSinTiempo'));
    } else if (body.actuators.every((e) => e === null)) {
      setIsPending(false);
      setError(t('dataOPermanent'));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/directoperation`, body, config)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            counter = 0;
            if (counter === 0) {
              setRunAsync(true);
              setTimeout(() => {
                checkAsync(res.data.asyncid);
              }, 4000);
            }
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };
  //device data refresh
  const getRefresh = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/grefresh?ghatorid=${idriInitData.ididri}&ghatorserial=${idriInitData.idriserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 2000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const masterEn = (e, state) => {
    e && e.preventDefault();
    // setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      master_en: state,
    };

    const fetchApi = () => {
      setIsPending(true);
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/masteren`, body, config)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            counter = 0;
            if (counter === 0) {
              setRunAsync(true);
              setTimeout(() => {
                checkAsync(res.data.asyncid);
              }, 2000);
            }
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    };
    // Object.values(idri.actuators)
    //   .map((a) => {
    //     return a.status;
    //   })
    //   .includes(1)
    //   ? window.alert(
    //       'no puede alternar el uso automático de maestras mientras al menos un actuador asociado está en su estado contrario a normal'
    //     )
    //   : fetchApi();
    fetchApi();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      postOperations(e);
    }
  };

  //first page load get instalation data
  useEffect(() => {
    getIdri();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
    msg !== null && window.alert(msg);
    setMsg(null);
  }, [error, msg]);

  return (
    <>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      )}
      {/* {idri.sim && ( */}
      {idri.inicialized && (
        <>
          <SensorsControl
            refresh={getRefresh}
            getIdri={getIdri}
            masterEn={masterEn}
            autoUse={autoUse}
            setAutoUse={setAutoUse}
          />
          <Table className='mb-0 test'>
            {Object.keys(act).length > 0 && (
              <thead>
                <tr className='primary text-center'>
                  <th colSpan={2} className='text-white'>
                    {t('actuadores')}
                  </th>
                </tr>
              </thead>
            )}
            {!isPending && (
              <ActuatorsControl
                refresh={getRefresh}
                setAct={setAct}
                act={act}
                autoUse={autoUse}
                setAutoUse={setAutoUse}
                handleSubmit={handleSubmit}
              />
            )}
          </Table>

          <Col id='error' xs={12}>
            {error && <Col className='text-center alert alert-danger'>{error}</Col>}
            {msg && (
              <Col className='text-center alert alert-success bg-transparent border-white'>
                {msg}
              </Col>
            )}
          </Col>

          {!isPending && Object.keys(act).length > 0 && (
            <Col className='text-center mt-3'>
              <Button
                type='submit'
                form='actuatorsForm'
                className='btn dark text-uppercase'
                // onClick={(e) => postOperations(e)}
              >
                {t('enviar')}
              </Button>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default TestInstalation;
