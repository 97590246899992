/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import ModalGenerico from '../Modal_generico';
import AvisoLegal from './AvisoLegal';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const FreeAccount = (props) => {
  const { t } = useTranslation();
  const [aviso, setAviso] = useState(false);
  const [freeData, setFreeData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef('reset_password_recaptcha');
  const [key, setKey] = useState(Date.now());

  const regFreeAccount = (e) => {
    setIsPending(true);
    setError(null);
    e.preventDefault();

    let lang = localStorage.getItem('language');
    let host = window.location.host;
    // let noIp = 'http://srtnicole.hopto.org:8000/urlaccountactiv';

    if (freeData.captchatoken && freeData.captchatoken !== '') {
      let email = freeData.email && `?email=${freeData.email}`;
      let captchatoken = freeData.captchatoken && `&captchatoken=${freeData.captchatoken}`;

      let body = {
        email: freeData.email,
        password: freeData.password,
        legal_notice_aprov: freeData.legal_notice_aprov,
        captchatoken: freeData.captchatoken,
        urlaccountactiv: 'https://' + host + '/urlaccountactiv',
        language: lang,
      };
      // captchatoken +
      // `&urlchangeform=https://${host}` +
      // `&language=${lang}`;}

      console.log(body);

      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/newfreeaccount`, body)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.status === 'ok') {
            console.log(res.data);
            setFreeData({});
            recaptchaRef.current.reset();
            setError(t('emailSent'));
            props.closeModal();
            setIsPending(false);
          } else {
            setIsPending(false);
            setFreeData();
            recaptchaRef.current.reset();
            setError(t('errorMensaje'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setIsPending(false);
      setError(t('noRobot'));
    }
  };

  useEffect(() => {
    setKey(Date.now());
  }, [localStorage.getItem('language')]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}
      <h6 className='fst-italic'>{t('intrDatos')}</h6>
      <h6 className='fw-normal fst-italic'>{t('ifHasAccount')}</h6>
      <Form onSubmit={(e) => regFreeAccount(e)}>
        <Row className='mt-4'>
          <Col xs={12} md={6}>
            <strong>{t('email')}</strong>
            <Form.Control
              required
              name='email'
              type='email'
              autoComplete='email'
              placeholder={t('introduceEmail')}
              value={freeData.email ? freeData.email : ''}
              onChange={(e) => setFreeData({ ...freeData, email: e.target.value })}
            />
          </Col>

          <Col xs={12} md={6}>
            <strong>{t('password').toLocaleLowerCase()}</strong> <span>({t('min8')})</span>
            <Form.Control
              required
              minLength={8}
              name='password'
              type='password'
              autoComplete='off'
              placeholder={t('introducePassword')}
              value={freeData.password ? freeData.password : ''}
              onChange={(e) => setFreeData({ ...freeData, password: e.target.value })}
            />
          </Col>
          <Col xs={12} className='d-flex my-3'>
            <Form.Check
              required
              type='checkbox'
              value={freeData.legal_notice_aprov ? freeData.legal_notice_aprov : false}
              onChange={(e) =>
                setFreeData({
                  ...freeData,
                  legal_notice_aprov: e.target.checked,
                })
              }
            />
            <span className='ms-2'>
              {t('read')}{' '}
              <span
                className='text-decoration-underline text-primary'
                onClick={() => setAviso(true)}
              >
                {t('avisoLegal')}
              </span>{' '}
              {t('agre')}
            </span>
          </Col>
          <Col xs={12}>
            <ReCAPTCHA
              id='captcha'
              key={key}
              hl={localStorage.getItem('language')}
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
              onChange={(e) =>
                setFreeData({
                  ...freeData,
                  captchatoken: e,
                })
              }
            />
          </Col>
          <Col className='text-center mt-4'>
            <Button type='submit' variant='btn dark text-white text-uppercase'>
              {t('accede')}
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalGenerico title={t('avisoLegal')} onClose={() => setAviso(false)} show={aviso}>
        <AvisoLegal closeModal={() => setAviso(false)} />
      </ModalGenerico>
    </>
  );
};

export default FreeAccount;
