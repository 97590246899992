import React from 'react';

const Contract = () => {
  return (
    <>
      <h5
        class='text-center'
        // style='margin-top:10px; margin-bottom:10px; font-size:100%;'
      >
        <u>Contrato de usuario</u>
      </h5>
      <div
      //    style='margin-top:10px; margin-left: 10px; margin-right: 10px; font-size:75%;'
      >
        <p>
          El presente CONTRATO DE USUARIO es un contrato celebrado entre Ud., como
          usuario final del Sistema de control telemático de riego y producción
          agrícolas Sisritel, en adelante Sisritel, y MICRO2ENGINEERING S.L., en
          adelante micro2e, como propietaria del sistema Sisritel y empresa
          responsable de la prestación del servicio.
        </p>

        <p
          class='text-center'
          //  style='font-style: italic; font-size:75%;'
        >
          Micro2engineering S. L. - C/ Pepita Samper, 3, 7ª - 46006 - Valencia -
          España - NIF: B98982564 -{' '}
          <a
            href='/cdn-cgi/l/email-protection'
            class='__cf_email__'
            data-cfemail='a5cccbc3cae5c8ccc6d7ca97c08bc6cac8'
          >
            [email&#160;protected]
          </a>{' '}
          - Registro mercantil de Valencia, tomo 10470, folio 171, hoja V-181008,
          inscripción 1ª
        </p>

        <h5
        //  style='font-size:100%;'
        >
          I. OBJETO DEL CONTRATO
        </h5>
        <p>
          El presente CONTRATO DE USUARIO referencia el contenido, los derechos y las
          obligaciones entre las partes, usuario y micro2e, derivados de la
          utilización del sistema Sisritel.
        </p>

        <h5
        //  style='font-size:100%;'
        >
          II. SISRITEL
        </h5>
        <p>
          Sisritel es un sistema de control telemático (a distancia) del riego y la
          producción agrícolas que permite a sus usuarios tener acceso a la
          información de sensores y actuar sobre dispositivos interruptores
          conectados a él.
        </p>

        <p>
          Independientemente de los automatismos que en un momento dado el sistema
          pueda ofrecer, entendiendo como automatismo el conjunto de reglas que
          producen una acción ante unas condiciones previamente definidas, las
          funciones básicas que el sistema Sisritel ofrece, por medio de aplicaciones
          que pueden ser accedidas a través del teléfono o de otro dispositivo
          electrónico capacitado, son:
        </p>

        <ul
        //  style='padding-left: 15px;'
        >
          <li>
            Apertura y cierre de sectores (válvulas o interruptores) a distancia.
          </li>
          <li>
            Programación de aperturas y cierres de válvulas e interruptores en base
            semanal y por horas.
          </li>
          <li>
            Configuración de un número variable de automatismos, incluidas las
            alertas.
          </li>
          <li>Acceso a información histórica sobre consumos y otras magnitudes.</li>
          <li>Acceso a información del estado de los sensores conectados.</li>
        </ul>

        <h5
        // style='font-size:100%;'
        >
          III. ACCESO A LAS FUNCIONALIDADES DE SISRITEL
        </h5>
        <p>
          Sisritel está formado por varios componentes tecnológicos. Para acceder a
          las funcionalidades citadas en el punto anterior es necesario la
          adquisición de un componente hardware (placa electrónica) específico por
          parte del usuario y suministrado por micro2e. Este componente hardware hace
          de concentrador y puerta de enlace con los sensores y actuadores conectados
          a él, y estará físicamente situado cerca de la instalación hidráulica del
          usuario.
        </p>

        <h5
        // style='font-size:100%;'
        >
          IV. SERVICIOS DE TERCEROS
        </h5>
        <p>
          Sisritel, para su correcto funcionamiento, requiere de los servicios de
          terceras empresas independientes de micro2e. En concreto, micro2e tiene
          concertada la provisión de servicios de:
        </p>
        <ul
        // style='padding-left: 15px;'
        >
          <li>
            Gestión de conectividad para el internet de las cosas (IoT) y M2M.
            Telecomunicaciones
          </li>
          <li>
            Hosting. Gestión de DNS, alojamiento, correo electrónico y servidores
          </li>
          <li>
            Plataforma como Servicio (PaaS) para despliegue y ejecución de
            aplicaciones
          </li>
        </ul>

        <p>
          micro2e se compromete a realizar las gestiones a su alcance en favor del
          buen funcionamiento de los servicios externos en los que se apoya el
          sistema Sisritel, sean los anteriormente mencionados u otros que puedan
          utilizarse en el futuro, si bien no puede responsabilizarse de las
          consecuencias que pueda tener la interrupción o mal funcionamiento puntual
          de los servicios prestados por terceros.
        </p>

        <h5
        // style='font-size:100%;'
        >
          V. ADQUISICIÓN E INSTALACIÓN DE EQUIPOS SISRITEL
        </h5>
        <p>
          micro2e trabaja y mantiene acuerdos con profesionales y empresas
          instaladoras independientes para la venta, instalación y mantenimiento de
          equipos Sisritel. De igual forma micro2e ejecuta proyectos de despliegue
          del sistema Sisritel en áreas geográficas específicas, para entidades y
          comunidades de regantes, que pueden conllevar acuerdos particulares que
          afectan a la adquisición, instalación y mantenimiento de equipos por parte
          de los usuarios finales.
        </p>
        <p>
          Tanto el usuario particular como el que pertenezca a una determinada
          organización promotora de un proyecto, adquirirá e instalará los equipos a
          través de las entidades que conforman la red de distribución y servicio
          técnico del sistema Sisritel.
        </p>

        <h5
        // style='font-size:100%;'
        >
          VI. OBLIGACIONES Y RESPONSABILIDADES DEL USUARIO
        </h5>
        <p>Con respecto al uso del sistema Sisritel, el usuario está obligado a:</p>

        <ul
        //  style='padding-left: 15px;'
        >
          <li>Utilizarlo conforme a lo estipulado en este contrato</li>
          <li>
            Garantizar la veracidad y actualidad de la información proporcionada al
            sistema
          </li>
          <li>
            Ser diligente en la custodia, cuidado y mantenimiento de los componentes
            puestos a su disposición y que forman parte del sistema
          </li>
          <li>
            Conocer y aceptar las condiciones de utilización del sistema, así como
            las modificaciones que se realicen sobre el mismo
          </li>
          <li>
            Notificar a los instaladores/distribuidores y/o a los responsables del
            Sisritel, a través del formulario de contacto de la página de acceso,
            sobre cualquier anomalía en el funcionamiento del sistema o que afecte a
            su fiabilidad
          </li>
          <li>
            Abstenerse de dar a los servicios de telecomunicaciones un uso distinto
            al de facilitar la conectividad entre la puerta de enlace y la plataforma
            backend
          </li>
          <li>
            No utilizar los servicios prestados por micro2e como soporte para la
            prestación de otros servicios, así como para la reventa o
            comercialización de dichos servicios
          </li>
          <li>
            Facilitar el acceso a las instalaciones por parte de las personas
            debidamente autorizadas para la realización de tareas de mantenimiento
            y/o actualización del sistema
          </li>
          <li>
            Abstenerse de instalar componentes o dispositivos, conectados al sistema,
            no autorizados o certificados por micro2e.
          </li>
          <li>
            Responder ante cualquier fraude, variación o modificación que se haga y
            no esté contemplada en el uso normal del sistema o esté autorizada
            expresamente por micro2e
          </li>
          <li>
            Responsabilizarse de las consecuencias que las configuraciones de los
            automatismos que el sistema permite conlleven
          </li>
          <li>
            Asegurarse de la recepción y la atención de las notificaciones de alertas
            enviadas por el sistema, por los medios que en cada momento se provean
          </li>
          <li>
            Satisfacer la cuota de servicio fijada en cada caso, con la periodicidad
            que se estipule
          </li>
          <li>
            Notificar con antelación sobre cualquier variación de los datos
            relacionados con la forma de pago de la cuota de servicio
          </li>
          <li>
            Responsabilizarse del mantenimiento y correcto funcionamiento de la
            instalación hidráulica. micro2e queda expresamente excluido de cualquier
            responsabilidad sobre anomalías, averías, mal funcionamiento o cualquier
            otra incidencia en la instalación hidráulica, ajenos al servicio prestado
            por Sisritel
          </li>
        </ul>

        <h5
        // style='font-size:100%;'
        >
          VII. FACTURACIÓN CUOTA DE SERVICIO
        </h5>
        <p>
          La cuota de servicio es una cantidad asociada a cada equipo que tiene que
          ser abonada por el usuario según la periodicidad acordada (por defecto
          mensual) y que sirve para cubrir los costes de operación vinculados al uso
          de las redes de telecomunicación del servicio de conectividad, al uso de
          los servicios de hosting, PaaS y persistencia de datos, y al desarrollo de
          nuevas funcionalidades y mantenimiento global (no específico) del sistema.
        </p>

        <p>
          La cuota de servicio será comunicada al usuario por parte de instalador en
          el momento de la adquisición e instalación de los equipos. El usuario podrá
          consultar la cuota de servicio asociada a cada equipo desde su cuenta de
          Sisritel.
        </p>

        <p>
          La cuota de servicio, según el caso, será satisfecha por aquellos usuarios
          particulares, mediante la elección de una de las formas de pago
          disponibles, o por las entidades y comunidades de regantes que hayan
          acordado con micro2e la ejecución de un proyecto de implantación del
          sistema Sisritel en su organización. Dependiendo de la forma de pago, la
          cuota podrá incrementarse en una cantidad igual al coste de prestación del
          servicio por parte de las entidades financieras que lo ejecuten. micro2e
          velará porque ese coste sea mínimo (o nulo), buscando la mejor opción
          disponible e introduciendo métodos de pago menos gravosos.
        </p>

        <p>
          La cuota de servicio podrá variar en función de las fluctuaciones que
          puedan producirse en el mercado asociado a los servicios necesarios para el
          correcto funcionamiento de Sisritel. Cualquier variación en la cuota de
          servicio será comunicada al usuario con antelación a su entrada en vigor.
        </p>

        <p>
          La demora superior a 30 días en el pago de una cuota podrá ocasionar la
          interrupción del servicio de Sisritel por parte de micro2e, y previo aviso
          de 30 días, la desconexión total y la eliminación de la cuenta del usuario
          en el sistema Sisritel.
        </p>

        <h5
        // style='font-size:100%;'
        >
          VIII. OBLIGACIONES DE MICRO2E
        </h5>
        <p>
          Con respecto al sistema Sisritel y los servicios asociados, micro2e deberá:
        </p>

        <ul
        // style='padding-left: 15px;'
        >
          <li>
            Informar al usuario, previa activación de su cuenta, de los términos,
            condiciones, derechos y obligaciones del uso del sistema Sisritel
          </li>
          <li>
            Asegurar el correcto funcionamiento del sistema Sisritel en los términos
            pactados en este contrato siempre que no concurra fuerza o causa mayor o
            quede comprometida la viabilidad del proyecto
          </li>
          <li>
            Garantizar el acceso al sistema a aquellos usuarios que hayan adquirido
            un equipo Sisritel y cumplan con las obligaciones del presente contrato
          </li>
          <li>
            Ofrecer la garantía que establezca la legislación vigente en cada momento
            para todos aquellos equipos y dispositivos suministrados por Sisritel
          </li>
          <li>
            Ofertar servicios técnicos y de mantenimiento, a través de la red de
            instaladores/distribuidores, para los equipos, dispositivos e
            infraestructura propia
          </li>
          <li>
            Ser diligente y conservar en medio seguro la información que el sistema
            recoja, tanto administrativa como del uso del mismo
          </li>
          <li>
            Disponer de los medios técnicos necesarios para corregir posibles
            anomalías detectadas
          </li>
          <li>
            Proveer al sistema de los mecanismos de seguridad oportunos para evitar
            usos indebidos
          </li>
          <li>
            Notificar a los usuarios cualquier incidencia o modificación que afecten
            al normal uso del sistema
          </li>
          <li>Mantener el sistema para garantizar una eficiente usabilidad</li>
          <li>
            Reanudar los servicios suspendidos una vez solucionada la causa que
            provocaron su suspensión
          </li>
          <li>
            Comunicar al usuario cualquier modificación que afecte al presente
            contrato
          </li>
        </ul>

        <p>
          Además micro2e, en la medida en que sus posibilidades técnicas y económicas
          lo permitan, procurará ir continuamente evolucionando el sistema, agregando
          soluciones tecnológicas que supongan un incremento de las funcionalidades
          que ofrece y mejorando su utilidad.
        </p>

        <h5
        //  style='font-size:100%;'
        >
          IX. TRATAMIENTO DE DATOS DE CLIENTES
        </h5>
        <p>
          En micro2e tratamos la información que nos facilita con el fin de
          prestarles el servicio solicitado y realizar su facturación. Los datos
          proporcionados se conservarán mientras se mantenga la relación comercial o
          durante el tiempo necesario para cumplir con las obligaciones legales y
          atender las posibles responsabilidades que pudieran derivar del
          cumplimiento de la finalidad para la que los datos fueron recabados. Los
          datos no se cederán a terceros salvo en los casos en que exista una
          obligación legal. Usted tiene derecho a obtener información sobre si en
          micro2e estamos tratando sus datos personales, por lo que puede ejercer sus
          derechos de acceso, rectificación, supresión y portabilidad de datos y
          oposición y limitación a su tratamiento ante micro2e, C/ Pepita Samper, 3-7
          46006 Valencia o en la dirección de correo electrónico{' '}
          <a
            href='/cdn-cgi/l/email-protection'
            class='__cf_email__'
            data-cfemail='244d4a424b64494d47564b16410a474b49'
          >
            [email&#160;protected]
          </a>
          , adjuntando copia de su DNI o documento equivalente. Asimismo, y
          especialmente si considera que no ha obtenido satisfacción plena en el
          ejercicio de sus derechos, podrá presentar una reclamación ante la
          autoridad nacional de control dirigiéndose a estos efectos a la Agencia
          Española de Protección de Datos, C/ Jorge Juan, 6 – 28001 Madrid.
        </p>
        <p>
          Asimismo, durante el proceso de alta solicitamos su autorización para
          ofrecerle productos y servicios relacionados con los contratados y
          fidelizarle como cliente.
        </p>

        <h5
        // style='font-size:100%;'
        >
          X. PROPIEDAD INTELECTUAL E INDUSTRIAL
        </h5>
        <p>
          Las aplicaciones que permiten el uso de Sisritel, su programación, edición,
          compilación y demás elementos necesarios para su funcionamiento, los
          diseños, logotipos, texto y/o gráficos son propiedad de micro2e o en su
          caso dispone de licencia o autorización expresa por parte de los autores.
          Los iconos fontawesome utilizados pertenecen a Fonticons, Inc y se usan
          bajo licencia "Creative Commons Attribution 4.0 International"
        </p>
        <p>
          Todos los contenidos se encuentran debidamente protegidos por la normativa
          de propiedad intelectual e industrial.
        </p>
        <p>
          Independientemente de la finalidad para la que fueran destinados, la
          reproducción total o parcial, uso, explotación, distribución y
          comercialización, requiere en todo caso de la autorización escrita previa
          por parte de micro2e. Cualquier uso no autorizado previamente por parte de
          micro2e será considerado un incumplimiento grave de los derechos de
          propiedad intelectual o industrial del autor.
        </p>
        <p>
          Los diseños, logotipos, texto y/o gráficos ajenos a micro2e y que pudieran
          aparecer en las aplicaciones, pertenecen a sus respectivos propietarios,
          siendo ellos mismos responsables de cualquier posible controversia que
          pudiera suscitarse respecto a los mismos. En todo caso, micro2e cuenta con
          la autorización expresa y previa por parte de estos.
        </p>
        <p>
          La legitimidad de los derechos de propiedad intelectual o industrial
          correspondientes a los contenidos aportados por terceros es de la exclusiva
          responsabilidad de los mismos.
        </p>
        <p>
          Para realizar cualquier tipo de observación respecto a posibles
          incumplimientos de los derechos de propiedad intelectual o industrial, así
          como sobre cualquiera de los contenidos de las aplicaciones, puede hacerlo
          a través del{' '}
          <a href='https://staging.sisritel.com/#contact'>
            formulario de contacto de la página de acceso
          </a>
        </p>

        <h5
        //  style='font-size:100%;'
        >
          XI. USO DE COOKIES
        </h5>
        <p>
          Una cookie es un pequeño fragmento de texto que los sitios o aplicaciones
          web que visita envían al navegador y que permite que el sitio web recuerde
          información sobre su visita, como su idioma preferido y otras opciones, con
          el fin de facilitar su próxima visita y hacer que el sitio le resulte más
          útil. Las cookies desempeñan un papel muy importante y contribuyen a tener
          una mejor experiencia de navegación para el usuario.
        </p>

        <p>
          La aplicación de Sisritel sólo utiliza cookies técnicas propias de
          identificación para mantener la sesión y de personalización para recordar
          opciones de navegación. No utiliza cookies de terceros, ni de análisis, ni
          cookies publicitarias o de publicidad comportamental.
        </p>

        <p>
          El uso de este tipo de cookies es imprescindible para la correcta ejecución
          de la aplicación web de Sisritel y no pueden ser modificadas. Con la
          aceptación de este contrato Vd. reconoce que queda informado y asume el uso
          de las mismas.
        </p>

        <p>
          Cualquier cambio en la Política de cookies de la aplicación será comunicado
          al usuario junto con la información pertinente sobre los derechos que le
          asisten.
        </p>

        <h5
        // style='font-size:100%;'
        >
          XII. LEGISLACIÓN APLICABLE Y JURISDICCIÓN
        </h5>
        <p>
          Para la resolución de todas las controversias o cuestiones relacionadas con
          la app web de Sisritel será de aplicación la legislación española. micro2e
          y el usuario, con renuncia expresa a cualquier otro fuero que pudiera
          corresponderles, se someten a la Jurisdicción de los Juzgados y Tribunales
          Valencia para cuantas cuestiones pudieran suscitarse o acciones ejercitarse
          derivadas de la prestación del servicio y sobre la interpretación,
          aplicación, cumplimiento o incumplimiento de lo aquí establecido.
        </p>

        <p>
          En el supuesto de que el usuario tenga su domicilio fuera de España,
          micro2e y el usuario, con renuncia expresa a cualquier otro fuero que
          pudiera corresponderles, se someten a la Jurisdicción de los Juzgados y
          Tribunales de VALENCIA.
        </p>
      </div>
    </>
  );
};

export default Contract;
