const textLineAnimation = () => {
  const elements = document.querySelectorAll('.animated');
  elements.forEach((element) => {
    if (element.scrollWidth > element.clientWidth) {
      element.classList.add('overflowing');
    } else {
      element.classList.remove('overflowing');
    }
  });
};

export default textLineAnimation;
