import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ModalGenerico from './Modal_generico';
import AvisoLegal from './Modals/AvisoLegal';
import moment from 'moment/moment';

const Footer = () => {
  const { t } = useTranslation();
  const [aviso, setAviso] = useState(false);

  return (
    <footer>
      <Container>
        <Row>
          <Col md={6}>
            <span className='copyright'>Copyright &copy; RedHator {moment().year()}</span>
          </Col>
          {/* <Col md={4}>
            <ul className='list-inline social-buttons'>
              <li className='list-inline-item'>
                <a
                  className='d-flex justify-content-center align-items-center'
                  href='https://twitter.com/'
                  target='_blank'
                  alt='twitter redhator'
                  rel='noreferrer'
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li className='list-inline-item'>
                <a
                  className='d-flex justify-content-center align-items-center'
                  href='https://www.facebook.com/'
                  target='_blank'
                  alt='facebook redhator'
                  rel='noreferrer'
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li className='list-inline-item'>
                <a
                  className='d-flex justify-content-center align-items-center'
                  href='https://www.youtube.com/channel/'
                  target='_blank'
                  alt='youtube redhator'
                  rel='noreferrer'
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
            </ul>
          </Col> */}
          <Col md={6}>
            <ul className='list-inline quicklinks'>
              <li className='list-inline-item'>
                <a
                  className='text-decoration-none'
                  data-toggle='modal'
                  href='#avisolegal'
                  onClick={() => setAviso(true)}
                >
                  {t('avisoLegal')}
                </a>
              </li>
            </ul>
            <ModalGenerico title={t('avisoLegal')} onClose={() => setAviso(false)} show={aviso}>
              <AvisoLegal closeModal={() => setAviso(false)} />
            </ModalGenerico>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
