/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalGenerico from '../../home/Modal_generico';
import MapSrt from './MapSrt';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';
import textLineAnimation from '../../../support_func/textLineAnimation';

const AdminIdri = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [idri, setIdri] = useState({});
  const [mapModal, setMapModal] = useState(false);
  const [coord, setCoord] = useState({ lat: '--', lng: '--' });
  const [status, setStatus] = useState(false);
  const markers = useSelector((state) => state.markers);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getAdminIdri = () => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/adminidri?ididri=${
          props.ididri && props.ididri
        }&idriserial=${props.ididri && props.idriserial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data.idri);
          let manDate = res.data.idri.manufacture_date
            ? res.data.idri.manufacture_date.split('/')
            : null;
          let discDate = res.data.idri.discount_end_date
            ? res.data.idri.discount_end_date.split('/')
            : null;
          let instDate = res.data.idri.installation_date
            ? res.data.idri.installation_date.split('/')
            : null;
          setIdri({
            ...res.data.idri,
            manufacture_date: manDate && `${manDate[2]}-${manDate[1]}-${manDate[0]}`,
            discount_end_date: discDate && `${discDate[2]}-${discDate[1]}-${discDate[0]}`,
            installation_date: instDate && `${instDate[2]}-${instDate[1]}-${instDate[0]}`,
          });
          setCoord({
            lat: parseFloat(res.data.idri.idricoor[0]),
            lng: parseFloat(res.data.idri.idricoor[1]),
          });

          Object.entries(markers).forEach(([key, idri]) => {
            if (idri.idriserial == res.data.idri.idriserial) {
              idri.suspended
                ? setStatus(t('usoSuspendido'))
                : // : idri.com_blocked
                // ? setStatus(t('usoComBlock'))
                !idri.inicialized || !idri.discharged
                ? setStatus(t('noInit'))
                : !idri.user_discharge
                ? setStatus(t('pendAlta'))
                : idri.last_read_error
                ? setStatus(t('ultConError'))
                : idri.operating
                ? setStatus(t('regando'))
                : idri.all_programs_disabled
                ? setStatus(t('progSuspendido'))
                : setStatus(t('accesible'));
            }
          });

          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  // console.log(idri);

  const postAdminIdri = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let manDate = idri.manufacture_date ? idri.manufacture_date.split('-') : null;
    let discDate = idri.discount_end_date ? idri.discount_end_date.split('-') : null;
    let instDate = idri.installation_date ? idri.installation_date.split('-') : null;
    let body = {
      ididri: idri.ididri,
      idriserial: idri.idriserial,
      control: idri.control,
      v_firmware: idri.v_firmware,
      v_subfirmware: idri.v_subfirmware,
      v_idri: idri.v_idri,
      idriname: idri.idriname,
      manufacture_date: manDate ? `${manDate[2]}/${manDate[1]}/${manDate[0]}` : '',
      manufactured_by: idri.manufactured_by,
      user_discharge: idri.user_discharge,
      suspended: idri.suspended,
      suspended_reason: idri.suspended_reason,
      withdrawn: idri.withdrawn,
      withdrawn_reason: idri.withdrawn_reason,
      discharged: idri.discharged,
      inicialized: idri.inicialized,
      // activated: idri.activated,
      fee: idri.fee,
      discount: idri.discount,
      discount_end_date: discDate ? `${discDate[2]}/${discDate[1]}/${discDate[0]}` : '',
      invoicing: idri.invoicing,
      installation_date: instDate ? `${instDate[2]}/${instDate[1]}/${instDate[0]}` : '',
      idricoor: [coord.lat, coord.lng],
    };
    console.log(body);

    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/adminidri`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setError(t('modificated'));
          getAdminIdri();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    getAdminIdri();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  useEffect(() => {
    textLineAnimation();
  });
  return (
    <Form onSubmit={(e) => postAdminIdri(e)}>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}
      <Card border='dark' className='mb-2'>
        <Row className='text-center py-1'>
          <Col
            xs={12}
            style={{
              color: Object.values(markers)
                .map((m) => {
                  if (m.idriserial == idri.idriserial) {
                    return m.suspended
                      ? '#000000'
                      : !m.inicialized || !m.discharged
                      ? '#777777'
                      : !m.user_discharge
                      ? '#ff8533'
                      : m.last_read_error
                      ? '#f00'
                      : m.operating
                      ? '#0099ff'
                      : m.all_programs_disabled
                      ? '#a64dff'
                      : '#00b300';
                  }
                })
                .find((value) => {
                  return value;
                }),
            }}
          >
            <span className=''>{status}</span>
          </Col>
        </Row>
      </Card>
      <Row className='px-0 '>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('idriId')}</strong>
          <Form.Control type='text' className='text-center' disabled value={idri.ididri} />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('idriSerial')}</strong>
          <Form.Control type='text' className='text-center' disabled value={idri.idriserial} />
        </Col>
        <Col xs={12} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('control')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.control}
            onChange={(e) =>
              setIdri({
                ...idri,
                control: e.target.value,
              })
            }
          />
        </Col>
      </Row>
      <Row className='px-0 '>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('vFirmware')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.v_firmware}
            onChange={(e) =>
              setIdri({
                ...idri,
                v_firmware: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('vSubFirmware')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.v_subfirmware}
            onChange={(e) =>
              setIdri({
                ...idri,
                v_subfirmware: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('vIdri')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.v_idri}
            onChange={(e) =>
              setIdri({
                ...idri,
                v_idri: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('idriName')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.idriname}
            onChange={(e) =>
              setIdri({
                ...idri,
                idriname: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('manufactureDate')}</strong>
          <Form.Control
            type='date'
            className='text-center'
            value={idri.manufacture_date}
            onChange={(e) =>
              setIdri({
                ...idri,
                manufacture_date: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('manufacturedBy')}</strong>
          <Form.Control
            type='text'
            className='text-center'
            value={idri.manufactured_by}
            onChange={(e) =>
              setIdri({
                ...idri,
                manufactured_by: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('installationDate')}</strong>
          <Form.Control
            type='date'
            className='text-center'
            // lang='fr'
            value={idri.installation_date}
            onChange={(e) =>
              setIdri({
                ...idri,
                installation_date: e.target.value,
              })
            }
          />
        </Col>
      </Row>
      <Row className='px-0 '>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('userDischarge')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.user_discharge}
            onChange={(e) =>
              setIdri({
                ...idri,
                user_discharge: e.target.checked,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('discharged')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.discharged}
            onChange={(e) =>
              setIdri({
                ...idri,
                discharged: e.target.checked,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('inicializado')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.inicialized}
            onChange={(e) =>
              setIdri({
                ...idri,
                inicialized: e.target.checked,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('activado')}</strong>
          <Form.Check
            disabled
            type='checkbox'
            checked={idri.activated}
            // onChange={(e) =>
            //   setIdri({
            //     ...idri,
            //     activated: e.target.checked,
            //   })
            // }
          />
        </Col>
      </Row>
      <Row className='px-0 '>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('invoicing')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.invoicing}
            onChange={(e) =>
              setIdri({
                ...idri,
                invoicing: e.target.checked,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('fee')}</strong>
          <Form.Control
            type='number'
            className='text-center'
            value={idri.fee}
            onChange={(e) =>
              setIdri({
                ...idri,
                fee: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('discount')}</strong>
          <Form.Control
            type='number'
            className='text-center'
            value={idri.discount}
            onChange={(e) =>
              setIdri({
                ...idri,
                discount: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={6} md={3} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('discountEndDate')}</strong>
          <Form.Control
            type='date'
            className='text-center'
            value={idri.discount_end_date ? idri.discount_end_date : ''}
            onChange={(e) =>
              setIdri({
                ...idri,
                discount_end_date: e.target.value,
              })
            }
          />
        </Col>
      </Row>
      <Row className='px-0 '>
        <Col xs={6} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('suspended')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.suspended}
            onChange={(e) =>
              setIdri({
                ...idri,
                suspended: e.target.checked,
              })
            }
          />
          <Col xs={12} className='animated text-truncate '>
            <strong>{t('suspendedReason')}</strong>
            <Form.Control
              as='textarea'
              style={{ height: '40px' }}
              type='text'
              className='text-center'
              value={idri.suspended_reason}
              onChange={(e) =>
                setIdri({
                  ...idri,
                  suspended_reason: e.target.value,
                })
              }
            />
          </Col>
        </Col>
        <Col xs={6} className='animated text-truncate px-1 px-md-3'>
          <strong>{t('withdrawn')}</strong>
          <Form.Check
            type='checkbox'
            checked={idri.withdrawn}
            onChange={(e) =>
              setIdri({
                ...idri,
                withdrawn: e.target.checked,
              })
            }
          />
          <Col xs={12} className='animated text-truncate '>
            <strong>{t('withdrawnReason')}</strong>
            <Form.Control
              as='textarea'
              style={{ height: '40px' }}
              type='text'
              className='text-center'
              value={idri.withdrawn_reason}
              onChange={(e) =>
                setIdri({
                  ...idri,
                  withdrawn_reason: e.target.value,
                })
              }
            />
          </Col>
        </Col>
      </Row>
      <Row className='px-0 '>
        <Col xs={12}>
          <strong>{t('idriCoor')}</strong>
          <Row>
            <Col xs={3} className=''>
              {t('latitud')}: {typeof coord.lat == 'string' ? '--' : coord.lat.toFixed(5)}
            </Col>
            <Col xs={3} className=''>
              {t('longitud')}: {typeof coord.lng == 'string' ? '--' : coord.lng.toFixed(5)}
            </Col>
            <Col xs={6} className='d-grid '>
              <Button
                type='button'
                className='  py-1 py-md-1 dark'
                data-toggle='modal'
                onClick={() => setMapModal(true)}
              >
                {t('ubicacion').toLowerCase()}
              </Button>
            </Col>
          </Row>
          <ModalGenerico
            className='bg-secondary'
            title={t('pulsaParaCoords')}
            onClose={() => setMapModal(false)}
            show={mapModal}
          >
            <MapSrt
              coords={coord}
              setCoord={setCoord}
              setMapModal={setMapModal}
              // closeModal={() => setMapModal(false)}
            />
          </ModalGenerico>
        </Col>
      </Row>

      <Row className='text-center mt-3'>
        <Col>
          <Button type='submit' size='lg' className=' py-md-1 dark'>
            {t('modificar').toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminIdri;
