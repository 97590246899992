/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import isValidNif from '../../../support_func/isValidNif';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const Invocing = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [invoiceData, setInvoiceData] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getInvoice = () => {
    setIsPending(true);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/userinvoicedata?iduser=${
          props.iduser ? props.iduser : parseInt(localStorage.getItem('id'))
        }&email=${props.email ? props.email : localStorage.getItem('email')}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setInvoiceData(res.data.user_invoice_data.data);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postInvoice = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      iduser: props.iduser ? props.iduser : parseInt(localStorage.getItem('id')),
      email: props.email ? props.email : localStorage.getItem('email'),
      data: { ...invoiceData },
    };
    console.log(body);
    if (
      body.data.address &&
      body.data.zip &&
      body.data.city &&
      body.data.province &&
      body.data.country
    ) {
      if (isValidNif(body.data.nif)) {
        axios
          .post(`${process.env.REACT_APP_API_SRT}/v2/userinvoicedata`, body, config)
          .then((res) => {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.status === 'ok') {
              setError(t('modificated'));
              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('errorMensaje'));
            }
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setError(t('nifIncorecto'));
        setIsPending(false);
      }
    } else {
      setError(t('address'));
      setIsPending(false);
    }
  };

  const deleteInvoice = (e) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_SRT}/v2/userinvoicedata?iduser=${
          props.iduser ? props.iduser : parseInt(localStorage.getItem('id'))
        }&email=${props.email ? props.email : localStorage.getItem('email')}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setInvoiceData({});
          setError(t('deleted'));
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <>
      <Row>
        <h5>{t('invoicingMod')}</h5>
      </Row>
      <Form
        onSubmit={(e) => {
          postInvoice(e);
        }}
      >
        <Row className='px-0'>
          <Col xs={12} md={6}>
            <strong>{t('nombre').toLowerCase()}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={150}
              value={invoiceData.name ? invoiceData.name : ''}
              onChange={(e) => setInvoiceData({ ...invoiceData, name: e.target.value })}
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('nif')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              // maxLength={50}
              value={invoiceData.nif ? invoiceData.nif : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  nif: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={12}>
            <strong>{t('direccion')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={200}
              value={invoiceData.address ? invoiceData.address : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  address: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('zip')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={20}
              value={invoiceData.zip ? invoiceData.zip : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  zip: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('city')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={50}
              value={invoiceData.city ? invoiceData.city : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  city: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('province')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={50}
              value={invoiceData.province ? invoiceData.province : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  province: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('country')}</strong>
            <Form.Control
              required
              type='text'
              className='text-center'
              maxLength={50}
              value={invoiceData.country ? invoiceData.country : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  country: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('invoicingEmail')}</strong>
            <Form.Control
              required
              type='email'
              className='text-center'
              // maxLength={50}
              value={invoiceData.email ? invoiceData.email : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  email: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('invoicingPhone')}</strong>
            <Form.Control
              required
              type='phone'
              className='text-center'
              maxLength={30}
              value={invoiceData.phone ? invoiceData.phone : ''}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  phone: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <Row className='text-center mt-3'>
          <Col>
            <Button
              type='submit'
              // variant='primary'
              size='lg'
              className='py-md-1 dark'
              // onClick={(e) => postinvoiceData(e, newinvoiceData)}
            >
              {t('enviar').toUpperCase()}
            </Button>
          </Col>
          {props.delInvoice && (
            <Col>
              <Button
                disabled={'editInvoice' in props && !props.editInvoice}
                variant='danger'
                size='lg'
                className='py-md-1'
                onClick={(e) => (window.confirm(t('seguro')) ? deleteInvoice(e) : null)}
              >
                {t('delInvoice').toUpperCase()}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Invocing;
