/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Popover, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGED, IDRI } from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import scaleDecimal from '../../../../support_func/scaleDecimal';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import { BiLink } from 'react-icons/bi';
import { BsSpeedometer } from 'react-icons/bs';
import scrollToElement from '../../../../support_func/scrollToElement';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ActuatorsControl = (props) => {
  const { t } = useTranslation();
  // const token = localStorage.getItem('autoAcceso');
  // const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const dispatch = useDispatch();
  //redux hooks
  const idriData = useSelector((state) => state.idriData);
  const idri = useSelector((state) => state.idri);
  const changed = useSelector((state) => state.changed);

  // const [autoUse, setAutoUse] = useState(false);
  const [autoUseDis, setAutoUseDis] = useState(false);
  const [localTime, setLocalTime] = useState(null);
  const [act, setAct] = useState({});
  // const [gapCheck, setGapCheck] = useState(false);
  const [masterMsg, setMasterMsg] = useState(idri.master_en);
  const [linked, setLinked] = useState([]);
  const [slaves, setSlaves] = useState([]);

  const extraGap = parseInt(process.env.REACT_APP_EXTRA_GAP) ?? 5;

  //actuators handlers
  const openHandler = (id, status, newInst, flow) => {
    // console.log(act);
    // eslint-disable-next-line array-callback-return
    Object.values(act).map((v) => {
      if (v.idactuator === id) {
        v.status = status === true ? 1 : 0;
        v.volumeAsoc = '';
        v.flow = '';
        if (status === false) {
          v.duration = newInst ? { h: 0, m: 0, s: 0 } : '';
          v.volumeAsoc = '';
          v.flow = '';
          setAct([...act]);
          props.setAct([...act]);
        } else {
          v.duration = newInst ? { h: 0, m: 0, s: 0 } : '';
          setAct([...act]);
          props.setAct([...act]);
        }
      }
    });
    // console.log(act);
    //actuator change checking
    if (!changed.test || changed.test === false) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, test: true },
      });
    }

    scrollToElement(id, status, 'center', 300);
  };
  const openTimeHandler = (id, value, hms) => {
    // console.log(value);

    let min = 1;
    let max = 255;
    let time = null;
    if (value !== '') {
      time = Math.max(min, Math.min(max, Number(value)));
    }
    Object.values(act).map((v) => {
      if (v.idactuator === id) {
        switch (hms) {
          case 'h':
            v.duration.h = value === '' ? 0 : value;
            break;
          case 'm':
            v.duration.m = value === '' ? 0 : value;
            break;
          case 's':
            v.duration.s = value === '' ? 0 : value;
            break;

          default:
            break;
        }
        !hms && (v.duration = time);
        setAct([...act]);
        props.setAct([...act]);
      }
    });
  };
  const volumeHandler = (id, value, idAsoc) => {
    const strValue = String(value).split('.');
    if (strValue[1] && strValue[1].length > scaleDecimal(checkScale(idAsoc))) {
      Object.values(act).map((v) => {
        if (v.idactuator === id) {
          v.volumeAsoc = isNaN(value)
            ? ''
            : parseFloat(value).toFixed(scaleDecimal(checkScale(idAsoc)));
          setAct([...act]);
          props.setAct([...act]);
        }
      });
    } else {
      Object.values(act).map((v) => {
        if (v.idactuator === id) {
          v.volumeAsoc = isNaN(value) ? '' : value;
          setAct([...act]);
          props.setAct([...act]);
        }
      });
    }
  };
  const flowHandler = (id, value, idAsoc) => {
    const strValue = String(value).split('.');
    if (strValue[1] && strValue[1].length > scaleDecimal(checkScale(idAsoc))) {
      Object.values(act).map((v) => {
        if (v.idactuator === id) {
          v.flow = isNaN(value) ? '' : parseFloat(value).toFixed(scaleDecimal(checkScale(idAsoc)));
          setAct([...act]);
          props.setAct([...act]);
        }
      });
    } else {
      Object.values(act).map((v) => {
        if (v.idactuator === id) {
          v.flow = isNaN(value) ? '' : value;
          setAct([...act]);
          props.setAct([...act]);
        }
      });
    }
  };

  const closeHandler = (id, noReload) => {
    let [result] = idri.actuators.filter((obj) => {
      return obj.idactuator === id;
    });
    result.status = result.status === 0 ? 1 : 0;
    // result.status_change_time = 0;
    result.status_change_date = null;
    dispatch({
      type: IDRI,
      payload: { ...idri },
    });
    openHandler(id, !act.find((x) => x.idactuator === id).status);

    let isBelow = (currentValue) => 0 < currentValue && currentValue < 2;

    if (parseFloat(idri.ghatorv) < 3) {
      if (
        idri.actuators &&
        Object.values(idri.actuators)
          .map(
            (obj) => obj.status_change_date && (moment(obj.status_change_date) - moment()) / 60000
          )
          .some(isBelow) === false
      ) {
        idri.wkmode === 0 ? props.refresh() : props.getIdri();
      }
    } else {
      let changeTime = Object.values(idri.actuators)
        .map((obj) => {
          if (obj.idactuator === id) {
            return obj.status_change_time;
          }
        })
        .find((value) => {
          return value;
        });

      let refresh = true;
      let sameChangeTimeId = [];

      Object.values(idri.actuators).forEach((obj) => {
        if (obj.status_change_time && obj.idactuator !== id) {
          if (obj.status_change_time - changeTime < 60 && obj.status_change_time - changeTime > 0) {
            refresh = false;
          } else if (obj.status_change_time - changeTime === 0) {
            sameChangeTimeId.push(obj.idactuator);
          }
        }
      });
      sameChangeTimeId.push(id);
      sameChangeTimeId.sort((a, b) => a - b);
      if (sameChangeTimeId[sameChangeTimeId.length - 1] !== id) {
        refresh = false;
      }
      console.log('reload');
      console.log(!noReload && refresh);
      !noReload && refresh && (setTimeout(function() {idri.wkmode === 0 ? props.refresh() : props.getIdri()}, 500));
    }
  };

  const permanentHandler = (id, value) => {
    Object.values(act).map((v) => {
      if (v.idactuator === id) {
        v.permanent = value;
        setAct([...act]);
        props.setAct([...act]);
      }
    });
  };
  const findActuator = (id) => {
    let actuator = act.find((x) => x.idactuator === id);
    return actuator;
  };

  useEffect(() => {
    let arr = [];
    idri.actuators &&
      idri.actuators.map((actuador) => {
        actuador.is_master === true && arr.push(true);
      });
    arr.length > 0 ? setAutoUseDis(false) : setAutoUseDis(true);
  }, [act, idri]);
  //counters check
  const contadores = [];
  idriData.kindsensors &&
    Object.entries(idriData.kindsensors).map(
      ([key, value]) => value.kind_code === 3 && contadores.push(key)
    );

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
    msg !== null && window.alert(msg);
    setMsg(null);
  }, [error, msg]);

  const checkAsoc = (idAsoc) => {
    return Object.values(idri.sensors.fm_sensors)
      .map((sensor) => {
        if (sensor.idsensor === idAsoc) {
          return sensor.magnitude;
        }
      })
      .find((value) => {
        return value;
      });
  };
  const checkScale = (idAsoc) => {
    return Object.values(idri.sensors.fm_sensors)
      .map((sensor) => {
        if (sensor.idsensor === idAsoc) {
          return sensor.scale;
        }
      })
      .find((value) => {
        return value;
      });
  };

  const masterGapCheck = (id) => {
    return (
      idri.master_en === true &&
      Object.values(idri.actuators)
        .map((v) => {
          if (
            v.is_master &&
            v.slaves.length > 0 &&
            v.slaves.includes(id)
            //  &&
            // v.gap_master > parseInt(sec)
          ) {
            return Object.values(act)
              .map((a) => {
                if (a.idactuator === id) {
                  if (
                    parseInt(a.duration.h) * 60 * 60 +
                      parseInt(a.duration.m) * 60 +
                      parseInt(a.duration.s) <
                    v.gap_master + extraGap
                  ) {
                    return v.gap_master + extraGap;
                  }
                }
              })
              .find((value) => {
                return value;
              });
          }
        })
        .find((value) => {
          return value;
        })
    );
  };

  const countDuration = (id) => {
    return Object.values(act)
      .map((a) => {
        if (a.idactuator === id) {
          return (
            parseInt(a.duration.h) * 60 * 60 + parseInt(a.duration.m) * 60 + parseInt(a.duration.s)
          );
        }
      })
      .find((value) => {
        return value;
      });
  };

  const volumeCheck = (id) => {
    return Object.values(act)
      .map((a) => {
        if (a.idactuator === id) {
          return a.volumeAsoc;
        }
      })
      .find((value) => {
        return value;
      });
  };

  const flowCheck = (id) => {
    return Object.values(act)
      .map((a) => {
        if (a.idactuator === id) {
          return a.flow;
        }
      })
      .find((value) => {
        return value;
      });
  };

  // console.log(act);
  // console.log(countDuration(497));
  // const [, forceUpdate] = useState(0);

  useEffect(() => {
    parseFloat(idri.ghatorv) >= 3
      ? setAct(
          idri.ghatorserial
            ? idri.actuators.map((actuator) => ({
                idactuator: actuator.idactuator,
                status: actuator.status,
                is_master: actuator.is_master,
                duration: { h: 0, m: 0, s: 0 },
                volumeAsoc: '',
                flow: '',
                permanent:
                  actuator.status === 0
                    ? false
                    : (actuator.status === 1 &&
                        actuator.status_change_time === null &&
                        Object.keys(actuator.fluid).length > 0 &&
                        actuator.fluid.status_change_afmv_remain === null &&
                        actuator.fluid.status_change_afv_remain === null) ||
                      (actuator.status === 1 &&
                        actuator.status_change_time === null &&
                        Object.keys(actuator.fluid).length === 0)
                    ? true
                    : false,
              }))
            : []
        )
      : setAct(
          idri.ghatorserial
            ? idri.actuators.map((actuator) => ({
                idactuator: actuator.idactuator,
                status: actuator.status,
                minutes: '',
              }))
            : []
        );
    // setAct(props.act);
    // forceUpdate((n) => !n);
    // console.log(act);
    setLocalTime(new Date());
    props.setAutoUse(idri.master_en);
    let related = [];
    idri.sensors.fm_sensors.forEach((s) => {
      s.related_actuators.forEach((a) =>
        related.push({ [a.idactuator]: { idsensor: s.idsensor, ninput: s.ninput, name: s.name, cmax_day_alert: s.alerts.cmax_day_alert } })
      );
    });
    setLinked(related.flat());

    let masterSlaves = [];
    idri.actuators.forEach((a) => {
      a.slaves.forEach((s) =>
        masterSlaves.push({ [s]: { idactuator: a.idactuator, noutput: a.noutput, name: a.name } })
      );
    });
    setSlaves(masterSlaves.flat());
  }, []);

  useEffect(() => {
    textLineAnimation();
  }); // console.log(idri);
  // console.log(act);

  // console.log(masterGapCheck(497));

  const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
}) => (condition ? wrapper(children) : children);

  return (
    <>
      {idri && idri.actuators && parseFloat(idri.ghatorv) >= 3 && Object.keys(act).length > 0 && (
        <tbody>
          {parseFloat(idri.ghatorv) >= 3 && !autoUseDis && (
            <tr className='lGray border border-1'>
              <td>
                <div className='d-flex justify-content-center'>
                  <Row className='text-center'>
                    <Col xs={12}>
                      <h6 className='m-0'>
                        {t('autoMaster')}:
                        {idri.master_en ? (
                          <span className='text-success ms-2'>{t('on')}</span>
                        ) : (
                          <span className='text-danger ms-2'>{t('off')}</span>
                        )}
                      </h6>
                    </Col>
                  </Row>
                </div>
              </td>
            </tr>
          )}
          <tr className='border border-1'>
            <th>
              <h5 className='text-center m-0'>{t('fluid')}</h5>
            </th>
          </tr>
          <tr className='border border-1 test'>
            <td className='border-0 p-0'>
              <Form id='actuatorsForm' onSubmit={(e) => props.handleSubmit(e)}>
                <Row className='gx-0 p-0'>
                  {idri.actuators.map(
                    (actuator) =>
                      actuator.kind.related_to === 'fluid' && (
                        <Col
                          xs={12}
                          md={6}
                          className='text-center p-0'
                          key={actuator.idactuator}
                          id={actuator.idactuator}
                          style={{ position: 'relative' }}
                        >
                          <Card border='dark' className='pb-2 ' style={{ height: '100%' }}>
                            <Card.Title className='d-flex justify-content-center mb-0'>
                              <span
                                className=' d-flex align-items-center text-truncate'
                                style={{ maxWidth: '65vw' }}
                              >
                                {actuator.name}
                              </span>
                              <Form.Check
                                inline
                                disabled
                                className='ms-1 me-0'
                                checked={
                                  actuator.kind.normal_state === false
                                    ? actuator.status === 0
                                      ? false
                                      : true
                                    : actuator.status === 0
                                    ? true
                                    : false
                                }
                                type='radio'
                                id='actuatorCheck'
                              />
                              <div className='mark'>
                                {actuator.kind.normal_state === false
                                  ? ' ' + t('close')
                                  : ' ' + t('open')}
                              </div>
                            </Card.Title>
                            <Card.Title className='m-0 d-flex justify-content-center'>
                              {linked.find((a) => a[actuator.idactuator] !== undefined) && (
                                <Col className='colorOrange'>
                                  <BsSpeedometer className='m-1' />
                                  {
                                    linked.find((a) => a[actuator.idactuator] !== undefined)[
                                      actuator.idactuator
                                    ].name
                                  }
                                </Col>
                              )}
                              {props.autoUse === true &&
                                slaves.find((s) => s[actuator.idactuator] !== undefined) && (
                                  <Col className='colorDarkOrange'>
                                    <BiLink className='m-1 me-0' />
                                    {
                                      slaves.find((s) => s[actuator.idactuator] !== undefined)[
                                        actuator.idactuator
                                      ].name
                                    }
                                  </Col>
                                )}
                            </Card.Title>

                            {actuator.is_master === true &&
                            // idri.master_en === false &&
                            (props.autoUse === true || idri.master_en) ? (
                              <>
                                <Row className='my-0 px-1'>
                                  <p className='text-danger dangerAnimation my-0'>{t('autoOC')}</p>
                                  <p className=''>
                                    {t('actuador')}{' '}
                                    {actuator.kind.normal_state === false
                                      ? t('abrira')
                                      : t('cerrara')}{' '}
                                    <span className='fw-bold'>{actuator.gap_master} </span>
                                    {t('segundosDespues')}{' '}
                                    {actuator.kind.normal_state === false
                                      ? t('cerrara')
                                      : t('abrira')}{' '}
                                    <span className='fw-bold'>{actuator.gap_master} </span>{' '}
                                    {t('segundosAntes')}
                                  </p>
                                </Row>

                                {actuator.waitingxchange &&
                                  actuator.status_change_time > 0 &&
                                  masterMsg && (
                                    <>
                                      <Col className='m-0 text-danger timer'>
                                        <p className=' text-dark m-0'>
                                          {actuator.kind.normal_state === true
                                            ? t('closing')
                                            : t('opening')}{' '}
                                          {t('in')}
                                        </p>
                                        <Timer
                                          hrs={moment
                                            .duration(
                                              actuator.status_change_time -
                                                (new Date() - localTime) / 1000,
                                              'seconds'
                                            )
                                            .asHours()}
                                          min={moment
                                            .duration(
                                              actuator.status_change_time -
                                                (new Date() - localTime) / 1000,
                                              'seconds'
                                            )
                                            .minutes()}
                                          sec={moment
                                            .duration(
                                              actuator.status_change_time -
                                                (new Date() - localTime) / 1000,
                                              'seconds'
                                            )
                                            .seconds()}
                                          close={() => {
                                            setMasterMsg(false);
                                            closeHandler(actuator.idactuator, true);
                                          }}
                                        />
                                      </Col>
                                    </>
                                  )}
                              </>
                            ) : (
                              <div>
                                <div className='position-relative'>
                                  {actuator.kind.normal_state === false && (
                                    <>
                                    <ConditionalWrapper
                                      condition={(actuator.status === 1 &&
                                        findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                        (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                        linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)}
                                      wrapper={children => (
                                        <OverlayTrigger
                                            overlay={(actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")
                                              ? 
                                              <Tooltip>{t('tooltipActRule1')} r{actuator.who_change}</Tooltip>
                                              : (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                              linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)
                                              ?
                                              <Tooltip>
                                                {t('tooltipActCmax')} {(linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].name)}
                                              </Tooltip>
                                              : ""
                                            }
                                            placement='top'
                                            trigger={['click', 'focus']}
                                        >
                                          {children}
                                        </OverlayTrigger>
                                      )}
                                    >
                                      <div>
                                        <Form.Check
                                          type='switch'
                                          id={`custom-switch${actuator.idactuator}`}
                                          checked={findActuator(actuator.idactuator).status}
                                          disabled={(actuator.status === 1 &&
                                            findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                            (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                            linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)}
                                          onChange={(e) => {
                                            openHandler(
                                              actuator.idactuator,
                                              e.target.checked,
                                              true,
                                              true
                                            );
                                          }}
                                        />                                        
                                        <span
                                          className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none'
                                          onClick={() =>
                                            !((actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                              (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                              linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)) ?
                                            openHandler(
                                              actuator.idactuator,
                                              !findActuator(actuator.idactuator).status,
                                              true,
                                              true
                                            ) : undefined
                                          }
                                        >
                                          {actuator &&
                                          actuator.status === 0 &&
                                          findActuator(actuator.idactuator).status === 0
                                            ? t('cerrado')
                                            : actuator.status === 0 &&
                                              findActuator(actuator.idactuator).status === 1
                                            ? t('abrir')
                                            : actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1
                                            ? t('abierto') + (actuator.which_change === "1" ? " (p" + actuator.who_change + ")" : 
                                                              actuator.which_change === "3" ? " (r" + actuator.who_change + ")" : "")
                                            : t('cerrar').toLowerCase()}
                                        </span>
                                        
                                      </div>
                                    </ConditionalWrapper>
                                    </>
                                  )}
                                  {actuator.kind.normal_state === true && (
                                    <>
                                    <ConditionalWrapper
                                      condition={(actuator.status === 1 &&
                                        findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                        (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                        linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)}
                                      wrapper={children => (
                                        <OverlayTrigger
                                            overlay={(actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")
                                              ? 
                                              <Tooltip>{t('tooltipActRule1')} r{actuator.who_change}</Tooltip>
                                              : (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                              linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)
                                              ?
                                              <Tooltip>
                                                {t('tooltipActCmax')} {(linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].name)}
                                              </Tooltip>
                                              : ""
                                            }
                                            placement='top'
                                            trigger={['click', 'focus']}
                                        >
                                          {children}
                                        </OverlayTrigger>
                                      )}
                                    >
                                      <div>
                                        <Form.Check
                                          type='switch'
                                          id={`custom-switch${actuator.idactuator}`}
                                          checked={
                                            findActuator(actuator.idactuator).status === 0
                                              ? true
                                              : false
                                          }
                                          disabled={(actuator.status === 1 &&
                                            findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                            (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                            linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)}
                                          onChange={(e) => {
                                            openHandler(actuator.idactuator, !e.target.checked, true);
                                          }}
                                        />                                        
                                        <span
                                          className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none'
                                          onClick={() =>
                                            !((actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                              (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                              linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)) ?
                                            openHandler(
                                              actuator.idactuator,
                                              !findActuator(actuator.idactuator).status,
                                              true
                                            ) : undefined
                                          }
                                        >
                                          {actuator.status === 0 &&
                                          findActuator(actuator.idactuator).status === 0
                                            ? t('abierto')
                                            : actuator.status === 0 &&
                                              findActuator(actuator.idactuator).status === 1
                                            ? t('cerrar').toLowerCase()
                                            : actuator.status === 1 &&
                                              findActuator(actuator.idactuator).status === 1
                                            ? t('cerrado') + (actuator.which_change === "1" ? " (p" + actuator.who_change + ")" : 
                                            actuator.which_change === "3" ? " (r" + actuator.who_change + ")" : "")
                                            : t('abrir')}
                                        </span>
                                      </div>
                                    </ConditionalWrapper>
                                    </>
                                  )}
                                </div>
                                <Row>
                                  {actuator.fluid.status_change_afmv_remain && (
                                    <Col
                                      xs={{
                                        span: actuator.fluid.status_change_afv_remain ? 6 : 12,
                                        order: 2,
                                      }}
                                      md={
                                        actuator.status_change_time &&
                                        actuator.fluid.status_change_afv_remain
                                          ? 6
                                          : !actuator.status_change_time &&
                                            actuator.fluid.status_change_afv_remain
                                          ? 6
                                          : 12
                                      }
                                    >
                                      <div className='animated text-truncate'>
                                        <strong>{t('contadorAsociado')}</strong>
                                      </div>
                                      <p className='mb-0'>
                                        {t('accumulated')}:
                                        {' ' +
                                          actuator.fluid.afmv_acum_last_change +
                                          checkAsoc(actuator.fluid.related_fm.idsensor)}
                                      </p>
                                      <p className='mb-0'>
                                        {t('remaining')}:
                                        {' ' +
                                          actuator.fluid.status_change_afmv_remain +
                                          checkAsoc(actuator.fluid.related_fm.idsensor)}
                                      </p>
                                    </Col>
                                  )}

                                  {/* {actuator.status_change_time && ( */}
                                  <Col
                                    xs={{ span: 12, order: 1 }}
                                    // md={{
                                    //   span:
                                    //     actuator.fluid.status_change_afmv_remain &&
                                    //     actuator.fluid.status_change_afv_remain
                                    //       ? 12
                                    //       : actuator.fluid.status_change_afmv_remain ||
                                    //         actuator.fluid.status_change_afv_remain
                                    //       ? 6
                                    //       : 12,
                                    //   order:
                                    //     actuator.fluid.status_change_afmv_remain &&
                                    //     actuator.fluid.status_change_afv_remain
                                    //       ? 1
                                    //       : 2,
                                    // }}
                                    className='m-0 text-danger '
                                  >
                                    {!props.isPending &&
                                      (actuator.status === 0 &&
                                      actuator.status_change_time === null &&
                                      actuator.waitingxchange ? (
                                        <Col className=' text-dark '>
                                          {t('waiting')}{' '}
                                          {actuator.kind.normal_state === true
                                            ? t('toClose')
                                            : t('toOpen')}
                                        </Col>
                                      ) : actuator.status === 0 &&
                                        actuator.status_change_time > 0 &&
                                        actuator.waitingxchange ? (
                                        <div className='timer'>
                                          <Col className='text-dark'>
                                            {actuator.kind.normal_state === true
                                              ? t('closing')
                                              : t('opening')}{' '}
                                            {t('in')}
                                          </Col>
                                          <Timer
                                            hrs={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .asHours()}
                                            min={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .minutes()}
                                            sec={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .seconds()}
                                            close={() => closeHandler(actuator.idactuator)}
                                          />
                                        </div>
                                      ) : actuator.status === 1 &&
                                        actuator.status_change_time === null &&
                                        actuator.waitingxchange ? (
                                        <Col className=' text-dark '>
                                          {t('waiting')}{' '}
                                          {actuator.kind.normal_state === true
                                            ? t('toOpen')
                                            : t('toClose')}
                                        </Col>
                                      ) : actuator.status === 1 &&
                                        actuator.status_change_time > 0 &&
                                        actuator.waitingxchange ? (
                                        <div className='timer'>
                                          <Col className='text-dark'>
                                            {actuator.kind.normal_state === true
                                              ? t('opening')
                                              : t('closing')}{' '}
                                            {t('in')}
                                          </Col>
                                          <Timer
                                            hrs={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .asHours()}
                                            min={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .minutes()}
                                            sec={moment
                                              .duration(
                                                actuator.status_change_time -
                                                  (new Date() - localTime) / 1000,
                                                'seconds'
                                              )
                                              .seconds()}
                                            close={() => closeHandler(actuator.idactuator)}
                                          />
                                        </div>
                                      ) : (
                                        actuator.status === 1 &&
                                        actuator.status_change_time &&
                                        localTime !== null && (
                                          <div className='timer mt-2'>
                                            <Timer
                                              hrs={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .asHours()}
                                              min={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .minutes()}
                                              sec={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .seconds()}
                                              close={() => closeHandler(actuator.idactuator)}
                                            />
                                          </div>
                                        )
                                      ))}
                                  </Col>
                                  {/* )} */}
                                  {actuator.fluid.status_change_afv_remain && (
                                    <Col
                                      xs={{
                                        span: actuator.fluid.status_change_afmv_remain ? 6 : 12,
                                        order: 3,
                                      }}
                                      md={
                                        actuator.status_change_time &&
                                        actuator.fluid.status_change_afmv_remain
                                          ? 6
                                          : !actuator.status_change_time &&
                                            actuator.fluid.status_change_afmv_remain
                                          ? 6
                                          : 12
                                      }
                                    >
                                      {actuator.fluid.status_change_afv_remain ? (
                                        <>
                                          <span className='fw-bold'>{t('caudal')}</span>

                                          <p className='mb-0'>
                                            {t('accumulated')}:
                                            {' ' +
                                              actuator.fluid.afv_acum_last_change +
                                              checkAsoc(actuator.fluid.related_fm.idsensor)}
                                          </p>
                                          <p className='mb-0'>
                                            {t('remaining')}:
                                            {' ' +
                                              actuator.fluid.status_change_afv_remain +
                                              checkAsoc(actuator.fluid.related_fm.idsensor)}
                                          </p>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </Col>
                                  )}
                                </Row>

                                {actuator.kind.normal_state === false &&
                                  findActuator(actuator.idactuator).status === 1 && (
                                    <>
                                      {(actuator.status === 0 &&
                                        actuator.status_change_time === null &&
                                        actuator.waitingxchange) ||
                                      (actuator.status === 0 &&
                                        actuator.status_change_time > 0 &&
                                        actuator.waitingxchange) ||
                                      (actuator.status === 1 &&
                                        actuator.status_change_time === null &&
                                        actuator.waitingxchange) ||
                                      (actuator.status === 1 &&
                                        actuator.status_change_time > 0 &&
                                        actuator.waitingxchange) ||
                                      (actuator.status === 1 &&
                                        findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                        (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                        linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert)                                       
                                        
                                        ? null : (
                                        <>
                                          <Col
                                            className={`${
                                              actuator.status_change_time ? 'mt-0' : 'mt-2 mb-1'
                                            }`}
                                          >
                                            <Form.Check
                                              id='autoUse'
                                              type='switch'
                                              inline
                                              checked={findActuator(actuator.idactuator).permanent}
                                              style={{ transform: 'scale(1.2)' }}
                                              onChange={(e) => {
                                                permanentHandler(
                                                  actuator.idactuator,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                            {t('permOpen')}
                                          </Col>

                                          {findActuator(actuator.idactuator).permanent ===
                                            false && (
                                            <>
                                              <Row className='px-3 align-items-end'>
                                                <Col xs={4} className='px-0'>
                                                  <Col xs={12} className='px-0'>
                                                    {actuator.status === 0 ? (
                                                      <span>{t('durationClose')}</span>
                                                    ) : (
                                                      t('actualClose')
                                                    )}
                                                  </Col>
                                                </Col>
                                                <Col xs={8} className='px-1'>
                                                  <Row className='m-0 p-0'>
                                                    <Col xs={4} className='px-0'>
                                                      <span>{t('horas')}</span>
                                                      <Form.Control
                                                        className='text-center'
                                                        type='number'
                                                        min={1}
                                                        placeholder=''
                                                        value={
                                                          findActuator(actuator.idactuator)
                                                            .duration &&
                                                          findActuator(actuator.idactuator).duration
                                                            .h
                                                            ? findActuator(actuator.idactuator)
                                                                .duration.h === 0
                                                              ? ''
                                                              : findActuator(actuator.idactuator)
                                                                  .duration.h
                                                            : ''
                                                        }
                                                        onChange={(e) => {
                                                          openTimeHandler(
                                                            actuator.idactuator,
                                                            e.target.value,
                                                            'h'
                                                          );
                                                        }}
                                                        onBlur={(e) =>
                                                          document
                                                            .getElementById(
                                                              `sec_${actuator.idactuator}_1`
                                                            )
                                                            .reportValidity()
                                                        }
                                                      />
                                                    </Col>
                                                    <Col xs={4} className='px-0'>
                                                      <span>{t('minutos')}</span>
                                                      <Form.Control
                                                        className='text-center'
                                                        type='number'
                                                        placeholder=''
                                                        min={1}
                                                        value={
                                                          findActuator(actuator.idactuator)
                                                            .duration &&
                                                          findActuator(actuator.idactuator).duration
                                                            .m
                                                            ? findActuator(actuator.idactuator)
                                                                .duration.m === 0
                                                              ? ''
                                                              : findActuator(actuator.idactuator)
                                                                  .duration.m
                                                            : ''
                                                        }
                                                        onChange={(e) => {
                                                          openTimeHandler(
                                                            actuator.idactuator,
                                                            e.target.value,
                                                            'm'
                                                          );
                                                        }}
                                                        onBlur={(e) =>
                                                          document
                                                            .getElementById(
                                                              `sec_${actuator.idactuator}_1`
                                                            )
                                                            .reportValidity()
                                                        }
                                                      />
                                                    </Col>
                                                    <Col xs={4} className='px-0'>
                                                      <div className='text-truncate'>
                                                        <span>{t('segundos')}</span>
                                                      </div>
                                                      <Form.Control
                                                        className='text-center'
                                                        type='number'
                                                        id={`sec_${actuator.idactuator}_1`}
                                                        // required={
                                                        //   masterGapCheck(
                                                        //     actuator.idactuator
                                                        //   ) >=
                                                        //   countDuration(
                                                        //     actuator.idactuator
                                                        //   ) +
                                                        //     extraGap
                                                        //     ? true
                                                        //     : false
                                                        // }
                                                        min={
                                                          masterGapCheck(actuator.idactuator)
                                                            ? masterGapCheck(actuator.idactuator)
                                                            : 1
                                                        }
                                                        placeholder=''
                                                        value={
                                                          findActuator(actuator.idactuator)
                                                            .duration &&
                                                          findActuator(actuator.idactuator).duration
                                                            .s
                                                            ? findActuator(actuator.idactuator)
                                                                .duration.s === 0
                                                              ? ''
                                                              : findActuator(actuator.idactuator)
                                                                  .duration.s
                                                            : ''
                                                        }
                                                        onChange={(e) => {
                                                          openTimeHandler(
                                                            actuator.idactuator,
                                                            e.target.value,
                                                            's'
                                                          );
                                                        }}
                                                        onBlur={(e) =>
                                                          !volumeCheck(actuator.idactuator) &&
                                                          !flowCheck(actuator.idactuator) &&
                                                          document
                                                            .getElementById(
                                                              `sec_${actuator.idactuator}_1`
                                                            )
                                                            .reportValidity()
                                                        }
                                                      />
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                              <Row className='px-3 align-items-end'>
                                                <Col xs={4} className='px-0'>
                                                  <span>
                                                    {actuator.status === 0 ? (
                                                      <span>{t('volumen')} </span>
                                                    ) : (
                                                      t('volumenCierre')
                                                    )}
                                                    {'(' +
                                                      checkAsoc(
                                                        actuator.fluid.related_fm.idsensor
                                                      ) +
                                                      ')'}
                                                  </span>
                                                </Col>
                                                <Col xs={8}>
                                                  <Row className='px-1'>
                                                    <Col
                                                      xs={actuator.fluid.flow ? 6 : 12}
                                                      className='px-0'
                                                    >
                                                      <div className='animated text-truncate'>
                                                        <strong className='fw-normal'>
                                                          {t('contadorAsociado')}
                                                        </strong>
                                                      </div>
                                                      <Form.Control
                                                        className='text-center'
                                                        type='number'
                                                        min={checkScale(
                                                          actuator.fluid.related_fm.idsensor
                                                        )}
                                                        // max='255'
                                                        step={checkScale(
                                                          actuator.fluid.related_fm.idsensor
                                                        )}
                                                        value={
                                                          findActuator(actuator.idactuator)
                                                            .volumeAsoc === isNaN
                                                            ? ''
                                                            : findActuator(actuator.idactuator)
                                                                .volumeAsoc
                                                        }
                                                        onChange={(e) => {
                                                          volumeHandler(
                                                            actuator.idactuator,
                                                            parseFloat(e.target.value),
                                                            actuator.fluid.related_fm.idsensor
                                                          );
                                                        }}
                                                        onBlur={(e) =>
                                                          e.target.value === ''
                                                            ? null
                                                            : e.target.value <
                                                                checkScale(
                                                                  actuator.fluid.related_fm.idsensor
                                                                ) &&
                                                              volumeHandler(
                                                                actuator.idactuator,
                                                                parseFloat(
                                                                  checkScale(
                                                                    actuator.fluid.related_fm
                                                                      .idsensor
                                                                  )
                                                                ),
                                                                actuator.fluid.related_fm.idsensor
                                                              )
                                                        }
                                                      />
                                                    </Col>
                                                    {actuator.fluid.flow && (
                                                      <Col xs={6} className='px-0'>
                                                        <span>{t('caudal')}</span>
                                                        <Form.Control
                                                          className='text-center'
                                                          type='number'
                                                          min='0'
                                                          // max='255'
                                                          step={checkScale(
                                                            actuator.fluid.related_fm.idsensor
                                                          )}
                                                          value={
                                                            findActuator(actuator.idactuator)
                                                              .flow === isNaN
                                                              ? ''
                                                              : findActuator(actuator.idactuator)
                                                                  .flow
                                                          }
                                                          onChange={(e) => {
                                                            flowHandler(
                                                              actuator.idactuator,
                                                              parseFloat(e.target.value),
                                                              actuator.fluid.related_fm.idsensor
                                                            );
                                                          }}
                                                          onBlur={(e) =>
                                                            e.target.value === ''
                                                              ? null
                                                              : e.target.value <
                                                                  checkScale(
                                                                    actuator.fluid.related_fm
                                                                      .idsensor
                                                                  ) &&
                                                                flowHandler(
                                                                  actuator.idactuator,
                                                                  parseFloat(
                                                                    checkScale(
                                                                      actuator.fluid.related_fm
                                                                        .idsensor
                                                                    )
                                                                  ),
                                                                  actuator.fluid.related_fm.idsensor
                                                                )
                                                          }
                                                        />
                                                      </Col>
                                                    )}
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                {actuator.kind.normal_state === true &&
                                  findActuator(actuator.idactuator).status === 1 && 
                                  !((actuator.status === 1 &&
                                    findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3") ||
                                    (linked.find((a) => a[actuator.idactuator] !== undefined) && 
                                    linked.find((a) => a[actuator.idactuator] !== undefined)[actuator.idactuator].cmax_day_alert))
                                  &&
                                  (
                                    <>
                                      <Col
                                        className={`${
                                          actuator.status_change_time ? 'mt-0' : 'mt-2 mb-1'
                                        }`}
                                      >
                                        <Form.Check
                                          id='autoUse'
                                          type='switch'
                                          inline
                                          checked={findActuator(actuator.idactuator).permanent}
                                          style={{ transform: 'scale(1.2)' }}
                                          onChange={(e) => {
                                            permanentHandler(actuator.idactuator, e.target.checked);
                                          }}
                                        />
                                        {t('permClose')}
                                      </Col>
                                      {findActuator(actuator.idactuator).permanent === false && (
                                        <Row className='px-3 align-items-end'>
                                          <Col xs={4} className='px-0'>
                                            {actuator.status === 0 ? (
                                              <span>{t('durationOpen')}</span>
                                            ) : (
                                              t('actualOpen')
                                            )}
                                          </Col>
                                          <Col xs={8} className='px-1'>
                                            <Row className='m-0 p-0'>
                                              <Col xs={4} className='px-0'>
                                                <span>{t('horas')}</span>
                                                <Form.Control
                                                  className='text-center'
                                                  type='number'
                                                  min={1}
                                                  placeholder=''
                                                  value={
                                                    findActuator(actuator.idactuator).duration &&
                                                    findActuator(actuator.idactuator).duration.h
                                                      ? findActuator(actuator.idactuator).duration
                                                          .h === 0
                                                        ? ''
                                                        : findActuator(actuator.idactuator).duration
                                                            .h
                                                      : ''
                                                  }
                                                  onChange={(e) => {
                                                    openTimeHandler(
                                                      actuator.idactuator,
                                                      e.target.value,
                                                      'h'
                                                    );
                                                  }}
                                                  onBlur={(e) =>
                                                    document
                                                      .getElementById(
                                                        `sec_${actuator.idactuator}_2`
                                                      )
                                                      .reportValidity()
                                                  }
                                                />
                                              </Col>
                                              <Col xs={4} className='px-0'>
                                                <span>{t('minutos')}</span>
                                                <Form.Control
                                                  className='text-center'
                                                  type='number'
                                                  placeholder=''
                                                  min={1}
                                                  value={
                                                    findActuator(actuator.idactuator).duration &&
                                                    findActuator(actuator.idactuator).duration.m
                                                      ? findActuator(actuator.idactuator).duration
                                                          .m === 0
                                                        ? ''
                                                        : findActuator(actuator.idactuator).duration
                                                            .m
                                                      : ''
                                                  }
                                                  onChange={(e) => {
                                                    openTimeHandler(
                                                      actuator.idactuator,
                                                      e.target.value,
                                                      'm'
                                                    );
                                                  }}
                                                  onBlur={(e) =>
                                                    document
                                                      .getElementById(
                                                        `sec_${actuator.idactuator}_2`
                                                      )
                                                      .reportValidity()
                                                  }
                                                />
                                              </Col>
                                              <Col xs={4} className='px-0'>
                                                <div className='text-truncate'>
                                                  <span>{t('segundos')}</span>
                                                </div>
                                                <Form.Control
                                                  className='text-center'
                                                  type='number'
                                                  id={`sec_${actuator.idactuator}_2`}
                                                  // required={
                                                  //   masterGapCheck(
                                                  //     actuator.idactuator
                                                  //   ) >=
                                                  //   countDuration(
                                                  //     actuator.idactuator
                                                  //   ) +
                                                  //     extraGap
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  min={
                                                    masterGapCheck(actuator.idactuator)
                                                      ? masterGapCheck(actuator.idactuator)
                                                      : 1
                                                  }
                                                  placeholder=''
                                                  value={
                                                    findActuator(actuator.idactuator).duration &&
                                                    findActuator(actuator.idactuator).duration.s
                                                      ? findActuator(actuator.idactuator).duration
                                                          .s === 0
                                                        ? ''
                                                        : findActuator(actuator.idactuator).duration
                                                            .s
                                                      : ''
                                                  }
                                                  onChange={(e) => {
                                                    openTimeHandler(
                                                      actuator.idactuator,
                                                      e.target.value,
                                                      's'
                                                    );
                                                  }}
                                                  onBlur={(e) =>
                                                    document
                                                      .getElementById(
                                                        `sec_${actuator.idactuator}_2`
                                                      )
                                                      .reportValidity()
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  )}
                              </div>
                            )}
                          </Card>
                        </Col>
                      )
                  )}
                </Row>
              </Form>
            </td>
          </tr>
          {idri.actuators
            .map((actuator) => {
              if (actuator.kind.related_to === 'onoff') {
                return true;
              }
            })
            .includes(true) && (
            <>
              <tr className='border border-1'>
                <th>
                  <h5 className='text-center m-0'>{t('onOff')}</h5>
                </th>
              </tr>
              <tr className='border border-1 test'>
                <td className='border-0 p-0'>
                  <Form>
                    <Row className='gx-0 p-0'>
                      {idri.actuators.map(
                        (actuator) =>
                          actuator.kind.related_to === 'onoff' && (
                            <Col
                              xs={12}
                              md={6}
                              className='text-center p-0'
                              key={actuator.idactuator}
                              id={actuator.idactuator}
                              style={{ position: 'relative' }}
                            >
                              <Card border='dark' className='pb-2' style={{ height: '100%' }}>
                                <Card.Title className='d-flex justify-content-center mb-0'>
                                  {actuator.name}
                                  <Form.Check
                                    inline
                                    disabled
                                    className='ms-1 me-0'
                                    checked={
                                      actuator.kind.normal_state === false
                                        ? actuator.status === 0
                                          ? false
                                          : true
                                        : actuator.status === 0
                                        ? true
                                        : false
                                    }
                                    type={'radio'}
                                    id={`actuatorCheck`}
                                  />
                                  <div className='mark'>
                                    {actuator.kind.normal_state === false
                                      ? ' ' + t('close')
                                      : ' ' + t('open')}
                                  </div>
                                </Card.Title>
                                <Card.Title className='m-0 d-flex justify-content-center'>
                                  { 
                                    <Col
                                      xs={6}
                                      className='colorOrange'
                                    >
                                    </Col>                                    
                                  }

                                  {/* {linked.find((a) => a[actuator.idactuator] !== undefined) && (
                                    <Col
                                      xs={
                                        linked.find((a) => a[actuator.idactuator] !== undefined)[
                                          actuator.idactuator
                                        ].name.length > 20 ||
                                        slaves.find((s) => s[actuator.idactuator] !== undefined)[
                                          actuator.idactuator
                                        ].name.length > 20
                                          ? 12
                                          : 6
                                      }
                                      className='colorOrange'
                                    >
                                      <BsSpeedometer className='m-1' />
                                      {
                                        linked.find((a) => a[actuator.idactuator] !== undefined)[
                                          actuator.idactuator
                                        ].name
                                      }
                                    </Col>
                                  )} */}
                                  {props.autoUse === true &&
                                    slaves.find((s) => s[actuator.idactuator] !== undefined) && (
                                      <Col
                                        xs={
                                         /*  linked.find((a) => a[actuator.idactuator] !== undefined)[
                                            actuator.idactuator
                                          ].name.length > 20 || */
                                          slaves.find((s) => s[actuator.idactuator] !== undefined)[
                                            actuator.idactuator
                                          ].name.length > 20
                                            ? 12
                                            : 6
                                        }
                                        className='colorDarkOrange'
                                      >
                                        <BiLink className='m-1 me-0' />
                                        {
                                          slaves.find((s) => s[actuator.idactuator] !== undefined)[
                                            actuator.idactuator
                                          ].name
                                        }
                                      </Col>
                                    )}
                                </Card.Title>
                                {actuator.is_master === true &&
                                // idri.master_en === false &&
                                (props.autoUse === true || idri.master_en) ? (
                                  <>
                                    <Row className='my-0 px-1'>
                                      <p className='text-danger dangerAnimation my-0'>
                                        {t('autoOC')}
                                      </p>
                                      <p className=''>
                                        {t('actuador')}{' '}
                                        {actuator.kind.normal_state === false
                                          ? t('cerrara')
                                          : t('abrira')}{' '}
                                        <span className='fw-bold'>{actuator.gap_master} </span>
                                        {t('segundosDespues')}{' '}
                                        {actuator.kind.normal_state === false
                                          ? t('abrira')
                                          : t('cerrara')}{' '}
                                        <span className='fw-bold'>{actuator.gap_master} </span>{' '}
                                        {t('segundosAntes')}
                                      </p>
                                    </Row>
                                    {!props.isPending &&
                                      actuator.waitingxchange &&
                                      actuator.status_change_time > 0 &&
                                      masterMsg && (
                                        <div className='timer'>
                                          <Col className='m-0 text-danger '>
                                            <p className=' text-dark m-0 timer'>
                                              {actuator.kind.normal_state === true
                                                ? t('closing')
                                                : t('opening')}{' '}
                                              {t('in')}
                                            </p>
                                            <Timer
                                              hrs={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .asHours()}
                                              min={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .minutes()}
                                              sec={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .seconds()}
                                              close={() => {
                                                setMasterMsg(false);
                                                closeHandler(actuator.idactuator, true);
                                              }}
                                            />
                                          </Col>
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <div className='position-relative'>
                                      {actuator.kind.normal_state === false && (
                                        <>
                                        <ConditionalWrapper
                                          condition={(actuator.status === 1 &&
                                            findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")}
                                          wrapper={children => (
                                            <OverlayTrigger
                                                overlay={<Tooltip>{t('tooltipActRule1')} r{actuator.who_change}</Tooltip>}
                                                placement='top'
                                                trigger={['click', 'focus']}
                                            >
                                                {children}
                                            </OverlayTrigger>
                                          )}
                                        >
                                          <div>
                                            <Form.Check
                                              type='switch'
                                              id={`custom-switch${actuator.idactuator}`}
                                              checked={findActuator(actuator.idactuator).status}
                                              disabled={actuator.status === 1 &&
                                                findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3"}
                                              onChange={(e) => {
                                                openHandler(
                                                  actuator.idactuator,
                                                  e.target.checked,
                                                  true
                                                );
                                              }}
                                            />                                          
                                            <span
                                              className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none'
                                              onClick={() =>
                                                !((actuator.status === 1 &&
                                                  findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")) 
                                                  ?
                                                  openHandler(
                                                    actuator.idactuator,
                                                    !findActuator(actuator.idactuator).status,
                                                    true
                                                  ) 
                                                  : undefined
                                              }
                                            >
                                              {actuator.status === 0 &&
                                              findActuator(actuator.idactuator).status === 0
                                                ? t('cerrado')
                                                : actuator.status === 0 &&
                                                  findActuator(actuator.idactuator).status === 1
                                                ? t('abrir')
                                                : actuator.status === 1 &&
                                                  findActuator(actuator.idactuator).status === 1
                                                ? t('abierto') + (actuator.which_change === "1" ? " (p" + actuator.who_change + ")" : 
                                                                    actuator.which_change === "3" ? " (r" + actuator.who_change + ")" : "")
                                                : t('cerrar').toLowerCase()}
                                            </span>
                                          </div>
                                        </ConditionalWrapper>
                                        </>
                                      )}
                                      {actuator.kind.normal_state === true && (
                                        <>
                                        <ConditionalWrapper
                                          condition={(actuator.status === 1 &&
                                            findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")}
                                          wrapper={children => (
                                            <OverlayTrigger
                                                overlay={<Tooltip>{t('tooltipActRule1')} r{actuator.who_change}</Tooltip>}
                                                placement='top'
                                                trigger={['click', 'focus']}
                                            >
                                                {children}
                                            </OverlayTrigger>
                                          )}
                                        >
                                          <div>
                                            <Form.Check
                                              type='switch'
                                              id={`custom-switch${actuator.idactuator}`}
                                              checked={
                                                findActuator(actuator.idactuator).status === 0
                                                  ? true
                                                  : false
                                              }
                                              disabled={actuator.status === 1 &&
                                                findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3"}
                                              onChange={(e) => {
                                                openHandler(
                                                  actuator.idactuator,
                                                  !e.target.checked,
                                                  true
                                                );
                                              }}
                                            />                                          
                                            <span
                                              className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none'
                                              onClick={() =>
                                                !((actuator.status === 1 &&
                                                  findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")) 
                                                  ?
                                                  openHandler(
                                                    actuator.idactuator,
                                                    !findActuator(actuator.idactuator).status,
                                                    true
                                                  )
                                                  : undefined
                                              }
                                            >
                                              {actuator.status === 0 &&
                                              findActuator(actuator.idactuator).status === 0
                                                ? t('abierto')
                                                : actuator.status === 0 &&
                                                  findActuator(actuator.idactuator).status === 1
                                                ? t('cerrar').toLowerCase()
                                                : actuator.status === 1 &&
                                                  findActuator(actuator.idactuator).status === 1
                                                ? t('cerrado') + (actuator.which_change === "1" ? " (p" + actuator.who_change + ")" : 
                                                                  actuator.which_change === "3" ? " (r" + actuator.who_change + ")" : "")
                                                : t('abrir')}
                                            </span>
                                          </div>
                                        </ConditionalWrapper>
                                        </>
                                      )}
                                    </div>
                                    {/* {actuator.status === 1 &&
                                        actuator.status_change_time && ( */}
                                    <Col className='m-0 text-danger '>
                                      {!props.isPending &&
                                        (actuator.status === 0 &&
                                        actuator.status_change_time === null &&
                                        actuator.waitingxchange ? (
                                          <Col className=' text-dark '>
                                            {t('waiting')}{' '}
                                            {actuator.kind.normal_state === true
                                              ? t('toClose')
                                              : t('toOpen')}
                                          </Col>
                                        ) : actuator.status === 0 &&
                                          actuator.status_change_time > 0 &&
                                          actuator.waitingxchange ? (
                                          <div className='timer'>
                                            <Col className=' text-dark timer'>
                                              {actuator.kind.normal_state === true
                                                ? t('closing')
                                                : t('opening')}{' '}
                                              {t('in')}
                                            </Col>
                                            <Timer
                                              hrs={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .asHours()}
                                              min={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .minutes()}
                                              sec={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .seconds()}
                                              close={() => closeHandler(actuator.idactuator)}
                                            />
                                          </div>
                                        ) : actuator.status === 1 &&
                                          actuator.status_change_time === null &&
                                          actuator.waitingxchange ? (
                                          <Col className='text-dark '>
                                            {t('waiting')}{' '}
                                            {actuator.kind.normal_state === true
                                              ? t('toOpen')
                                              : t('toClose')}
                                          </Col>
                                        ) : actuator.status === 1 &&
                                          actuator.status_change_time > 0 &&
                                          actuator.waitingxchange ? (
                                          <div className='timer'>
                                            <Col className=' text-dark timer'>
                                              {actuator.kind.normal_state === true
                                                ? t('opening')
                                                : t('closing')}{' '}
                                              {t('in')}
                                            </Col>
                                            <Timer
                                              hrs={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .asHours()}
                                              min={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .minutes()}
                                              sec={moment
                                                .duration(
                                                  actuator.status_change_time -
                                                    (new Date() - localTime) / 1000,
                                                  'seconds'
                                                )
                                                .seconds()}
                                              close={() => closeHandler(actuator.idactuator)}
                                            />
                                          </div>
                                        ) : (
                                          actuator.status === 1 &&
                                          actuator.status_change_time &&
                                          localTime !== null && (
                                            <div className='timer mt-2'>
                                              <Timer
                                                hrs={moment
                                                  .duration(actuator.status_change_time, 'seconds')
                                                  .asHours()}
                                                min={moment
                                                  .duration(actuator.status_change_time, 'seconds')
                                                  .minutes()}
                                                sec={moment
                                                  .duration(actuator.status_change_time, 'seconds')
                                                  .seconds()}
                                                close={() => closeHandler(actuator.idactuator)}
                                              />
                                            </div>
                                          )
                                        ))}
                                    </Col>
                                    {/* )} */}

                                    {actuator.kind.normal_state === false &&
                                      findActuator(actuator.idactuator).status === 1 && (
                                        <>
                                          {(actuator.status === 0 &&
                                            actuator.status_change_time === null &&
                                            actuator.waitingxchange) ||
                                          (actuator.status === 0 &&
                                            actuator.status_change_time > 0 &&
                                            actuator.waitingxchange) ||
                                          (actuator.status === 1 &&
                                            actuator.status_change_time === null &&
                                            actuator.waitingxchange) ||
                                          (actuator.status === 1 &&
                                            actuator.status_change_time > 0 &&
                                            actuator.waitingxchange) ||
                                          (actuator.status === 1 &&
                                            findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")

                                            ? null : (
                                            <>
                                              <Col
                                                className={`${
                                                  actuator.status_change_time ? 'mt-0' : 'mt-2 mb-1'
                                                }`}
                                              >
                                                <Form.Check
                                                  id='autoUse'
                                                  type='switch'
                                                  inline
                                                  checked={
                                                    findActuator(actuator.idactuator).permanent
                                                  }
                                                  style={{
                                                    transform: 'scale(1.2)',
                                                  }}
                                                  onChange={(e) => {
                                                    permanentHandler(
                                                      actuator.idactuator,
                                                      e.target.checked
                                                    );
                                                  }}
                                                />
                                                {t('permOpen')}
                                              </Col>
                                              {findActuator(actuator.idactuator).permanent ===
                                                false && (
                                                <Row className='px-3 align-items-end'>
                                                  <Col xs={4} className='px-0'>
                                                    {actuator.status === 0 ? (
                                                      <span>{t('durationClose')}</span>
                                                    ) : (
                                                      t('actualClose')
                                                    )}
                                                  </Col>
                                                  <Col xs={8} className='px-1'>
                                                    <Row className='m-0 p-0'>
                                                      <Col xs={4} className='px-0'>
                                                        <span>{t('horas')}</span>
                                                        <Form.Control
                                                          className='text-center'
                                                          type='number'
                                                          min={1}
                                                          placeholder=''
                                                          value={
                                                            findActuator(actuator.idactuator)
                                                              .duration &&
                                                            findActuator(actuator.idactuator)
                                                              .duration.h
                                                              ? findActuator(actuator.idactuator)
                                                                  .duration.h === 0
                                                                ? ''
                                                                : findActuator(actuator.idactuator)
                                                                    .duration.h
                                                              : ''
                                                          }
                                                          onChange={(e) => {
                                                            openTimeHandler(
                                                              actuator.idactuator,
                                                              e.target.value,
                                                              'h'
                                                            );
                                                          }}
                                                          onBlur={(e) =>
                                                            document
                                                              .getElementById(
                                                                `sec_${actuator.idactuator}_3`
                                                              )
                                                              .reportValidity()
                                                          }
                                                        />
                                                      </Col>
                                                      <Col xs={4} className='px-0'>
                                                        <span>{t('minutos')}</span>
                                                        <Form.Control
                                                          className='text-center'
                                                          type='number'
                                                          placeholder=''
                                                          min={1}
                                                          value={
                                                            findActuator(actuator.idactuator)
                                                              .duration &&
                                                            findActuator(actuator.idactuator)
                                                              .duration.m
                                                              ? findActuator(actuator.idactuator)
                                                                  .duration.m === 0
                                                                ? ''
                                                                : findActuator(actuator.idactuator)
                                                                    .duration.m
                                                              : ''
                                                          }
                                                          onChange={(e) => {
                                                            openTimeHandler(
                                                              actuator.idactuator,
                                                              e.target.value,
                                                              'm'
                                                            );
                                                          }}
                                                          onBlur={(e) =>
                                                            document
                                                              .getElementById(
                                                                `sec_${actuator.idactuator}_3`
                                                              )
                                                              .reportValidity()
                                                          }
                                                        />
                                                      </Col>
                                                      <Col xs={4} className='px-0'>
                                                        <div className='text-truncate'>
                                                          <span>{t('segundos')}</span>
                                                        </div>
                                                        <Form.Control
                                                          className='text-center'
                                                          type='number'
                                                          id={`sec_${actuator.idactuator}_3`}
                                                          // required={
                                                          //   masterGapCheck(
                                                          //     actuator.idactuator
                                                          //   ) >=
                                                          //   countDuration(
                                                          //     actuator.idactuator
                                                          //   ) +
                                                          //     extraGap
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                          min={
                                                            masterGapCheck(actuator.idactuator)
                                                              ? masterGapCheck(actuator.idactuator)
                                                              : 1
                                                          }
                                                          placeholder=''
                                                          value={
                                                            findActuator(actuator.idactuator)
                                                              .duration &&
                                                            findActuator(actuator.idactuator)
                                                              .duration.s
                                                              ? findActuator(actuator.idactuator)
                                                                  .duration.s === 0
                                                                ? ''
                                                                : findActuator(actuator.idactuator)
                                                                    .duration.s
                                                              : ''
                                                          }
                                                          onChange={(e) => {
                                                            openTimeHandler(
                                                              actuator.idactuator,
                                                              e.target.value,
                                                              's'
                                                            );
                                                          }}
                                                          onBlur={(e) =>
                                                            document
                                                              .getElementById(
                                                                `sec_${actuator.idactuator}_3`
                                                              )
                                                              .reportValidity()
                                                          }
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    {actuator.kind.normal_state === true &&
                                      findActuator(actuator.idactuator).status === 1 &&
                                      !(actuator.status === 1 &&
                                        findActuator(actuator.idactuator).status === 1 && actuator.which_change === "3")
                                      && (
                                        <>
                                          <Col
                                            className={`${
                                              actuator.status_change_time ? 'mt-0' : 'mt-2 mb-1'
                                            }`}
                                          >
                                            <Form.Check
                                              id='autoUse'
                                              type='switch'
                                              inline
                                              checked={findActuator(actuator.idactuator).permanent}
                                              style={{
                                                transform: 'scale(1.2)',
                                              }}
                                              onChange={(e) => {
                                                permanentHandler(
                                                  actuator.idactuator,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                            {t('permClose')}
                                          </Col>
                                          {findActuator(actuator.idactuator).permanent ===
                                            false && (
                                            <Row className='px-3 align-items-end'>
                                              <Col xs={4} className='px-0'>
                                                {actuator.status === 0 ? (
                                                  <span>{t('durationOpen')}</span>
                                                ) : (
                                                  t('actualOpen')
                                                )}
                                              </Col>
                                              <Col xs={8} className='px-1'>
                                                <Row className='m-0 p-0'>
                                                  <Col xs={4} className='px-0'>
                                                    <span>{t('horas')}</span>
                                                    <Form.Control
                                                      className='text-center'
                                                      type='number'
                                                      min={1}
                                                      placeholder=''
                                                      value={
                                                        findActuator(actuator.idactuator)
                                                          .duration &&
                                                        findActuator(actuator.idactuator).duration.h
                                                          ? findActuator(actuator.idactuator)
                                                              .duration.h === 0
                                                            ? ''
                                                            : findActuator(actuator.idactuator)
                                                                .duration.h
                                                          : ''
                                                      }
                                                      onChange={(e) => {
                                                        openTimeHandler(
                                                          actuator.idactuator,
                                                          e.target.value,
                                                          'h'
                                                        );
                                                      }}
                                                      onBlur={(e) =>
                                                        document
                                                          .getElementById(
                                                            `sec_${actuator.idactuator}_4`
                                                          )
                                                          .reportValidity()
                                                      }
                                                    />
                                                  </Col>
                                                  <Col xs={4} className='px-0'>
                                                    <span>{t('minutos')}</span>
                                                    <Form.Control
                                                      className='text-center'
                                                      type='number'
                                                      placeholder=''
                                                      min={1}
                                                      value={
                                                        findActuator(actuator.idactuator)
                                                          .duration &&
                                                        findActuator(actuator.idactuator).duration.m
                                                          ? findActuator(actuator.idactuator)
                                                              .duration.m === 0
                                                            ? ''
                                                            : findActuator(actuator.idactuator)
                                                                .duration.m
                                                          : ''
                                                      }
                                                      onChange={(e) => {
                                                        openTimeHandler(
                                                          actuator.idactuator,
                                                          e.target.value,
                                                          'm'
                                                        );
                                                      }}
                                                      onBlur={(e) =>
                                                        document
                                                          .getElementById(
                                                            `sec_${actuator.idactuator}_4`
                                                          )
                                                          .reportValidity()
                                                      }
                                                    />
                                                  </Col>
                                                  <Col xs={4} className='px-0'>
                                                    <div className='text-truncate'>
                                                      <span>{t('segundos')}</span>
                                                    </div>
                                                    <Form.Control
                                                      className='text-center'
                                                      type='number'
                                                      id={`sec_${actuator.idactuator}_4`}
                                                      min={
                                                        masterGapCheck(actuator.idactuator)
                                                          ? masterGapCheck(actuator.idactuator)
                                                          : 1
                                                      }
                                                      placeholder=''
                                                      value={
                                                        findActuator(actuator.idactuator)
                                                          .duration &&
                                                        findActuator(actuator.idactuator).duration.s
                                                          ? findActuator(actuator.idactuator)
                                                              .duration.s === 0
                                                            ? ''
                                                            : findActuator(actuator.idactuator)
                                                                .duration.s
                                                          : ''
                                                      }
                                                      onChange={(e) => {
                                                        openTimeHandler(
                                                          actuator.idactuator,
                                                          e.target.value,
                                                          's'
                                                        );
                                                      }}
                                                      onBlur={(e) =>
                                                        document
                                                          .getElementById(
                                                            `sec_${actuator.idactuator}_4`
                                                          )
                                                          .reportValidity()
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      )}
                                  </>
                                )}
                              </Card>
                            </Col>
                          )
                      )}
                    </Row>
                  </Form>
                </td>
              </tr>
            </>
          )}
        </tbody>
      )}
      {/* {console.log(idri)}
      {console.log(idri.actuators)}
      {console.log(parseFloat(idri.v_idri) < 3)}
      {console.log(Object.keys(act).length > 0)} */}
      {idri && idri.actuators && parseFloat(idri.ghatorv) < 3 && Object.keys(act).length > 0 && (
        <tbody>
          <tr>
            <td className='p-0'>
              <Form id='actuatorsForm3' onSubmit={(e) => props.handleSubmit(e)}>
                <Row className='gx-0 test'>
                  {idri.actuators.map((actuator) => (
                    <Col
                      xs={12}
                      md={6}
                      className='text-center'
                      key={actuator.idactuator}
                      id={actuator.idactuator}
                    >
                      <Card border='dark' className='pb-2' style={{ height: '100%' }}>
                        <Card.Title className='d-flex justify-content-center mb-0'>
                          <span
                            className='d-flex align-items-center text-truncate'
                            style={{ maxWidth: '65vw' }}
                          >
                            {actuator.name}
                          </span>
                          <Form.Check
                            inline
                            disabled
                            className='ms-1 me-0'
                            checked={actuator.status === 0 ? false : true}
                            type={'radio'}
                            id={`actuatorCheck`}
                          />
                          <div className='mark'>
                            {actuator.kind.normal_state === false
                              ? ' ' + t('close')
                              : ' ' + t('open')}
                          </div>
                        </Card.Title>
                        <Card.Title className='m-0 d-flex justify-content-center'>
                          {linked.find((a) => a[actuator.idactuator] !== undefined) && (
                            <Col className='colorOrange'>
                              <BsSpeedometer className='m-1' />
                              {
                                linked.find((a) => a[actuator.idactuator] !== undefined)[
                                  actuator.idactuator
                                ].name
                              }
                            </Col>
                          )}
                        </Card.Title>
                        <Col>
                          <div className='position-relative'>
                            <Form.Check
                              type='switch'
                              id={`custom-switch${actuator.idactuator}`}
                              checked={act.find((x) => x.idactuator === actuator.idactuator).status}
                              onChange={(e) => {
                                openHandler(actuator.idactuator, e.target.checked);
                              }}
                            />
                            <span
                              className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none'
                              onClick={() =>
                                openHandler(
                                  actuator.idactuator,
                                  !findActuator(actuator.idactuator).status
                                )
                              }
                            >
                              {actuator.status === 0 &&
                              act.find((x) => x.idactuator === actuator.idactuator).status === 0
                                ? t('cerrado')
                                : actuator.status === 0 &&
                                  act.find((x) => x.idactuator === actuator.idactuator).status === 1
                                ? t('abrir')
                                : actuator.status === 1 &&
                                  act.find((x) => x.idactuator === actuator.idactuator).status === 1
                                ? t('abierto')
                                : t('cerrar').toLowerCase()}
                            </span>
                          </div>
                        </Col>
                        {actuator.status === 1 &&
                          actuator.status_change_date &&
                          typeof actuator.status_change_date === 'string' && (
                            <Col className='m-0 text-danger timer mt-2'>
                              <Timer
                                hrs={moment
                                  .duration(moment(actuator.status_change_date) - moment())
                                  .asHours()}
                                min={moment
                                  .duration(moment(actuator.status_change_date) - moment())
                                  .minutes()}
                                sec={moment
                                  .duration(moment(actuator.status_change_date) - moment())
                                  .seconds()}
                                close={() => closeHandler(actuator.idactuator)}
                              />
                            </Col>
                          )}
                        {act.find((x) => x.idactuator === actuator.idactuator).status === 1 && (
                          <Row className='justify-content-center m-0 p-0'>
                            <Col xs={12} md={9} className='px-2 mb-0'>
                              {actuator.status === 0
                                ? t('minCierre') + ' (max.255)'
                                : t('actualizarCierre') + ' (max.255)'}

                              <Form.Control
                                type='number'
                                className='text-center '
                                min='1'
                                max='255'
                                value={
                                  act.find((x) => x.idactuator === actuator.idactuator).duration
                                }
                                onChange={(e) => {
                                  openTimeHandler(actuator.idactuator, e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                        {idri.sensors.fm_sensors.map((s) => {
                          // console.log(s.idsensos);
                          // console.log(actuator.fluid.related_fm.idsensor);
                          if (s.idsensor === actuator.fluid.related_fm.idsensor) {
                            // console.log('alert true');
                            if (s.alerts.cmax_day_alert) {
                              return (
                                <div className='animated text-truncate'>
                                  <strong className='mt-2 text-danger fw-normal'>
                                    {t('contadorAsoc')}{' '}
                                    <span className='fw-bold'>{actuator.name}</span>{' '}
                                    {t('haveAlert')}
                                  </strong>
                                </div>
                              );
                            }
                          }
                        })}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Form>
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default ActuatorsControl;
