/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGED, IDRI_DATA, IDRI_INIT_DATA } from '../../../../redux/constants/idriConstants';
import isValidNif from '../../../../support_func/isValidNif';
import Timer from '../../../../support_func/Timer';
import ModalGenerico from '../../../home/Modal_generico';
import BoardChange from '../../modals/BoardChange';
import MapSrt from '../../modals/MapSrt';
import CreatableSelect from 'react-select/creatable';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
const IdriInit = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [coord, setCoord] = useState({ lat: '--', lng: '--' });
  const [mapModal, setMapModal] = useState(false);
  const [boardModal, setBoardModal] = useState(false);
  const [newInstInsert, setNewInstInsert] = useState({});
  const [charged, setCharged] = useState(false);
  const [newBoard, setNewBoard] = useState(false);
  const [runAsync, setRunAsync] = useState(false);
  const [behalfeds, setBehalfeds] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedNif, setSelectedNif] = useState();
  const [inBehalfed, setInBehalfed] = useState(false);

  const dispatch = useDispatch();
  //redux hooks
  const storedUser = useSelector((state) => state.user);
  const idriInitData = useSelector((state) => state.idriInitData);
  const idriData = useSelector((state) => state.idriData);
  const changed = useSelector((state) => state.changed);

  //initializing a new device
  const goConfig = (e) => {
    e.preventDefault();
    setIsPending(true);

    //formation of the request body
    let body = {
      ididri: idriInitData.ididri,
      control: idriInitData.newControl ?? idriInitData.control,
      idriserial: idriInitData.newIdriserial ?? idriInitData.idriserial,
      simprovider: newInstInsert.simprovider,
      iccid: newInstInsert.iccid,
      emailowner: newInstInsert.emailowner ?? selectedEmail,
      nifowner: newInstInsert.nifowner ?? selectedNif,
      idriname: newInstInsert.idriname,
      idricoor: [coord.lat, coord.lng],
      // idriscope: newInstInsert.idriscope || 0,
      ownerlang: newInstInsert.ownerlang,
      add_behalfed_list: newInstInsert.add_behalfed_list,
      behalfed_default: newInstInsert.behalfed_default,
    };
    console.log(body);
    //coordinate validation
    if (typeof coord.lat == 'string' || typeof coord.lng == 'string') {
      setIsPending(false);
      setError(t('selectCoord'));
      //NIF validation
    } else if (isValidNif(body.nifowner)) {
      setError(null);
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/newidri`, body, config)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setIsPending(false);
            localStorage.removeItem(`init-${idriInitData.idriserial}`);
            getIdriInitData();
            getIdriData();
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setIsPending(false);
      setError(t('nifIncorecto'));
    }
  };
  //get data initializing device
  const getIdriInitData = (id, serial, errorAsync, edit) => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/editidri?ididri=${
          id ? id : idriInitData.ididri
        }&idriserial=${serial ? serial : idriInitData.idriserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          errorAsync && setError(errorAsync);
          edit && setError(t('modificated') + ' ' + t('success'));
          edit &&
            setNewInstInsert({
              ...newInstInsert,
              add_behalfed_list: false,
              behalfed_default: false,
            });
          setCoord({
            lat: parseFloat(res.data.idricoor[0]).toFixed(5),
            lng: parseFloat(res.data.idricoor[1]).toFixed(5),
          });
          //store data in redux storage
          dispatch({
            type: IDRI_INIT_DATA,
            payload: { ...res.data },
          });
          props.setIdriStatus &&
            props.setIdriStatus({
              discharged: res.data.discharged,
              inicialized: res.data.inicialized,
            });

          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError, null, props.closeModal);
      });
  };

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 4000);
    }
  };

  //get async response from device
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          if (res.data.found === true && res.data.response.result === true) {
            getIdriInitData(idriInitData.ididri, idriInitData.newIdriserial, t('conExito'));
            // getIdriData(idriInitData.newIdriserial);
            counter = null;
            // props.setPage('testInst');
          } else if (res.data.found === true && res.data.response.result === false) {
            // setIsPending(false);

            if (res.data.response.nicolecode_id === 5) {
              getIdriInitData(
                idriInitData.ididri,
                idriInitData.newIdriserial,
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
              getIdriData(idriInitData.newIdriserial);
            } else {
              getIdriInitData(
                idriInitData.ididri,
                idriInitData.newIdriserial,
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
              getIdriData(idriInitData.newIdriserial);
            }
            // getIdriInitData(idriInitData.newIdriserial, 'error');
            // getIdriData(idriInitData.newIdriserial);
          } else if (res.data.found === false && counter < 24) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            // setIsPending(false);
            // setError(t('noConnection'));

            getIdriInitData(
              idriInitData.ididri,
              idriInitData.newIdriserial,
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            getIdriData(idriInitData.newIdriserial);
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //get divice data
  const getIdriData = (serial, noNextPage) => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/confidri?ididri=${idriInitData.ididri}&idriserial=${
          serial ? serial : idriInitData.idriserial
        }`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setIsPending(false);
          // console.log(res.data);
          //store data in redux storage
          dispatch({
            type: IDRI_DATA,
            payload: res.data,
          });
          props.setIdriStatus &&
            props.setIdriStatus({
              discharged: res.data.discharged,
              inicialized: res.data.inicialized,
            });
          if (changed.edit && changed.edit === true) {
            dispatch({
              type: CHANGED,
              payload: { ...changed, edit: false },
            });
          }
          !noNextPage && setError(t('inicializado') + ' ' + t('success'));
          // !noNextPage && props.setPage('newSensors');
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //post divice data
  const postIdriData = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let host = window.location.host;
    let activate = '/urlaccountactiv';
    let init = '/inicio';
    // let noIp = 'http://srtnicole.hopto.org:8000';
    //formation of the request body
    let body = {
      ididri: idriInitData.ididri,
      control: idriInitData.newControl ?? idriInitData.control,
      idriserial: idriInitData.newIdriserial ?? idriInitData.idriserial,
      simprovider:
        newInstInsert.simprovider ??
        Object.entries(idriInitData.simproviders)
          .map(([key, value]) => {
            value.includes(idriInitData.simprovider);
            return key;
          })
          .toString(),
      iccid: newInstInsert.iccid ?? idriInitData.sim.iccid,
      emailowner: newInstInsert.emailowner ?? idriInitData.gamo.email ?? selectedEmail,
      nifowner: newInstInsert.nifowner ?? idriInitData.gamo.nif ?? selectedNif,
      add_behalfed_list: newInstInsert.add_behalfed_list,
      behalfed_default: newInstInsert.behalfed_default,
      idriname: newInstInsert.idriname ?? idriInitData.idriname,
      idricoor: [coord.lat, coord.lng] ?? idriInitData.idricoor,
      // idriscope: newInstInsert.idriscope || 0,
      ownerlang: newInstInsert.ownerlang ?? idriInitData.gamo.language,
      urlaccountactiv: 'https://' + host + activate,
      urlfront: 'https://' + host + init,
    };
    console.log(body);
    if (isValidNif(body.nifowner)) {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/editidri`, body, config)
        .then((res) => {
          if (res.data.code === '0') {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.asyncid === null) {
              setIsPending(false);
              localStorage.removeItem(`init-${idriInitData.idriserial}`);
              getIdriInitData(null, null, null, true);
              // getIdriData();
            } else {
              counter = 0;
              setRunAsync(true);
              setTimeout(() => {
                checkAsync(res.data.asyncid);
              }, 20000);
            }
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setIsPending(false);
      setError(t('nifIncorecto'));
    }
  };

  const delBoardChange = () => {
    let obj = idriInitData;
    delete obj['newIdriserial'];
    delete obj['newControl'];
    dispatch({
      type: IDRI_INIT_DATA,
      payload: obj,
    });
    setNewBoard(false);
  };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={CreatableSelect} />;

  //first page load get instalation data
  useEffect(() => {
    setIsPending(true);
    if (props.id) {
      getIdriInitData(props.id && props.id, props.serial && props.serial);
    } else if (
      localStorage.getItem(`init-${idriInitData.idriserial}`) &&
      localStorage.getItem(`init-${idriInitData.idriserial}`) !== '{}' &&
      window.confirm(t('hayDatos'))
    ) {
      let obj = JSON.parse(localStorage.getItem(`init-${idriInitData.idriserial}`));

      setNewInstInsert(obj);

      setIsPending(false);
    } else {
      localStorage.removeItem(`init-${idriInitData.idriserial}`);
      setNewInstInsert({});
      setIsPending(false);
      (idriInitData.sim || Object.keys(idriData).length !== 0) && !error && getIdriInitData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(props);
  useEffect(() => {
    if (Object.keys(idriInitData).length > 0) {
      let list = [];
      !idriInitData.behalfeds.some((e) => e.email === storedUser.email) &&
        list.push({
          email: storedUser.email ?? (idriInitData.gamo && idriInitData.gamo.email) ?? '',
          nif: storedUser.nif ?? (idriInitData.gamo && idriInitData.gamo.nif) ?? '',
        });
      idriInitData.gamo &&
        !idriInitData.behalfeds.some((e) => e.email === idriInitData.gamo.email) &&
        list.push({
          email: idriInitData.gamo.email,
          nif: idriInitData.gamo.nif,
        });
      if (idriInitData.behalfeds && idriInitData.behalfeds.length > 0) {
        idriInitData.behalfeds.forEach((user) => {
          list.push({
            email: user.email,
            nif: user.nif,
            default: user.default,
          });
          if (user.default && !idriInitData.gamo?.email) {
            setSelectedEmail(user.email);
            setSelectedNif(user.nif);
            // setNewInstInsert({
            //   ...newInstInsert,
            //   emailowner: user.email,
            //   nifowner: user.nif,
            // });
            setInBehalfed(idriInitData.behalfeds.some((e) => e.email === user.email));
          } else {
            setSelectedEmail(idriInitData.gamo ? idriInitData.gamo.email : storedUser.email);
            setSelectedNif(idriInitData.gamo ? idriInitData.gamo.nif : storedUser.nif);
            // setNewInstInsert({
            //   ...newInstInsert,
            //   emailowner: user.email,
            //   nifowner: user.nif,
            // });
            setInBehalfed(
              idriInitData.behalfeds.some(
                (e) => e.email === (idriInitData.gamo ? idriInitData.gamo.email : storedUser.email)
              )
            );
          }
        });
      } else {
        setSelectedEmail(idriInitData.gamo ? idriInitData.gamo.email : storedUser.email);
        setSelectedNif(idriInitData.gamo ? idriInitData.gamo.nif : storedUser.nif);
        setInBehalfed(
          idriInitData.behalfeds.some(
            (e) => e.email === (idriInitData.gamo ? idriInitData.gamo.email : storedUser.email)
          )
        );
      }

      setBehalfeds(
        Array.from(new Set(list.map((item) => item.email))).map((email) =>
          list.find((item) => item.email === email)
        )
      );
      // setInBehalfed(selectedEmail === props.profiles.email);
    }

    idriInitData.installation_date && getIdriData(null, true);
    // getIdriData(null, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idriInitData]);
  // console.log(selectedEmail);
  // console.log(selectedNif);
  // console.log(newInstInsert);

  // useEffect(() => {
  //   console.log(inBehalfed);
  // }, [inBehalfed]);

  useEffect(() => {
    if (charged && (!changed.edit || changed.edit === false)) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, edit: true },
      });
    }
    setCharged(true);
    typeof newInstInsert !== 'object' &&
      changed.edit &&
      localStorage.setItem(`init-${idriInitData.idriserial}`, JSON.stringify(newInstInsert));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInstInsert]);

  // useEffect(() => {
  //   let obj = JSON.parse(localStorage.getItem(`init-${idriInitData.idriserial}`));
  //   obj && changed
  //     ? setNewInstInsert({
  //         ...obj,
  //         coord: coord,
  //       })
  //     : setNewInstInsert({ ...newInstInsert, coord: coord });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [coord]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     `init-${idriInitData.idriserial}`,
  //     JSON.stringify(newInstInsert)
  //   );
  // }, [newInstInsert]);

  //auto hide error message
  // console.log('render');
  // if (window.confirm(error) === true) {
  //   setError(null)
  // } else {
  //   text = "You canceled!";
  // }
  // error && window.alert(error) && setError(null);
  useEffect(() => {
    idriInitData.behalfeds &&
      setInBehalfed(idriInitData.behalfeds.some((e) => e.email === selectedEmail));
  }, [selectedEmail]);
  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log('IDRIINIT');

  return (
    <div id='nueva_instalacion'>
      {!props.id && (
        <Row>
          <Col className='text-center'>
            {!idriInitData.installation_date && (
              <h5>
                <u>{t('newIdris')}</u>
              </h5>
            )}
          </Col>
        </Row>
      )}
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('comprobando')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      ) : (
        <Form
          id='nueva_instalacion_form'
          onSubmit={(e) => (idriInitData.sim ? postIdriData(e) : goConfig(e))}
        >
          <div id='nueva_instalacion_common_block'>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={idriInitData.sim ? 6 : 12}>
                    <strong>#{t('serieIdri')}</strong>
                    {/* {idriInitData.newIdriserial && (
                      <span className='text-danger'> (no actualizado)</span>
                    )} */}
                    <Form.Control
                      disabled
                      required
                      type='text'
                      name='idri_nserie'
                      value={idriInitData.idriserial ?? ''}
                    />
                  </Col>
                  {idriInitData.sim &&
                    (idriInitData.newIdriserial && newBoard ? (
                      <>
                        <Col xs={6}>
                          <strong>
                            {t('nuevo').toLowerCase()} #{t('serieIdri')}
                          </strong>
                          <InputGroup className='mb-3'>
                            <Form.Control
                              disabled
                              required
                              type='text'
                              name='idri_nserie'
                              value={idriInitData.newIdriserial ?? ''}
                            />

                            <Button variant='outline-danger' onClick={() => delBoardChange()}>
                              X
                            </Button>
                          </InputGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={6} className='d-flex align-items-end'>
                          <a className='text-center w-100 pb-2' onClick={() => setBoardModal(true)}>
                            {t('changeBoard')}
                          </a>
                        </Col>
                        <ModalGenerico
                          title={t('ingresarDatos')}
                          onClose={() => setBoardModal(false)}
                          show={boardModal}
                        >
                          <BoardChange
                            setBoardModal={setBoardModal}
                            setNewBoard={setNewBoard}
                            closeModal={() => setBoardModal(false)}
                          />
                        </ModalGenerico>
                      </>
                    ))}
                </Row>
              </Col>

              <Col xs={12} md={6}>
                <strong>{t('simProvedor')}</strong>
                <Form.Select
                  required
                  value={
                    newInstInsert.simprovider
                      ? newInstInsert.simprovider
                      : idriInitData.sim
                      ? idriInitData.sim.simprovider
                      : ''
                  }
                  name='sim_provedor'
                  onChange={(e) =>
                    setNewInstInsert({
                      ...newInstInsert,
                      simprovider: e.target.selectedIndex.toString(),
                    })
                  }
                >
                  <option value='' disabled>
                    {t('selectProvedor')}...
                  </option>
                  {idriInitData &&
                    idriInitData.simproviders &&
                    Object.entries(idriInitData.simproviders).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value.toLowerCase()}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col>
                <strong>{t('iccidSim')}</strong>
                <Form.Control
                  required
                  type='text'
                  name='sim_ref'
                  value={
                    newInstInsert.iccid
                      ? newInstInsert.iccid
                      : idriInitData.sim
                      ? idriInitData.sim.iccid
                      : ''
                  }
                  onChange={(e) =>
                    setNewInstInsert({
                      ...newInstInsert,
                      iccid: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row
              className={`${
                !inBehalfed &&
                selectedEmail !== storedUser.email &&
                'm-0 my-2 p-0 border rounded backGray'
              }`}
            >
              <Col>
                <strong>{t('emailProp')}</strong>
                <div className='position-relative customFocus'>
                  <Form.Select
                    value=''
                    onChange={(e) => {
                      let email = JSON.parse(e.target.value).email;
                      setSelectedEmail(JSON.parse(e.target.value).email);
                      setSelectedNif(JSON.parse(e.target.value).nif);
                      setNewInstInsert({
                        ...newInstInsert,
                        emailowner: JSON.parse(e.target.value).email,
                        nifowner: JSON.parse(e.target.value).nif,
                      });
                      setInBehalfed(idriInitData.behalfeds.some((e) => e.email === email));
                    }}
                    onBlur={() =>
                      setInBehalfed(idriInitData.behalfeds.some((e) => e.email === selectedEmail))
                    }
                  >
                    <option value='' disabled>
                      {t('selectEmail')}...
                    </option>
                    {behalfeds.length > 0 ? (
                      behalfeds.map((u, i) => (
                        <option key={i} value={JSON.stringify(u)}>
                          {u.email}
                        </option>
                      ))
                    ) : (
                      <option value={selectedEmail}>{selectedEmail}</option>
                    )}
                  </Form.Select>
                  {/* {console.log(behalfeds)}
                  {console.log(inBehalfed)} */}
                  <Form.Control
                    className='position-absolute top-50 start-0 translate-middle-y rounded-0 rounded-start'
                    style={{ width: 'calc(100% - 40px' }}
                    required
                    type='email'
                    name='email_propietario'
                    value={selectedEmail ?? ''}
                    onChange={(e) => {
                      let email = e.target.value;
                      setSelectedEmail(e.target.value);
                      idriInitData.behalfeds.some((e) => e.email === email)
                        ? setSelectedNif(idriInitData.behalfeds.find((e) => e.email === email).nif)
                        : setSelectedNif('');
                      setNewInstInsert({
                        ...newInstInsert,
                        emailowner: email,
                      });
                    }}
                    onBlur={() =>
                      setInBehalfed(idriInitData.behalfeds.some((e) => e.email === selectedEmail))
                    }
                  />
                </div>
              </Col>
              {!inBehalfed && selectedEmail !== storedUser.email && (
                <Row className='d-flex justify-content-center align-items-center my-1 my-md-2 pt-md-2 pb-0'>
                  <Col xs={12} md={6}>
                    <Row className='d-flex justify-content-center align-items-center'>
                      <Col xs={8} className='d-md-flex'>
                        <strong>{t('toBehalfed')}</strong>
                      </Col>
                      <Col xs={4}>
                        <Form.Check
                          style={{ transform: 'scale(1.5)' }}
                          className='ms-4'
                          checked={newInstInsert.add_behalfed_list}
                          onChange={(e) => {
                            setNewInstInsert({
                              ...newInstInsert,
                              add_behalfed_list: e.target.checked,
                              behalfed_default: false,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className='mt-1 mt-md-0'>
                    <Row className='d-flex justify-content-center align-items-center'>
                      <Col xs={8} className='d-md-flex'>
                        <strong>{t('asDefault')}</strong>{' '}
                      </Col>
                      <Col xs={4}>
                        <Form.Check
                          style={{ transform: 'scale(1.5)' }}
                          className='ms-4'
                          disabled={!newInstInsert.add_behalfed_list}
                          checked={newInstInsert.behalfed_default}
                          onChange={(e) => {
                            setNewInstInsert({
                              ...newInstInsert,
                              behalfed_default: e.target.checked,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Row>
            <Row>
              <Col>
                <strong>{t('nifProp')}</strong>
                <div className='position-relative customFocus'>
                  <Form.Select
                    value=''
                    onChange={(e) => {
                      let email = JSON.parse(e.target.value).email;
                      setSelectedEmail(JSON.parse(e.target.value).email);
                      setSelectedNif(JSON.parse(e.target.value).nif);
                      setNewInstInsert({
                        ...newInstInsert,
                        emailowner: JSON.parse(e.target.value).email,
                        nifowner: JSON.parse(e.target.value).nif,
                      });
                      setInBehalfed(idriInitData.behalfeds.some((e) => e.email === email));
                    }}
                  >
                    <option value='' disabled>
                      {t('selectNif')}...
                    </option>
                    {behalfeds.length > 0 ? (
                      behalfeds.map((u, i) => (
                        <option key={i} value={JSON.stringify(u)}>
                          {u.nif}
                        </option>
                      ))
                    ) : (
                      <option value={selectedNif}>{selectedNif}</option>
                    )}
                  </Form.Select>
                  <Form.Control
                    className='position-absolute top-50 start-0 translate-middle-y rounded-0 rounded-start'
                    style={{ width: 'calc(100% - 40px' }}
                    required
                    type='text'
                    name='nif_propietario'
                    value={selectedNif ?? ''}
                    onChange={(e) => {
                      setNewInstInsert({
                        ...newInstInsert,
                        nifowner: e.target.value,
                      });
                      setSelectedNif(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{t('nombreCampo')}</strong>
                <Form.Control
                  required
                  type='text'
                  name='nombre_campo'
                  minLength='3'
                  maxLength='30'
                  value={
                    newInstInsert.idriname
                      ? newInstInsert.idriname
                      : idriInitData
                      ? idriInitData.idriname
                      : ''
                  }
                  onChange={(e) =>
                    setNewInstInsert({
                      ...newInstInsert,
                      idriname: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{t('idiomaPreferida')}</strong>
                <Form.Select
                  required
                  value={
                    newInstInsert.ownerlang
                      ? newInstInsert.ownerlang
                      : idriInitData.gamo
                      ? idriInitData.gamo.language
                      : ''
                  }
                  onChange={(e) =>
                    setNewInstInsert({
                      ...newInstInsert,
                      ownerlang: e.target.value,
                    })
                  }
                >
                  <option key='' value='' disabled>
                    {t('selectIdioma')}...
                  </option>
                  <option key='es' value='es'>
                    {t('es')}
                  </option>
                  <option key='en' value='en'>
                    {t('en')}
                  </option>
                  <option key='fr' value='fr'>
                    {t('fr')}
                  </option>
                </Form.Select>
              </Col>
            </Row>
          </div>

          <div id='nueva_instalacion_pick_map_section'>
            <Row className='text-center mt-3'>
              <Col>
                <Button
                  type='button'
                  className='btn-sm dark'
                  data-toggle='modal'
                  onClick={() => setMapModal(true)}
                >
                  {t('coordInstalacion')}
                </Button>

                <Row className='justify-content-center my-3'>
                  <Col xs={4} md={3}>
                    {t('latitud')}:{' '}
                    {typeof coord.lat == 'string' ? coord.lat : coord.lat.toFixed(5)}
                  </Col>
                  <Col xs={4} md={3}>
                    {t('longitud')}:{' '}
                    {typeof coord.lng == 'string' ? coord.lng : coord.lng.toFixed(5)}
                  </Col>
                </Row>

                <ModalGenerico
                  title={t('pulsaParaCoords')}
                  onClose={() => setMapModal(false)}
                  show={mapModal}
                >
                  <MapSrt
                    setCoord={setCoord}
                    setMapModal={setMapModal}
                    // closeModal={() => setMapModal(false)}
                  />
                </ModalGenerico>
              </Col>
            </Row>
          </div>

          {/* <div className='border border-dark p-2'>
            <Row>
              <Col className='text-center'>
                <strong>{t('alcance')}:</strong>
              </Col>
            </Row>
            <Col className='d-flex justify-content-center'>
              <Form.Check
                required={newInstInsert.comm}
                disabled={
                  idriInitData.entities &&
                  Object.keys(idriInitData.entities).length === 0
                }
                type='checkbox'
                label={t('perteneceComunidad')}
                checked={
                  newInstInsert.comm === true || newInstInsert.comm === false
                    ? newInstInsert.comm
                    : idriInitData.community
                }
                onChange={(e) =>
                  setNewInstInsert({
                    ...newInstInsert,
                    comm: e.target.checked,
                    free: !e.target.checked,
                  })
                }
              />
            </Col>
            <Col className='d-flex justify-content-center'>
              <Form.Check
                required={newInstInsert.free}
                disabled={!idriInitData.freeidriallowed}
                type='checkbox'
                label={t('libre')}
                checked={
                  newInstInsert.free === true || newInstInsert.free === false
                    ? newInstInsert.free
                    : !idriInitData.community
                }
                onChange={(e) => {
                  setNewInstInsert({
                    ...newInstInsert,
                    free: e.target.checked,
                    comm: !e.target.checked,
                    entity: '',
                    idriscope: 0,
                  });
                }}
              />
            </Col>
            {((newInstInsert.comm !== true &&
              newInstInsert.comm !== false &&
              idriInitData.community) ||
              newInstInsert.comm) && (
              <Row id='nueva_instalacion_selecciona_comunidad' className='mt-1'>
                <Col className='text-center'>
                  <strong>{t('selectComunidad')}</strong>
                  <Select menuShouldScrollIntoView
                    required={newInstInsert.comm}
                    placeholder={t('selectComun')}
                    // isClearable
                    isSearchable={true}
                    options={Object.entries(idriInitData.entities).map(
                      ([id, name]) => {
                        return {
                          id: id,
                          label: name,
                        };
                      }
                    )}
                    value={
                      newInstInsert.entity
                        ? newInstInsert.entity
                        : idriInitData.community
                        ? {
                            id: idriInitData.community.id,
                            label: idriInitData.community.name,
                          }
                        : ''
                    }
                    styles={{
                      option: (styles, { isFocused, isSelected }) => {
                        return {
                          ...styles,
                          backgroundColor: isFocused
                            ? 'rgba(0, 123, 255, 0.3)'
                            : isSelected && 'rgba(0, 0, 0, 0)',
                          color: isSelected && 'rgba(0, 0, 0)',
                        };
                      },
                    }}
                    onChange={(e) => {
                      setNewInstInsert({
                        ...newInstInsert,
                        entity: e,
                        idriscope: parseInt(e.id),
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
          </div> */}
          {/* {error && (
            <Col xs={12} className='alert alert-danger text-center p-0 my-0'>
              {error}
            </Col>
          )} */}
          <Col className='text-center my-3'>
            <Button type='submit' name='commit' className='btn btn-secondary text-uppercase dark'>
              {idriInitData.sim ? t('editInstalacion') : t('irConfig')}
            </Button>
          </Col>
        </Form>
      )}
    </div>
  );
};

export default IdriInit;
