import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IDRI, IDRI_DATA, IDRI_INIT_DATA } from '../../../../redux/constants/idriConstants';
import ModalGenerico from '../../../home/Modal_generico';
import Bank from '../../modals/Bank';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import { useSelector } from 'react-redux';

const NewIdri = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const go_to = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [error12, setError12] = useState(false);
  const [userData, setUserData] = useState(null);
  const [bankModal, setBankModal] = useState(false);
  const userStore = useSelector((state) => state.user);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const dispatch = useDispatch();
  //redux hooks
  const [initInst, setInitInst] = useState({ idriserial: '', control: '' });
  const [pList, setPList] = useState([]);
  //check device availability
  const checkIdri = (e) => {
    setIsPending(true);
    setError(null);
    setError12(false);
    setUserData(null);
    e.preventDefault();
    if (initInst.idriserial && initInst.idriserial === '') {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_SRT}/v2/newidri?idriserial=${initInst.idriserial}&control=${initInst.control}`,
          config
        )
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setIsPending(false);
            let obj = { ...res.data, control: initInst.control };
            dispatch({
              type: IDRI_INIT_DATA,
              payload: obj,
            });
            props.setPage('init');
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError, null, null, setError12);
        });
    }
  };
  // console.log('render');
  const pendingList = () => {
    setIsPending(true);
    setError(null);
    // e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/instdischargependingidris`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setIsPending(false);
          setPList(res.data.pending_user_inst);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getIdriInitData = (id, serial) => {
    setIsPending(true);
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/editidri?ididri=${id}&idriserial=${serial}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          //store data in redux storage
          dispatch({
            type: IDRI_INIT_DATA,
            payload: res.data,
          });
          props.setPage('init');
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getUser = (id, email) => {
    setError12(false);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/user?iduser=${id}&email=${email}`, config)
      .then((res) => {
        console.log(res.data.user);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setUserData(res.data.user.data);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    !token && go_to('/');

    dispatch({
      type: IDRI_INIT_DATA,
      payload: {},
    });
    dispatch({
      type: IDRI_DATA,
      payload: {},
    });
    dispatch({
      type: IDRI,
      payload: {},
    });
    pendingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   error12 && getUser(props.idUser, props.email);
  // }, [error12]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
    error12 && getUser(userStore.usuarioid, userStore.email);
    setIsPending(false);
  }, [error]);

  return (
    <>
      <h5 className='text-center '>{t('indiqueSerie')}</h5>
      <Form className='my-3' onSubmit={(e) => checkIdri(e)}>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>
                <strong>{t('numeroSerie')}</strong>
              </Form.Label>
              <Form.Control
                type='text'
                name='serial'
                required
                onChange={(e) =>
                  setInitInst({
                    ...initInst,
                    idriserial: e.target.value,
                  })
                }
              />
            </Col>
            <Col>
              <Form.Label>
                <strong>{t('numeroControlador')}</strong>
              </Form.Label>
              <Form.Control
                type='text'
                name='control'
                required
                onChange={(e) =>
                  setInitInst({
                    ...initInst,
                    control: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Col className='mt-3 text-center'>
          <Button
            id='boton_nueva_instalacion'
            type='submit'
            className='dark'
            // variant='primary'
            // size='sm'
          >
            {t('empezarConfig')}
          </Button>
        </Col>
      </Form>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : (
        <>
          {pList.length > 0 && (
            <>
              <hr className='my-4'></hr>
              <h5 className='text-center '>{t('selectPending')}</h5>
              <Row className='justify-content-center'>
                {pList &&
                  pList.map((idri) => (
                    <Col key={idri.ididri} xs={6} md={3} className='text-center mb-2'>
                      <Button
                        variant='outline-success'
                        size='sm'
                        onClick={() => {
                          getIdriInitData(idri.ididri, idri.idriserial);
                        }}
                      >
                        {t('editar')} {idri.idriserial}
                      </Button>
                    </Col>
                  ))}
              </Row>
            </>
          )}
          {error12 && (
            <>
              {userData && (
                <>
                  <hr className='mt-2 mb-2'></hr>
                  <Col>
                    <h6 className='text-center text-danger '>{t('bankNeeded')}</h6>
                  </Col>
                  <Col className='text-center'>
                    <Button
                      // variant='primary'
                      // size='sm'
                      className='dark'
                      onClick={() => setBankModal(true)}
                    >
                      {t('bank')}
                    </Button>
                  </Col>
                  <ModalGenerico
                    title={t('bank')}
                    onClose={() => {
                      setBankModal(false);
                    }}
                    show={bankModal}
                  >
                    <Bank
                      id={userData.iduser}
                      email={userData.email}
                      setUserData={setUserData}
                      setError12={setError12}
                      closeModal={() => setBankModal(false)}
                    />
                  </ModalGenerico>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewIdri;
