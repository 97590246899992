import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import unavailable from '../../../support_func/unavailable';
import axios from 'axios';
import errorHandler from '../../../support_func/errorHandler';
import { useNavigate } from 'react-router-dom';

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const go_to = useNavigate();
  const token = localStorage.getItem('autoAcceso');
  const initialState = {
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
  };
  const [inputState, setInputState] = useState(initialState);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const Submit = (e) => {
    e.preventDefault();
    setIsPending(true);
    if (
      inputState.currentPassword === '' ||
      inputState.newPassword === '' ||
      inputState.newPassword2 === ''
    ) {
      setIsPending(false);
      setError(t('camposVacios'));
    } else if (inputState.newPassword !== inputState.newPassword2) {
      setIsPending(false);
      setError(t('noSimilar'));
    } else {
      let body = {
        currentpassword: inputState.currentPassword,
        newpassword: inputState.newPassword,
      };
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/userpwdchange`, body, config)
        .then(async (res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            localStorage.setItem('autoAcceso', res.data.token);
            setIsPending(false);
            await setError(t('passSuccess'));
            setInputState(initialState);
            // props.closeModal();
            window.location.href = '/inicio';
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError, true);
        });
    }
  };

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Form onSubmit={(e) => window.confirm(t('seguro')) && Submit(e)}>
      <Row className='px-0 justify-content-center'>
        <Col xs={12} md={6}>
          <strong>{t('passCurrent')}</strong>
          <Form.Control
            required
            className='text-center'
            type='password'
            minLength={8}
            maxLength={50}
            value={inputState.currentPassword}
            onChange={(e) => setInputState({ ...inputState, currentPassword: e.target.value })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <strong>{t('newPass').toLowerCase()}</strong>
          <Form.Control
            required
            className='text-center'
            type='password'
            minLength={8}
            maxLength={50}
            value={inputState.newPassword}
            onChange={(e) => setInputState({ ...inputState, newPassword: e.target.value })}
          />
        </Col>
        <Col xs={12} md={6}>
          <strong>{t('newPassRepeat')}</strong>
          <Form.Control
            required
            className='text-center'
            type='password'
            minLength={8}
            maxLength={50}
            value={inputState.newPassword2}
            onChange={(e) => setInputState({ ...inputState, newPassword2: e.target.value })}
          />
        </Col>
      </Row>
      <Row className='text-center mt-3'>
        <Col>
          <Button
            type='submit'
            // variant='primary'
            size='lg'
            className='py-md-1 dark'
          >
            {t('change').toUpperCase()}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
