/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGED, IDRI } from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import ActuatorsControl from '../newInstalation/ActuatorsControl';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';

const IdriManual = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const dispatch = useDispatch();
  //redux hooks
  const idri = useSelector((state) => state.idri);
  const changed = useSelector((state) => state.changed);

  const [runAsync, setRunAsync] = useState(false);
  // const [, forceUpdate] = useState(0);
  // const [autoUse, setAutoUse] = useState(false);
  const [act, setAct] = useState({});

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 2000);
    }
  };
  //get async response from device
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          if (res.data.found === true && res.data.response.result === true) {
            // setIsPending(false);
            getIdri();
            scrollToElement('error', true, 'start', 300);
            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdri(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
              // props.control === 'manual' && props.setControl('');
            } else {
              getIdri(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
              // props.control === 'manual' && props.setControl('');
            }
            scrollToElement('manual', true, 'start', 300);
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            getIdri(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            props.control === 'manual' && idri.wkmode === 0 && props.setControl('');
            // setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  //get device data
  const getIdri = (errorAsync) => {
    setIsPending(true);
    setError(null);
    setRunAsync(false);
    Object.keys(idri).length !== 0 &&
      dispatch({
        type: IDRI,
        payload: {},
      });
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(errorAsync);
          errorAsync && props.setError(errorAsync);
          errorAsync && props.control === 'manual' && idri.wkmode === 0 && props.setControl('');
          localStorage.setItem('stage', ['testInst', idri.ghatorid, idri.ghatorid]);

          let idriCopy = JSON.parse(JSON.stringify(res.data.ghator));

          const actuatorToUpdate = idriCopy.actuators.find(
            (a) =>
              !a.is_master &&
              a.status === 1 &&
              a.status_change_time !== null &&
              a.status_change_time <= 6
          );
          if (actuatorToUpdate) {
            console.log('some');
            actuatorToUpdate.status = 1;
            actuatorToUpdate.status_change_time = 7;
            // actuatorToUpdate.status = 0;
          }

          dispatch({
            type: IDRI,
            payload: idriCopy,
          });

          // dispatch({
          //   type: IDRI,
          //   payload: res.data.idri,
          // });
          if (changed.test && changed.test === true) {
            dispatch({
              type: CHANGED,
              payload: { ...changed, test: false },
            });
          }
          // setLastUpdate(moment().format('L') + ' ' + moment().format('LTS'));
          // setIsPending(false);
          parseFloat(idri.ghatorv) >= 3
            ? setAct(
                res.data.ghator.ghatorserial
                  ? res.data.ghator.actuators.map((actuator) => ({
                      idactuator: actuator.idactuator,
                      status:
                        res.data.ghator.master_en &&
                        res.data.ghator.wkmode === 2 &&
                        !actuator.is_master &&
                        actuator.status === 1 &&
                        actuator.status_change_time < 3
                          ? 0
                          : actuator.status,
                      // status: actuator.status,
                      duration: { h: 0, m: 0, s: 0 },
                      volumeAsoc:
                        actuator.fluid.total_consum_fm_related &&
                        actuator.fluid.total_consum_fm_related !== '0.0'
                          ? actuator.fluid.total_consum_fm_related
                          : 0,
                      flow:
                        actuator.fluid.total_consum_flow_related &&
                        actuator.fluid.total_consum_flow_related !== '0.0'
                          ? actuator.fluid.total_consum_flow_related
                          : 0,
                      permanent:
                        actuator.status === 0
                          ? false
                          : (actuator.status === 1 &&
                              actuator.status_change_time === null &&
                              Object.keys(actuator.fluid).length > 0 &&
                              actuator.fluid.status_change_afmv_remain === null &&
                              actuator.fluid.status_change_afv_remain === null) ||
                            (actuator.status === 1 &&
                              actuator.status_change_time === null &&
                              Object.keys(actuator.fluid).length === 0)
                          ? true
                          : false,
                    }))
                  : []
              )
            : setAct(
                res.data.ghator.ghatorserial
                  ? res.data.ghator.actuators.map((actuator) => ({
                      idactuator: actuator.idactuator,
                      status: actuator.status,
                      minutes: '',
                    }))
                  : []
              );
          props.setAutoUse(res.data.ghator.master_en);
          setIsPending(false);
          // forceUpdate((n) => !n);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //send changed actuators state to server
  const postOperations = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {};
    parseFloat(idri.ghatorv) < 3
      ? (body = {
          ghatorid: parseInt(idri.ghatorid),
          ghatorserial: idri.ghatorserial,
          actuators: act.map((a) =>
            Object.values(idri.actuators).find((obj) => {
              return obj.idactuator === a.idactuator && obj;
            }).status !== a.status ||
            Object.values(idri.actuators).find((obj) => {
              return obj.idactuator === a.idactuator && obj;
            }).status === 1
              ? {
                  idactuator: a.idactuator,
                  status: a.status,
                  minutes: a.duration !== null && a.duration !== undefined ? a.duration : '',
                }
              : null
          ),
        })
      : (body = {
          ghatorid: parseInt(idri.ghatorid),
          ghatorserial: idri.ghatorserial,
          // master_en: autoUse,
          actuators: act.map((a) =>
            (idri.master_en && a.is_master) ||
            (a.status === 1 &&
              !a.permanent &&
              a.volumeAsoc === '' &&
              a.flow === '' &&
              parseInt(a.duration.h) * 60 * 60 +
                parseInt(a.duration.m) * 60 +
                parseInt(a.duration.s) ===
                0)
              ? null
              : (Object.values(idri.actuators).find((obj) => {
                  return obj.idactuator === a.idactuator && obj;
                }).status !== a.status ||
                  Object.values(idri.actuators).find((obj) => {
                    return obj.idactuator === a.idactuator && obj;
                  }).status === 1) &&
                (a.permanent ||
                  parseInt(a.duration.h) * 60 * 60 +
                    parseInt(a.duration.m) * 60 +
                    parseInt(a.duration.s) !==
                    0 ||
                  a.volumeAsoc !== 0 ||
                  a.flow !== 0)
              ? {
                  idactuator: a.idactuator,
                  status: a.status,
                  seconds: a.permanent
                    ? ''
                    : a.duration &&
                      parseInt(a.duration.h) * 60 * 60 +
                        parseInt(a.duration.m) * 60 +
                        parseInt(a.duration.s) !==
                        0
                    ? parseInt(a.duration.h) * 60 * 60 +
                      parseInt(a.duration.m) * 60 +
                      parseInt(a.duration.s)
                    : '',
                  afmv: a.permanent ? '' : a.volumeAsoc && a.volumeAsoc !== 0 ? a.volumeAsoc : '',
                  afv: a.permanent ? '' : a.flow && a.flow !== 0 ? a.flow : '',
                }
              : null
          ),
        });
    console.log(body);
    if (
      parseFloat(idri.ghatorv) < 3 &&
      body.actuators
        .map((e) => {
          if (e !== null && e.status === 1 && e.minutes === '') {
            return true;
          } else {
            return false;
          }
        })
        .every((e) => e === true)
    ) {
      setIsPending(false);
      setError(t('withTime'));
    } else if (body.actuators.every((e) => e === null)) {
      setIsPending(false);
      setError(t('permanent'));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/directoperation`, body, config)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            counter = 0;
            if (counter === 0) {
              setRunAsync(true);
              setTimeout(() => {
                checkAsync(res.data.asyncid);
              }, 6000);
            }
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  //device data refresh
  const getRefresh = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/grefresh?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 2000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      postOperations(e);
    }
  };

  useEffect(() => {
    Object.keys(idri).length > 0 && parseFloat(idri.ghatorv) >= 3
      ? setAct(
          idri.ghatorserial
            ? idri.actuators.map((actuator) => ({
                idactuator: actuator.idactuator,
                status: actuator.status,
                duration: { h: 0, m: 0, s: 0 },
                volumeAsoc:
                  actuator.fluid.total_consum_fm_related &&
                  actuator.fluid.total_consum_fm_related !== '0.0'
                    ? actuator.fluid.total_consum_fm_related
                    : 0,
                flow:
                  actuator.fluid.total_consum_flow_related &&
                  actuator.fluid.total_consum_flow_related !== '0.0'
                    ? actuator.fluid.total_consum_flow_related
                    : 0,
                permanent:
                  actuator.status === 0
                    ? false
                    : (actuator.status === 1 &&
                        actuator.status_change_time === null &&
                        Object.keys(actuator.fluid).length > 0 &&
                        actuator.fluid.status_change_afmv_remain === null &&
                        actuator.fluid.status_change_afv_remain === null) ||
                      (actuator.status === 1 &&
                        actuator.status_change_time === null &&
                        Object.keys(actuator.fluid).length === 0)
                    ? true
                    : false,
              }))
            : []
        )
      : setAct(
          idri.ghatorserial
            ? idri.actuators.map((actuator) => ({
                idactuator: actuator.idactuator,
                status: actuator.status,
                minutes: '',
              }))
            : []
        );
    scrollToElement('manual', true, 'start', 300);
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(idri);
  return (
    <>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      )}
      <Table className={`mb-0 ${!idri.user_permissions.action && 'disabledCustom'}`} id='manual'>
        <ActuatorsControl
          refresh={getRefresh}
          getIdri={getIdri}
          setAct={setAct}
          autoUse={props.autoUse}
          setAutoUse={props.setAutoUse}
          handleSubmit={handleSubmit}
          isPending={isPending}
        />
      </Table>
      {Object.keys(act).length > 0 && (
        <Col className='text-center mt-3'>
          <Button
            type='submit'
            form={parseFloat(idri.ghatorv) < 3 ? 'actuatorsForm3' : 'actuatorsForm'}
            className='dark text-uppercase'
            disabled={!idri.user_permissions.action}
            // onClick={(e) => postOperations(e)}
          >
            {t('enviar')}
          </Button>
        </Col>
      )}
    </>
  );
};

export default IdriManual;
