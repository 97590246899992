import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userReducer,
  sensorsListReducer,
  idriInitDataReducer,
  idriDataReducer,
  actuatorsListReducer,
  idriReducer,
  changedReducer,
  markersReducer,
  asyncReducer,
  asyncCounterReducer,
} from './reducers/idriReducer';

//redux storage hooks
const reducer = combineReducers({
  user: userReducer,

  idriInitData: idriInitDataReducer,
  idriData: idriDataReducer,

  sensorsList: sensorsListReducer,
  actuatorsList: actuatorsListReducer,

  idri: idriReducer,

  markers: markersReducer,

  changed: changedReducer,

  asyncControl: asyncReducer,
  asyncCounter: asyncCounterReducer,
});

const initialState = {};

// const middleware = [thunk];

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware,))
// );

const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk)));

export default store;
