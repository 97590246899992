/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState } from 'react';
import { createRef } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDRI_INIT_DATA } from '../../../redux/constants/idriConstants';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const BoardChange = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ serie: '', control: '' });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let innerRef = createRef();
  const dispatch = useDispatch();
  const idriInitData = useSelector((state) => state.idriInitData);
  //redux hooks
  const [newBoard, setNewBoard] = useState({
    idriserial: '',
    control: '',
    v_idri: '',
  });
  //check device availability
  const checkIdri = (e) => {
    setIsPending(true);
    setError(null);
    e.preventDefault();
    if (newBoard.idriserial && newBoard.idriserial === '') {
      setIsPending(false);
      setError(t('camposVacios'));
      // } else if (
      //   (parseFloat(idriInitData.v_idri) < 3.0 && newBoard.idriserial >= 5000) ||
      //   (idriInitData.idriserial >= 5000 && newBoard.idriserial < 5000)
      // ) {
      //   setIsPending(false);
      //   setError('permitido cambiar la placa solo de la misma versión');
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_SRT}/v2/newidri?idriserial=${newBoard.idriserial}&control=${newBoard.control}`,
          config
        )
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            if (
              (parseFloat(idriInitData.v_idri) < 3.0 && parseFloat(res.data.v_idri) >= 3.0) ||
              (parseFloat(idriInitData.v_idri) >= 3.0 && parseFloat(res.data.v_idri) < 3.0)
            ) {
              setIsPending(false);
              setError(t('permetidoMismaVersion'));
            } else {
              setIsPending(false);
              dispatch({
                type: IDRI_INIT_DATA,
                payload: {
                  ...idriInitData,
                  newIdriserial: newBoard.idriserial,
                  newControl: newBoard.control,
                },
              });
              props.setNewBoard(true);
              props.setBoardModal(false);
            }
            // } else {
            //   setIsPending(false);
            //   setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  //auto hide error message
  error &&
    setTimeout(() => {
      setError(null);
    }, 10000);

  useEffect(() => {
    setTimeout(() => {
      innerRef.current.focus();
    }, 1);
  }, []);

  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : (
        <div className='my-3'>
          <Form.Group>
            <Row>
              <Col xs={12} md={6} className='text-start'>
                <Form.Label>
                  <strong>{t('numeroSerie')}</strong>
                </Form.Label>
                <Form.Control
                  ref={innerRef}
                  type='number'
                  name='serial'
                  required
                  value={data.serie ? data.serie : ''}
                  onChange={(e) => {
                    setNewBoard({
                      ...newBoard,
                      idriserial: e.target.value,
                    });
                    setData({ ...data, serie: e.target.value });
                  }}
                />
              </Col>
              <Col xs={12} md={6} className='text-start'>
                <Form.Label>
                  <strong>{t('numeroControlador')}</strong>
                </Form.Label>
                <Form.Control
                  type='number'
                  name='control'
                  required
                  value={data.control ? data.control : ''}
                  onChange={(e) => {
                    setNewBoard({
                      ...newBoard,
                      control: e.target.value,
                    });
                    setData({ ...data, control: e.target.value });
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          <Col className='mt-3'>
            <Button className='btn-sm dark' onClick={(e) => checkIdri(e)}>
              {t('aceptar')}
            </Button>
          </Col>
        </div>
      )}
      {error && (
        <Col xs={12} className='alert alert-danger text-center p-0 my-0'>
          {error}
        </Col>
      )}
    </>
  );
};

export default BoardChange;
