import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';

const ProgsDisable = (props) => {
  const { t } = useTranslation();
  const [dateActive, setDateActive] = useState(false);

  const Input = React.forwardRef(({ value, onChange, onClick }, ref) => (
    <InputGroup className='noBoxShadow'>
      <Form.Control className='text-center' value={value} onChange={onChange} onClick={onClick} />
      <Button variant='outline-secondary' onClick={() => props.setEndDate('')}>
        <FaTimes />
      </Button>
    </InputGroup>
  ));

  return (
    <Row>
      <Col xs={12}>
        <strong>{t('suspProgsConfirm')}</strong>
      </Col>
      <Col xs={12} className='my-3'>
        {t('wantEnableDate')}
        <Form.Check
          type='switch'
          className='noBoxShadow'
          id='c-switch'
          checked={dateActive}
          onChange={(e) => {
            setDateActive(!dateActive);
            !e.target.checked && props.setEndDate('');
          }}
        />
      </Col>
      {dateActive && (
        <Col xs={12} className='mb-3'>
          {t('enableDate')}
          <DatePicker
            required
            locale={localStorage.getItem('language')}
            calendarStartDay={1}
            // customInput={<Form.Control className='text-center' />}
            customInput={<Input />}
            dateFormat='dd/MM/yyyy H:mm'
            selected={props.endDate}
            onChange={(date) => props.setEndDate(date)}
            showTimeSelect
            timeFormat='H:mm'
            timeIntervals={10}
            minDate={moment().toDate()}
            minTime={
              props.endDate && !moment().isSame(moment(props.endDate), 'day')
                ? moment().startOf('day')
                : moment().add(10, 'm').toDate()
            }
            maxTime={moment().endOf('day').toDate()}
            timeCaption={t('time')}
            withPortal
            onCalendarClose={() =>
              props.endDate < moment().add(10, 'm').toDate() &&
              props.setEndDate(moment().add(10, 'm').toDate())
            }
          />
        </Col>
      )}
      <Col xs={12}>
        <Button
          // size='sm'
          variant='danger'
          onClick={(e) => {
            props.allProgram(e);
            props.closeModal();
          }}
        >
          {t('suspProgs')}
        </Button>
      </Col>
    </Row>
  );
};

export default ProgsDisable;
