/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// import { MARKERS } from '../../../redux/constants/idriConstants';
import isValidIban from '../../../support_func/isValidIban';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const Bank = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [userBank, setUserBank] = useState({});
  const [newUserBank, setNewUserBank] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getUserBank = (id, email) => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/userbank?iduser=${id}&email=${email}`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          Object.keys(res.data.user_bank.data).length === 0 && setNewUserBank(true);
          setUserBank(res.data.user_bank.data);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postUserBank = (e, newData) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      iduser: props.iduser,
      bank_name: userBank.bank_name,
      holder: userBank.holder,
      IBAN: userBank.IBAN,
      email: props.email,
    };
    console.log(body);
    if (isValidIban(body.IBAN)) {
      (newData
        ? axios.post(`${process.env.REACT_APP_API_SRT}/v2/userbank`, body, config)
        : axios.patch(`${process.env.REACT_APP_API_SRT}/v2/userbank`, body, config)
      )
        .then((res) => {
          // console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setError(`${newData ? t('created') : t('modificated')} ${t('success')}`);
            getUserBank(props.iduser, props.email);
            setNewUserBank(false);
            setIsPending(false);
            props.setUserData && props.setUserData(null);
            props.setError12 && props.setError12(false);
            props.closeModal();
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
          setIsPending(false);
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setError(t('ibanIncorecto'));
      setIsPending(false);
    }
  };

  const getNewUserBank = () => {
    setIsPending(true);
    console.log(props);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghatoruserdischarge?ghatorid=${props.ghatorid}&ghatorserial=${props.ghatorserial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          res.data.user_bank.IBAN === null && setNewUserBank(true);
          setUserBank({ ...res.data.user_bank, idriname: props.ghatorname });
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postNewUserBank = (e, newData) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: props.ghatorid,
      ghatorserial: props.ghatorserial,
      ghatorname: userBank.idriname ? userBank.idriname : props.ghatorname,
      user_bank: {
        bank_name: userBank.bank_name,
        holder: userBank.holder,
        IBAN: userBank.IBAN,
      },
    };
    console.log(body);
    if (isValidIban(body.user_bank.IBAN)) {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/ghatoruserdischarge`, body, config)
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setError(`${newData ? t('created') : t('modificated')} ${t('success')}`);
            // let markerIndex = props.markers.findIndex(
            //   (m) => m.ididri == props.ididri
            // );
            // props.markers[markerIndex].user_discharge = true;
            // dispatch({
            //   type: MARKERS,
            //   payload: props.markers,
            // });
            props.getMarkers();
            props.getIdri();
            setIsPending(false);
            props.closeModal();
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
          setIsPending(false);
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setError(t('ibanIncorecto'));
      setIsPending(false);
    }
  };

  useEffect(() => {
    props.iduser && getUserBank(props.iduser, props.email);
    props.ghatorid && getNewUserBank();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}
      <Form
        onSubmit={(e) =>
          props.ghatorid ? postNewUserBank(e, newUserBank) : postUserBank(e, newUserBank)
        }
      >
        <Row className='px-0'>
          <Col xs={12} md={4}>
            <strong>{t('holder')}</strong>
            <Form.Control
              required={newUserBank || 'ididri' in props}
              type='text'
              maxLength={150}
              value={userBank.holder ? userBank.holder : ''}
              onChange={(e) => setUserBank({ ...userBank, holder: e.target.value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <strong>IBAN</strong>
            <Form.Control
              required={newUserBank || 'ididri' in props}
              type='text'
              value={userBank.IBAN ? userBank.IBAN : ''}
              onChange={(e) => setUserBank({ ...userBank, IBAN: e.target.value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <strong>{t('bankName')}</strong>
            <Form.Control
              required={newUserBank || 'ididri' in props}
              type='text'
              maxLength={50}
              value={userBank.bank_name ? userBank.bank_name : ''}
              onChange={(e) =>
                setUserBank({
                  ...userBank,
                  bank_name: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        {props.ghatorname && (
          <Row>
            <Col>
              <hr className='mb-1 mt-3'></hr>
              <strong>{t('nombreCampo')}</strong>
              <Form.Control
                required={newUserBank || 'ididri' in props}
                type='text'
                className='text-center'
                maxLength={150}
                value={userBank.idriname}
                onChange={(e) => setUserBank({ ...userBank, idriname: e.target.value })}
              />
            </Col>
          </Row>
        )}
        <Row className='text-center mt-3'>
          <Col>
            <Button
              disabled={'editBank' in props && !props.editBank}
              type='submit'
              // variant='primary'
              size='lg'
              className='py-md-1 dark'
            >
              {props.ghatorid
                ? t('darAlta').toUpperCase()
                : `${newUserBank ? t('create') : t('modificar').toUpperCase()} ${t(
                    'bank'
                  )}`.toUpperCase()}
              {}
            </Button>
          </Col>
        </Row>
      </Form>
      {/* </>
      )} */}
    </>
  );
};

export default Bank;
