import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DevicesMap = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        Cupidatat occaecat commodo nisi nulla culpa ad cillum laborum Lorem. Tempor voluptate et
        aliquip deserunt nulla anim duis incididunt veniam nisi. Velit culpa et minim sint mollit
        aliqua. Labore consequat aliqua elit sunt dolore ex amet et et voluptate.
      </Row>
    </Container>
  );
};

export default DevicesMap;
