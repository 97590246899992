/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import IdriPrograms from './IdriPrograms';
import IdriManual from './IdriManual';
import Timer from '../../../../support_func/Timer';
import IdriAlerts from './IdriAlerts';
import { useDispatch, useSelector } from 'react-redux';
import { IDRI, IDRI_DATA, IDRI_INIT_DATA } from '../../../../redux/constants/idriConstants';
import SensorsControl from '../newInstalation/SensorsControl';
import IdriRules from './IdriRules';
import IdriHistorical from './IdriHistorical';
import moment from 'moment';
import variables from '../../../../scss/variables.scss';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import ModalGenerico from '../../../home/Modal_generico';
import ProgsDisable from '../../modals/ProgsDisable';
import scrollToElement from '../../../../support_func/scrollToElement';

const IdriManage = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [control, setControl] = useState(null);
  const [runAsync, setRunAsync] = useState(false);
  const [status, setStatus] = useState(false);
  const [autoUse, setAutoUse] = useState(false);
  const [password, setPassword] = useState(null);
  const firmInit = {
    modal: false,
    enable: null,
    fail: null,
    warning: null,
    data: null,
  };
  const [firmware, setFirmware] = useState(firmInit);
  const [programsDisModal, setProgramsDisModal] = useState(false);
  const [endDate, setEndDate] = useState();

  const dispatch = useDispatch();
  const idri = useSelector((state) => state.idri);
  const markers = useSelector((state) => state.markers);
  const userStore = useSelector((state) => state.user);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log(props);
  //get device data
  const getIdri = (errorAsync, serial, id) => {
    setIsPending(true);
    setError(null);
    setFirmware(firmInit);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${
          id ? id : props.ghator && props.ghator.id ? props.ghator.id : props.ghator.ghatorid
        }&ghatorserial=${
          serial
            ? serial
            : props.ghator && props.ghator.serial
            ? props.ghator.serial
            : props.ghator.ghatorserial
        }`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        // console.log(res.data);
        if (res.data.code === '0') {
          console.log(res.data.ghator);
          errorAsync && setError(errorAsync);
          endDate && setEndDate('');
          dispatch({
            type: IDRI,
            payload: res.data.ghator,
          });
          // setIdri(res.data.idri);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 2000);
    }
  };
  //get async response from device
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          if (res.data.found === true && res.data.response.result === true) {
            getIdri(null, idri.ghatorserial, idri.ghatorid);
            // getIdri(t('conExito'), idri.idriserial, idri.ididri);
            scrollToElement('error', true, 'start', 300);
            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            // setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdri(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown'),
                idri.ghatorserial,
                idri.ghatorid
              );
              control === 'manual' && setControl('');
            } else {
              getIdri(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown'),
                idri.ghatorserial,
                idri.ghatorid
              );
              control === 'manual' && setControl('');
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            getIdri(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection'),
              idri.ghatorserial,
              idri.ghatorid
            );
            control === 'manual' && setControl('');
            // setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  //device data refresh
  const getRefresh = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/grefresh?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 2000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getCRefresh = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/idricrefresh?ididri=${idri.ghatorid}&idriserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 2000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  // const cAlta = (e) => {
  //   e && e.preventDefault();
  //   setIsPending(true);
  //   setError(null);
  //   let body = { ididri: idri.ididri, idriserial: idri.idriserial };
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_SRT}/v2/cancelnewidridischarge`,
  //       body,
  //       config
  //     )
  //     .then((res) => {
  //       if (res.data.code === '0') {
  //         setIsPending(false);
  //         console.log(res.data);
  //         window.alert('alta cancelada');
  //       } else {
  //         setIsPending(false);
  //         setError(t('problem'));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);
  //       let code = err.response.data.code;
  //       if (code === '1') {
  //         setIsPending(false);
  //         localStorage.removeItem('autoAcceso');
  //         alert(t('sessionExpired'));
  //         window.location.href = '/';
  //       } else {
  //         setIsPending(false);
  //         setError(
  //           t(`error${err.response.data.msg_code}`)
  //             ? t(`error${err.response.data.msg_code}`)
  //             : t('errorUnknown')
  //         );
  //       }
  //     });
  // };

  const masterEn = (e, state) => {
    // console.log(state);
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      master_en: state,
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/masteren`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 2000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const allProgram = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      enable: idri.all_programs_disabled,
      renable_date: endDate ? moment.utc(endDate).local().format('DD-MM-YYYY HH:mm:ss') : null,
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/prograblall`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const numOrNot = (value, isInt) => {
    const num = isInt ? parseInt(value) : parseFloat(value);

    return Number.isNaN(num) ? null : num;
  };

  const returnSensorsList = (obj) => {
    let fm = Object.entries(obj.fm).map(([k, v]) => {
      return {
        idsensor: k,
        leak_en: v.leak_en,
        leak_threshold: numOrNot(v.leak_threshold),
        leak_close_en: v.leak_close_en,
        leak_close_n: numOrNot(v.leak_close_n),
        leak_close_delay: numOrNot(v.leak_close_delay),
        leak_reset_t: numOrNot(v.leak_reset_t),
        noflow_en: v.noflow_en,
        noflow_delay: numOrNot(v.noflow_delay),
        flow_value_en: v.flow_value_en,
        flow_more_than: numOrNot(v.flow_more_than),
        flow_less_than: numOrNot(v.flow_less_than),
        cmax_day_en: v.cmax_day_en,
        cmax_day_threshold: numOrNot(v.cmax_day_threshold),
        cmax_month_en: v.cmax_month_en,
        cmax_month_threshold: numOrNot(v.cmax_month_threshold),
        cmax_month_threshold_fw: numOrNot(v.cmax_month_threshold_fw),
        cmin_day_en: v.cmin_day_en,
        cmin_day_threshold: numOrNot(v.cmin_day_threshold),
      };
    });
    let nfm = Object.entries(obj.nfm).map(([k, v]) => {
      // delete v['name'];
      // delete v['status'];
      // delete v['type']
      return {
        idsensor: k,
        state_value_alert_en: v.state_value_alert_en,
        value_more_than: numOrNot(v.value_more_than),
        value_less_than: numOrNot(v.value_less_than),
      };
    });
    return fm.concat(nfm);
  };

  const editAlerts = (e, type, id, obj) => {
    let sensors = structuredClone(obj);
    // console.log(type);
    // console.log(sensors);
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {};
    if (id) {
      // console.log(sensors[type][id]);
      body = {
        ghatorid: idri.ghatorid,
        ghatorserial: idri.ghatorserial,
        sensors: [
          {
            idsensor: id,
            leak_en: sensors[type][id].leak_en,
            leak_threshold: numOrNot(sensors[type][id].leak_threshold),
            leak_close_en: sensors[type][id].leak_close_en,
            leak_close_n:
              numOrNot(sensors[type][id].leak_close_n, true) ||
              numOrNot(sensors[type][id].leak_close_en && sensors[type][id].leak_close_n_min, true),
            leak_close_delay:
              numOrNot(sensors[type][id].leak_close_delay, true) ||
              numOrNot(
                sensors[type][id].leak_close_en && sensors[type][id].leak_close_delay_min,
                true
              ),
            leak_reset_t:
              numOrNot(sensors[type][id].leak_reset_t, true) ||
              numOrNot(sensors[type][id].leak_close_en && sensors[type][id].leak_reset_t_min, true),
            noflow_en: sensors[type][id].noflow_en,
            noflow_delay:
              numOrNot(sensors[type][id].noflow_delay, true) ||
              numOrNot(sensors[type][id].leak_close_en && sensors[type][id].noflow_delay_min, true),
            flow_value_en: sensors[type][id].flow_value_en,
            flow_more_than: numOrNot(sensors[type][id].flow_more_than),
            flow_less_than: numOrNot(sensors[type][id].flow_less_than),
            cmax_day_en: sensors[type][id].cmax_day_en,
            cmax_day_threshold: numOrNot(sensors[type][id].cmax_day_threshold),
            cmax_month_en: sensors[type][id].cmax_month_en,
            cmax_month_threshold: numOrNot(sensors[type][id].cmax_month_threshold),
            cmax_month_threshold_fw: numOrNot(sensors[type][id].cmax_month_threshold_fw),
            cmin_day_en: sensors[type][id].cmin_day_en,
            cmin_day_threshold: numOrNot(sensors[type][id].cmin_day_threshold),
            state_value_alert_en: sensors[type][id].state_value_alert_en,
            value_more_than: numOrNot(sensors[type][id].value_more_than),
            value_less_than: numOrNot(sensors[type][id].value_less_than),
          },
        ],
      };
    } else {
      body = {
        ghatorid: idri.ghatorid,
        ghatorserial: idri.ghatorserial,
        sensors: returnSensorsList(sensors),
      };
    }

    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/setalerts`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }
          // setIsPending(false);
          // getIdri();
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const deleteIdri = (e) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_SRT}/v2/removeidri?ididri=${idri.ghatorid}&idriserial=${idri.ghatorserial}&password=${password}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setPassword(null);
          localStorage.removeItem(`init-${idri.ghatorserial}`);
          localStorage.removeItem(`zonaBatTec-${idri.ghatorserial}`);
          localStorage.removeItem(`sensConf-${idri.ghatorserial}`);
          localStorage.removeItem(`actConf-${idri.ghatorserial}`);
          localStorage.removeItem(`newprog-${idri.ghatorserial}`);
          localStorage.removeItem(`progs-${idri.ghatorserial}`);
          localStorage.removeItem(`openProgs-${idri.ghatorserial}`);
          localStorage.removeItem(`openAlerts-${idri.ghatorserial}`);
          // window.location.reload();
          props.setPage('map');
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const resendRules = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/resendrules`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    dispatch({
      type: IDRI_INIT_DATA,
      payload: {},
    });
    dispatch({
      type: IDRI_DATA,
      payload: {},
    });
    dispatch({
      type: IDRI,
      payload: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIdri();
    // getRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (control === 'manual') {
      idri.wkmode !== 0 ? getIdri() : getRefresh();
    }
    if (control === 'prog') {
      getIdri();
    }
    if (control === 'alert') {
      getIdri();
    }
    if (control === 'rules') {
      getIdri();
    }
  }, [control]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  useEffect(() => {
    if (idri.ghatorid) {
      (!localStorage.getItem(`firmRemind-${userStore.usuarioid}-${idri.ghatorid}`) ||
        (localStorage.getItem(`firmRemind-${userStore.usuarioid}-${idri.ghatorid}`) &&
          moment()._d >=
            moment(localStorage.getItem(`firmRemind-${userStore.usuarioid}-${idri.ghatorid}`))
              ._d)) &&
        Object.keys(idri).length > 0 &&
        parseFloat(idri.ghatorv) >= 3 &&
        idri.last_firm_available &&
        idri.v_firmware !== idri.last_firm_available &&
        setFirmware({ ...firmware, modal: true });
    }
  }, [idri.ghatorid]);

  return (
    <Container className='text'>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      )}
      {Object.keys(idri).length > 0 && (
        <>
          <SensorsControl
            refresh={getRefresh}
            // cAlta={cAlta}
            cRefresh={getCRefresh}
            getIdri={getIdri}
            profiles={props.profiles}
            page={props.page}
            setPage={props.setPage}
            autoUse={autoUse}
            setAutoUse={setAutoUse}
            masterEn={masterEn}
            control={control}
            setControl={setControl}
            idri={props.idri}
            setData={props.setData}
            profile={props.profile}
            password={password}
            setPassword={setPassword}
            deleteIdri={deleteIdri}
            firstPage={props.firstPage}
            setFirstPage={props.setFirstPage}
            setToMap={props.setToMap}
          />
          {idri.rules_mismatch && (
            <Card border='dark' className='rounded-0'>
              <Row className='text-center px-4 px-md-0'>
                <Col
                  xs={Object.keys(idri.programs).length > 0 ? 6 : 12}
                  className='d-flex align-items-center justify-content-center  alert-danger'
                >
                  <span>{t('mismatch')}</span>
                </Col>
                {Object.keys(idri.programs).length > 0 && (
                  <Col xs={6} className='py-2 pe-0'>
                    <Button
                      size='sm'
                      className=''
                      variant='primary'
                      onClick={(e) => setControl('rules')}
                    >
                      {t('toRules')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Card>
          )}
          {/* <Card border='primary' className='rounded-0 rounded-bottom'> */}
          <Card className='rounded-0 rounded-bottom'>
            <Row className='text-center px-4 px-md-0'>
              <Col
                xs={Object.keys(idri.programs).length > 0 ? 6 : 12}
                className='d-flex align-items-center justify-content-center'
              >
                <span
                  style={{
                    fontSize: variables.fontSize,
                    color: idri.suspended
                      ? '#000000'
                      : !idri.inicialized || !idri.discharged
                      ? '#777777'
                      : !idri.user_discharge
                      ? '#ea5a0b'
                      : (
                          idri.wkmode === 2
                            ? moment(idri.last_read_date)
                                .add(idri.tsleep, 'minutes')
                                .isBefore(moment())
                            : idri.last_read_error
                        )
                      ? '#f00'
                      : idri.operating
                      ? '#0066cc'
                      : idri.all_programs_disabled
                      ? '#a64dff'
                      : '#006600',
                  }}
                >
                  {idri.suspended
                    ? t('usoSuspendido')
                    : !idri.inicialized || !idri.discharged
                    ? t('noInit')
                    : !idri.user_discharge
                    ? t('pendAlta')
                    : (
                        idri.wkmode === 2
                          ? moment(idri.last_read_date)
                              .add(idri.tsleep, 'minutes')
                              .isBefore(moment())
                          : idri.last_read_error
                      )
                    ? t('ultConError')
                    : idri.operating
                    ? t('regando')
                    : idri.all_programs_disabled
                    ? t('progSuspendido')
                    : t('accesible')}
                </span>
              </Col>
              {Object.keys(idri.programs).length > 0 && (
                <Col xs={6} className='py-1 pe-0'>
                  <Button
                    size='sm'
                    style={{ fontSize: variables.fontSize }}
                    disabled={!idri.user_permissions.program}
                    variant={idri.all_programs_disabled ? 'success' : 'danger'}
                    onClick={(e) =>
                      !idri.all_programs_disabled ? setProgramsDisModal(true) : allProgram(e)
                    }
                  >
                    {idri.all_programs_disabled ? t('habProgs') : t('suspProgs')}
                  </Button>
                  <ModalGenerico
                    title={t('suspProgs')}
                    onClose={() => {
                      setProgramsDisModal(false);
                      setEndDate('');
                    }}
                    show={programsDisModal}
                  >
                    <ProgsDisable
                      endDate={endDate}
                      setEndDate={setEndDate}
                      allProgram={() => allProgram()}
                      closeModal={() => setProgramsDisModal(false)}
                    />
                  </ModalGenerico>
                </Col>
              )}
              {idri.all_programs_disabled && idri.renable_all_programs_date && (
                <Col xs={12}>
                  <hr className='my-0' />
                  {t('enableDate') + ': '}
                  <strong>
                    {moment
                      .utc(idri.renable_all_programs_date)
                      .local()
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </strong>
                </Col>
              )}
            </Row>
          </Card>
          <Row className='justify-content-center'>
            <Col xs={8} md={4} id='control'>
              <Form.Select
                className='my-2 text-center '
                value={control ?? ''}
                onChange={(e) => {
                  setControl(e.target.value);
                  scrollToElement('control', true, 'start', 300);
                }}
              >
                <option value='' disabled>
                  {t('queHacer')}...
                </option>
                <option value='manual' disabled={!idri.user_permissions.action}>
                  {t('controlManual')}
                </option>
                <option value='prog' disabled={!idri.user_permissions.view}>
                  {t('progs')}
                </option>
                <option value='alert' disabled={!idri.user_permissions.view}>
                  {t('alerts')}
                </option>
                {parseFloat(idri.ghatorv) >= 3 && (
                  <option value='rules' disabled={!idri.user_permissions.view}>
                    {t('rules')}
                  </option>
                )}
                <option value='hist' disabled={!idri.user_permissions.view}>
                  {t('histGraf')}
                </option>
              </Form.Select>
            </Col>
          </Row>
          {control === 'manual' && (
            <>
              {!isPending && (
                <IdriManual
                  key={idri.ghatorserial}
                  autoUse={autoUse}
                  setAutoUse={setAutoUse}
                  control={control}
                  setControl={setControl}
                  setError={setError}
                />
              )}
            </>
          )}
          {control === 'prog' && <IdriPrograms key={idri.ghatorserial} />}
          {control === 'alert' && (
            <IdriAlerts key={idri.ghatorserial} editAlerts={editAlerts} getIdri={getIdri} />
          )}
          {control === 'rules' && (
            <IdriRules key={idri.ghatorserial} resendRules={(e) => resendRules(e)} />
          )}
          {control === 'hist' && <IdriHistorical />}
          <Modal
            show={firmware.modal}
            onClose={() =>
              setFirmware({
                modal: false,
                enable: null,
                warning: null,
                data: null,
              })
            }
            centered
          >
            <Modal.Header className='d-flex justify-content-center'>
              <Modal.Title className=' text-center'>
                {idri.v_firmware_update_trial && idri.fail_update_trial
                  ? idri.last_read_error
                    ? t('firmFailRefresh')
                    : t('firmFail')
                  : idri.v_firmware_update_trial && idri.fail_update_trial === false
                  ? t('firmNoResponse')
                  : idri.v_firmware_update_trial && idri.fail_update_trial === null
                  ? t('firmNoResponseRefresh')
                  : firmware.warning
                  ? t('configInSettings')
                  : firmware.enable !== false
                  ? t('firmUpdate')
                  : t('firmRemind')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {idri.v_firmware_update_trial && idri.fail_update_trial ? (
                  idri.last_read_error ? (
                    <>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          onClick={() => {
                            setFirmware({ ...firmware, modal: false });
                            props.setmenuActivo('settings');
                          }}
                        >
                          {t('goSettings')}
                        </Button>
                      </Col>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            setFirmware({ ...firmware, modal: false });
                            localStorage.setItem(
                              `firmRemind-${userStore.usuarioid}-${idri.ghatorid}`,
                              moment(moment()).add(1, 'days')
                            );
                          }}
                        >
                          {t('cerrar')}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          onClick={() => {
                            setFirmware({ ...firmware, modal: false });
                            localStorage.setItem('settings', `["3"]`);
                            props.setmenuActivo('settings');
                          }}
                        >
                          {t('goSettings')}
                        </Button>
                      </Col>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            setFirmware({ ...firmware, modal: false });
                            localStorage.setItem(
                              `firmRemind-${userStore.usuarioid}-${idri.ghatorid}`,
                              moment(moment()).add(1, 'days')
                            );
                          }}
                        >
                          {t('cerrar')}
                        </Button>
                      </Col>
                    </>
                  )
                ) : idri.v_firmware_update_trial && idri.fail_update_trial === false ? (
                  <Col xs={12} className='d-grid gap-2'>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        setFirmware({ ...firmware, modal: false });
                        localStorage.setItem(
                          `firmRemind-${idri.ghatorid}`,
                          moment(moment()).add(1, 'day')
                        );
                      }}
                    >
                      {t('cerrar')}
                    </Button>
                  </Col>
                ) : idri.v_firmware_update_trial && idri.fail_update_trial === null ? (
                  <Button
                    variant='secondary'
                    onClick={() => {
                      setFirmware({ ...firmware, modal: false });
                      localStorage.setItem(
                        `firmRemind-${userStore.usuarioid}-${idri.ghatorid}`,
                        moment(moment()).add(1, 'day')
                      );
                    }}
                  >
                    {t('cerrar')}
                  </Button>
                ) : firmware.warning ? (
                  <Col xs={12} className='d-grid gap-2'>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        setFirmware({ ...firmware, modal: false });
                        localStorage.setItem(
                          `firmRemind-${userStore.usuarioid}-${idri.ghatorid}`,
                          moment(moment()).add(6, 'months')
                        );
                      }}
                    >
                      {t('cerrar')}
                    </Button>
                  </Col>
                ) : firmware.enable === false ? (
                  <>
                    <Col xs={6} className='d-grid gap-2'>
                      <Button
                        variant='primary'
                        size='lg'
                        className='py-md-1'
                        onClick={() => {
                          localStorage.setItem(
                            `firmRemind-${userStore.usuarioid}-${idri.ghatorid}`,
                            moment(moment()).add(3, 'days')
                          );
                          setFirmware({ ...firmware, modal: false });
                        }}
                      >
                        {t('yes')}
                      </Button>
                    </Col>
                    <Col xs={6} className='d-grid gap-2'>
                      <Button
                        variant='primary'
                        size='lg'
                        className='py-md-1'
                        onClick={() => setFirmware({ ...firmware, warning: true })}
                      >
                        {t('no')}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={6} className='d-grid gap-2'>
                      <Button
                        variant='primary'
                        size='lg'
                        className='py-md-1'
                        onClick={() => {
                          localStorage.setItem('settings', `["3"]`);
                          props.setmenuActivo('settings');
                          // localStorage.setItem('menu', 'settings');
                          setFirmware({
                            modal: false,
                            enable: null,
                            warning: null,
                            data: null,
                          });
                        }}
                      >
                        {t('yes')}
                      </Button>
                    </Col>
                    <Col xs={6} className='d-grid gap-2'>
                      <Button
                        variant='primary'
                        size='lg'
                        className='py-md-1'
                        onClick={() => setFirmware({ ...firmware, enable: false })}
                      >
                        {t('no')}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default IdriManage;
