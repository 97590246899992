/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Marker, Popup } from 'react-leaflet';
import { Row, Card, Col, Button, Image } from 'react-bootstrap';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { IDRI } from '../../../../redux/constants/idriConstants';
import ModalGenerico from '../../../home/Modal_generico';
import AdminIdri from '../../modals/AdminIdri';
import Bank from '../../modals/Bank';
import Spinner from 'react-bootstrap/Spinner';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import { IconContext } from 'react-icons';
import { GiNightSleep } from 'react-icons/gi';
import scaleDecimal from '../../../../support_func/scaleDecimal';

const DeviceMarker = (props) => {
  let deviceInit = props.device;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const deviceStore = useSelector((state) => state.idri);
  const [device, setDevice] = useState({});
  const [lastUpdate, setLastUpdate] = useState('');
  const [bankModal, setBankModal] = useState(false);
  const [adminInstalModal, setAdminInstalModal] = useState(false);
  const [markerLoading, setMarkerLoading] = useState(false);
  const [iconOptions, setIconOptions] = useState({});
  const [error, setError] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('autoAcceso')}`,
    },
  };

  let icon = L.divIcon(iconOptions);

  const getDevice = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${deviceInit.ghatorid}&ghatorserial=${deviceInit.ghatorserial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setTimeout(() => {
            setDevice(res.data.ghator);
            dispatch({
              type: IDRI,
              payload: res.data.ghator,
            });
            // dispatch(setDeviceStore(res.data.device));
            setLastUpdate(
              moment(res.data.ghator.last_read_date).format('L') +
                ' ' +
                moment(res.data.ghator.last_read_date).format('LTS')
            );
            setMarkerLoading(false);
          }, 500);
        } else {
          // dispatch(setStatus({ status: 'error', code: null, error: t('problem') }));
          // setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, null, setError);
      });
  };
  useEffect(() => {
    setIconOptions({
      // key: key,
      html: markerLoading
        ? `<img id='markerLoading' src='/images/loading@2x.gif' alt='loading'/>`
        : `<svg class='${deviceInit.wkmode === 2 ? 'svg-marker-sleep' : 'svg-marker'}'>
      <circle class=${
        (deviceInit.last_read_error && deviceInit.pending_alerts) ||
        (deviceInit.wkmode === 2 &&
          moment(deviceInit.last_read_date).add(deviceInit.tsleep, 'minutes').format('LLL') <
            moment().format('LLL'))
          ? 'svg-marker-circle-alert-red'
          : deviceInit.pending_alerts
          ? 'svg-marker-circle-alert'
          : (
              deviceInit.wkmode === 2
                ? moment(deviceInit.last_read_date)
                    .add(deviceInit.tsleep, 'minutes')
                    .isBefore(moment())
                : deviceInit.last_read_error
            )
          ? 'svg-marker-circle-error'
          : 'svg-marker-circle'
      } style='stroke: ${
            (deviceInit.suspended && '#000000') ||
            // (deviceInit.com_blocked && '#ff0000') ||
            ((!deviceInit.inicialized || !deviceInit.discharged) && '#777777') ||
            (!deviceInit.user_discharge && '#ff8533') ||
            ((deviceInit.wkmode === 2
              ? moment(deviceInit.last_read_date)
                  .add(deviceInit.tsleep, 'minutes')
                  .isBefore(moment())
              : deviceInit.last_read_error) &&
              '#00b300') ||
            (deviceInit.operating && '#0099ff') ||
            (deviceInit.all_programs_disabled && '#a64dff') ||
            '#00b300'
          }
       '/>
    </svg>`,
      className: `${markerLoading && 'pe-none'} ${'marker-' + deviceInit.ghatorid}`,
      iconSize: [30, 30],
      // iconAnchor: [15, 15],
    });
  }, [device, markerLoading]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);
  return (
    <Marker
      ref={(ref) => deviceInit.ghatorid && (props.markerRefs.current[deviceInit.ghatorid] = ref)}
      icon={icon}
      position={
        deviceInit.ghatorcoor
          ? L.latLng(parseFloat(deviceInit.ghatorcoor[0]), parseFloat(deviceInit.ghatorcoor[1]))
          : [0, 0]
      }
      eventHandlers={{
        click: (e) => {
          setMarkerLoading(true);
          getDevice();
        },
      }}
    >
      <Popup>
        {Object.keys(deviceStore).length > 0 && !markerLoading ? (
          <div className='position-relative'>
            {deviceStore.wkmode !== 0 && (
              <div id='markerSleep'>
                <IconContext.Provider
                  value={{
                    attr: { fill: 'yellow' },
                    size: '1.5em',
                  }}
                >
                  <span>
                    <GiNightSleep className='p-0 m-0 me-md-2' />
                  </span>
                </IconContext.Provider>
              </div>
            )}
            <Row className='m-0 px-3 mx-auto justify-content-center text-center'>
              {deviceStore.inicialized && (
                <Col xs={12} className='fw-normal lh-sm my-0'>
                  {lastUpdate}
                </Col>
              )}
              <Col xs={12} className=' fw-bold colorBrown lh-sm my-0'>
                {deviceStore.ghatorname}
              </Col>
              {/* {deviceStore.user_discharge && ( */}
              <Col xs={12} className='fw-normal lh-sm my-0'>
                ({deviceStore.ghatorserial} - f{deviceStore.v_firmware} - p{deviceStore.ghatorv})
              </Col>
              {/* )} */}
              <Col xs={12} className='fw-bold lh-sm my-0'>
                {deviceStore.owner.name}
              </Col>
              <Col xs={12}>
                <a
                  target='_blank'
                  href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${deviceStore.owner.email}`}
                  className='fw-normal lh-sm text-info text-decoration-none ps-0 popupBtn'
                  rel='noreferrer'
                >
                  {deviceStore.owner.email}
                </a>
              </Col>
            </Row>
            <hr className='m-2 mt-1' />
            {deviceStore.user_discharge &&
              deviceStore.sensors.fm_sensors.map((sensor) => (
                // <Row key={sensor.idsensor} className='m-0 px-3 justify-content-center text-center'>
                <Card key={sensor.idsensor} border='dark' className='mx-3 px-1 popup'>
                  <Card.Title className=' my-0 text-decoration-underline'>{sensor.name}</Card.Title>

                  <Row className='my-0 mx-0 justify-content-center align-items-center'>
                    <Col className='p-0'>
                      {t('consumo')} {t('hoy')}:{' '}
                      {/* </Col>
                  <Col className='p-0 ps-2'> */}
                      {sensor.dayconsum && sensor.dayconsum !== null
                        ? parseFloat(sensor.dayconsum).toFixed(scaleDecimal(sensor.scale))
                        : '0.00'}{' '}
                      {sensor.magnitude}
                    </Col>
                  </Row>
                  <Row className='my-0 mx-0 justify-content-center align-items-center'>
                    <Col className='p-0'>
                      {t('consumo')} {t('mes')}:{' '}
                      {/* </Col>
                  <Col className='p-0 ps-2'> */}
                      {sensor.monthconsum && sensor.monthconsum !== null
                        ? parseFloat(sensor.monthconsum).toFixed(scaleDecimal(sensor.scale))
                        : '0.00'}{' '}
                      {sensor.magnitude}
                    </Col>
                  </Row>
                </Card>
                // </Row>
              ))}
            <Row className='m-0 px-3 justify-content-center '>
              <Col xs={6} className='px-0'>
                {t('estado')}:
              </Col>
              <Col className='ps-1'>
                <span
                  style={{
                    color: deviceStore.suspended
                      ? '#000000'
                      : !deviceStore.inicialized || !deviceStore.discharged
                      ? '#777777'
                      : !deviceStore.user_discharge
                      ? '#ea5a0b'
                      : (
                        deviceStore.wkmode === 2
                            ? moment(deviceStore.last_read_date)
                                .add(deviceStore.tsleep, 'minutes')
                                .isBefore(moment())
                            : deviceStore.last_read_error
                        )
                      ? '#f00'
                      : deviceStore.operating
                      ? '#0066cc'
                      : deviceStore.all_programs_disabled
                      ? '#a64dff'
                      : '#006600',
                  }}
                >
                  {deviceStore.suspended
                    ? t('usoSuspendido')
                    : !deviceStore.inicialized || !deviceStore.discharged
                    ? t('noInit')
                    : !deviceStore.user_discharge
                    ? t('pendAlta')
                    : (
                      deviceStore.wkmode === 2
                          ? moment(deviceStore.last_read_date)
                              .add(deviceStore.tsleep, 'minutes')
                              .isBefore(moment())
                          : deviceStore.last_read_error
                      )
                    ? t('ultConError')
                    : deviceStore.operating
                    ? t('regando')
                    : deviceStore.all_programs_disabled
                    ? t('progSuspendido')
                    : t('accesible')}
                </span>
                {/* <span className='text-success'>
                  {deviceStore.inicialized ? t('accesible') : t('noDisponible')}
                </span> */}
              </Col>
            </Row>
            {/* {deviceStore.user_discharge && ( */}
            <Row className='m-0 px-3 justify-content-center '>
              <Col xs={6} className='px-0'>
                {t('batIn')}:
              </Col>
              <Col className='ps-1'>
                <span className='text-success'>
                  {deviceStore.bat_int_value ? deviceStore.bat_int_value + ' v' : t('noDisponible')}
                </span>
              </Col>
            </Row>
            <Row className='m-0 px-3 justify-content-center '>
              <Col xs={6} className='px-0'>
                {t('batExtShort')}:
              </Col>
              <Col className='ps-1'>
                <span className='text-success'>
                  {deviceStore.bat_ext_value ? deviceStore.bat_ext_value + ' v' : t('noDisponible')}
                </span>
              </Col>
            </Row>
            <hr className='m-2' />

            {/* )} */}
            <div className='d-flex justify-content-center text-center'>
              <Row className='justify-content-center text-danger dangerAnimation text-center w200'>
                <Col xs={12} className=''>
                  {deviceStore.pending_alerts && (
                    <Row className='m-0 px-3 justify-content-center text-danger dangerAnimation'>
                      {t('alertsPend')}
                    </Row>
                  )}
                  {/* {deviceStore.suspended
                    ? t('usoSuspendido')
                    : !deviceStore.inicialized || !deviceStore.discharged
                    ? t('noInit')
                    : !deviceStore.user_discharge
                    ? t('pendAlta')
                    : (
                        deviceInit.wkmode === 2
                          ? moment(deviceInit.last_read_date)
                              .add(deviceInit.tsleep, 'minutes')
                              .isBefore(moment())
                          : deviceInit.last_read_error
                      )
                    ? t('ultConError')
                    : deviceStore.operating
                    ? t('regando')
                    : deviceStore.all_programs_disabled
                    ? t('progSuspendido')
                    : null} */}
                  {/* </Row> */}
                  {deviceStore.discharged ? (
                    <>
                      {(userStore.profiles.admi || userStore.email === deviceStore.owner.email) &&
                        !deviceStore.user_discharge && (
                          <>
                            <Col className='mt-2 px-0 text-danger'>{t('finAlta')}</Col>
                            <Button
                              className='py-1 px-2 mt-2 dark'
                              onClick={() => {
                                setBankModal(!bankModal);
                              }}
                            >
                              {t('darAlta')}
                            </Button>
                            <ModalGenerico
                              title={t('darAlta')}
                              onClose={() => {
                                setBankModal(false);
                              }}
                              show={bankModal}
                            >
                              <Bank
                                ghatorid={deviceStore.ghatorid}
                                ghatorserial={deviceStore.ghatorserial}
                                ghatorname={deviceStore.ghatorname}
                                getIdri={getDevice}
                                closeModal={() => setBankModal(false)}
                                // markers={props.markers}
                                getMarkers={props.getMarkers}
                              />
                            </ModalGenerico>
                          </>
                        )}
                    </>
                  ) : (
                    <Row className='m-0 px-3 justify-content-center  text-danger'>
                      {t('pendAltaInst')}
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            {props.profile === 'admi' ||
            localStorage.getItem('profile') === 'admi' ||
            deviceStore.user_permissions.view ? (
              <Row className='m-0 px-3 justify-content-center'>
                <Col className='text-center'>
                  <Button
                    className='py-1 px-2 mt-2 popupBtn dark'
                    disabled={!deviceStore.user_permissions.view}
                    onClick={() => {
                      props.setIdriManage && props.setIdriManage(deviceStore);
                      props.setData &&
                        props.setData({
                          id: deviceStore.ghatorid,
                          serial: deviceStore.ghatorserial,
                        });
                      props.setPage && props.setPage('idri');
                    }}
                  >
                    {t('gestInstal')}
                  </Button>
                </Col>
              </Row>
            ) : null}
            {(localStorage.getItem('menu') === 'usersAdmin' || props.profile === 'admi') && (
              <Row className='m-0 px-3 justify-content-center'>
                <Col className='text-center'>
                  <Button
                    className='py-1 px-2 mt-2 popupBtn dark'
                    onClick={() => {
                      setAdminInstalModal(true);
                    }}
                  >
                    {t('adminInstal')}
                  </Button>
                </Col>
                <ModalGenerico
                  title={t('adminInstal')}
                  onClose={() => {
                    setAdminInstalModal(false);
                    props.getMarkers();
                  }}
                  show={adminInstalModal}
                >
                  <AdminIdri
                    ididri={deviceStore.ghatorid}
                    idriserial={deviceStore.ghatorserial}
                    getMarkers={props.getMarkers}
                  />
                </ModalGenerico>
              </Row>
            )}
          </div>
        ) : (
          <>
            <Spinner animation='grow' className='mx-5 my-2' />
          </>
        )}
      </Popup>
    </Marker>
  );
};

export default DeviceMarker;
