/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  PointElement,
  LineElement,
  BarController,
  Filler,
  LogarithmicScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, addMonths, addYears, subDays, subMonths, subYears } from 'date-fns';
import moment from 'moment';
import axios from 'axios';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import variables from '../../../../scss/variables.scss';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';
import selectStyles from '../../../../support_func/selectStyles';

const IdriHistorical = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Filler,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LogarithmicScale
  );
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const idri = useSelector((state) => state.idri);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [period, setPeriod] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [historical, setHistorical] = useState({});
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [consume, setConsume] = useState(false);
  const [flowVolume, setFlowVolume] = useState(false);
  // const [relatedActuators, setRelatedActuators] = useState([]);
  // const [relatedComplete, setRelatedComplete] = useState([]);
  // const [histAct, setHistAct] = useState({});
  const [startEndDate, setStartEndDate] = useState([null, null]);

  const periods = [
    {
      value: 1,
      label: t('day'),
    },
    {
      value: 2,
      label: t('mes'),
    },
    {
      value: 3,
      label: t('year'),
    },
    {
      value: 4,
      label: t('range'),
    },
  ];

  const getHistorical = async (e, init, end, ret, idactuator) => {
    e && e.preventDefault();
    setIsPending(true);
    let result = {};
    await axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/${
          !ret && 'idsensor' in selectedOption ? 'sensorhistorical' : 'actuatorhistorical'
        }?${
          !ret && 'idsensor' in selectedOption
            ? 'idsensor=' + selectedOption.idsensor
            : 'idactuator=' + (ret ? idactuator : selectedOption.idactuator)
        }&initdate=${
          init
            ? init
            : period.value === 3
            ? moment(dateRange[0]).format('yyyy') + '-01'
            : moment(dateRange[0]).format('yyyy-MM')
        }&enddate=${
          end
            ? end
            : dateRange[1]
            ? moment(dateRange[1]).format('yyyy-MM')
            : period.value === 3
            ? moment(dateRange[0]).format('yyyy') + '-12'
            : moment(dateRange[0]).format('yyyy-MM')
        }`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(ret);
          if (ret) {
            // setRelatedActuators((relatedActuators) => [
            //   ...relatedActuators,
            result = res.data;
            // console.log(result);
            // ]);
          } else {
            setHistorical(res.data);
          }
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
    // console.log(result);
    return result;
  };

  const firstData = () => {
    let month =
      dateRange[0] !== null &&
      moment(dateRange[0]).format('yyyy').toString() + moment(dateRange[0]).format('MM').toString();
    if (
      historical.first_historical_date !== 0 &&
      historical.first_historical_date.toString().match(/.{1,6}/g)[0] == month &&
      month in historical.historical &&
      period.value === 1
        ? parseInt(moment(dateRange[0]).format('DD')) ==
          Math.min(...Object.keys(historical.historical[month]))
        : period.value === 3
        ? month.slice(0, 4) == historical.first_historical_date.toString().slice(0, 4)
        : month == historical.first_historical_date.toString().slice(0, 6)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const lastData = () => {
    let month =
      dateRange[0] !== null &&
      moment(dateRange[0]).format('yyyy').toString() + moment(dateRange[0]).format('MM').toString();
    if (
      historical.last_historical_date !== 0 &&
      historical.last_historical_date.toString().match(/.{1,6}/g)[0] == month &&
      month in historical.historical &&
      period.value === 1
        ? parseInt(moment(dateRange[0]).format('DD')) + 1 >
          Math.max(...Object.keys(historical.historical[month]))
        : period.value === 3
        ? month.slice(0, 4) == historical.last_historical_date.toString().slice(0, 4)
        : month == historical.last_historical_date.toString().slice(0, 6)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const randomRgb = () => {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = (num >> 8) & 255;
    var b = num & 255;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  };

  const findMadnitude = (id, type, flow) => {
    if (type === 'fm') {
      return Object.values(idri.sensors.fm_sensors)
        .map((s) => {
          if (s.idsensor === id) {
            //return ' (' + s.magnitude + ')';
            return ' (' + s.magnitude + `${flow ? '/h' : ''})`;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (type === 'nfm') {
      return Object.values(idri.sensors.none_fm_sensors)
        .map((s) => {
          if (s.idsensor === id) {
            return ' (' + s.magnitude + ')';
          }
        })
        .find((value) => {
          return value;
        });
    } else {
      return Object.values(idri.sensors.fm_sensors)
        .map((s) => {
          if (
            s.idsensor ===
            Object.values(idri.actuators)
              .map((s) => {
                if (s.idactuator === id) {
                  return s.fluid.related_fm.idsensor;
                }
              })
              .find((value) => {
                return value;
              })
          ) {
            return ' (' + s.magnitude + ')';
            //return ' (' + s.magnitude + `${flow ? '/h' : ''})`;
          }
        })
        .find((value) => {
          return value;
        });
    }
  };

  useEffect(() => {
    let sensors = [{ label: t('sensores') + ':', disabled: true }];
    let actuators = [{ label: t('actuadores').toLowerCase() + ':', disabled: true }];
    let listSensors = [];
    let listActuators = [];
    idri &&
      Object.values(idri.sensors.fm_sensors).forEach((s) => {
        listSensors.push({
          idsensor: s.idsensor,
          name: s.name,
          magnitude: s.magnitude,
          related: Object.values(s.related_actuators).map((a) => {
            return a.idactuator;
          }),
          code: 3,
          label: s.name + ' - ' + t('contador') + '(' + s.magnitude + ')',
        });
      });
    idri &&
      Object.values(idri.sensors.none_fm_sensors).forEach((s) => {
        listSensors.push({
          idsensor: s.idsensor,
          name: s.name,
          normal_state: s.kind_sensor.normal_state,
          code: s.kind_sensor.code,
          label: s.name + ' - ' + s.lecture_kind,
        });
      });
    idri &&
      Object.values(idri.actuators).forEach((a) => {
        listActuators.push({
          idactuator: a.idactuator,
          name: a.name,
          status: a.status,
          normal_state: a.kind.normal_state,
          code: a.kind.id,
          label: a.name + ' - ' + a.kind.related_to,
        });
      });
    setList(sensors.concat(listSensors).concat(actuators).concat(listActuators));
  }, [idri]);

  useEffect(() => {
    if (Object.keys(selectedOption).length > 0) {
      setDateRange([moment()._d, null]);
      setPeriod({
        value: 1,
        label: t('day'),
      });
      getHistorical(null, moment().format('yyyy-MM'), moment().format('yyyy-MM'));
    }
    setConsume(false);
  }, [selectedOption]);

  useEffect(() => {
    if (period.value === 1) {
      dateRange[0] && getHistorical(null, moment(dateRange[0]).format('yyyy-MM'));
      setConsume(false);
    } else if (period.value === 2) {
      dateRange[0] &&
        getHistorical(
          null,
          moment(dateRange[0]).format('yyyy-MM'),
          moment(dateRange[0]).format('yyyy-MM')
        );
    } else if (period.value === 3) {
      dateRange[0] &&
        getHistorical(
          null,
          moment(dateRange[0]).format('yyyy') + '-01',
          moment(dateRange[0]).format('yyyy') + '-12'
        );
    } else if (period.value === 4) {
      dateRange[1] &&
        getHistorical(
          null,
          moment(dateRange[0]).format('yyyy-MM'),
          moment(dateRange[1]).format('yyyy-MM')
        );
    }

    scrollToElement('chartHistorical', true, 'start', 500);
  }, [period, dateRange, consume, flowVolume]);

  useEffect(() => {
    const dayInSec = 86400;
    let day = parseInt(moment(dateRange[0]).format('DD'));
    let month =
      moment(dateRange[0]).format('yyyy').toString() + moment(dateRange[0]).format('MM').toString();

    if (historical.historical && Object.keys(historical.historical).length > 0) {
      let newHist = {};

      Object.entries(historical.historical).forEach(([hMonth, mData]) => {
        newHist = {
          ...newHist,
          [hMonth]: {},
        };
        Object.entries(mData).forEach(([hDay, dData]) => {
          newHist = {
            ...newHist,
            [hMonth]: { ...newHist[hMonth], [hDay]: {} },
          };
          Object.entries(dData).forEach(([hTime, tData]) => {
            let newTime = moment
              .utc(
                moment.duration(
                  moment.duration(hTime).asSeconds() + parseInt(historical.ghator_offset_seconds),
                  'seconds'
                )._milliseconds
              )
              .format('HH:mm:ss');

            if (
              moment.duration(hTime).asSeconds() + parseInt(historical.ghator_offset_seconds) >
              dayInSec
            ) {
              if (parseInt(hDay) + 1 <= moment(month).daysInMonth()) {
                newHist = {
                  ...newHist,
                  [hMonth]: {
                    ...newHist[hMonth],
                    [parseInt(hDay) + 1]: {
                      ...newHist[hMonth][parseInt(hDay) + 1],
                      [newTime]: tData,
                    },
                  },
                };
              }
            } else if (
              moment.duration(hTime).asSeconds() + parseInt(historical.ghator_offset_seconds) <
              0
            ) {
              if (parseInt(hDay) > 1) {
                newHist = {
                  ...newHist,
                  [hMonth]: {
                    ...newHist[hMonth],
                    [parseInt(hDay) - 1]: {
                      ...newHist[hMonth][parseInt(hDay) - 1],
                      [newTime]: tData,
                    },
                  },
                };
              }
            } else {
              newHist = {
                ...newHist,
                [hMonth]: {
                  ...newHist[hMonth],
                  [parseInt(hDay)]: {
                    ...newHist[hMonth][parseInt(hDay)],
                    [newTime]: tData,
                  },
                },
              };
            }
          });
        });
      });

      // console.log(newHist);

      setOptions({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: historical.name,
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              maxRotation: 90,
              minRotation: 90,
            },
          },
          y: {
            ticks: {
              autoSkip: false,
              display:
                (historical.kind_sensor && historical.kind_sensor.code === 4) ||
                (historical.kind && historical.kind.related_to === 'onoff')
                  ? false
                  : true,
            },
            grid: {
              display: historical.kind_sensor && historical.kind_sensor.code === 4 ? false : true,
            },
            type: consume ? 'logarithmic' : 'linear',
          },
          y2:
            (historical.kind_sensor && historical.kind_sensor.code === 4) ||
            (historical.kind && historical.kind.id)
              ? {
                  type: 'category',
                  labels: ['ON', 'OFF'],
                  stackWeight: 1,
                }
              : { display: false },
        },
        elements: {
          point: {
            radius: period.value === 1 ? 3 : 1,
            // pointStyle: 'cross',
            borderColor: 'black',
            backgroundColor: 'black',
            pointHoverRadius: 10,
          },
        },
      });
      // console.log(month);
      // if (month in historical.historical) {
      if (period.value === 2) {
        const getMonthData = async () => {
          let labelsList = [];
          let dataList = [];
          let cMonth = Array(parseInt(moment(dateRange[0]).daysInMonth()));
          let array = [];

          if (consume) {
            for (const idactuator of selectedOption.related) {
              const res = await getHistorical(
                null,
                moment(dateRange[0]).format('yyyy-MM'),
                moment(dateRange[0]).format('yyyy-MM'),
                true,
                idactuator
              );
              array.push(res);
            }
            let intermediateHistAct = {};
            array.forEach((h) => {
              intermediateHistAct = {
                ...intermediateHistAct,
                [h.name]: h.historical,
              };
            });

            for (let i = 1; i <= cMonth.length; i++) {
              labelsList.push(i + '/' + moment(dateRange[0]).format('MM/yyyy'));
            }
            Object.entries(newHist).forEach(([ym, value]) => {
              for (let i = 1; i <= cMonth.length; i++) {
                let dayFirst = newHist[ym][i] ? newHist[ym][i] : null;
                let dayNext = newHist[ym][i + 1] ? newHist[ym][i + 1] : null;

                dataList[i - 1] = [
                  dayFirst
                    ? (
                        parseFloat(
                          dayNext
                            ? dayNext[Object.keys(dayNext)[0]][0]
                            : dayFirst[Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]][0]
                        ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][0]) || 0)
                      ).toFixed(2)
                    : null,
                  dayFirst
                    ? (
                        parseFloat(
                          dayNext
                            ? dayNext[Object.keys(dayNext)[0]][1]
                            : dayFirst[Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]][1] // cambio indice 0 x 1
                        ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                      ).toFixed(2)
                    : null,
                ];
              }
            });
            let actuators = Object.entries(intermediateHistAct).map(([name, hist]) => {
              let dataAct = [];
              Object.entries(hist).forEach(([ym, value]) => {
                for (let i = 1; i <= cMonth.length; i++) {
                  let dayFirst = hist[ym][i] ? hist[ym][i] : null;
                  let dayNext = hist[ym][i + 1] ? hist[ym][i + 1] : null;

                  dataAct[i - 1] = [
                    dayFirst
                      ? (
                          parseFloat(
                            dayNext
                              ? dayNext[Object.keys(dayNext)[0]][1]
                              : dayFirst[Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]][1]
                          ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                        ).toFixed(2)
                      : null,
                    dayFirst
                      ? (
                          parseFloat(
                            dayNext
                              ? dayNext[Object.keys(dayNext)[0]][2]
                              : dayFirst[Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]][2] //cambio indice 1 x 2
                          ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][2]) || 0) // añado || 0
                        ).toFixed(2)
                      : null,
                  ];
                }
              });
              //console.log(dataAct);
              //console.log(flowVolume);

              let color = randomRgb();
              return {
                label: name + findMadnitude(historical.idsensor, 'fm', false),
                data: dataAct.map((data) => {
                  return data[flowVolume ? 1 : 0];
                }),
                borderColor: color,
                backgroundColor: color,
              };
            });
            //console.log(actuators);
            //console.log(dataList);
            let chartData = {
              labels: labelsList,
              datasets: [
                {
                  label: historical.name + findMadnitude(historical.idsensor, 'fm'),
                  data: dataList.map((data) => {
                    return data[0];
                  }),
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
              ].concat(actuators),
            };
            setData({
              ...chartData,
            });
          } else {
            Object.entries(newHist).forEach(([ym, value]) => {
              Object.entries(value).forEach(([d, v]) => {
                Object.entries(v).forEach(([h, arr]) => {
                  labelsList.push(
                    d +
                      '/' +
                      ym.toString().match(/.{1,4}/g)[1] +
                      '/' +
                      ym.toString().match(/.{1,4}/g)[0] +
                      ' - ' +
                      h
                  );
                  dataList.push(arr);
                });
              });
            });
            let chartData = {
              labels: labelsList,
              datasets:
                historical.kind_sensor && historical.kind_sensor.code === 1
                  ? [
                      {
                        label: t('value') + findMadnitude(historical.idsensor, 'nfm'),
                        data: dataList.map((data) => data[0]),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ]
                  : historical.kind_sensor && historical.kind_sensor.code === 3
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                          label:
                            t('caudal').toLowerCase() +
                            findMadnitude(historical.idsensor, 'fm', true),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                      ]
                  : historical.kind_sensor && historical.kind_sensor.code === 4
                  ? [
                      {
                        label: t('estado'),
                        data: dataList.map((data) => {
                          return data[0] ? 'ON' : 'OFF';
                        }),
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : historical.kind && historical.kind.related_to === 'fluid'
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                        {
                          label: t('caudal') + findMadnitude(historical.idactuator, null, true),
                          data: dataList.map((data) => {
                            return data[2];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                      ]
                  : historical.kind && historical.kind.related_to === 'onoff'
                  ? [
                      {
                        label: t('estado'),
                        data:
                          day in newHist[month] &&
                          Object.values(newHist[month][day]).map((d) => {
                            return d[0] ? 'ON' : 'OFF';
                          }),
                        // type: 'bar',
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : [],
            };
            setData({
              ...chartData,
            });
          }
        };

        getMonthData();
      } else if (period.value === 3) {
        const getYearData = async () => {
          let labelsList = [];
          let dataList = [];
          let array = [];
          if (consume) {
            for (const idactuator of selectedOption.related) {
              const res = await getHistorical(
                null,
                moment(dateRange[0]).format('yyyy') + '-01',
                moment(dateRange[0]).format('yyyy') + '-12',
                true,
                idactuator
              );
              array.push(res);
            }
            let intermediateHistAct = {};
            array.forEach((h) => {
              intermediateHistAct = {
                ...intermediateHistAct,
                [h.name]: h.historical,
              };
            });

            Object.entries(newHist).forEach(([ym, value]) => {
              for (let i = 1; i <= moment(ym.toString(), 'YYYYMM').daysInMonth(); i++) {
                let dayFirst = newHist[ym][i] ? newHist[ym][i] : null;
                let dayNext = newHist[ym][i + 1] ? newHist[ym][i + 1] : null;

                labelsList.push(i + '/' + moment(ym.toString(), 'YYYYMM').format('MM/yyyy'));

                dayFirst
                  ? dataList.push([
                      dayFirst
                        ? (
                            parseFloat(
                              dayNext
                                ? dayNext[Object.keys(dayNext)[0]][0]
                                : dayFirst[
                                    Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                  ][0]
                            ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][0]) || 0)
                          ).toFixed(2)
                        : null,
                      dayFirst
                        ? (
                            parseFloat(
                              dayNext
                                ? dayNext[Object.keys(dayNext)[0]][1]
                                : dayFirst[
                                    Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                  ][1] // cambio indice 0 x 1
                            ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                          ).toFixed(2)
                        : null,
                    ])
                  : dataList.push([null, null]);
              }
            });
            let actuators = Object.entries(intermediateHistAct).map(([name, hist]) => {
              let dataAct = [];
              Object.entries(hist).forEach(([ym, value]) => {
                for (let i = 1; i <= moment(ym.toString(), 'YYYYMM').daysInMonth(); i++) {
                  let dayFirst = hist[ym][i] ? hist[ym][i] : null;
                  let dayNext = hist[ym][i + 1] ? hist[ym][i + 1] : null;

                  dayFirst
                    ? dataAct.push([
                        dayFirst
                          ? (
                              parseFloat(
                                dayNext
                                  ? dayNext[Object.keys(dayNext)[0]][1]
                                  : dayFirst[
                                      Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                    ][1]
                              ) -( parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                            ).toFixed(2)
                          : null,
                        dayFirst
                          ? (
                              parseFloat(
                                dayNext
                                  ? dayNext[Object.keys(dayNext)[0]][2]
                                  : dayFirst[
                                      Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                    ][2] // cambio indice 1 x 2 
                              ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][2]) || 0)
                            ).toFixed(2)
                          : null,
                      ])
                    : dataAct.push([null, null]);
                }
              });
              // console.log(dataAct);

              let color = randomRgb();
              return {
                label: name + findMadnitude(historical.idsensor, 'fm', false),
                data: dataAct.map((data) => {
                  return data[flowVolume ? 1 : 0];
                }),
                borderColor: color,
                backgroundColor: color,
              };
            });

            let chartData = {
              labels: labelsList,
              datasets: [
                {
                  label: historical.name + findMadnitude(historical.idsensor, 'fm'),
                  data: dataList.map((data) => {
                    return data[0];
                  }),
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
              ].concat(actuators),
            };
            setData({
              ...chartData,
            });
          } else {
            Object.entries(newHist).forEach(([ym, value]) => {
              Object.entries(value).forEach(([d, v]) => {
                Object.entries(v).forEach(([h, arr]) => {
                  labelsList.push(
                    d +
                      '/' +
                      ym.toString().match(/.{1,4}/g)[1] +
                      '/' +
                      ym.toString().match(/.{1,4}/g)[0] +
                      ' - ' +
                      h
                  );
                  dataList.push(arr);
                });
              });
            });
            let chartData = {
              labels: labelsList,
              datasets:
                historical.kind_sensor && historical.kind_sensor.code === 1
                  ? [
                      {
                        label: t('value') + findMadnitude(historical.idsensor, 'nfm'),
                        data: dataList.map((data) => data[0]),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ]
                  : historical.kind_sensor && historical.kind_sensor.code === 3
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                          label:
                            t('caudal').toLowerCase() +
                            findMadnitude(historical.idsensor, 'fm', true),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                      ]
                  : historical.kind_sensor && historical.kind_sensor.code === 4
                  ? [
                      {
                        label: t('estado'),
                        data: dataList.map((data) => {
                          return data[0] ? 'ON' : 'OFF';
                        }),
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : historical.kind && historical.kind.related_to === 'fluid'
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                        {
                          label: t('caudal') + findMadnitude(historical.idactuator, null, true),
                          data: dataList.map((data) => {
                            return data[2];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                      ]
                  : historical.kind && historical.kind.related_to === 'onoff'
                  ? [
                      {
                        label: t('estado'),
                        data:
                          newHist[month] &&
                          day in newHist[month] &&
                          Object.values(newHist[month][day]).map((d) => {
                            return d[0] ? 'ON' : 'OFF';
                          }),
                        // type: 'bar',
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : [],
            };
            setData({
              ...chartData,
            });
          }
        };

        getYearData();
      } else if (period.value === 4) {
        const getRangeData = async () => {
          let labelsList = [];
          let dataList = [];
          let array = [];

          if (consume) {
            for (const idactuator of selectedOption.related) {
              const res = await getHistorical(
                null,
                moment(dateRange[0]).format('yyyy-MM'),
                moment(dateRange[1]).format('yyyy-MM'),
                true,
                idactuator
              );
              array.push(res);
            }
            let intermediateHistAct = {};
            array.forEach((h) => {
              intermediateHistAct = {
                ...intermediateHistAct,
                [h.name]: h.historical,
              };
            });

            Object.entries(newHist).forEach(([ym, value]) => {
              for (let i = 1; i <= moment(ym.toString(), 'YYYYMM').daysInMonth(); i++) {
                if (
                  moment(ym.toString() + (i < 10 ? 0 + i.toString() : i.toString()), 'YYYYMMDD')
                    ._d >= moment(dateRange[0]) &&
                  moment(ym.toString() + (i < 10 ? 0 + i.toString() : i.toString()), 'YYYYMMDD')
                    ._d <= moment(dateRange[1])
                ) {
                  labelsList.push(i + '/' + moment(ym.toString(), 'YYYYMM').format('MM/yyyy'));

                  let dayFirst = newHist[ym][i] ? newHist[ym][i] : null;
                  let dayNext = newHist[ym][i + 1] ? newHist[ym][i + 1] : null;

                  dayFirst
                    ? dataList.push([
                        dayFirst
                          ? (
                              parseFloat(
                                dayNext
                                  ? dayNext[Object.keys(dayNext)[0]][0]
                                  : dayFirst[
                                      Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                    ][0]
                              ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][0]) || 0)
                            ).toFixed(2)
                          : null,
                        dayFirst
                          ? (
                              parseFloat(
                                dayNext
                                  ? dayNext[Object.keys(dayNext)[0]][1]
                                  : dayFirst[
                                      Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                    ][1] // cambio indice 0 x 1 
                              ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                            ).toFixed(2)
                          : null,
                      ])
                    : dataList.push([null, null]);
                }
              }
            });

            let actuators = Object.entries(intermediateHistAct).map(([name, hist]) => {
              let dataAct = [];
              Object.entries(hist).forEach(([ym, value]) => {
                for (let i = 1; i <= moment(month.toString(), 'YYYYMM').daysInMonth(); i++) {
                  if (
                    moment(ym.toString() + (i < 10 ? 0 + i.toString() : i.toString()), 'YYYYMMDD')
                      ._d >= moment(dateRange[0]) &&
                    moment(ym.toString() + (i < 10 ? 0 + i.toString() : i.toString()), 'YYYYMMDD')
                      ._d <= moment(dateRange[1])
                  ) {
                    let dayFirst = hist[ym][i] ? hist[ym][i] : null;
                    let dayNext = hist[ym][i + 1] ? hist[ym][i + 1] : null;

                    dayFirst
                      ? dataAct.push([
                          dayFirst
                            ? (
                                parseFloat(
                                  dayNext
                                    ? dayNext[Object.keys(dayNext)[0]][1]
                                    : dayFirst[
                                        Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                      ][1]
                                ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][1]) || 0)
                              ).toFixed(2)
                            : null,
                          dayFirst
                            ? (
                                parseFloat(
                                  dayNext
                                    ? dayNext[Object.keys(dayNext)[0]][2]
                                    : dayFirst[
                                        Object.keys(dayFirst)[Object.keys(dayFirst).length - 1]
                                      ][2] // cambio indice 1 x 2
                                ) - (parseFloat(dayFirst[Object.keys(dayFirst)[0]][2]) || 0)
                              ).toFixed(2)
                            : null,
                        ])
                      : dataAct.push([null, null]);
                  }
                }
              });
              let color = randomRgb();
              return {
                label: name + findMadnitude(historical.idsensor, 'fm', false),
                data: dataAct.map((data) => {
                  return data[flowVolume ? 1 : 0];
                }),
                borderColor: color,
                backgroundColor: color,
              };
            });

            let chartData = {
              labels: labelsList,
              datasets: [
                {
                  label: historical.name + findMadnitude(historical.idsensor, 'fm'),
                  data: dataList.map((data) => {
                    return data[0];
                  }),
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
              ].concat(actuators),
            };
            setData({
              ...chartData,
            });
          } else {
            Object.entries(newHist).forEach(([ym, value]) => {
              Object.entries(value).forEach(([d, v]) => {
                Object.entries(v).forEach(([h, arr]) => {
                  if (
                    moment(ym.toString() + (d < 10 ? 0 + d.toString() : d.toString()), 'YYYYMMDD')
                      ._d >= moment(dateRange[0]) &&
                    moment(ym.toString() + (d < 10 ? 0 + d.toString() : d.toString()), 'YYYYMMDD')
                      ._d <= moment(dateRange[1])
                  ) {
                    labelsList.push(
                      d +
                        '/' +
                        ym.toString().match(/.{1,4}/g)[1] +
                        '/' +
                        ym.toString().match(/.{1,4}/g)[0] +
                        ' - ' +
                        h
                    );
                    dataList.push(arr);
                  }
                });
              });
            });
            let chartData = {
              labels: labelsList,
              datasets:
                historical.kind_sensor && historical.kind_sensor.code === 1
                  ? [
                      {
                        label: t('value') + findMadnitude(historical.idsensor, 'nfm'),
                        data: dataList.map((data) => data[0]),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      },
                    ]
                  : historical.kind_sensor && historical.kind_sensor.code === 3
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                          label:
                            t('caudal').toLowerCase() +
                            findMadnitude(historical.idsensor, 'fm', true),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                          data: dataList.map((data) => {
                            return data[0];
                          }),
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                      ]
                  : historical.kind_sensor && historical.kind_sensor.code === 4
                  ? [
                      {
                        label: t('estado'),
                        data: dataList.map((data) => {
                          return data[0] ? 'ON' : 'OFF';
                        }),
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : historical.kind && historical.kind.related_to === 'fluid'
                  ? parseFloat(idri.ghatorv) >= 3
                    ? [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                        {
                          label: t('caudal') + findMadnitude(historical.idactuator, null, true),
                          data: dataList.map((data) => {
                            return data[2];
                          }),
                          borderColor: 'rgb(53, 162, 235)',
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                      ]
                    : [
                        {
                          label: t('estado'),
                          data: dataList.map((data) => {
                            return data[0] ? 'ON' : 'OFF';
                          }),
                          stepped: true,
                          fill: true,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                          yAxisID: 'y2',
                        },
                        {
                          label: t('volumen') + findMadnitude(historical.idactuator),
                          data: dataList.map((data) => {
                            return data[1];
                          }),
                          borderColor: 'rgb(0, 255, 153)',
                          backgroundColor: 'rgba(0, 255, 153, 0.5)',
                        },
                      ]
                  : historical.kind && historical.kind.related_to === 'onoff'
                  ? [
                      {
                        label: t('estado'),
                        data:
                          newHist[month] &&
                          day in newHist[month] &&
                          Object.values(newHist[month][day]).map((d) => {
                            return d[0] ? 'ON' : 'OFF';
                          }),
                        // type: 'bar',
                        stepped: true,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y2',
                      },
                    ]
                  : [],
            };
            setData({
              ...chartData,
            });
          }
        };

        getRangeData();
      } else {
        let chartData = {
          labels:
            newHist[month] &&
            day in newHist[month] &&
            Object.keys(newHist[month][period.value === 1 ? day : 0]),
          datasets:
            historical.kind_sensor &&
            (historical.kind_sensor.code === 1 || historical.kind_sensor.code === 2)
              ? [
                  {
                    label: t('value') + findMadnitude(historical.idsensor, 'nfm'),
                    data:
                      newHist[month] &&
                      day in newHist[month] &&
                      Object.values(newHist[month][day]).map((d) => d[0]),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                ]
              : historical.kind_sensor && historical.kind_sensor.code === 3
              ? parseFloat(idri.ghatorv) >= 3
                ? [
                    {
                      label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[0]),
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                      label:
                        t('caudal').toLowerCase() + findMadnitude(historical.idsensor, 'fm', true),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[1]),
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ]
                : [
                    {
                      label: t('value') + findMadnitude(historical.idsensor, 'fm'),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[0]),
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                  ]
              : historical.kind_sensor &&
                (historical.kind_sensor.code === 0 || historical.kind_sensor.code === 4)
              ? [
                  {
                    label: t('estado'),
                    data:
                      newHist[month] &&
                      day in newHist[month] &&
                      Object.values(newHist[month][day]).map((d) => (d[0] ? 'ON' : 'OFF')),
                    stepped: true,
                    fill: true,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    yAxisID: 'y2',
                  },
                ]
              : historical.kind.related_to && historical.kind.related_to === 'fluid'
              ? parseFloat(idri.ghatorv) >= 3
                ? [
                    {
                      label: t('estado'),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => {
                          return d[0] ? 'ON' : 'OFF';
                        }),
                      // type: 'bar',
                      stepped: true,
                      fill: true,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      yAxisID: 'y2',
                    },
                    {
                      label: t('volumen') + findMadnitude(historical.idactuator),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[1]),
                      borderColor: 'rgb(0, 255, 153)',
                      backgroundColor: 'rgba(0, 255, 153, 0.5)',
                    },
                    {
                      label: t('caudal') + findMadnitude(historical.idactuator, null, true),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[2]),
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ]
                : [
                    {
                      label: t('estado'),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => {
                          return d[0] ? 'ON' : 'OFF';
                        }),
                      // type: 'bar',
                      stepped: true,
                      fill: true,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      yAxisID: 'y2',
                    },
                    {
                      label: t('volumen') + findMadnitude(historical.idactuator),
                      data:
                        newHist[month] &&
                        day in newHist[month] &&
                        Object.values(newHist[month][day]).map((d) => d[1]),
                      borderColor: 'rgb(0, 255, 153)',
                      backgroundColor: 'rgba(0, 255, 153, 0.5)',
                    },
                  ]
              : historical.kind.related_to && historical.kind.related_to === 'onoff'
              ? [
                  {
                    label: t('estado'),
                    data:
                      newHist[month] &&
                      day in newHist[month] &&
                      Object.values(newHist[month][day]).map((d) => {
                        return d[0] ? 'ON' : 'OFF';
                      }),
                    // type: 'bar',
                    stepped: true,
                    fill: true,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    yAxisID: 'y2',
                  },
                ]
              : [],
        };
        // console.log(chartData);
        setData({
          ...chartData,
        });
      }
      // }
      setStartEndDate([
        historical.first_historical_date !== 0
          ? moment(
              historical.first_historical_date.toString().match(/.{1,4}/g)[0] +
                '-' +
                historical.first_historical_date
                  .toString()
                  .match(/.{1,4}/g)[1]
                  .match(/.{1,2}/g)[0] +
                '-' +
                historical.first_historical_date
                  .toString()
                  .match(/.{1,4}/g)[1]
                  .match(/.{1,2}/g)[1]
            )._d
          : moment()._d,
        historical.last_historical_date !== 0
          ? moment(
              historical.last_historical_date.toString().match(/.{1,4}/g)[0] +
                '-' +
                historical.last_historical_date
                  .toString()
                  .match(/.{1,4}/g)[1]
                  .match(/.{1,2}/g)[0] +
                '-' +
                historical.last_historical_date
                  .toString()
                  .match(/.{1,4}/g)[1]
                  .match(/.{1,2}/g)[1]
            )._d
          : moment()._d,
      ]);
    } else {
      // setOptions({});
      // setData({});
      setOptions({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: historical.name,
          },
        },
        scales: {
          x: {},
          y: {},
        },
      });

      setData({
        labels: [],
        datasets: [],
      });
    }
  }, [historical, dateRange]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  useEffect(() => {
    setPeriod({
      value: period.value,
      label: periods.find((o) => o.value === period.value)?.label,
    });
  }, [i18n.language]);

  useEffect(() => {
    textLineAnimation();
  });
  // console.log(consume);
  // console.log(parseFloat(idri.ghatorv)<3.0)
  return (
    <>
      {idri.user_permissions.edit ? (
        <Form id='historical' onSubmit={(e) => getHistorical(e)}>
          <Row>
            <Col xs={12} md={4}>
              <div className='animated text-truncate text-center'>
                <strong>{t('sensorOrActuator')}</strong>
              </div>
              <Select
                menuShouldScrollIntoView
                required
                placeholder={t('sensorOrActuator')}
                value={Object.keys(selectedOption).length > 0 ? selectedOption : null}
                onChange={setSelectedOption}
                options={list}
                isOptionDisabled={(option) => option.disabled}
                styles={selectStyles}
              />
            </Col>
            {Object.keys(selectedOption).length > 0 && (
              <Col xs={period.value === 4 ? 12 : 6} md={2}>
                <strong>{t('period')}</strong>
                <Select
                  menuShouldScrollIntoView
                  required
                  value={period}
                  onChange={setPeriod}
                  options={consume ? periods.filter((p) => p.value !== 1) : periods}
                  styles={selectStyles}
                />
              </Col>
            )}
            {Object.keys(period).length > 0 && (
              <>
                {period.value === 1 && (
                  <Col xs={6} md={4}>
                    <strong>{t('day')}</strong>
                    <DatePicker
                      required
                      locale={localStorage.getItem('language')}
                      calendarStartDay={1}
                      customInput={<Form.Control className='valueInput' />}
                      dateFormat='dd/MM/yyyy'
                      selected={startDate}
                      onChange={(date) => {
                        setDateRange([date, null]);
                      }}
                      minDate={startEndDate[0]}
                      maxDate={startEndDate[1]}
                      withPortal
                    />
                  </Col>
                )}
                {period.value === 2 && (
                  <Col xs={6} md={4}>
                    <strong>{t('mes')}</strong>
                    <DatePicker
                      required
                      locale={localStorage.getItem('language')}
                      calendarStartDay={1}
                      customInput={<Form.Control className='valueInput' />}
                      dateFormat='MM/yyyy'
                      selected={startDate}
                      onChange={(date) => {
                        setDateRange([date, null]);
                      }}
                      minDate={startEndDate[0]}
                      maxDate={startEndDate[1]}
                      withPortal
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </Col>
                )}
                {period.value === 3 && (
                  <Col xs={6} md={4}>
                    <strong>{t('year')}</strong>
                    <DatePicker
                      required
                      locale={localStorage.getItem('language')}
                      calendarStartDay={1}
                      customInput={<Form.Control className='valueInput' />}
                      dateFormat='yyyy'
                      selected={startDate}
                      onChange={(date) => {
                        console.log(typeof date);
                        setDateRange([date, addMonths(date, 11)]);
                      }}
                      minDate={startEndDate[0]}
                      maxDate={startEndDate[1]}
                      withPortal
                      showYearPicker
                    />
                  </Col>
                )}
                {period.value === 4 && (
                  <Col xs={12} md={4}>
                    <strong>{t('dateRange')}</strong>
                    <DatePicker
                      required
                      locale={localStorage.getItem('language')}
                      calendarStartDay={1}
                      customInput={<Form.Control className='valueInput' />}
                      dateFormat='dd/MM/yyyy'
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(date) => {
                        setDateRange(date);
                      }}
                      minDate={startEndDate[0]}
                      maxDate={startEndDate[1]}
                      withPortal
                      showYearDropdown
                      showMonthDropdown
                    />
                  </Col>
                )}
              </>
            )}
            {dateRange[0] !== null && (
              <Col xs={12} md={2} className='d-grid gap-2'>
                <Button
                  type='submit'
                  // variant='primary'
                  style={{ fontSize: variables.fontSize }}
                  size='lg'
                  className='mt-2 mt-md-0 dark'
                >
                  {t('getData')}
                </Button>
              </Col>
            )}
            {historical.historical &&
              Object.keys(historical.historical).length > 0 &&
              historical.kind_sensor &&
              historical.kind_sensor.code === 3 && (
                <>
                  <Col xs={12} className='d-flex justify-content-center mt-3 mb-0'>
                    <strong className='me-4 me-md-5'>{t('getConsume')}</strong>
                    <Form.Check
                      type='switch'
                      className='noBoxShadow'
                      style={{ transform: 'scale(1.8)' }}
                      checked={consume}
                      onChange={(e) => {
                        setConsume(e.target.checked);
                        e.target.checked &&
                          period.value === 1 &&
                          setPeriod({
                            value: 2,
                            label: t('mes'),
                          });
                      }}
                    />
                  </Col>
                  {parseFloat(idri.ghatorv) >= 3 && consume && (
                    <Col xs={12} className='d-flex justify-content-center my-1'>
                      <strong className='me-4 me-md-4'>{t('asocCont')}</strong>
                      <Form.Check
                        type='switch'
                        id='switchBetween'
                        style={{ transform: 'scale(1.6)' }}
                        checked={flowVolume}
                        onChange={(e) => {
                          setFlowVolume(e.target.checked ? true : false);
                        }}
                      />
                      <strong className='ms-3 ms-md-3'>{t('asocCaudal')}</strong>
                    </Col>
                  )}
                </>
              )}
          </Row>
        </Form>
      ) : (
        <Col className='text-center text-danger'>{t('error12')}</Col>
      )}

      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>
            <span>{t('comprobando')}...</span>
          </div>
        </div>
      ) : (
        <>
          {/* {console.log(options)} */}
          {/* {console.log(data)} */}
          {historical.historical && (
            // Object.keys(historical.historical).length > 0 &&
            // Object.keys(options).length > 0 &&
            // Object.keys(data).length > 0 &&
            <>
              {period.value !== 4 && (
                <Row className='justify-content-center mt-3'>
                  <Col xs={2} md={1} className='text-center'>
                    <Button
                      variant='light'
                      className='p-0'
                      disabled={firstData()}
                      onClick={() =>
                        setDateRange([
                          (period.value === 1 && subDays(dateRange[0], 1)) ||
                            (period.value === 2 && subMonths(dateRange[0], 1)) ||
                            (period.value === 3 && subYears(dateRange[0], 1)),

                          null,
                        ])
                      }
                    >
                      <FiArrowLeftCircle size={30} />
                    </Button>
                  </Col>
                  <Col xs={6} md={2} className='text-center'>
                    {period.value === 1 && moment(dateRange[0]).format('DD/MM/yyyy').toString()}
                    {period.value === 2 && moment(dateRange[0]).format('MM/yyyy').toString()}
                    {period.value === 3 && moment(dateRange[0]).format('yyyy').toString()}
                  </Col>
                  <Col xs={2} md={1} className='text-center'>
                    <Button
                      variant='light'
                      className='p-0'
                      disabled={lastData()}
                      onClick={() =>
                        setDateRange([
                          (period.value === 1 && addDays(dateRange[0], 1)) ||
                            (period.value === 2 && addMonths(dateRange[0], 1)) ||
                            (period.value === 3 && addYears(dateRange[0], 1)),
                          null,
                        ])
                      }
                    >
                      <FiArrowRightCircle size={30} />
                    </Button>
                  </Col>
                </Row>
              )}
              <div style={{ height: '60vh' }}>
                {/* <Bar options={options} data={data} /> */}
                {/* {console.log(data)} */}
                {/* {console.log(options)} */}
                {consume ? (
                  <Bar id='chartHistorical' options={options} data={data} />
                ) : (
                  <Line id='chartHistorical' options={options} data={data} />
                )}
              </div>
              {'idsensor' in selectedOption &&
                consume &&
                (selectedOption.code === 1 || selectedOption.code === 3) && (
                  <Row className='d-inline-flex'>
                    <strong>
                      {t('total') + ' ' + selectedOption.name}:
                      {data.datasets && data.datasets[0] && data.datasets[0].data
                        ? ' ' +
                          data.datasets[0].data
                            .reduce(
                              (acc, val) =>
                                acc +
                                (val !== null && !isNaN(parseFloat(val)) ? parseFloat(val) : 0),
                              0
                            )
                            .toFixed(2) +
                          // data.datasets[0].data.findLast((el) => {
                          //   if (typeof parseFloat(el) === 'number') {
                          //     return el;
                          //   }
                          // }) -
                          // data.datasets[0].data.find((el) => {
                          //   if (typeof parseFloat(el) === 'number') {
                          //     return el;
                          //   }
                          // }) +
                          '(' +
                          selectedOption.magnitude +
                          ')'
                        : ' ' + t('noData')}
                    </strong>
                  </Row>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IdriHistorical;
