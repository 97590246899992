/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IDRI } from '../../../../redux/constants/idriConstants';
import Map from '../../../home/Map';
import IdriManage from '../../components/idris/IdriManage';
import IdrisMap from '../../components/idris/IdrisMap';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';
import { useSelector } from 'react-redux';

const Idris = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(false);
  const [profileChange, setProfileChange] = useState(false);
  const [profiles, setProfiles] = useState();
  const [page, setPage] = useState('map');
  const [data, setData] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const userStore = useSelector((state) => state.user);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: IDRI,
      payload: {},
    });
    // getProfiles();
    // getMarkers();
  }, []);

  useEffect(() => {
    localStorage.getItem('profile') &&
      profile !== localStorage.getItem('profile') &&
      setProfile(localStorage.getItem('profile'));
  }, [localStorage.getItem('profile')]);

  useEffect(() => {
    setPage('map');
  }, [props.firstPage]);

  useEffect(() => {
    scrollToElement(`tabs-tab-${profile}`, true, 'nearest', 300);
  }, [profile]);

  useEffect(() => {
    setProfiles(userStore.profiles);
    setIsPending(false);
  }, [userStore]);

  // console.log(profile);
  // const [key, setKey] = useState('home');
  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : Object.keys(profiles).length > 0 ? (
        <>
          {page === 'map' && (
            <>
              <div
                id='rightTabs'
                style={screenWidth < 768 ? { width: `${screenWidth - 60 + 'px'}` } : {}}
              >
                <Tabs
                  id='tabs'
                  className='d-flex flex-nowrap'
                  activeKey={profile ? profile : 'gamo'}
                  onSelect={(e) => {
                    setProfile(e);
                    setProfileChange(!profileChange);
                    localStorage.setItem('profile', e);
                  }}
                  // defaultActiveKey={profile ? profile : ''}
                  // id='justify-tab-example'
                  // className='mb-3'
                  justify
                >
                  {profiles.gamo && <Tab eventKey='gamo' id='tab-gamo' title={t('misIdris')}></Tab>}
                  {profiles.delg && <Tab eventKey='delg' id='tab-delg' title={t('delegado')}></Tab>}
                  {profiles.inst && (
                    <Tab eventKey='inst' id='tab-inst' title={t('instalByMe')}></Tab>
                  )}
                  {profiles.admi && <Tab eventKey='admi' id='tab-admi' title={t('todos')}></Tab>}
                </Tabs>
              </div>
              <div id='idriMap'>
                {profile && (
                  <IdrisMap
                    profile={profile}
                    changed={profileChange}
                    setData={setData}
                    setPage={setPage}
                  />
                )}
              </div>
            </>
          )}
          {page === 'idri' && (
            <IdriManage
              ghator={data}
              setData={setData}
              profile={profile}
              profiles={profiles}
              page={page}
              setPage={setPage}
              setmenuActivo={props.setmenuActivo}
            />
          )}
          {/* {page === 'newSensors' && <IdriConfig setPage={setPage} />}
          {page === 'testInst' && <TestInstalation setPage={setPage} />} */}
        </>
      ) : (
        <>
          <h5 className='text-center'>{t('publicInst')}</h5>
          <Map />
        </>
      )}
    </>
  );
};

export default Idris;
