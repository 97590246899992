/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from 'react-pro-sidebar';
//icons from react icons
import { FaMoneyBillWave, FaUserCog, FaUsersCog } from 'react-icons/fa';
import {
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiHelpCircle,
  FiSettings,
} from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { TbStatusChange } from 'react-icons/tb';
import { RiAdminLine, RiOrganizationChart } from 'react-icons/ri';
import { GrUserAdmin, GrUserSettings } from 'react-icons/gr';
import {
  MdOutlineSettingsInputAntenna,
  MdAddCircleOutline,
  MdGroupAdd,
  MdOutlineLibraryBooks,
} from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';

import 'react-pro-sidebar/dist/css/styles.css';
import variables from '../../scss/variables.scss';

import { Link } from 'react-router-dom';
import Bienvenida from './menuViews/all/Bienvenida';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TranslationTable from './menuViews/admin/TranslationTable';
import NuevoIdri from './menuViews/all/NuevoIdri';
import Flags from 'country-flag-icons/react/3x2';
import Idris from './menuViews/all/Idris';
import UsersManagement from './components/userManagement/UsersManagement';
import MyProfile from './menuViews/all/MyProfile';
// import EntityManagement from './components/communityManagement/CommunityManagement';
import { BiFirstPage } from 'react-icons/bi';
import Permissions from './menuViews/all/Permissions';
import Settings from './menuViews/all/Settings';
import FirmwareManagement from './menuViews/admin/FirmwareManagement';
import ProgramsControl from './menuViews/all/ProgramsControl';
import { IoMdSwitch } from 'react-icons/io';
import { BsStackOverflow } from 'react-icons/bs';
import GlobalAlert from './menuViews/admin/GlobalAlert';

const Prosidenavbar = ({ toggled, setToggled, profiles, notifications, setNotifications }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  };
  const es_none = profiles.profiles.hasOwnProperty('none');
  const es_gamo = profiles.profiles.hasOwnProperty('gamo');
  const es_comm = profiles.profiles.hasOwnProperty('comm');
  const es_admin = profiles.profiles.hasOwnProperty('admi');

  const [menuCollapse, setMenuCollapse] = useState(true);
  //custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  // console.log(profiles);
  const menu = localStorage.getItem('menu');
  const isMenu = menu && menu !== '';
  const [openA, setOpenA] = useState(false);
  const [openP, setOpenP] = useState(false);
  // const [page, setPage] = useState();
  const [menuActivo, setmenuActivo] = useState(isMenu ? menu : 'help');
  const [firstPage, setFirstPage] = useState(false);
  //menu display handler
  const handleMenuItem = (e) => {
    console.log(e.currentTarget.id);
    localStorage.setItem('menu', e.currentTarget.id);
    setmenuActivo(e.currentTarget.id);
    setToggled(false);
    // e.currentTarget.id === 'idris' && setPage('map');
    // setTimeout(() => {
    //   setPage();
    // }, 100);
  };
  return (
    <>
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar
        collapsed={menuCollapse}
        breakPoint='md'
        toggled={toggled}
        onToggle={() => setToggled(false)}
      >
        <SidebarHeader>
          <div className='logotext_sidebar'>
            {/* Icon change using menucollapse state */}
            <p>
              <a href='/'>
                {menuCollapse ? (
                  <Image
                    src={variables.logo_v1_2 && variables.logo_v1_2.slice(1, -1)}
                    alt='home'
                    width='50px'
                  />
                ) : (
                  <Image
                    src={variables.logo_v3_2 && variables.logo_v3_2.slice(1, -1)}
                    alt='home'
                    width='150px'
                  />
                )}
              </a>
            </p>
          </div>
          <div>
            {/* changing menu collapse icon on click */}
            {menuCollapse ? (
              <div className='closemenu_sidebar_right'>
                <FiArrowRightCircle size={20} onClick={menuIconClick} />
              </div>
            ) : (
              <div className='closemenu_sidebar_left'>
                <FiArrowLeftCircle size={20} onClick={menuIconClick} />
                <Row className='text-center px-5'>
                  {/* language switch buttons */}
                  <Col>
                    <Flags.ES className='langBtn' onClick={() => changeLanguage('es')} />
                  </Col>
                  <Col>
                    <Flags.GB className='langBtn' onClick={() => changeLanguage('en')} />
                  </Col>
                  <Col>
                    <Flags.FR className='langBtn' onClick={() => changeLanguage('fr')} />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape='square'>
            {/* Menu General */}
            <MenuItem
              active={menuActivo === 'idris'}
              icon={<MdOutlineSettingsInputAntenna />}
              id='idris'
              onClick={(e) => {
                handleMenuItem(e);
                setFirstPage(!firstPage);
              }}
            >
              gHators
            </MenuItem>

            <MenuItem
              active={menuActivo === 'newIdris'}
              icon={<MdAddCircleOutline />}
              id='newIdris'
              onClick={(e) => {
                handleMenuItem(e);
                setFirstPage(!firstPage);
              }}
            >
              {t('newIdris')}
            </MenuItem>

            {/* Menu Comunidad */}
            {es_comm && (
              <>
                <SubMenu title={t('gestEnt')} icon={<RiOrganizationChart />}>
                  <MenuItem
                    active={menuActivo === 'lecSensores'}
                    id='lecSensores'
                    onClick={(e) => handleMenuItem(e)}
                  >
                    {t('lecSensores')}
                  </MenuItem>
                  <MenuItem
                    active={menuActivo === 'incComu'}
                    id='incComu'
                    onClick={(e) => handleMenuItem(e)}
                  >
                    {t('incidencias')}
                  </MenuItem>
                  {profiles.profiles.comm.access_level === 1 && (
                    <MenuItem
                      active={menuActivo === 'usersComu'}
                      id='usersComu'
                      onClick={(e) => handleMenuItem(e)}
                    >
                      {t('usersComu')}
                    </MenuItem>
                  )}
                </SubMenu>
              </>
            )}

            {/* Menu Admin */}
            {es_admin && (
              <>
                <SubMenu
                  open={openA}
                  title={t('gestAdmin')}
                  icon={<GrUserAdmin className='gricon' />}
                  // icon={<RiAdminLine />}
                  onClick={() => {
                    setMenuCollapse(false);
                    setOpenA(menuCollapse ? true : !openA);
                  }}
                >
                  {!menuCollapse && (
                    <>
                      <MenuItem
                        active={menuActivo === 'permAdmin'}
                        id='permAdmin'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('gestPerm')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'firmware'}
                        id='firmware'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('firmwareManage')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'alertGlob'}
                        id='alertGlob'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('alertGlob')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'cargaIdris'}
                        id='cargaIdris'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('cargaIdris')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'IncAdmin'}
                        id='IncAdmin'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('incidencias')}
                      </MenuItem>
                    </>
                  )}
                </SubMenu>
                <MenuItem
                  active={menuActivo === 'usersAdmin'}
                  // icon={<GrUserSettings />}
                  icon={<FaUsersCog />}
                  id='usersAdmin'
                  onClick={(e) => {
                    handleMenuItem(e);
                    setFirstPage(!firstPage);
                  }}
                >
                  {t('gestUsers')}
                </MenuItem>
                {/* <MenuItem
                  active={menuActivo === 'entitiesAdmin'}
                  icon={<RiOrganizationChart />}
                  id='entitiesAdmin'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('gestEnt')}
                </MenuItem> */}
                <MenuItem
                  active={menuActivo === 'cuotasAdmin'}
                  icon={<FaMoneyBillWave />}
                  id='cuotasAdmin'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('gestCuotas')}
                </MenuItem>
              </>
            )}
            {
              <>
                <MenuItem
                  icon={<TbStatusChange />}
                  // icon={<FaUserCog />}
                  active={menuActivo === 'permis'}
                  id='permis'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('permissions')}
                </MenuItem>
              </>
            }
            {
              <>
                <MenuItem
                  icon={<BsStackOverflow />}
                  active={menuActivo === 'programs'}
                  id='batch'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('batchControl')}
                </MenuItem>
              </>
            }
            {
              <>
                <MenuItem
                  icon={<CgProfile />}
                  active={menuActivo === 'dataGamo'}
                  id='dataGamo'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('miPerfil')}
                  {/* <SubMenu
                  open={openP}
                  title={t('miPerfil')}
                  icon={<CgProfile />}
                  onClick={() => {
                    setMenuCollapse(false);
                    setOpenP(menuCollapse ? true : !openP);
                  }}
                > */}
                  {/* {!menuCollapse && (
                    <>

                      <MenuItem
                        active={menuActivo === 'dataGamo'}
                        id='dataGamo'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('misDatos')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'factura'}
                        id='factura'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('factura')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'bank'}
                        id='bank'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('bank')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'infCouta'}
                        id='infCouta'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('infCouta')}
                      </MenuItem>
                      <MenuItem
                        active={menuActivo === 'contrato'}
                        id='contrato'
                        onClick={(e) => handleMenuItem(e)}
                      >
                        {t('contrato')}
                      </MenuItem>
                    </>
                  )} */}
                </MenuItem>
              </>
            }
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape='square'>
            <>
              <MenuItem
                active={menuActivo === 'settings'}
                icon={<FiSettings />}
                id='settings'
                onClick={(e) => handleMenuItem(e)}
              >
                {t('settings')}
              </MenuItem>
            </>
          </Menu>
        </SidebarFooter>

        {es_admin && (
          <SidebarFooter>
            <Menu iconShape='square'>
              <>
                <MenuItem
                  active={menuActivo === 'trans'}
                  icon={<MdOutlineLibraryBooks />}
                  id='trans'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('traduccion')}
                </MenuItem>
              </>
            </Menu>
          </SidebarFooter>
        )}
        <SidebarFooter>
          <Menu iconShape='square'>
            {!es_none && (
              <>
                <MenuItem
                  active={menuActivo === 'help'}
                  icon={<FiHelpCircle />}
                  id='help'
                  onClick={(e) => handleMenuItem(e)}
                >
                  {t('help')}
                </MenuItem>
                <MenuItem
                  icon={<FiLogOut />}
                  onClick={() => {
                    localStorage.removeItem('autoAcceso');
                    window.location.href = '/';
                  }}
                >
                  {t('logout')}
                  {/* <Link to='/' /> */}
                </MenuItem>
              </>
            )}
          </Menu>
        </SidebarFooter>
      </ProSidebar>
      <main>
        <div className='btn-toggle py-3' onClick={() => setToggled(true)}>
          <GiHamburgerMenu />
        </div>
        {/* display the selected page in the menu */}
        {(!menuActivo || menuActivo === 'help') && <Bienvenida />}

        {menuActivo === 'public' && 'Sensores publicos'}
        {menuActivo === 'idris' && <Idris firstPage={firstPage} setmenuActivo={setmenuActivo} />}
        {menuActivo === 'idrisComu' && 'Idris entidad'}
        {menuActivo === 'idrisAdmin' && 'Idris admin'}

        {menuActivo === 'newIdris' && (
          <NuevoIdri profiles={profiles} firstPage={firstPage} idUser={profiles.usuarioid} />
        )}

        {menuActivo === 'lecSensores' && 'Lectura sensores de comunidades'}
        {menuActivo === 'incComu' && 'Incidencias de comunidades'}
        {menuActivo === 'usersComu' && <UsersManagement profiles={profiles} />}

        {menuActivo === 'permAdmin' && <Permissions />}
        {menuActivo === 'firmware' && <FirmwareManagement />}
        {menuActivo === 'alertGlob' && <GlobalAlert />}
        {menuActivo === 'cargaIdris' && 'Carga de Idris admin'}
        {menuActivo === 'IncAdmin' && 'Incidencias admin'}
        {menuActivo === 'usersAdmin' && (
          <UsersManagement profiles={profiles} firstPage={firstPage} setFirstPage={setFirstPage} />
        )}
        {/* {menuActivo === 'entitiesAdmin' && <EntityManagement profiles={profiles} />} */}
        {menuActivo === 'cuotasAdmin' && 'Gestión cuotas admin'}
        {menuActivo === 'permis' && <Permissions />}
        {menuActivo === 'batch' && <ProgramsControl />}
        {menuActivo === 'dataGamo' && <MyProfile profiles={profiles} />}
        {menuActivo === 'dataComu' && 'Datos entidad'}

        {menuActivo === 'factura' && 'Datos de facturación'}
        {menuActivo === 'bank' && 'Datos bankarios'}
        {menuActivo === 'infCouta' && 'Inf. cuotas'}
        {menuActivo === 'contrato' && 'Contrato'}

        {menuActivo === 'configInst' && 'Configuración de instalaciones'}
        {menuActivo === 'settings' && (
          <Settings notifications={notifications} setNotifications={setNotifications} />
        )}
        {menuActivo === 'trans' && <TranslationTable />}
        {menuActivo === 'permAcceso' && 'Permisos de acceso'}
        {menuActivo === 'confApp' && 'Configuración app'}

        {/* {subMenu === 'Idrimanagement' && idrisId && (
          <Idrimanagement idIdri={idrisId} />
        )} */}
      </main>
    </>
  );
};

export default Prosidenavbar;
