import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flags from 'country-flag-icons/react/3x2';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const ChangeEmail = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const go_to = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState();

  const changeEmail = (e) => {
    setIsPending(true);
    e.preventDefault();
    if (!password) {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      let body = {
        email: email,
        newemail: newEmail,
        password: password,
        change_email_token: token,
      };
      console.log(body);
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/useremailchange`, body)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            localStorage.setItem('autoAcceso', res.data.token);
            localStorage.setItem('id', res.data.iduser);
            localStorage.setItem('email', res.data.email);
            if (!['es', 'en', 'fr'].includes(res.data.language)) {
              localStorage.setItem('language', 'en');
            } else {
              localStorage.setItem('language', res.data.language);
            }
            i18n.changeLanguage(localStorage.getItem('language'));
            setIsPending(false);
            go_to('/inicio');
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  let token = location.pathname.split('/')[2];
  let email = decodeURIComponent(location.search.split('email=')[1].split('&')[0]);
  let newEmail = decodeURIComponent(location.search.split('newemail=')[1].split('&')[0]);
  let lang = decodeURIComponent(location.search.split('language=')[1]);

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18next.changeLanguage(lang);
  };

  useEffect(() => {
    localStorage.setItem('language', lang);
  }, [lang]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Container className='text-center'>
      <Col
        xs={2}
        md={1}
        className='d-md-flex text-center me-md-3 mt-3 position-absolute top-0 end-0'
      >
        <Col xs={12} md={4} className='pb-0'>
          <Flags.ES className='langBtn' onClick={() => changeLanguage('es')} />
        </Col>
        <Col xs={12} md={4} className='pb-0'>
          <Flags.GB className='langBtn' onClick={() => changeLanguage('en')} />
        </Col>
        <Col xs={12} md={4} className='pb-0'>
          <Flags.FR className='langBtn' onClick={() => changeLanguage('fr')} />
        </Col>
      </Col>
      <div className='mx-auto logo128' />
      <Row className='justify-content-center'>
        <Col>
          <h2 className='text-uppercase'>{t('changeEmail')}</h2>
        </Col>
      </Row>

      <hr />

      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}

      <Form onSubmit={(e) => changeEmail(e)}>
        <Row>
          <Col xs={12} md={6}>
            <strong>{t('oldEmail')}</strong>
            <Form.Control required disabled type='email' value={email} className='text-center' />
          </Col>
          <Col xs={12} md={6}>
            <strong>{t('newEmail')}</strong>
            <Form.Control required disabled type='email' value={newEmail} className='text-center' />
          </Col>
        </Row>
        <Row className='px-0 mt-3 justify-content-center'>
          <strong className=''>{t('introducePassword')}</strong>
          <Col xs={12} md={6}>
            <Form.Control
              required
              type='password'
              className='text-center'
              value={password ? password : ''}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='text-center mt-3 '>
          <Col>
            <Button
              type='submit'
              // variant='primary'
              size='lg'
              className='py-md-1 dark'
            >
              {t('change').toUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ChangeEmail;
