/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';
import { BsPauseCircle, BsPlayCircle } from 'react-icons/bs';
import {
  FaDivide,
  FaEquals,
  FaGreaterThan,
  FaGreaterThanEqual,
  FaLessThan,
  FaLessThanEqual,
  FaMinus,
  FaNotEqual,
  FaPlus,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';

import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import Action from './Action';
import Condition from './Condition';

const RuleConfig = (props) => {
  const { t } = useTranslation();
  const idri = useSelector((state) => state.idri);

  const [sensorsList, setSensorsList] = useState();
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);

  const [data, setData] = useState({});
  const [conditions, setConditions] = useState({ 0: {} });
  const [actions, setActions] = useState({ 0: {} });
  const [noactions, setNoActions] = useState(props.noactions);
  const [priority, setPriority] = useState({});

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const selectStyles =
    window.innerWidth < 577
      ? {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0',
          }),
        }
      : {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
        };

  const resetFocus = () => {
    props.getIdri();
    // input.focus(); // focus
    // input.blur(); // removing focus
  };

  const kindTransform = (type, kind) => {
    if (type === 1) {
      if (kind === 1 || kind === 2) {
        return 2;
      } else if (kind === 3) {
        return 0;
      } else if (kind === 0 || kind === 4) {
        return 3;
      }
    } else if (type === 2) {
      if (kind === 'fluid') {
        return 8;
      } else if (kind === 'onoff') {
        return 4;
      }
    }
  };
  const kindToText = (kind) => {
    if (kind === 0) {
      return 'se';
    } else if (kind === 1) {
      return 'fl';
    } else if (kind === 2) {
      return 'se';
    } else if (kind === 3) {
      return 'se';
    } else if (kind === 4) {
      return 'ac';
    } else if (kind === 5) {
      return 'co';
    }
  };
  const idByInput = (input, kind) => {
    if (kind === 0 || kind === 1) {
      return Object.values(idri.sensors.fm_sensors)
        .map((sensor) => {
          if (sensor.ninput === parseInt(input)) {
            return sensor.idsensor;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 2 || kind === 3) {
      return Object.values(idri.sensors.none_fm_sensors)
        .map((sensor) => {
          if (sensor.ninput === parseInt(input)) {
            return sensor.idsensor;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 4) {
      return Object.values(idri.actuators)
        .map((actuator) => {
          if (actuator.noutput === parseInt(input)) {
            return actuator.idactuator;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 5) {
      return null;
    }
  };
  const labelByInput = (input, kind) => {
    // console.log(input);
    // console.log(kind);
    if (kind === 0 || kind === 1) {
      return Object.values(idri.sensors.fm_sensors)
        .map((sensor) => {
          if (sensor.ninput === parseInt(input)) {
            return `${sensor.name} (${sensor.magnitude}) ${
              kind === 1 ? `(${t('caudal')})` : `(${t('value')})`
            }`;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 2 || kind === 3) {
      return Object.values(idri.sensors.none_fm_sensors)
        .map((sensor) => {
          if (sensor.ninput === parseInt(input)) {
            return `${sensor.name} (${
              kind === 2
                ? sensor.magnitude
                : sensor.kind_sensor.normal_state
                ? t('abierto')
                : t('cerrado')
            })`;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 4) {
      return Object.values(idri.actuators)
        .map((actuator) => {
          if (actuator.noutput === parseInt(input) || actuator.idactuator === parseInt(input)) {
            return `${actuator.name} (${actuator.kind.normal_state ? t('abierto') : t('cerrado')}${
              actuator.fluid.category ? '-' + actuator.fluid.category : ''
            })`;
          }
        })
        .find((value) => {
          return value;
        });
    } else if (kind === 5) {
      return 'constant';
    } else if (kind === 8) {
      return Object.values(idri.actuators)
        .map((actuator) => {
          if (actuator.idactuator === parseInt(input)) {
            return `${actuator.name} (${actuator.kind.normal_state ? t('abierto') : t('cerrado')}${
              actuator.fluid.category ? '-' + actuator.fluid.category : ''
            })`;
          }
        })
        .find((value) => {
          return value;
        });
    }
  };
  const labelLogic = (type) => {
    if (type === 0) {
      return (
        <div>
          <FaEquals />
        </div>
      );
    } else if (type === 1) {
      return (
        <div>
          <FaNotEqual />
        </div>
      );
    } else if (type === 2) {
      return (
        <div>
          <FaLessThanEqual />
        </div>
      );
    } else if (type === 3) {
      return (
        <div>
          <FaGreaterThanEqual />
        </div>
      );
    } else if (type === 4) {
      return (
        <div>
          <FaLessThan />
        </div>
      );
    } else if (type === 5) {
      return (
        <div>
          <FaGreaterThan />
        </div>
      );
    }
  };
  const labelAr = (type) => {
    if (type === 0) {
      return (
        <div>
          <FaPlus />
        </div>
      );
    } else if (type === 1) {
      return (
        <div>
          <FaMinus />
        </div>
      );
    } else if (type === 2) {
      return (
        <div>
          <FaTimes />
        </div>
      );
    } else if (type === 3) {
      return (
        <div>
          <FaDivide />
        </div>
      );
    } else if (type === 4) {
      return (
        <div>
          <FaMinus style={{ transform: 'rotate(90deg)' }} />
          <FaMinus style={{ transform: 'rotate(90deg)' }} />
        </div>
      );
    }
  };
  const kindById = (id) => {
    return Object.values(idri.actuators)
      .map((actuator) => {
        if (actuator.idactuator === parseInt(id)) {
          return actuator.kind.related_to;
        }
      })
      .find((value) => {
        return value;
      });
  };

  const [, forceUpdate] = useState(0);
  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  useEffect(() => {
    setBlock(props.block);
  }, [props]);

  useEffect(() => {
    setConditions({ 0: {} });
    setActions({ 0: {} });
    let allList = [];
    Object.values(idri.sensors.fm_sensors).map((sensor) => {
      allList.push({
        id: sensor.idsensor,
        kind: 1,
        type: 'fl',
        label: sensor.name + ' (' + sensor.magnitude + ')' + ` (${t('caudal')})`,
      });
    });
    Object.values(idri.sensors.fm_sensors).map((sensor) => {
      allList.push({
        id: sensor.idsensor,
        kind: 0,
        type: 'se',
        label: sensor.name + ' (' + sensor.magnitude + ')' + ` (${t('value')})`,
      });
    });
    Object.values(idri.sensors.none_fm_sensors).map((sensor) => {
      allList.push({
        id: sensor.idsensor,
        kind: kindTransform(1, sensor.kind_sensor.code),
        type: 'se',
        label:
          sensor.name +
          ' (' +
          (sensor.kind_sensor.normal_state === true || sensor.kind_sensor.normal_state === false
            ? sensor.kind_sensor.normal_state
              ? t('abierto')
              : t('cerrado')
            : sensor.magnitude) +
          ')',
      });
    });
    let slavesGap = [];
    Object.values(idri.actuators).forEach((actuator) => {
      if (actuator.is_master) {
        actuator.slaves.forEach((slave) => {
          slavesGap.push({ [slave]: actuator.gap_master });
        });
      }
    });

    Object.values(idri.actuators).map((actuator) => {
      allList.push({
        id: actuator.idactuator,
        kind: kindTransform(2, actuator.kind.related_to),
        type: 'ac',
        state: actuator.kind.normal_state,
        label:
          actuator.name +
          ' (' +
          (actuator.kind.normal_state ? t('abierto') : t('cerrado')) +
          (actuator.fluid.category ? '-' + actuator.fluid.category : '') +
          ')',
        disabled: actuator.is_master && idri.master_en,
        gap: actuator.is_master
          ? actuator.gap_master
          : // eslint-disable-next-line eqeqeq
            slavesGap.find((obj) => Object.keys(obj)[0] == actuator.idactuator)?.[
              actuator.idactuator
            ],
      });
    });
    allList.push(
      {
        kind: 5,
        type: 'co',
        value: '',
        label: t('const'),
      },
      {
        kind: 6,
        type: 'co',
        value: true,
        label: t('activado'),
      },
      {
        kind: 7,
        type: 'co',
        value: false,
        label: t('desactivado'),
      }
    );
    // console.log(allList);
    setSensorsList(allList);
  }, [idri]);

  useEffect(() => {
    // props.rule && console.log(props.rule.ruleconditions.conditions);
    let conds = props.rule ? props.rule.ruleconditions.conditions : [];
    let acts = props.rule ? props.rule.ruleactions : [];
    // console.log(conds);
    let condsList = Object.entries(conds).map(([i, c]) => {
      // console.log(c);
      return {
        operator1: {
          0: {
            id: idByInput(c.logic1.operating[0].id_val, c.logic1.operating[0].operatingkind),
            kind: c.logic1.operating[0].operatingkind,
            type: kindToText(c.logic1.operating[0].operatingkind),
            label: labelByInput(c.logic1.operating[0].id_val, c.logic1.operating[0].operatingkind),
          },
          1: {
            id: c.logic1.operating[1]?.input_ref
              ? {
                  id: idByInput(c.logic1.operating[0].id_val, c.logic1.operating[0].operatingkind),
                  kind: c.logic1.operating[0].operatingkind,
                  type: kindToText(c.logic1.operating[0].operatingkind),
                  label: labelByInput(
                    c.logic1.operating[0].id_val,
                    c.logic1.operating[0].operatingkind
                  ),
                }
              : idByInput(c.logic1.operating[0].id_val, c.logic1.operating[0].operatingkind),
            kind: c.logic1.operating[1] && c.logic1.operating[1].operatingkind,
            type: c.logic1.operating[1] && kindToText(c.logic1.operating[1].operatingkind),
            value: c.logic1.operating[1] && c.logic1.operating[1].id_val,
            label:
              c.logic1.operating[1] &&
              labelByInput(c.logic1.operating[1].id_val, c.logic1.operating[1].operatingkind),
            // id: {
            //   id: idByInput(c.logic1.operating[0].id_val, c.logic1.operating[0].operatingkind),
            //   kind: c.logic1.operating[0].operatingkind,
            //   type: kindToText(c.logic1.operating[0].operatingkind),
            //   label: labelByInput(
            //     c.logic1.operating[0].id_val,
            //     c.logic1.operating[0].operatingkind
            //   ),
            // },
          },
        },
        math: {
          0: {
            value:
              c.logic1.operators[0] || c.logic1.operators[0] === 0 ? c.logic1.operators[0] : null,
            label:
              c.logic1.operators[0] || c.logic1.operators[0] === 0
                ? labelAr(c.logic1.operators[0])
                : null,
          },
        },
        operator2: {
          0: {
            id:
              c.logic2.operating[0].operatingkind === 5
                ? {
                    id: idByInput(
                      c.logic2.operating[0].input_ref ?? c.logic1.operating[0].id_val,
                      c.logic1.operating[0].operatingkind
                    ),
                    kind: c.logic1.operating[0].operatingkind,
                    type: kindToText(c.logic1.operating[0].operatingkind),
                    label: labelByInput(
                      c.logic2.operating[0].input_ref ?? c.logic1.operating[0].id_val,
                      c.logic1.operating[0].operatingkind
                    ),
                  }
                : idByInput(c.logic2.operating[0].id_val, c.logic2.operating[0].operatingkind),
            value:
              c.logic2.operating[0].id_val === 'true'
                ? true
                : c.logic2.operating[0].id_val === 'false'
                ? false
                : c.logic2.operating[0].id_val,
            kind:
              c.logic2.operating[0].id_val === 'true' || c.logic2.operating[0].id_val === 'false'
                ? 7
                : c.logic2.operating[0].operatingkind,
            type: kindToText(c.logic2.operating[0].operatingkind),
            label:
              c.logic2.operating[0].id_val === 'true'
                ? t('activado')
                : c.logic2.operating[0].id_val === 'false'
                ? t('desactivado')
                : labelByInput(c.logic2.operating[0].id_val, c.logic2.operating[0].operatingkind),
          },
          1: {
            kind: c.logic2.operating[1] && c.logic2.operating[1].operatingkind,
            type: c.logic2.operating[1] && kindToText(c.logic2.operating[1].operatingkind),
            value: c.logic2.operating[1] && c.logic2.operating[1].id_val,
            label:
              c.logic2.operating[1] &&
              labelByInput(c.logic2.operating[1].id_val, c.logic2.operating[1].operatingkind),
            id: {
              id:
                c.logic2.operating[0].operatingkind === 5 &&
                c.logic2.operating[1] &&
                c.logic2.operating[1].input_ref !== null
                  ? idByInput(c.logic2.operating[1].input_ref, c.logic1.operating[0].operatingkind)
                  : idByInput(c.logic2.operating[0].id_val, c.logic2.operating[0].operatingkind),
              kind:
                c.logic2.operating[0].operatingkind === 5 &&
                c.logic2.operating[1] &&
                c.logic2.operating[1].input_ref !== null
                  ? c.logic1.operating[0].operatingkind
                  : c.logic2.operating[0].operatingkind,
              type:
                c.logic2.operating[0].operatingkind === 5 &&
                c.logic2.operating[1] &&
                c.logic2.operating[1].input_ref !== null
                  ? kindToText(c.logic1.operating[0].operatingkind)
                  : kindToText(c.logic2.operating[0].operatingkind),
              label:
                c.logic2.operating[0].operatingkind === 5 &&
                c.logic2.operating[1] &&
                c.logic2.operating[1].input_ref !== null
                  ? labelByInput(
                      c.logic2.operating[1].input_ref,
                      c.logic1.operating[0].operatingkind
                    )
                  : labelByInput(c.logic2.operating[0].id_val, c.logic2.operating[0].operatingkind),
            },
          },
        },
        math2: {
          0: {
            value:
              c.logic2.operators[0] || c.logic2.operators[0] === 0 ? c.logic2.operators[0] : null,
            label:
              c.logic2.operators[0] || c.logic2.operators[0] === 0
                ? labelAr(c.logic2.operators[0])
                : null,
          },
        },
        logic: {
          value: c.comparoperator,
          label: labelLogic(c.comparoperator),
        },
      };
    });

    let actsList = Object.entries(acts).map(([i, a]) => {
      // console.log(a);
      return {
        actuator: {
          id: a.idactuator,
          kind: kindTransform(2, kindById(a.idactuator)),
          state: a.normal_state,
          type: 'ac',
          label: labelByInput(a.idactuator, kindTransform(2, kindById(a.idactuator))),
        },
        disconnection: {
          kind: a.duration === null && a.afmv === null && a.afv === null ? 1 : 0,
          label:
            a.duration === null && a.afmv === null && a.afv === null
              ? t('active')
              : t('changeState'),
        },
        action: {
          kind: a.action === true ? 1 : 0,
          label: a.action === true ? t('activate') : t('deactivate'),
        },
        duration: a.duration,
        volume: a.afmv,
        flow: a.afv,
      };
    });

    // console.log(condsList);
    props.rule && setConditions(condsList);
    props.rule && props.rule.ruleactions.length > 0 && setActions(actsList);
    props.rule && props.rule.ruleactions.length === 0 && setActions({ 0: {} })
    props.rule && setData({ ...data, idrule: props.rule.idrule });
  }, [idri]);

  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, []);

  // console.log(props)
  // useEffect(() => {
  //   console.log(conditions);
  // }, [conditions]);
  console.log(props.rule);
  console.log(actions);
  console.log(Object.keys(actions).length);
  return (
    <>
      {sensorsList && (
        <Form
          onSubmit={(e) => {
            props.addRule(e, { data, conditions, actions, priority, noactions });
          }}
        >
          {props.rule && (
            <Col xs={12}>
              {props.rule.enable ? (
                <span className='text-success habilitado'>{t('abilitado')}</span>
              ) : (
                <span className='text-danger'>{t('disabilitado')}</span>
              )}
            </Col>
          )}
          <div
            className={
              (props.rule && props.editing !== props.rule.idrule) ||
              (block && !props.rule) ||
              (props.rule && !edit)
                ? 'dis'
                : ''
            }
          >
            <Row className='my-1 my-md-2 mx-0'>
              <Col xs={12}>{t('conditions')}</Col>
              {Object.entries(conditions).map(([i, c]) => {
                return (
                  <div key={i} className='p-0 m-0'>
                    {Object.keys(conditions).length > 1 && i > 0 && (
                      <Col className='text-center'>
                        <strong>AND</strong>
                      </Col>
                    )}
                    <Condition
                      index={i}
                      condition={c}
                      sensorsList={sensorsList}
                      conditions={conditions}
                      setConditions={setConditions}
                      forceUpdate={forceUpdate}
                      hide={(!block && !props.rule) || (props.rule && edit)}
                    />
                  </div>
                );
              })}
              {((!block && !props.rule) || (props.rule && edit)) &&
                conditions[0].operator1 &&
                Object.keys(conditions).length < 5 && (
                  <Col xs={12} className='my-1'>
                    <Button
                      variant='outline-primary'
                      // size='sm'
                      className='px-1 py-0 ruleBtn'
                      onClick={() =>
                        setConditions({
                          ...conditions,
                          [parseInt(Object.keys(conditions)[Object.keys(conditions).length - 1]) +
                          1]: {},
                        })
                      }
                    >
                      {t('conditionAdd')}
                    </Button>
                  </Col>
                )}
            </Row>
            {conditions[0].operator1 && (
              <Row className='my-1 my-md-2 mx-0'>
                <hr className='my-1' />
                <Col xs={12}>{t('actions')}</Col>
                <strong className='me-4 me-md-4'>{t('noActions')}</strong>
                <Form.Check
                  type='checkbox'
                  checked={noactions}                  
                  onChange={(e) =>
                    setNoActions(e.target.checked ? true : false)
                  }
                />
                {!noactions && (Object.entries(actions).map(([i, a]) => {
                  return (
                    <Action
                      key={i}
                      index={i}
                      action={a}
                      sensorsList={sensorsList}
                      actions={actions}
                      setActions={setActions}
                      forceUpdate={forceUpdate}
                    />
                  );
                }))}
               {!noactions && (((!block && !props.rule) || (props.rule && edit)) &&
                  (Object.keys(actions).length > 0 && actions[0].actuator) &&                
                  Object.keys(actions).length < 5 && (
                    <Col xs={12} className='my-1'>
                      <Button
                        variant='outline-primary'
                        // size='sm'
                        className='px-1 py-0 ruleBtn'
                        onClick={() =>
                          setActions({
                            ...actions,
                            [parseInt(Object.keys(actions)[Object.keys(actions).length - 1]) + 1]:
                              {},
                          })
                        }
                      >
                        {t('actionAdd')}
                      </Button>
                    </Col>
                  ))}
              </Row>
            )}
            {((!block && !props.rule) || (props.rule && edit)) &&
              conditions[0].operator1 &&
              ((props.new && props.list.length > 0) || props.list.length > 1) && (
                <Row className='my-1 my-md-2 mx-0'>
                  <hr className='my-1' />
                  <Col xs={12}>{t('priority')}</Col>
                  <Card className='p-0' border='warning'>
                    <Row className='text-center mx-0'>
                      <Col xs={6} md={6} className='p-0'>
                        <Select
                          menuShouldScrollIntoView
                          required={props.list.length > 0}
                          placeholder={t('order')}
                          // isClearable
                          isSearchable={true}
                          options={[
                            {
                              kind: 0,
                              label: t('before'),
                            },
                            {
                              kind: 1,
                              label: t('after'),
                            },
                          ]}
                          value={priority.position ? priority.position : ''}
                          styles={selectStyles}
                          onChange={(e) => {
                            setPriority({ ...priority, position: e });
                          }}
                        />
                      </Col>
                      <Col xs={6} md={6} className='p-0'>
                        <Select
                          menuShouldScrollIntoView
                          required={props.list.length > 0}
                          placeholder={t('rule')}
                          // isClearable
                          isSearchable={true}
                          options={
                            !props.new
                              ? props.list
                                  .map((r) => {
                                    return {
                                      index: r.priority,
                                      label: `${t('rule')} ${parseInt(r.priority)}`,
                                    };
                                  })
                                  .filter((r) => r.index !== props.rule.priority)
                              : props.list.map((r) => {
                                  return {
                                    index: r.priority,
                                    label: `${t('rule')} ${parseInt(r.priority)}`,
                                  };
                                })
                          }
                          value={priority.rule ? priority.rule : ''}
                          styles={selectStyles}
                          onChange={(e) => {
                            setPriority({ ...priority, rule: e });
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Row>
              )}
            {props.new && (
              <Col xs={12}>
                <Button type='submit' className='dark'>
                  {t('add') + ' ' + t('regla')}
                </Button>
              </Col>
            )}
          </div>

          {!props.new && (
            <>
              {edit && (
                <Col className='text-center'>
                  <Button
                    className='mt-2 dark'
                    type='submit'
                    // onClick={(e) => props.editProgram(e, false)}
                  >
                    {t('modificar').toLowerCase()} {t('rule')}
                  </Button>
                </Col>
              )}
              <Col
                className={`d-flex justify-content-end align-items-center active my-2 ${
                  props.rule && props.editing && props.editing !== props.rule.idrule ? 'dis' : ''
                }`}
              >
                <Form.Check
                  type='checkbox'
                  checked={
                    typeof props.checkRules === 'object' &&
                    props.checkRules.includes(props.rule.idrule)
                  }
                  className='ps-3 me-auto'
                  style={{ transform: 'scale(1.5)' }}
                  onChange={(e) =>
                    e.target.checked === true
                      ? props.setCheckRules([...props.checkRules, props.rule.idrule])
                      : props.setCheckRules(
                          props.checkRules.filter((item) => {
                            return item !== props.rule.idrule;
                          })
                        )
                  }
                />
                {parseFloat(idri.ghatorv) >= 3 && (
                  <OverlayTrigger
                    placement='top'
                    rootClose='true'
                    overlay={
                      <Tooltip>
                        {props.rule.enable
                          ? `${t('suspender')} ${t('regla')}`
                          : `${t('abilitar')} ${t('regla')}`}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant={props.rule.enable ? 'outline-danger' : 'outline-success'}
                      className='p-0 px-1 mx-3'
                      onClick={(e) =>
                        window.confirm(
                          t('seguro') +
                            ` ${props.rule.enable ? t('suspender') : t('abilitar')} ${t(
                              'regla'
                            )} #` +
                            props.rule.priority
                        )
                          ? props.enableRule(e, props.rule.idrule, props.rule.enable)
                          : null
                      }
                    >
                      {props.rule.enable ? (
                        <BsPauseCircle type='button' />
                      ) : (
                        <BsPlayCircle type='button' />
                      )}
                    </Button>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement='top'
                  rootClose='true'
                  overlay={
                    <Tooltip>
                      {t('edit').toLowerCase()} {t('regla')}
                    </Tooltip>
                  }
                >
                  <Button
                    variant='outline-primary'
                    active={edit}
                    // size='sm'
                    className='p-0 px-1 mx-3'
                    onClick={(e) => {
                      if (edit === true && window.confirm(t('cancelModProg'))) {
                        resetFocus();
                        props.setEditing(null);
                        setEdit(!edit);
                        // props.setEditing(null);
                        // setEdit(!edit);
                      } else {
                        // setBlock(!block);
                        edit === false && props.setEditing(props.rule.idrule);
                        edit === false && setEdit(!edit);
                      }
                      props.setBlock(edit === true ? false : true);
                      // setBlock(!block);
                    }}
                  >
                    <BiEdit type='button' />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  rootClose='true'
                  overlay={
                    <Tooltip>
                      {t('del')} {t('regla')}
                    </Tooltip>
                  }
                >
                  <Button
                    variant='outline-danger'
                    // size='sm'
                    className='p-0 px-1 mx-3'
                    onClick={(e) =>
                      window.confirm(t('seguroDelRule') + ' #' + props.rule.priority + '?')
                        ? props.delRule(props.rule.idrule)
                        : null
                    }
                  >
                    <FaTrashAlt type='button' />
                  </Button>
                </OverlayTrigger>
              </Col>
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default RuleConfig;
