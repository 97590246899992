import { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import FreeAccount from './Modals/FreeAccount';
import ModalGenerico from './Modal_generico';

const Header = ({ isLoggedIn }) => {
  const go_to = useNavigate();
  const { t } = useTranslation();

  const [showFreeAccount, setShowFreeAccount] = useState(false);

  const headerStyle = {
    paddingBottom: '80px',
    paddingTop: '400px',
    color: 'white',
    position: 'relative',
  };
  const actionButtonStyle = {
    minHeight: '50px',
    color: 'white',
  };

  const handleAcceso = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      go_to('/inicio');
    }
  };

  return (
    <header className='masthead'>
      <Container>
        <div className='text-center'>
          <div className='intro-heading'></div>
          <Row style={headerStyle} className='d-flex justify-content-center'>
            <Col xs={12} className='position-absolute top-50 start-50 translate-middle'>
              <Image
                // src={'images/logo_redhator_3_fi_2.png'}
                src={'images/logo_redhator_mundo.png'}
                className='img-fluid d-block mx-auto'
                alt='logo'
                width='400'
              />
            </Col>
            <Col xs={9} md={6} lg={3}>
              <Link
                to='#'
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='btn btn-head dark text-uppercase mx-1 d-grid gap-2 d-flex justify-content-center align-items-center'
                style={actionButtonStyle}
                onClick={() => setShowFreeAccount(true)}
              >
                {t('sensoresPublicos')}
              </Link>
            </Col>
            {/* <Col xs={6} md={3}>
              <Link
                to='red'
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='btn btn-head dark text-uppercase mx-1 d-grid gap-2 d-flex justify-content-center align-items-center'
                style={actionButtonStyle}
              >
                {t('equipoSisritel')}
              </Link>
            </Col> */}
            <Col xs={12} className='position-absolute bottom-0 start-50 translate-middle-x'>
              <Link
                to='acceso'
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='btn btn-lg btn-success text-uppercase mx-3'
                onClick={(e) => handleAcceso(e)}
              >
                {t('accede')}
              </Link>
            </Col>
          </Row>
          <ModalGenerico
            title={t('createFree')}
            onClose={() => setShowFreeAccount(false)}
            show={showFreeAccount}
          >
            <FreeAccount closeModal={() => setShowFreeAccount(false)} />
          </ModalGenerico>
        </div>
      </Container>
    </header>
  );
};

export default Header;
