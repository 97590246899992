import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './containers/Home';
import Srtapp from './containers/Srtapp';
import CambiarPassword from './containers/CambiarPassword';
import NewUser from './components/srtapp/modals/NewUser';
import ChangeEmail from './components/srtapp/modals/ChangeEmail';
import Maintenance from './containers/Maintenance';
import Unavailable from './containers/Unavailable';

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/inicio' element={<Srtapp />} />
          <Route path='/passwordreset/:token/edit' element={<CambiarPassword />} />
          <Route path='/urlaccountactiv/:token/edit' element={<NewUser />} />
          <Route path='/emailchange/:token/edit' element={<ChangeEmail />} />

          <Route path='/maintenance' element={<Maintenance />} />
          <Route path='/unavailable' element={<Unavailable />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
