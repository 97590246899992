/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2';
import i18next from 'i18next';
import errorHandler from '../support_func/errorHandler';
import unavailable from '../support_func/unavailable';

const CambiarPassword = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const go_to = useNavigate();

  const initialState = { email: '', token: '', password: '' };
  const [resetPass, setResetPass] = useState(initialState);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [returnHome, setReturnHome] = useState(false);

  let token = location.pathname.split('/')[2];
  let createReset = location.pathname.split('/')[1];
  let email = decodeURIComponent(location.search.split('=')[1]);

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18next.changeLanguage(lang);
  };

  //data reset on page load
  useEffect(() => {
    setResetPass({
      email: email,
      token: token,
      password: password,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  //sending data to the server
  const submit = (e) => {
    setIsPending(true);
    e.preventDefault();
    //password checks
    if (password !== confirmPassword) {
      setIsPending(false);
      setError(t('noSimilar'));
    } else if (password === '' || confirmPassword === '') {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/forgotpwd`, resetPass)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setIsPending(false);
            setResetPass(initialState);
            setPassword('');
            setConfirmPassword('');
            setMsg(t('passSuccess'));
            localStorage.setItem('autoAcceso', res.data.token);
            localStorage.setItem('id', res.data.iduser);
            localStorage.setItem('email', res.data.email);
            if (!['es', 'en', 'fr'].includes(res.data.language)) {
              localStorage.setItem('language', 'en');
            } else {
              localStorage.setItem('language', res.data.language);
            }
            i18n.changeLanguage(localStorage.getItem('language'));
            setTimeout(() => {
              setMsg(null);
              setReturnHome(true);
            }, 3000);
          } else {
            setIsPending(false);
            setResetPass(initialState);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  return (
    <section id='password_reset' className='py-0'>
      <Container className='text-center'>
        <Col
          xs={2}
          md={1}
          className='d-md-flex text-center me-md-3 mt-3 position-absolute top-0 end-0'
        >
          <Col xs={12} md={4} className='pb-0'>
            <Flags.ES className='langBtn' onClick={() => changeLanguage('es')} />
          </Col>
          <Col xs={12} md={4} className='pb-0'>
            <Flags.GB className='langBtn' onClick={() => changeLanguage('en')} />
          </Col>
          <Col xs={12} md={4} className='pb-0'>
            <Flags.FR className='langBtn' onClick={() => changeLanguage('fr')} />
          </Col>
        </Col>
        {/* <Image
          width='50'
          src='/images/logo_sisritel_punto_200x200.png'
          alt='Sisritel logo'
        /> */}
        <div
          // src='/images/logos/logo_sisritel_punto_128x128.png'
          className='mx-auto logo128'
        />
        <Row className='justify-content-center'>
          <Col>
            <h2 className='text-uppercase'>
              {createReset === 'inicio' ? t('creaPass') : t('cambiaPass')}
            </h2>
            <h5 className='text-muted '>
              {createReset === 'inicio' ? t('intrDataCreatePass') : t('intrData')}
            </h5>
          </Col>
        </Row>

        <hr />

        <Form id='user_password_reset_form' onSubmit={submit}>
          <Row md={2} className='justify-content-center'>
            <Col>
              <Form.Group className='text-start'>
                <Form.Label className='mt-2' htmlFor='password_reset_password'>
                  <strong>{t('newPass').toLowerCase()}</strong> ({t('min8')})
                </Form.Label>
                <Form.Control
                  required
                  minLength={8}
                  type='password'
                  id='password_reset_password'
                  autoComplete='off'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='text-start'>
                <Form.Label className='mt-2' htmlFor='password_reset_password_confirmation'>
                  <strong>{t('pass2').toLowerCase()}</strong>
                </Form.Label>
                <Form.Control
                  required
                  minLength={8}
                  type='password'
                  id='password_reset_password_confirmation'
                  autoComplete='off'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          {!returnHome && (
            <Row>
              <Col className='text-center'>
                <Button
                  type='submit'
                  id='changePassButton'
                  className='btn dark text-uppercase mt-5'
                >
                  {createReset === 'inicio' ? t('createPass') : t('cambiarPass')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>

        {isPending && (
          <div className='working_apis'>
            <div className='text-working-apis'>{t('comprobando')}...</div>
          </div>
        )}
        {(error && <Col className='text-center alert alert-warning mt-3'>{error}</Col>) ||
          (msg && <Col className='text-center alert alert-success mt-3'>{msg}</Col>)}

        {returnHome && (
          <Row>
            <Col md={6}>
              <Button
                type='button'
                id='goHome'
                className='btn dark text-uppercase mt-5'
                onClick={() => go_to('/')}
              >
                {t('homePage')}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                type='button'
                id='goProfile'
                className='btn dark text-uppercase mt-5'
                onClick={() => go_to('/inicio')}
              >
                {t('login')}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default CambiarPassword;
