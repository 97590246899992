/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Tooltip, useMap } from 'react-leaflet';
import L, { latLngBounds } from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import IdrisPopup from './IdrisPopup';
import Select from 'react-select';
import { MARKERS } from '../../../../redux/constants/idriConstants';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Image, OverlayTrigger, Row } from 'react-bootstrap';
import { Tooltip as TooltipB } from 'react-bootstrap';
import { HiRefresh } from 'react-icons/hi';
import { IconContext } from 'react-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useRef } from 'react';
import DeviceMarker from './DeviceMarker';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import ModalGenerico from '../../../home/Modal_generico';
import DevicesMap from '../../info/DevicesMap';
import selectStyles from '../../../../support_func/selectStyles';

const IdrisMap = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const position = [39.5, -0.7];
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [bounds, setBounds] = useState([]);
  const [flyTo, setFlyTo] = useState([]);
  const [list, setList] = useState([]);
  const [defOption, setDefOption] = useState(null);
  const [markerLoading, setMarkerLoading] = useState({});
  const [devicesMapInfo, setDevicesMapInfo] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useDispatch();
  const markers = useSelector((state) => state.markers);
  const allTheRefs = {};
  const markerRef = useRef();
  const markerRefs = useRef({});

  // const [, forceUpdate] = useState(0);
  const [state, setState] = useState(null);
  // useEffect(() => {
  //   console.log('force');
  //   forceUpdate((n) => !n);
  // }, [markers]);
  const GetCoords = () => {
    return (
      <>
        {markers &&
          Object.values(markers).map((device) => {
            return (
              <div key={device.ghatorid}>
                {device && (
                  <DeviceMarker
                    device={device}
                    setData={props.setData}
                    profile={props.profile}
                    getMarkers={getMarkers}
                    setIdriManage={props.setIdriManage}
                    page={props.page}
                    setPage={props.setPage}
                    markerRefs={markerRefs}
                  />
                )}
              </div>
            );
          })}
      </>
    );
  };

  const getMarkers = () => {
    setIsPending(true);
    props.profile &&
      axios
        .get(
          props.iduser
            ? `${process.env.REACT_APP_API_SRT}/v2/userghators?iduser=${props.iduser}&email=${
                props.email ? props.email : localStorage.getItem('email')
              }&profile=${props.profile ? props.profile : localStorage.getItem('profile')}`
            : `${process.env.REACT_APP_API_SRT}/v2/ghators?profile=${
                props.profile ? props.profile : localStorage.getItem('profile')
              }`,
          config
        )
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setIsPending(false);

            const bounds = latLngBounds([0, 0]);
            Object.values(res.data.ghators).forEach((ghator) => {
              bounds.extend(ghator.ghatorcoor);
              setBounds(bounds);
            });

            dispatch({
              type: MARKERS,
              payload: res.data.ghators,
            });
            setDefOption(null);
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
          setIsPending(false);
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
  };

  const SetView = () => {
    const map = useMap();
    map.fitBounds(bounds, {
      padding: [50, 50],
    });
    if (Object.keys(markers).length <= 1) {
      map.setZoom(12);
    }
    map.invalidateSize();

    const mapDiv = document.getElementById('mapIdris');
    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });
    resizeObserver.observe(mapDiv);

    return null;
  };

  useEffect(() => {
    getMarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile]);

  useEffect(() => {
    let listIdris = [];
    markers &&
      Object.entries(markers).forEach(([key, ghator]) => {
        listIdris.push({
          ghator: ghator.ghatorserial,
          ghatorid: ghator.ghatorid,
          label: ghator.ghatorname + ' (' + ghator.ghatorserial + ')',
        });
      });
    setList(listIdris);
  }, [markers]);

  /* ==============OLD MARKERS============== */
  // const FlyTo = (prop) => {
  //   const map = useMap();
  //   // console.log(prop.iduser);
  //   useEffect(() => {
  //     prop.latlng &&
  //       prop.latlng.length > 0 &&
  //       map.flyTo(prop.latlng, props.mng ? 13 : 16, {
  //         animate: true,
  //         duration: 1,
  //       });
  //   }, [defOption]);
  //   map.on('zoomend', function () {
  //     let mark = parseInt(
  //       Object.entries(markers)
  //         .map(([key, idri]) => {
  //           if (idri.idricoor === prop.latlng) {
  //             return idri.idriserial;
  //           }
  //         })
  //         .find((value) => {
  //           return value;
  //         })
  //     );

  //     if (mark && allTheRefs[mark] !== null) {
  //       // console.log(allTheRefs[mark]);
  //       allTheRefs[mark].openPopup();
  //     }
  //   });
  //   setBounds([]);
  //   setFlyTo(null);
  //   return null;
  // };

  /* ==============NEW MARKERS============== */
  const FlyTo = () => {
    const latLngNum = flyTo.length > 0 ? [parseFloat(flyTo[0]), parseFloat(flyTo[1])] : null;
    const map = useMap();
    useEffect(() => {
      if (flyTo.length > 0) {
        latLngNum &&
          latLngNum.length > 0 &&
          typeof latLngNum[0] === 'number' &&
          map.flyTo(latLngNum, 15, {
            // animate: true,
            duration: 0.5,
          });
      }

      let dev = markers
        .map((m) => {
          if (m.ghatorcoor === flyTo) {
            return m.ghatorid;
          }
        })
        .find((value) => {
          return value;
        });

      const marker = Object.entries(markerRefs.current)
        .map(([k, v]) => {
          if (dev && parseInt(k) === dev) {
            return v;
          }
        })
        .find((value) => {
          return value;
        });

      if (marker) {
        marker.fire('click');
      }
    }, [flyTo]);
    return null;
  };

  useEffect(() => {
    setFlyTo(null);
    setDefOption(null);
  }, [props.changed]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(list);

  return (
    <>
      <Select
        menuShouldScrollIntoView
        placeholder={t('selectIdris')}
        isSearchable={true}
        options={list}
        // defaultValue={defOption}
        value={defOption ? defOption : ''}
        // defaultInputValue='defaultInputValue'
        menuPortalTarget={document.body}
        className='mt-2'
        id='selectMap'
        styles={selectStyles}
        onChange={(e) => {
          setDefOption(e);
          setState(e.ghatorid);
          // setFlyTo(markers[e.idri].idricoor);
          setFlyTo(
            Object.values(markers)
              .map((m) => {
                if (e.ghatorid === m.ghatorid) {
                  return m.ghatorcoor;
                }
              })
              .find((value) => {
                return value;
              })
          );
        }}
      />
      {/* {console.log(defOption)} */}
      <MapContainer
        style={{ height: `${props.mng ? '50vh' : '75vh'}` }}
        center={position}
        zoom={10}
        attributionControl={false}
        className='noAttributes'
        id='mapIdris'
      >
        <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_MAP_API_KEY} type={'hybrid'} />
        {markers && <GetCoords />}

        {Object.keys(bounds).length > 0 && <SetView />}
        {flyTo && <FlyTo latlng={flyTo} />}
      </MapContainer>

      <IconContext.Provider value={{ color: '#0066cc' }}>
        <Row className='m-0 justify-content-center text-center mt-3'>
          <OverlayTrigger
            placement='bottom'
            rootClose='true'
            overlay={<TooltipB>{t('refresh')}</TooltipB>}
          >
            <Col xs={1} className='p-0 mx-3 testSvg'>
              <HiRefresh
                type='button'
                onClick={(e) => {
                  setFlyTo(null);
                  getMarkers();
                }}
              />
            </Col>
          </OverlayTrigger>
         {/*  <OverlayTrigger
            placement='bottom'
            rootClose='true'
            overlay={<TooltipB>{t('info')}</TooltipB>}
          >
            <Col xs={1} className='p-0 mx-3 testSvg'>
              <FaInfoCircle type='button' onClick={() => setDevicesMapInfo(true)} />
            </Col>
          </OverlayTrigger> */}
        </Row>
      </IconContext.Provider>
      <ModalGenerico
        title={t('-devicesMapInfo')}
        onClose={() => setDevicesMapInfo(false)}
        show={devicesMapInfo}
      >
        <DevicesMap closeModal={() => setDevicesMapInfo(false)} />
      </ModalGenerico>
    </>
  );
};

export default IdrisMap;
