import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Maintenance = () => {
  const { t, i18n } = useTranslation();

  const htmlString = `<html><head>
  <style>html, body {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background-color: #F7F8FB;
    height: 100%;
    -webkit-font-smoothing: antialiased; }
  
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  
  .message {
    text-align: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    max-width: 600px; }
  
  .message__title {
    font-size: 22px;
    font-weight: 100;
    margin-top: 15px;
    color: #47494E;
    margin-bottom: 8px; }
  
  p {
    -webkit-margin-after: 0px;
    -webkit-margin-before: 0px;
    font-size: 15px;
    color: #7F828B;
    line-height: 21px;
    margin-bottom: 4px; }
  
  .btn {
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 14px;
    color: #7F828B;
    border: 1px solid #7F828B; }
  
  .hk-logo, .app-icon {
    fill: #DBE1EC; }
  
  .info {
    fill: #9FABBC; }
  
  body.friendly {
    background: -webkit-linear-gradient(-45deg, #8363a1 0%, #74a8c3 100%);
    background: linear-gradient(135deg, #8363a1 0%, #74a8c3 100%); }
  
  body.friendly .message__title {
    color: #fff; }
  
  body.friendly p {
    color: rgba(255, 255, 255, 0.6); }
  
  body.friendly .hk-logo, body.friendly .app-icon {
    fill: rgba(255, 255, 255, 0.9); }
  
  body.friendly .info {
    fill: rgba(255, 255, 255, 0.9); }
  
  body.friendly .btn {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.9); }
  
  .info_area {
    position: fixed;
    right: 12px;
    bottom: 12px; }
  
  .logo {
    position: fixed;
    left: 12px;
    bottom: 12px; }
  
  code {
    background: #313440;
    border-radius: 4px;
    padding: 2px 6px;
    color: #D9DCEF; }
  
  code span {
    color: #D093E3; }
  
  p a {
    color: #408fec;
    text-decoration: none; }
  </style>
  <base target="_parent">
  </head>
  <body>
    <div class="spacer"></div>
    <div class="message">
      <img src="/images/logo_redhator_1.png" alt="logo_redhator" width="49">
      <div class="message__title"> Manteniendo redHator </div> <hr width="100%">
      <span style="border-image: initial; border: 1px solid orange; font-weight: bold; padding: 1px 3px 0px 3px; margin-bottom: 5px; font-size: 80%;">es</span>
      <p>  Estamos realizando tareas de mantenimiento del sistema. Intenta acceder de nuevo más tarde. Disculpa las molestias.</p>
      <hr width="100%">
      <span style="border-image: initial; border: 1px solid orange; font-weight: bold; padding: 1px 3px 0px 3px; margin-bottom: 5px; font-size: 80%;">fr</span>
      <p>  Nous sommes en train de effectuer des tâches de maintenance du système. Veuillez réessayer de vous connecter plus tard. Nous nous excusons pour tout inconvénient momentané</p>
      <hr width="100%">
      <span style="border-image: initial; border: 1px solid orange; font-weight: bold; padding: 1px 3px 0px 3px; margin-bottom: 5px; font-size: 80%;">en</span>
      <p>  We are performing system maintenance tasks. Please try to access again later. Sorry for the inconvenience.</p>
      <hr width="100%">
    </div> 
    <div class="logo"></div> 
    <div class="info_area"></div> 
   
  
  </body></html>`;
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      <Row className='justify-content-center mb-3'>
        <Col xs={6} className='text-center'>
          <Button
            className='text-white'
            onClick={() => {
              window.location.href = '/inicio';
            }}
          >
            {t('refresh')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Maintenance;
