import React, { useEffect } from 'react';
import Navbar from '../components/home/Navbar';
import Header from '../components/home/Header';
import Help from '../components/home/Help';
import QueEs from '../components/home/QueEs';
import Login from '../components/home/Login';
import Red from '../components/home/Red';
import Portfolio from '../components/home/Portfolio';
import Contact from '../components/home/Contact';
import Footer from '../components/home/Footer';
import Scroll from 'react-scroll';

const Home = () => {
  const token = localStorage.getItem('autoAcceso');
  const isLoggedIn = token && token !== '';
  var scroller = Scroll.scroller;
  useEffect(() => {
    if (localStorage.getItem('toContact')) {
      scroller.scrollTo('contact', {
        duration: 1000,
        smooth: true,
        offset: -50,
      });
      localStorage.removeItem('toContact');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='home'>
      <div id='page-top'>
        <Navbar isLoggedIn={isLoggedIn} />
      </div>

      <Header isLoggedIn={isLoggedIn} />

      {/* <Red /> */}

      {/* <QueEs /> */}

      {/* <Portfolio /> */}

      {!isLoggedIn && <Login />}

      {/* <Help /> */}

      <Contact />

      {/* <section id='imageFinal'></section> */}

      <Footer />
    </div>
  );
};

export default Home;
