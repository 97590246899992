/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import scaleDecimal from '../../../../support_func/scaleDecimal';
import moment from 'moment';
import scrollToElement from '../../../../support_func/scrollToElement';
import minMax from '../../../../support_func/minMax';

const IdriAlerts = (props) => {
  const { t } = useTranslation();

  // const [autoUse, setAutoUse] = useState(false);
  const idri = useSelector((state) => state.idri);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [, forceUpdate] = useState(0);

  const [alerts, setAlerts] = useState({});

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  const checkAlertParametr = (type, key, param) => {
    return idri.sensors[type]
      .map((s) => {
        if (s.idsensor == key) {
          return s.alerts_config[param];
        }
      })
      .find((value) => {
        return value;
      });
  };

  const updateAlert = (type, key, param, value, min, max) => {
    let uAlert = alerts;
    // console.log(alerts);
    Object.entries(uAlert[type]).map(([k, v]) => {
      if (k == key) {
        // console.log(value);
        if (param === 'cmax_month_threshold_fw') {
          uAlert[type][key][param] = minMax(value, min, max);
        } else if (param === 'value_less_than' || param === 'value_more_than') {
          uAlert[type][key][param] = value ? minMax(value, min, max) : 0;
        } else if (param === 'leak_reset_t') {
          uAlert[type][key][param] = value
            ? minMax(value, min, max)
            : uAlert[type][key].leak_en
            ? min
            : '';
        } else if (param === 'leak_close_n' || param === 'leak_close_delay') {
          uAlert[type][key][param] = value
            ? minMax(value, min, max)
            : uAlert[type][key].leak_close_en
            ? min
            : '';
        } else if (param === 'noflow_delay') {
          uAlert[type][key][param] = value
            ? minMax(value, min, max)
            : uAlert[type][key].noflow_en
            ? min
            : '';
        } else {
          uAlert[type][key][param] = value === false || value ? value : 0;
        }
        // console.log(uAlert[type][key][param]);
      }
    });
    setAlerts(uAlert);
    // console.log(alerts);
  };

  const moreLessCheck = () => {
    return Object.values(alerts.nfm)
      .map((s) => {
        // console.log(s);
        if (
          s.type === 1 &&
          s.state_value_alert_en &&
          !(
            (parseFloat(s.value_more_than) > 0 && !parseFloat(s.value_less_than)) ||
            (!parseFloat(s.value_more_than) && parseFloat(s.value_less_than) > 0) ||
            (parseFloat(s.value_more_than) > 0 &&
              parseFloat(s.value_less_than) > 0 &&
              parseFloat(s.value_more_than) > parseFloat(s.value_less_than)) ||
            (!s.state_value_alert_en &&
              ((parseFloat(s.value_more_than) === null && parseFloat(s.value_less_than) === null) ||
                (parseFloat(s.value_more_than) === 0 && parseFloat(s.value_less_than) === 0)))
          )
        ) {
          return s.name;
        }
      })
      .find((value) => {
        return value;
      });
  };

  const valueDecCheck = (value, scale) => {
    if (scaleDecimal(value) > scaleDecimal(scale)) {
      return parseFloat(value).toFixed(scaleDecimal(scale));
    } else {
      return value;
    }
  };

  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, [idri]);

  useEffect(() => {
    let fm = idri.sensors.fm_sensors.map((fm) => {
      return {
        [fm.idsensor]: {
          name: fm.name,
          magnitude: fm.magnitude,
          scale: fm.scale,
          ...fm.alerts_config,
          noflow_delay: fm.alerts_config.noflow_delay === 0 ? '' : fm.alerts_config.noflow_delay,
          leak_close_delay:
            fm.alerts_config.leak_close_delay === 0 ? '' : fm.alerts_config.leak_close_delay,
          leak_close_n: fm.alerts_config.leak_close_n === 0 ? '' : fm.alerts_config.leak_close_n,
          cmax_month_threshold_fw:
            fm.alerts_config.cmax_month_threshold_fw === 0
              ? ''
              : parseInt(parseFloat(fm.alerts_config.cmax_month_threshold_fw) * 100),
          ...fm.alerts,
        },
      };
    });
    let nfm = idri.sensors.none_fm_sensors.map((nfm) => {
      return {
        [nfm.idsensor]: {
          name: nfm.name,
          magnitude: nfm.magnitude,
          scale: nfm.scale,
          status: nfm.status,
          type: nfm.kind_sensor.code,
          ...nfm.alerts_config,
          min_analog_value: nfm.kind_sensor.min_analog_value,
          max_analog_value: nfm.kind_sensor.max_analog_value,
          ...nfm.alerts,
        },
      };
    });
    var fmObject = {};
    for (var i = 0; i < fm.length; i++) {
      fmObject[Object.keys(fm[i])[0]] = Object.values(fm[i])[0];
    }
    var nfmObject = {};
    for (var n = 0; n < nfm.length; n++) {
      nfmObject[Object.keys(nfm[n])[0]] = Object.values(nfm[n])[0];
    }
    let m = { fm: fmObject, nfm: nfmObject };
    setAlerts(m);
  }, [idri]);

  useEffect(() => {
    // console.log(alerts);
  }, [alerts]);

  return (
    <>
      {idri && alerts && Object.keys(alerts).length > 0 && (
        // parseFloat(idri.v_idri) < 3 &&
        // Object.keys(sens).length > 0 &&
        <>
          <Accordion
            defaultActiveKey={
              localStorage.getItem(`openAlerts-${idri.ghatorserial}`)
                ? JSON.parse(localStorage.getItem(`openAlerts-${idri.ghatorserial}`))
                : null
            }
            // defaultActiveKey={idri.sensors.fm_sensors.map((sensor) => {
            //   return sensor.idsensor;
            // })}
            // flush
            alwaysOpen
            className='acc my-2'
            // className={`my-2 ${!idri.user_permissions.alerts && 'disabledCustom'}`}
            onSelect={(e) => {
              localStorage.setItem(`openAlerts-${idri.ghatorserial}`, JSON.stringify(e));
            }}
          >
            {Object.keys(alerts).length > 0 &&
              Object.keys(alerts.fm).length > 0 &&
              Object.entries(alerts.fm).map(([key, sensor]) => (
                <Accordion.Item id={key} key={key} eventKey={key}>
                  <Accordion.Header
                    onClick={(e) => {
                      scrollToElement(key, true, 'start', 300);
                    }}
                  >
                    <Table bordered className='mb-0'>
                      <thead>
                        <tr className='primary text-center'>
                          <th colSpan={2} className='lh-sm'>
                            {(sensor.leak_alert && sensor.leak_en) ||
                            (sensor.noflow_alert && sensor.noflow_en) ||
                            (sensor.cmax_day_alert && sensor.cmax_day_en) ||
                            (sensor.cmin_day_alert && sensor.cmin_day_en) ||
                            (sensor.flow_alert && sensor.flow_value_en) ||
                            (sensor.cmax_month_alert && sensor.cmax_month_en) ? (
                              <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip>{t('hayAlertaDisparada')}</Tooltip>}
                              >
                                <p className='fw-bold lh-sm my-0 dangerAnimation'>
                                  {t('alertContConfig')}: {sensor.name}{' '}
                                  <FiAlertTriangle fontSize='1.2em' />
                                </p>
                              </OverlayTrigger>
                            ) : (
                              <p className='fw-normal lh-sm my-0 '>
                                {t('alertContConfig')}: {sensor.name}
                              </p>
                            )}

                            {/* <p className='fw-normal lh-sm my-0 text'>
                              Configuración alertas de contador: {sensor.name}
                            </p> */}
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </Accordion.Header>
                  <Accordion.Body
                    // className='text-center p-0'
                    className={`text-center p-0 ${
                      (!idri.user_permissions.edit || !idri.user_permissions.alerts) &&
                      'disabledCustom'
                    }`}
                  >
                    {/* {idri.sensors.fm_sensors.map((sensor) => ( */}
                    <Card key={key} border='dark' className='p-2 text-center rounded-0'>
                      <Row className='text-center'>
                        {sensor.cmax_day_alert && sensor.cmax_day_en ? (
                          <OverlayTrigger
                            placement={'top'}
                            overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                          >
                            <div className='fw-bold text-success mb-0 text-danger dangerAnimation'>
                              {t('consumoMaxD').charAt(0).toUpperCase() + t('consumoMaxD').slice(1)}{' '}
                              <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div className='fw-bold text-success mb-0'>
                            {t('consumoMaxD').charAt(0).toUpperCase() + t('consumoMaxD').slice(1)}
                          </div>
                        )}
                        <Col
                          xs={6}
                          className='d-flex justify-content-center align-items-center'
                          id={`${key}cmax_day_en`}
                        >
                          <div
                            className='position-relative pointer'
                            onClick={() => {
                              updateAlert('fm', key, 'cmax_day_en', !sensor.cmax_day_en);
                              scrollToElement(
                                `${key}cmax_day_en`,
                                sensor.cmax_day_en,
                                'center',
                                300
                              );
                              forceUpdate((n) => !n);
                            }}
                          >
                            <Form.Check
                              type='switch'
                              id={`custom-switch${'cmax_day_en'}`}
                              checked={sensor.cmax_day_en}
                              onChange={() => {}}
                            />
                            <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                              {!checkAlertParametr('fm_sensors', key, 'cmax_day_en') &&
                              !sensor.cmax_day_en
                                ? t('disabilitado')
                                : !checkAlertParametr('fm_sensors', key, 'cmax_day_en') &&
                                  sensor.cmax_day_en
                                ? t('abilitar')
                                : checkAlertParametr('fm_sensors', key, 'cmax_day_en') &&
                                  sensor.cmax_day_en
                                ? t('abilitado')
                                : t('disabilitar')}
                            </span>
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <span className='fw-bold '>
                            {t('volumen')} ({sensor.magnitude})
                          </span>
                          <Form.Control
                            className='text-center p-0 p-md-2'
                            type='number'
                            value={sensor.cmax_day_threshold ? sensor.cmax_day_threshold : ''}
                            min='0'
                            step={sensor.scale}
                            onChange={(e) => {
                              updateAlert(
                                'fm',
                                key,
                                'cmax_day_threshold',
                                parseFloat(valueDecCheck(e.target.value, sensor.scale))
                              );
                              forceUpdate((n) => !n);
                            }}
                          />
                        </Col>
                      </Row>
                      <hr className='m-0 my-1'></hr>
                      <Row className='text-center'>
                        {sensor.cmin_day_alert && sensor.cmin_day_en ? (
                          <OverlayTrigger
                            placement={'top'}
                            overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                          >
                            <div className='fw-bold text-success mb-0 text-danger dangerAnimation'>
                              {t('consumoMinD')}{' '}
                              <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div className='fw-bold text-success mb-0'>{t('consumoMinD')}</div>
                        )}
                        <Col
                          xs={6}
                          className='d-flex justify-content-center align-items-center'
                          id={`${key}cmin_day_en`}
                        >
                          <div
                            className='position-relative pointer'
                            onClick={() => {
                              updateAlert('fm', key, 'cmin_day_en', !sensor.cmin_day_en);
                              scrollToElement(
                                `${key}cmin_day_en`,
                                sensor.cmin_day_en,
                                'center',
                                300
                              );
                              forceUpdate((n) => !n);
                            }}
                          >
                            <Form.Check
                              type='switch'
                              id={`custom-switch${'cmin_day_en'}`}
                              checked={sensor.cmin_day_en}
                              onChange={() => {}}
                            />
                            <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                              {!checkAlertParametr('fm_sensors', key, 'cmin_day_en') &&
                              !sensor.cmin_day_en
                                ? t('disabilitado')
                                : !checkAlertParametr('fm_sensors', key, 'cmin_day_en') &&
                                  sensor.cmin_day_en
                                ? t('abilitar')
                                : checkAlertParametr('fm_sensors', key, 'cmin_day_en') &&
                                  sensor.cmin_day_en
                                ? t('abilitado')
                                : t('disabilitar')}
                            </span>
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <span className='fw-bold '>
                            {t('volumen')} ({sensor.magnitude})
                          </span>
                          <Form.Control
                            className='text-center p-0 p-md-2'
                            type='number'
                            value={sensor.cmin_day_threshold ? sensor.cmin_day_threshold : ''}
                            min='0'
                            onChange={(e) => {
                              updateAlert(
                                'fm',
                                key,
                                'cmin_day_threshold',
                                parseFloat(valueDecCheck(e.target.value, sensor.scale))
                              );
                              forceUpdate((n) => !n);
                            }}
                          />
                        </Col>
                      </Row>
                      <hr className='m-0 my-1'></hr>
                      <Row className='text-center '>
                        {sensor.cmax_month_alert && sensor.cmax_month_en ? (
                          <OverlayTrigger
                            placement={'top'}
                            overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                          >
                            <div className='fw-bold text-success mb-0 text-danger dangerAnimation'>
                              {t('consumoMaxM').charAt(0).toUpperCase() + t('consumoMaxM').slice(1)}{' '}
                              <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div className='fw-bold text-success mb-0'>
                            {t('consumoMaxM').charAt(0).toUpperCase() + t('consumoMaxM').slice(1)}
                          </div>
                        )}
                        <Col
                          xs={6}
                          className='d-flex justify-content-center align-items-center'
                          id={`${key}cmax_month_en`}
                        >
                          <div
                            className='position-relative pointer'
                            onClick={() => {
                              updateAlert('fm', key, 'cmax_month_en', !sensor.cmax_month_en);
                              scrollToElement(
                                `${key}cmax_month_en`,
                                sensor.cmax_month_en,
                                'center',
                                300
                              );
                              forceUpdate((n) => !n);
                            }}
                          >
                            <Form.Check
                              type='switch'
                              id={`custom-switch${'cmax_month_en'}`}
                              // className='position-absolute top-50 start-50 translate-middle'
                              checked={sensor.cmax_month_en}
                              onChange={() => {}}
                            />
                            <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                              {!checkAlertParametr('fm_sensors', key, 'cmax_month_en') &&
                              !sensor.cmax_month_en
                                ? t('disabilitado')
                                : !checkAlertParametr('fm_sensors', key, 'cmax_month_en') &&
                                  sensor.cmax_month_en
                                ? t('abilitar')
                                : checkAlertParametr('fm_sensors', key, 'cmax_month_en') &&
                                  sensor.cmax_month_en
                                ? t('abilitado')
                                : t('disabilitar')}
                            </span>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <Row className='align-items-end'>
                            <Col xs={6} className='pe-1'>
                              <span className='fw-bold '>
                                {t('primerAviso')} (%)
                                {/* // {screenWidth > 720 ? 'primer ' : 'pr. '}aviso(%) */}
                              </span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                type='number'
                                value={
                                  sensor.cmax_month_threshold_fw
                                    ? sensor.cmax_month_threshold_fw
                                    : ''
                                }
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'cmax_month_threshold_fw',
                                    parseFloat(valueDecCheck(e.target.value, 0.01)),
                                    0.01,
                                    100
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>
                            <Col xs={6} className='ps-1'>
                              <span className='fw-bold '>
                                {t('volumen')} ({sensor.magnitude})
                              </span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                required={sensor.cmax_month_en}
                                type='number'
                                value={
                                  sensor.cmax_month_threshold ? sensor.cmax_month_threshold : ''
                                }
                                min='0'
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'cmax_month_threshold',
                                    parseFloat(valueDecCheck(e.target.value, sensor.scale))
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr className='m-0 my-1'></hr>
                      <Row className='text-center'>
                        {sensor.leak_alert && sensor.leak_en ? (
                          <OverlayTrigger
                            placement={'top'}
                            overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                          >
                            <div className='fw-bold mb-0 text-danger'>
                              <Col
                                xs={12}
                                className='d-flex justify-content-center align-items-center dangerAnimation'
                              >
                                {t('alertFuga').charAt(0).toUpperCase() + t('alertFuga').slice(1)}{' '}
                                <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                              </Col>
                              <Col xs={12}>
                                {sensor.leak_alert_start_date &&
                                  t('startDate') +
                                    ': ' +
                                    moment
                                      .utc(sensor.leak_alert_start_date)
                                      .local()
                                      .format('DD-MM-YYYY, HH:mm:ss')}
                              </Col>
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div className='fw-bold text-success mb-0'>
                            {t('alertFuga').charAt(0).toUpperCase() + t('alertFuga').slice(1)}
                          </div>
                        )}
                        <Col
                          xs={6}
                          className='d-flex justify-content-center align-items-center'
                          id={`${key}leak_en`}
                        >
                          <div
                            className='position-relative pointer'
                            onClick={(e) => {
                              updateAlert('fm', key, 'leak_en', !sensor.leak_en);
                              e.target.checked === false &&
                                updateAlert('fm', key, 'leak_close_en', false);
                              scrollToElement(`${key}leak_en`, sensor.leak_en, 'center', 300);
                              forceUpdate((n) => !n);
                            }}
                          >
                            <Form.Check
                              type='switch'
                              id={`custom-switch${'leak_close_en'}`}
                              checked={sensor.leak_en}
                              onChange={() => {}}
                            />
                            <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                              {!checkAlertParametr('fm_sensors', key, 'leak_en') && !sensor.leak_en
                                ? t('disabilitado')
                                : !checkAlertParametr('fm_sensors', key, 'leak_en') &&
                                  sensor.leak_en
                                ? t('abilitar')
                                : checkAlertParametr('fm_sensors', key, 'leak_en') && sensor.leak_en
                                ? t('abilitado')
                                : t('disabilitar')}
                            </span>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <Row className='align-items-end'>
                            <Col
                              xs={parseFloat(idri.ghatorv) >= 3 ? 6 : 12}
                              className={`${parseFloat(idri.ghatorv) >= 3 ? 'pe-1' : ''}`}
                            >
                              <span className='fw-bold '>
                                {t('umbral')} ({sensor.magnitude})
                              </span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                type='number'
                                value={sensor.leak_threshold ? sensor.leak_threshold : ''}
                                min='0'
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'leak_threshold',
                                    parseFloat(valueDecCheck(e.target.value, sensor.scale))
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>
                            {parseFloat(idri.ghatorv) >= 3 && (
                              <Col xs={6} className='ps-1'>
                                <span className='fw-bold '>{t('resetTime')}</span>
                                <Form.Control
                                  className='text-center p-0 p-md-2'
                                  type='number'
                                  min={sensor.leak_reset_t_min}
                                  max={sensor.leak_reset_t_max}
                                  value={sensor.leak_reset_t ? sensor.leak_reset_t : ''}
                                  onChange={(e) => {
                                    updateAlert(
                                      'fm',
                                      key,
                                      'leak_reset_t',
                                      parseInt(e.target.value),
                                      0,
                                      sensor.leak_reset_t_max
                                    );
                                    forceUpdate((n) => !n);
                                  }}
                                  onBlur={(e) => {
                                    updateAlert(
                                      'fm',
                                      key,
                                      'leak_reset_t',
                                      parseInt(e.target.value),
                                      sensor.leak_reset_t_min,
                                      sensor.leak_reset_t_max
                                    );
                                    forceUpdate((n) => !n);
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                        {parseFloat(idri.ghatorv) >= 3 && (
                          <Row className='align-items-end px-0 mx-0'>
                            <Col xs={6} className='my-2 d-flex justify-content-center'>
                              <Form.Check
                                type='switch'
                                id='c-switch'
                                disabled={!sensor.leak_en}
                                style={{ transform: 'scale(2)' }}
                                checked={sensor.leak_close_en}
                                onChange={(e) => {
                                  updateAlert('fm', key, 'leak_close_en', e.target.checked);
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>

                            <Col xs={3} className='pe-1'>
                              <span className='fw-bold '>{t('intentosAC')}</span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                type='number'
                                min={sensor.leak_close_n_min}
                                max={sensor.leak_close_n_max}
                                disabled={!sensor.leak_close_en}
                                value={sensor.leak_close_n ? sensor.leak_close_n : ''}
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'leak_close_n',
                                    parseInt(e.target.value),
                                    0,
                                    sensor.leak_close_n_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                                onBlur={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'leak_close_n',
                                    parseInt(e.target.value),
                                    sensor.leak_close_n_min,
                                    sensor.leak_close_n_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>

                            <Col xs={3} className='ps-1'>
                              <span className='fw-bold '>{t('segEntreIntentos')}</span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                type='number'
                                min={sensor.leak_close_delay_min}
                                max={sensor.leak_close_delay_max}
                                disabled={!sensor.leak_close_en}
                                value={sensor.leak_close_delay ? sensor.leak_close_delay : ''}
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'leak_close_delay',
                                    parseInt(e.target.value),
                                    0,
                                    sensor.leak_close_delay_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                                onBlur={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'leak_close_delay',
                                    parseInt(e.target.value),
                                    sensor.leak_close_delay_min,
                                    sensor.leak_close_delay_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                      </Row>
                      {parseFloat(idri.ghatorv) >= 3 && (
                        <>
                          <hr className='m-0 my-1 mt-2'></hr>
                          <Row className='text-center'>
                            {sensor.noflow_alert && sensor.noflow_en ? (
                              <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                              >
                                <div className='fw-bold mb-0 text-danger'>
                                  <Col
                                    xs={12}
                                    className='d-flex justify-content-center align-items-center dangerAnimation'
                                  >
                                    {t('alertaPaso')}{' '}
                                    <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                                  </Col>
                                  <Col xs={12}>
                                    {sensor.noflow_alert_start_date &&
                                      t('startDate') +
                                        ': ' +
                                        moment
                                          .utc(sensor.noflow_alert_start_date)
                                          .local()
                                          .format('DD-MM-YYYY, HH:mm:ss')}
                                  </Col>
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <div className='fw-bold text-success mb-0'>{t('alertaPaso')}</div>
                            )}
                            <Col
                              xs={6}
                              className='d-flex justify-content-center align-items-center'
                              id={`${key}noflow_en`}
                            >
                              <div
                                className='position-relative pointer'
                                onClick={() => {
                                  updateAlert('fm', key, 'noflow_en', !sensor.noflow_en);
                                  scrollToElement(
                                    `${key}noflow_en`,
                                    sensor.noflow_en,
                                    'center',
                                    300
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              >
                                <Form.Check
                                  type='switch'
                                  id={`custom-switch${'noflow_en'}`}
                                  checked={sensor.noflow_en}
                                  onChange={() => {}}
                                />
                                <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                                  {!checkAlertParametr('fm_sensors', key, 'noflow_en') &&
                                  !sensor.noflow_en
                                    ? t('disabilitado')
                                    : !checkAlertParametr('fm_sensors', key, 'noflow_en') &&
                                      sensor.noflow_en
                                    ? t('abilitar')
                                    : checkAlertParametr('fm_sensors', key, 'noflow_en') &&
                                      sensor.noflow_en
                                    ? t('abilitado')
                                    : t('disabilitar')}
                                </span>
                              </div>
                            </Col>
                            <Col xs={6} md={6}>
                              <span className='fw-bold '>{t('segSinPulsos')}</span>
                              <Form.Control
                                className='text-center p-0 p-md-2'
                                type='number'
                                min={sensor.noflow_delay_min}
                                max={sensor.noflow_delay_max}
                                value={sensor.noflow_delay ? sensor.noflow_delay : ''}
                                onChange={(e) => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'noflow_delay',
                                    parseInt(e.target.value),
                                    0,
                                    sensor.noflow_delay_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                                onBlur={() => {
                                  updateAlert(
                                    'fm',
                                    key,
                                    'noflow_delay',
                                    sensor.noflow_delay,
                                    sensor.noflow_delay_min,
                                    sensor.noflow_delay_max
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              />
                            </Col>
                          </Row>

                          <hr className='m-0 my-1'></hr>
                          <Row className='text-center'>
                            {sensor.flow_alert && sensor.flow_value_en ? (
                              <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                              >
                                <div className='fw-bold mb-0 text-danger'>
                                  <Col
                                    xs={12}
                                    className='d-flex justify-content-center align-items-center dangerAnimation'
                                  >
                                    {t('umbralCaudal')}{' '}
                                    <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                                  </Col>
                                  <Col xs={12}>
                                    {sensor.flow_alert_start_date &&
                                      t('startDate') +
                                        ': ' +
                                        moment
                                          .utc(sensor.flow_alert_start_date)
                                          .local()
                                          .format('DD-MM-YYYY, HH:mm:ss')}
                                  </Col>
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <div className='fw-bold text-success mb-0'>{t('umbralCaudal')}</div>
                            )}
                            <Col
                              xs={6}
                              className='d-flex justify-content-center align-items-center'
                              id={`${key}flow_value_en`}
                            >
                              <div
                                className='position-relative pointer'
                                onClick={() => {
                                  updateAlert('fm', key, 'flow_value_en', !sensor.flow_value_en);
                                  scrollToElement(
                                    `${key}flow_value_en`,
                                    sensor.flow_value_en,
                                    'center',
                                    300
                                  );
                                  forceUpdate((n) => !n);
                                }}
                              >
                                <Form.Check
                                  type='switch'
                                  id={`custom-switch${'flow_value_en'}`}
                                  checked={sensor.flow_value_en}
                                  onChange={() => {}}
                                />
                                <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                                  {!idri.sensors.none_fm_sensors
                                    .map((s) => {
                                      if (s.idsensor == key) {
                                        return s.alerts_config.flow_value_en;
                                      }
                                    })
                                    .find((value) => {
                                      return value;
                                    }) && !sensor.flow_value_en
                                    ? t('disabilitado')
                                    : !idri.sensors.none_fm_sensors
                                        .map((s) => {
                                          if (s.idsensor == key) {
                                            return s.alerts_config.flow_value_en;
                                          }
                                        })
                                        .find((value) => {
                                          return value;
                                        }) && sensor.flow_value_en
                                    ? t('abilitar')
                                    : idri.sensors.none_fm_sensors
                                        .map((s) => {
                                          if (s.idsensor == key) {
                                            return s.alerts_config.flow_value_en;
                                          }
                                        })
                                        .find((value) => {
                                          return value;
                                        }) && sensor.flow_value_en
                                    ? t('abilitado')
                                    : t('disabilitar')}
                                </span>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <Row className='align-items-end'>
                                <Col xs={6} className='pe-1'>
                                  <span className='fw-bold '>
                                    {t('menosQue')} ({sensor.magnitude})
                                    {/* {screenWidth > 720 ? 'menos ' : 'men. '}que({sensor.magnitude}) */}
                                  </span>
                                  {/* {console.log(sensor.flow_less_than)}
                              {console.log(
                                parseFloat(sensor.flow_less_than).toFixed(
                                  scaleDecimal(sensor.scale)
                                )
                              )} */}
                                  <Form.Control
                                    className='text-center p-0 p-md-2'
                                    type='number'
                                    value={
                                      sensor.flow_less_than ? parseFloat(sensor.flow_less_than) : ''
                                    }
                                    min={sensor.min_analog_value}
                                    max={sensor.max_analog_value}
                                    onChange={(e) => {
                                      updateAlert(
                                        'fm',
                                        key,
                                        'flow_less_than',
                                        parseFloat(valueDecCheck(e.target.value, sensor.scale))
                                      );
                                      forceUpdate((n) => !n);
                                    }}
                                  />
                                </Col>
                                <Col xs={6} className='ps-1'>
                                  <span className='fw-bold '>
                                    {t('masQue')} ({sensor.magnitude})
                                  </span>
                                  <Form.Control
                                    className='text-center p-0 p-md-2'
                                    type='number'
                                    value={
                                      sensor.flow_more_than ? parseFloat(sensor.flow_more_than) : ''
                                    }
                                    min={sensor.min_analog_value}
                                    max={sensor.max_analog_value}
                                    onChange={(e) => {
                                      updateAlert(
                                        'fm',
                                        key,
                                        'flow_more_than',
                                        parseFloat(valueDecCheck(e.target.value, sensor.scale))
                                      );
                                      forceUpdate((n) => !n);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Card>
                    <Col className='text-center my-2'>
                      <Button
                        className='dark text-uppercase'
                        onClick={(e) => {
                          parseFloat(idri.ghatorv) < 3
                            ? props.editAlerts(e, 'fm', key, alerts)
                            : (parseFloat(sensor.flow_more_than) > 0 &&
                                !parseFloat(sensor.flow_less_than)) ||
                              (!parseFloat(sensor.flow_more_than) &&
                                parseFloat(sensor.flow_less_than) > 0) ||
                              (parseFloat(sensor.flow_more_than) > 0 &&
                                parseFloat(sensor.flow_less_than) > 0 &&
                                parseFloat(sensor.flow_more_than) >
                                  parseFloat(sensor.flow_less_than)) ||
                              (!sensor.flow_value_en &&
                                ((sensor.flow_more_than === null &&
                                  sensor.flow_less_than === null) ||
                                  (sensor.flow_more_than === 0 && sensor.flow_less_than === 0)))
                            ? props.editAlerts(e, 'fm', key, alerts)
                            : alert(t('maxMasMin'));
                        }}
                      >
                        {t('configurar')} {sensor.name}
                      </Button>
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            {Object.keys(alerts).length > 0 &&
              Object.keys(alerts.nfm).length > 0 &&
              Object.entries(alerts.nfm).map(([key, sensor]) => (
                <Accordion.Item id={key} key={key} eventKey={key}>
                  <Accordion.Header
                    onClick={(e) => {
                      scrollToElement(key, true, 'start', 300);
                    }}
                  >
                    <Table bordered className='mb-0'>
                      <thead>
                        <tr className='primary text-center'>
                          <th colSpan={2} className='lh-sm'>
                            {sensor.state_value_alert && sensor.state_value_alert_en ? (
                              <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip>{t('hayAlertaDisparada')}</Tooltip>}
                              >
                                <p className='fw-bold lh-sm my-0 dangerAnimation'>
                                  {t('alertSensorConf')}: {sensor.name}{' '}
                                  <span>
                                    <FiAlertTriangle fontSize='1.2em' />
                                  </span>
                                </p>
                              </OverlayTrigger>
                            ) : (
                              <p className='fw-normal lh-sm my-0 '>
                                {t('alertSensorConf')}: {sensor.name}
                              </p>
                            )}
                            {/* <p className='fw-normal lh-sm my-0 text'>
                              Configuración alertas de sensor: {sensor.name}
                            </p> */}
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </Accordion.Header>
                  <Accordion.Body
                    // className='text-center p-0'
                    className={`text-center p-0 ${
                      (!idri.user_permissions.edit || !idri.user_permissions.alerts) &&
                      'disabledCustom'
                    }`}
                  >
                    {/* {idri.sensors.none_fm_sensors.map((sensor) => ( */}
                    <Card key={key} border='dark' className='p-2 text-center rounded-0'>
                      {sensor.type === 1 || sensor.type === 2 ? (
                        <Row className='text-center'>
                          {sensor.state_value_alert && sensor.state_value_alert_en ? (
                            <OverlayTrigger
                              placement={'top'}
                              overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                            >
                              <div className='fw-bold mb-0 text-danger'>
                                <Col
                                  xs={12}
                                  className='d-flex justify-content-center align-items-center dangerAnimation'
                                >
                                  {t('umbralMaxMin')}
                                  <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                                </Col>
                                <Col xs={12}>
                                  {sensor.state_value_alert_start_date &&
                                    t('startDate') +
                                      ': ' +
                                      moment
                                        .utc(sensor.state_value_alert_start_date)
                                        .local()
                                        .format('DD-MM-YYYY, HH:mm:ss')}
                                </Col>
                              </div>
                            </OverlayTrigger>
                          ) : (
                            <div className='fw-bold text-success mb-0'>{t('umbralMaxMin')}</div>
                          )}
                          <Col
                            xs={6}
                            className='d-flex justify-content-center align-items-center'
                            id={`${key}state_value_alert_en`}
                          >
                            <div
                              className='position-relative pointer'
                              onClick={() => {
                                updateAlert(
                                  'nfm',
                                  key,
                                  'state_value_alert_en',
                                  !sensor.state_value_alert_en
                                );
                                scrollToElement(
                                  `${key}state_value_alert_en`,
                                  sensor.state_value_alert_en,
                                  'center',
                                  300
                                );
                                forceUpdate((n) => !n);
                              }}
                            >
                              <Form.Check
                                type='switch'
                                id={`custom-switch${'state_value_alert_en'}`}
                                checked={sensor.state_value_alert_en}
                                onChange={() => {}}
                              />
                              <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                                {!idri.sensors.none_fm_sensors
                                  .map((s) => {
                                    if (s.idsensor == key) {
                                      return s.alerts_config.state_value_alert_en;
                                    }
                                  })
                                  .find((value) => {
                                    return value;
                                  }) && !sensor.state_value_alert_en
                                  ? t('disabilitado')
                                  : !idri.sensors.none_fm_sensors
                                      .map((s) => {
                                        if (s.idsensor == key) {
                                          return s.alerts_config.state_value_alert_en;
                                        }
                                      })
                                      .find((value) => {
                                        return value;
                                      }) && sensor.state_value_alert_en
                                  ? t('abilitar')
                                  : idri.sensors.none_fm_sensors
                                      .map((s) => {
                                        if (s.idsensor == key) {
                                          return s.alerts_config.state_value_alert_en;
                                        }
                                      })
                                      .find((value) => {
                                        return value;
                                      }) && sensor.state_value_alert_en
                                  ? t('abilitado')
                                  : t('disabilitar')}
                              </span>
                            </div>
                          </Col>
                          <Col xs={6}>
                            <Row className='align-items-end'>
                              <Col xs={6} className='pe-1'>
                                <span className='fw-bold '>
                                  {t('menosQue')} ({sensor.magnitude})
                                  {/* {screenWidth > 720 ? 'menos ' : 'men. '}que(
                              {sensor.magnitude}) */}
                                </span>
                                <Form.Control
                                  className='text-center p-0 p-md-2'
                                  type='number'
                                  value={sensor.value_less_than ? sensor.value_less_than : ''}
                                  min={sensor.min_analog_value}
                                  max={sensor.max_analog_value}
                                  onChange={(e) => {
                                    updateAlert(
                                      'nfm',
                                      key,
                                      'value_less_than',
                                      parseFloat(valueDecCheck(e.target.value, sensor.scale)),
                                      parseFloat(sensor.min_analog_value),
                                      parseFloat(sensor.max_analog_value)
                                    );
                                    forceUpdate((n) => !n);
                                  }}
                                />
                              </Col>
                              <Col xs={6} className='ps-1'>
                                <span className='fw-bold '>
                                  {t('masQue')} ({sensor.magnitude})
                                </span>
                                <Form.Control
                                  className='text-center p-0 p-md-2'
                                  type='number'
                                  value={sensor.value_more_than ? sensor.value_more_than : ''}
                                  min={sensor.min_analog_value}
                                  max={sensor.max_analog_value}
                                  onChange={(e) => {
                                    updateAlert(
                                      'nfm',
                                      key,
                                      'value_more_than',
                                      parseFloat(valueDecCheck(e.target.value, sensor.scale)),
                                      parseFloat(sensor.min_analog_value),
                                      parseFloat(sensor.max_analog_value)
                                    );
                                    forceUpdate((n) => !n);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row className='text-center'>
                          {sensor.state_value_alert && sensor.state_value_alert_en ? (
                            <OverlayTrigger
                              placement={'top'}
                              overlay={<Tooltip>{t('alertaDisparada')}</Tooltip>}
                            >
                              <div className='fw-bold mb-0 text-danger'>
                                <Col
                                  xs={12}
                                  className='d-flex justify-content-center align-items-center dangerAnimation'
                                >
                                  {t('activEstado')}
                                  <FiAlertTriangle className='mx-1' fontSize='1.2em' />
                                </Col>
                                <Col xs={12}>
                                  {sensor.state_value_alert_start_date &&
                                    t('startDate') +
                                      ': ' +
                                      moment
                                        .utc(sensor.state_value_alert_start_date)
                                        .local()
                                        .format('DD-MM-YYYY, HH:mm:ss')}
                                </Col>
                              </div>
                            </OverlayTrigger>
                          ) : (
                            <div className='fw-bold text-success mb-0'>{t('activEstado')}</div>
                          )}
                          <Col
                            xs={6}
                            className='d-flex justify-content-center align-items-center'
                            id={`${key}state_value_alert_en`}
                          >
                            <div
                              className='position-relative pointer'
                              onClick={() => {
                                updateAlert(
                                  'nfm',
                                  key,
                                  'state_value_alert_en',
                                  !sensor.state_value_alert_en
                                );
                                scrollToElement(
                                  `${key}state_value_alert_en`,
                                  sensor.state_value_alert_en,
                                  'center',
                                  300
                                );
                                forceUpdate((n) => !n);
                              }}
                            >
                              <Form.Check
                                type='switch'
                                id={`custom-switch${'state_value_alert_en'}`}
                                checked={sensor.state_value_alert_en}
                                onChange={() => {}}
                              />
                              <span className='position-absolute top-50 start-50 translate-middle text-white fw-bold user-select-none pe-none'>
                                {!idri.sensors.none_fm_sensors
                                  .map((s) => {
                                    if (s.idsensor == key) {
                                      return s.alerts_config.state_value_alert_en;
                                    }
                                  })
                                  .find((value) => {
                                    return value;
                                  }) && !sensor.state_value_alert_en
                                  ? t('disabilitado')
                                  : !idri.sensors.none_fm_sensors
                                      .map((s) => {
                                        if (s.idsensor == key) {
                                          return s.alerts_config.state_value_alert_en;
                                        }
                                      })
                                      .find((value) => {
                                        return value;
                                      }) && sensor.state_value_alert_en
                                  ? t('abilitar')
                                  : idri.sensors.none_fm_sensors
                                      .map((s) => {
                                        if (s.idsensor == key) {
                                          return s.alerts_config.state_value_alert_en;
                                        }
                                      })
                                      .find((value) => {
                                        return value;
                                      }) && sensor.state_value_alert_en
                                  ? t('abilitado')
                                  : t('disabilitar')}
                              </span>
                            </div>
                          </Col>
                          <Col xs={6} className='d-flex align-items-center justify-content-center'>
                            {/* <p className='m-0'> */}
                            <span className='fw-bold me-1'>{t('actualState')}:</span>{' '}
                            {idri.sensors.none_fm_sensors
                              .map((s) => {
                                if (s.idsensor == key) {
                                  return s.status;
                                }
                              })
                              .find((value) => {
                                return value;
                              })
                              ? t('activado')
                              : t('desactivado')}
                            {/* </p> */}
                          </Col>
                        </Row>
                      )}

                      <Col className='text-center mt-2'>
                        <Button
                          className='dark text-uppercase'
                          onClick={(e) => {
                            sensor.type !== 1 || sensor.type !== 2
                              ? props.editAlerts(e, 'nfm', key, alerts)
                              : (parseFloat(sensor.value_more_than) > 0 &&
                                  !parseFloat(sensor.value_less_than)) ||
                                (!parseFloat(sensor.value_more_than) &&
                                  parseFloat(sensor.value_less_than) > 0) ||
                                (parseFloat(sensor.value_more_than) > 0 &&
                                  parseFloat(sensor.value_less_than) > 0 &&
                                  parseFloat(sensor.value_more_than) >
                                    parseFloat(sensor.value_less_than)) ||
                                (!sensor.state_value_alert_en &&
                                  ((sensor.value_more_than === null &&
                                    sensor.value_less_than === null) ||
                                    (sensor.value_more_than === 0 && sensor.value_less_than === 0)))
                              ? props.editAlerts(e, 'nfm', key, alerts)
                              : alert(t('maxMasMin'));
                          }}
                        >
                          {t('configurar')} {sensor.name}
                        </Button>
                      </Col>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
          <Col
            className={`text-center p-0 ${
              (!idri.user_permissions.edit || !idri.user_permissions.alerts) && 'disabledCustom'
            }`}
          >
            <Button
              className='dark text-uppercase'
              onClick={(e) => {
                !moreLessCheck() ? props.editAlerts(e, null, null, alerts) : alert(t('maxMasMin'));
              }}
            >
              {t('configurarTodos')}
            </Button>
          </Col>
        </>
      )}
    </>
  );
};

export default IdriAlerts;
