/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Timer = (props) => {
  const idri = useSelector((state) => state.idri);

  const totalSec = props.hrs
    ? parseInt(props.hrs) * 60 * 60 + props.min * 60 + props.sec
    : 0 + props.min * 60 + props.sec;

  const endTime = new Date().getTime() + totalSec * 1000;

  const [start, setStart] = useState(true);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (start) {
      let myInterval = setInterval(() => {
        let now = new Date().getTime();
        let left = parseInt((endTime - now) / 1000);
        if (left < 1) {
          clearInterval(myInterval);
          !props.waiting && props.close();
        } else {
          setTimeLeft({
            hours: Math.floor(left / (60 * 60)),
            minutes: Math.floor((left % (60 * 60)) / 60),
            seconds: Math.floor(left % 60),
          });
        }
      }, 1000);
      return () => clearInterval(myInterval);
    }
  }, []);

  return (
    <>
      {timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 ? null : (
        <p className='ms-1 mb-0'>
          {timeLeft.hours > 0 && `${timeLeft.hours}h `}
          {timeLeft.minutes}m {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}s
        </p>
      )}
    </>
  );
};

export default Timer;
