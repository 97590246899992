const minMax = (value, min, max) => {
  if (isNaN(value)) {
    if (min === 0) {
      return '';
    }
    return min;
  }
  let res = '';
  if (!max) {
    res = parseFloat(value) < parseFloat(min) ? parseFloat(min) : parseFloat(value);
  } else {
    res = Math.max(parseFloat(min), Math.min(parseFloat(max), Number(value)));
  }
  return res === 0 ? '' : res;
};

export default minMax;
