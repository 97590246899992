/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_ACTUATOR,
  ADD_SENSOR,
  CHANGED,
  DEL_ACTUATOR,
  DEL_SENSOR,
  IDRI_DATA,
} from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import Actuador from './Actuador';
import Sensor from './Sensor';
import Select from 'react-select';
import moment from 'moment';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import scrollToElement from '../../../../support_func/scrollToElement';
import selectStyles from '../../../../support_func/selectStyles';

const IdriConfig = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [key, setKey] = useState(0);
  const [runAsync, setRunAsync] = useState(false);
  const [autoUse, setautoUse] = useState(false);
  const [restore, setRestore] = useState(false);
  const [, forceUpdate] = useState(0);
  const dispatch = useDispatch();
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idriData = useSelector((state) => state.idriData);
  const idri = useSelector((state) => state.idri);
  const sensorsList = useSelector((state) => state.sensorsList);
  const actuatorsList = useSelector((state) => state.actuatorsList);
  const changed = useSelector((state) => state.changed);

  const checkNet = [5, 15, 30, 60, 90, 120, 180, 240];

  //get data of the new device
  const getIdriData = (errorAsync) => {
    setIsPending(true);
    setError(null);
    setRunAsync(false);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/confidri?ididri=${
          props.id ? props.id : idriInitData.ididri
        }&idriserial=${props.serial ? props.serial : idriInitData.idriserial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // setIsPending(false);
          errorAsync && setError(errorAsync);
          errorAsync &&
            props.getIdriInitData &&
            props.getIdriInitData(idriInitData.ididri, idriInitData.idriserial);
          console.log(res.data);
          dispatch({
            type: CHANGED,
            payload: { ...changed, config: false },
          });
          let idriData = structuredClone(res.data);
          if (typeof res.data.modeup === 'number' && res.data.modeup !== res.data.wkmode) {
            idriData.wkmode = res.data.modeup;
          }
          if (typeof idriData.tsleepup === 'number' && idriData.tsleepup !== res.data.tsleep) {
            idriData.tsleep = res.data.tsleepup;
          }
          if (typeof idriData.tioup === 'number' && idriData.tioup !== res.data.tio) {
            idriData.tio = res.data.tioup;
          }
          dispatch({
            type: IDRI_DATA,
            payload: idriData,
          });
          // dispatch({
          //   type: IDRI_DATA,
          //   payload: res.data,
          // });
          props.setIdriStatus &&
            props.setIdriStatus({
              discharged: res.data.discharged,
              inicialized: res.data.inicialized,
            });
          if (restore === false) {
            dispatch({
              type: DEL_SENSOR,
              payload: {},
            });
            dispatch({
              type: DEL_ACTUATOR,
              payload: {},
            });

            let sens = {};
            res.data.sensors.map(
              (sensor, index) => (sens = { ...sens, [index]: sensor }),
              setKey(key + 1)
            );
            dispatch({
              type: ADD_SENSOR,
              payload: sens,
            });
            let act = {};
            res.data.actuators.map((sensor, index) => (act = { ...act, [index]: sensor }));
            dispatch({
              type: ADD_ACTUATOR,
              payload: act,
            });
            setKey(res.data.sensors.length + res.data.actuators.length);
            // localStorage.setItem('config', JSON.stringify(res.data));
          } else {
            setKey(0);
            let sens = {};
            if (localStorage.getItem(`sensConf-${idriInitData.idriserial}`)) {
              sens = JSON.parse(localStorage.getItem(`sensConf-${idriInitData.idriserial}`));
            } else {
              res.data.sensors.map(
                (sensor, index) => (sens = { ...sens, [index]: sensor }),
                setKey(key + 1)
              );
            }
            let act = {};
            if (localStorage.getItem(`actConf-${idriInitData.idriserial}`)) {
              act = JSON.parse(localStorage.getItem(`actConf-${idriInitData.idriserial}`));
            } else {
              res.data.actuators.map((sensor, index) => (act = { ...act, [index]: sensor }));
            }
            let zBT = {};
            if (localStorage.getItem(`zonaBatTec-${idriInitData.idriserial}`)) {
              zBT = JSON.parse(localStorage.getItem(`zonaBatTec-${idriInitData.idriserial}`));
              dispatch({
                type: IDRI_DATA,
                payload: {
                  ...idriData,
                  tz: zBT.tz,
                  battint: zBT.battint,
                  battext: zBT.battext,
                  battextthreshold: zBT.battextthreshold,
                  battext_alert_en: zBT.battext_alert_en,
                  delay_polling: zBT.delay_polling,
                  net_tech: zBT.net_tech,
                  wkmode: zBT.wkmode,                  
                  tsleep: zBT.tsleep,
                  tio: zBT.tio,
                },
              });
            }
            dispatch({
              type: ADD_SENSOR,
              payload: sens,
            });
            dispatch({
              type: ADD_ACTUATOR,
              payload: act,
            });
            setKey(Object.keys(sens).length + Object.keys(act).length);
          }
          setIsPending(false);
          forceUpdate((n) => !n);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 4000);
    }
  };

  //get async response from device
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          if (res.data.found === true && res.data.response.result === true) {
            props.getIdriInitData &&
              props.getIdriInitData(idriInitData.ididri, idriInitData.idriserial);
            getIdriData(t('conExito'));

            counter = null;
            props.setPage && props.setPage('testInst');
          } else if (res.data.found === true && res.data.response.result === false) {
            // setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdriData(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              getIdriData(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 37) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            getIdriData(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            // setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //add sensor input
  const addIn = () => {
    dispatch({
      type: ADD_SENSOR,
      payload: {
        ...sensorsList,
        [key]: {},
      },
    });
    setKey(key + 1);
    scrollToElement('addSensor', true, 'center', 300);
  };
  //add actuator output
  const addOut = () => {
    dispatch({
      type: ADD_ACTUATOR,
      payload: {
        ...actuatorsList,
        [key]: {},
      },
    });
    setKey(key + 1);
    scrollToElement('addActuator', true, 'center', 300);
  };
  //submit behavior
  const submit = (e) => {
    e.preventDefault();
  };
  //forms validation on submit
  const submitForm = (e) => {
    e.preventDefault();
    let validForms = [];
    let conf = document.getElementById('submitConfig');
    conf && conf.click();
    if (
      (document.getElementById('configForm') &&
        document.getElementById('configForm').checkValidity()) ||
      parseFloat(idriInitData.v_idri) < 3
    ) {
      // sensors required fields validation
      Object.keys(sensorsList).forEach((i) => {
        let btn = document.getElementById(`submitBtnSens_${i}`);
        let form = document.getElementById(`entradas_list_${i}`);
        btn && btn.click();
        let check = [];
        form &&
          form.querySelectorAll('[required]').forEach((i) => {
            if (i.value) {
              check.push(true);
            }
          });
        if (form.querySelectorAll('[required]').length === check.length) {
          validForms.push(i);
        }
      });
      //actuators required fields validation
      Object.keys(actuatorsList).forEach((i) => {
        let btn = document.getElementById(`submitBtnAct_${i}`);
        let form = document.getElementById(`salidas_list_${i}`);
        btn && btn.click();
        let check = [];
        form &&
          form.querySelectorAll('[required]').forEach((i) => {
            if (i.value) {
              check.push(true);
            }
          });
        if (form.querySelectorAll('[required]').length === check.length) {
          validForms.push(i);
        }
      });
      if (
        validForms.length ===
        Object.keys(sensorsList).length + Object.keys(actuatorsList).length
      ) {
        postIdri(e);
      }
    }
  };
  //send instalation sensors data to server
  const postIdri = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    //formation of the request body
    let body = {
      ididri: props.id ? props.id : idriInitData.ididri,
      idriserial: props.serial ? props.serial : idriInitData.idriserial,
      tz: idriData.tz ? idriData.tz : 0,
      wkmode: idriData.wkmode,
      net_tech: idriData.net_tech,
      delay_polling: idriData.delay_polling ? idriData.delay_polling : 60,
      // alt_net_tech:
      //   idriData.alt_net_tech === true || idriData.alt_net_tech === null
      //     ? true
      //     : false,
      battint: idriData.battint === true || idriData.battint === null ? true : false,
      battext: idriData.battext === null ? false : idriData.battext,
      battextthreshold:
        idriData.battext && parseFloat(idriData.battextthreshold) > 0
          ? idriData.battextthreshold
          : !idriData.battext
          ? ''
          : 10.5,
      battext_alert_en: idriData.battext_alert_en,
      delay_polling: idriData.delay_polling,
      tsleep: idriData.tsleep,
      tio: idriData.tio,
      master_en: idriData.master_en ? idriData.master_en : false,
      sensors:
        Object.keys(sensorsList).length > 0
          ? Object.values(sensorsList).map((sensor) => ({
              idsensor: sensor.id ? sensor.id : '',
              idremote: sensor.ninput >= 41 ? sensor.idremote : null,
              ninput: sensor.ninput,
              name: sensor.name,
              brand: sensor.brand,
              model: sensor.model,
              kind: sensor.kind,
              scale: sensor.scale ? sensor.scale : idriData.kindsensors[sensor.kind].default_scale,
              initvalue: sensor.value
                ? sensor.value
                : contadores.includes(String(sensor.kind))
                ? 0
                : '',
              leak_threshold:
                contadores.includes(String(sensor.kind)) && sensor.leak_threshold
                  ? sensor.leak_threshold
                  : 3,
              leak_close_en:
                contadores.includes(String(sensor.kind)) && sensor.leak_close_en
                  ? sensor.leak_close_en
                  : false,
              leak_close_n:
                contadores.includes(String(sensor.kind)) && sensor.leak_close_n
                  ? sensor.leak_close_n
                  : sensor.leak_close_en
                  ? 1
                  : '',
              leak_close_delay:
                contadores.includes(String(sensor.kind)) && sensor.leak_close_delay
                  ? sensor.leak_close_delay
                  : sensor.leak_close_en
                  ? 15
                  : '',
              leak_reset_t:
                contadores.includes(String(sensor.kind)) && sensor.leak_reset_t
                  ? sensor.leak_reset_t
                  : 300,

              cmax_day_threshold:
                contadores.includes(String(sensor.kind)) && sensor.cmax_day_threshold
                  ? sensor.cmax_day_threshold
                  : 100,
              main: sensor.main ? sensor.main : false,
              customcode: sensor.main ? sensor.customcode : '',
            }))
          : [''],
      actuators:
        Object.keys(actuatorsList).length > 0
          ? Object.values(actuatorsList).map((actuador) => ({
              idactuator: actuador.id ? actuador.id : '',
              idremote: actuador.noutput >= 41 ? actuador.idremote : null,
              noutput: actuador.noutput,
              name: actuador.name,
              brand: actuador.brand,
              model: actuador.model,
              kind: actuador.kind,
              category: actuador.category || actuador.category === 0 ? actuador.category : '',
              lfminput: actuador.lfminput ? actuador.lfminput : '',
              flow: actuador.flow ? actuador.flow : '',
              linked_fm: actuador.linked_fm,
              master: actuador.master ? actuador.master : false,
              gap_master: actuador.master && actuador.gap_master ? actuador.gap_master : '',
              associated_masters: actuador.associated_masters ? actuador.associated_masters : [],
            }))
          : [''],
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/confidri`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          localStorage.removeItem(`zonaBatTec-${idriInitData.idriserial}`);
          localStorage.removeItem(`sensConf-${idriInitData.idriserial}`);
          localStorage.removeItem(`actConf-${idriInitData.idriserial}`);
          counter = 0;
          setRunAsync(true);
          if (idri.wkmode === 0) {
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 20000);
          } else {
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }

          if (changed.config && changed.config === true) {
            dispatch({
              type: CHANGED,
              payload: { ...changed, config: false },
            });
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //send instalation sensors data to server
  const postMain = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    //formation of the request body
    let body = {
      ghatorid: props.id ? props.id : idriInitData.ididri,
      ghatorserial: props.serial ? props.serial : idriInitData.idriserial,
      tz: idriData.tz ? idriData.tz : 0,
      wkmode: idriData.wkmode,
      net_tech: idriData.net_tech,
      delay_polling: idriData.delay_polling ? idriData.delay_polling : 60,
      battint: idriData.battint === true || idriData.battint === null ? true : false,
      battext: idriData.battext === null ? false : idriData.battext,
      battextthreshold:
        idriData.battext && parseFloat(idriData.battextthreshold) > 0
          ? idriData.battextthreshold
          : !idriData.battext
          ? ''
          : 10.5,
      battext_alert_en: idriData.battext_alert_en,
      delay_polling: idriData.delay_polling,
      tsleep: idriData.tsleep,
      tio: idriData.tio,
      master_en: idriData.master_en ? idriData.master_en : false,
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/confighatorpg`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          localStorage.removeItem(`zonaBatTec-${idriInitData.idriserial}`);
          localStorage.removeItem(`sensConf-${idriInitData.idriserial}`);
          localStorage.removeItem(`actConf-${idriInitData.idriserial}`);
          counter = 0;
          setRunAsync(true);
          if (idri.wkmode === 0) {
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 12000);
          } else {
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }

          if (changed.config && changed.config === true) {
            dispatch({
              type: CHANGED,
              payload: { ...changed, config: false },
            });
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  //counters check
  const contadores = [];
  idriData.kindsensors &&
    Object.entries(idriData.kindsensors).map(
      ([key, value]) => value.kind_code === 3 && contadores.push(key)
    );

  //auto hide error message
  // if (error || msg) {
  //   setTimeout(() => {
  //     setError(null);
  //     setMsg(null);
  //   }, 10000);
  // }
  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
    msg !== null && window.alert(msg);
    setMsg(null);
  }, [error, msg]);

  //time zones control
  const tz = [];
  idriData.tz_options &&
    idriData.tz_options.forEach((element) => {
      tz.push({
        value: element.code,
        label:
          element.zone +
          ' ' +
          (element.utc_gap >= 0
            ? '+' + moment.utc(element.utc_gap * 1000).format('h:mm')
            : '-' + moment.utc(element.utc_gap * 1000).format('h:mm')),
      });
    });

  const [selectedOption, setSelectedOption] = useState();
  !selectedOption && tz.length > 0 && setSelectedOption(idriData.tz ? tz[idriData.tz] : tz[0]);

  const updateIdriData = (obj) => {
    dispatch({
      type: IDRI_DATA,
      payload: { ...idriData, [Object.keys(obj)]: Object.values(obj)[0] },
    });
    if (!changed.config || changed.config === false) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: true },
      });
    }
  };

  const minMax = (value, min, max) => {
    let res = Math.max(min, Math.min(max, Number(value)));
    return res;
  };

  // useEffect(() => {
  //   // getIdriData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    Object.keys(idriData).length > 0 &&
      dispatch({
        type: IDRI_DATA,
        payload: {
          ...idriData,
          tz: selectedOption ? selectedOption.value : idriData.tz,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    // console.log(sensorsList);
    // console.log(sensorsList !== {});
    if (
      changed &&
      changed.config === true &&
      sensorsList &&
      Object.keys(sensorsList).length !== 0
    ) {
      localStorage.setItem(`sensConf-${idriInitData.idriserial}`, JSON.stringify(sensorsList));
    }

    changed &&
      changed.config === true &&
      Object.keys(sensorsList).length === 0 &&
      localStorage.removeItem(`sensConf-${idriInitData.idriserial}`);
  }, [sensorsList]);

  useEffect(() => {
    let arr = [];
    // eslint-disable-next-line array-callback-return
    if (
      // changed &&
      // changed.config === true &&
      actuatorsList &&
      Object.keys(actuatorsList).length !== 0
    ) {
      Object.values(actuatorsList).map((actuador) => {
        actuador.master === true && arr.push(true);
      });
      arr.length > 0 ? setautoUse(true) : setautoUse(false);

      changed &&
        changed.config === true &&
        localStorage.setItem(`actConf-${idriInitData.idriserial}`, JSON.stringify(actuatorsList));
    }

    changed &&
      changed.config === true &&
      Object.keys(actuatorsList).length === 0 &&
      localStorage.removeItem(`actConf-${idriInitData.idriserial}`);
  }, [actuatorsList]);

  useEffect(() => {
    if (changed && changed.config === true) {
      let zBT = idriData
        ? {
            tz: idriData.tz,
            // battint: idriData.battint,
            battext: idriData.battext,
            battextthreshold: idriData.battextthreshold,
            battext_alert_en: idriData.battext_alert_en,
            delay_polling: idriData.delay_polling,
            net_tech: idriData.net_tech,
            wkmode: idriData.wkmode,
            tsleep: idriData.tsleep,
            tio: idriData.tio,
            tsleepup: idriData.tsleep,
            tioup: idriData.tio,
          }
        : {};
      localStorage.setItem(`zonaBatTec-${idriInitData.idriserial}`, JSON.stringify(zBT));
    }
  }, [idriData]);

  useEffect(() => {
    if (
      changed &&
      changed.config === true &&
      Object.keys(sensorsList).length === 0 &&
      Object.keys(actuatorsList).length === 0
    ) {
      setKey(0);
    }
  }, [sensorsList, actuatorsList]);

  useEffect(() => {
    if (
      (localStorage.getItem(`sensConf-${idriInitData.idriserial}`) ||
        localStorage.getItem(`actConf-${idriInitData.idriserial}`) ||
        localStorage.getItem(`zonaBatTec-${idriInitData.idriserial}`)) &&
      (localStorage.getItem(`sensConf-${idriInitData.idriserial}`) !== '{}' ||
        localStorage.getItem(`actConf-${idriInitData.idriserial}`) !== '{}' ||
        localStorage.getItem(`zonaBatTec-${idriInitData.idriserial}` !== '{}')) &&
      window.confirm(t('hayDatos'))
    ) {
      setRestore(true);
      setIsPending(false);
      // restore && getIdriData();
      // fetchData();
    } else {
      setRestore(false);
      setIsPending(false);
      localStorage.removeItem(`sensConf-${idriInitData.idriserial}`);
      localStorage.removeItem(`actConf-${idriInitData.idriserial}`);
      localStorage.removeItem(`zonaBatTec-${idriInitData.idriserial}`);
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: false },
      });
      getIdriData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    restore && getIdriData();
  }, [restore]);

  useEffect(() => {
    textLineAnimation();
  });

  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('comprobando')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={3} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      ) : (
        Object.keys(idriData).length > 0 && (
          <>
            {parseFloat(idriInitData.v_idri) >= 3 && (
              <Form
                id='configForm'
                onSubmit={(e) => {
                  submit(e);
                  // postMain(e);
                }}
              >
                {/* <> */}
                <Row className='d-flex align-items-end'>
                  {idriData.tz_options && (
                    <Col xs={12} md={3}>
                      <div className='animated text-truncate'>
                        <strong>{t('zonaHoraria')}</strong>
                      </div>
                      <Select
                        menuShouldScrollIntoView
                        required
                        styles={selectStyles}
                        value={selectedOption ?? ''}
                        onChange={setSelectedOption}
                        options={tz}
                      />
                    </Col>
                  )}
                  {/* {idriData.wkmode === 2 && <Row></Row>} */}
                  {/* <Col xs={4} md={3} className='text-center'>
                    <div className='animated text-truncate'>
                      <strong>{t('batIn')}</strong>
                    </div>
                    <Form.Check
                      className='mt-2'
                      type='checkbox'
                      // checked={idriData.battint}
                      checked={idriData.battint === null ? true : idriData.battint}
                      onChange={(e) => {
                        updateIdriData({ battint: e.target.checked });
                      }}
                    />
                  </Col> */}
                  <Col xs={6} md={3} className='text-center'>
                    <div className='animated text-truncate'>
                      <strong>{t('batIn')}</strong>
                    </div>
                    <Form.Check
                      className='mt-2'
                      type='checkbox'
                      checked={idriData.battint}
                      onChange={(e) => {
                        updateIdriData({ battint: e.target.checked });
                      }}
                    />
                  </Col>
                  <Col xs={6} md={3} className='text-center'>
                    <div className='animated text-truncate'>
                      <strong>{t('batExt')}</strong>
                    </div>
                    <Form.Check
                      className='mt-2'
                      type='checkbox'
                      checked={idriData.battext}
                      onChange={(e) => {
                        updateIdriData({ battext: e.target.checked });
                      }}
                    />
                  </Col>
                  {idriData.battext && (
                    <Col xs={6} md={3} className='text-center'>
                      <div className='animated text-truncate'>
                        <strong>{t('batExtEn')}</strong>
                      </div>
                      <Form.Check
                        /* required */
                        type='checkbox'
                        checked={idriData.battext_alert_en}
                        onChange={(e) => {
                          updateIdriData({ battext_alert_en: e.target.checked });
                        }}
                      />
                    </Col>
                  )}                 
                  {idriData.battext && idriData.battext_alert_en && (
                    <Col xs={6} md={3} className='text-center'>
                      <div className='animated text-truncate'>
                        <strong>{t('umbral')}</strong>  (v)
                      </div>
                      <Form.Control
                        required
                        type='number'
                        min={0}
                        step={0.1}
                        value={
                          idriData.battextthreshold && parseFloat(idriData.battextthreshold) > 0
                            ? idriData.battextthreshold
                            : 10.5
                        }
                        onChange={(e) => {
                          updateIdriData({
                            battextthreshold: parseFloat(e.target.value),
                          });
                        }}
                      />
                    </Col>
                  )}
                  <Col xs={12} md={3}>
                    <div className='animated text-truncate'>
                      <strong>{t('selectNet')}</strong>
                    </div>
                    <Form.Select
                      id='net'
                      required
                      value={idriData.net_tech || idriData.net_tech === 0 ? idriData.net_tech : ''}
                      onChange={(e) => {
                        updateIdriData({
                          net_tech: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value='' disabled>
                        {t('selectNetType')}...
                      </option>
                      {idriData.net_tech_options &&
                        idriData.net_tech_options.map((value) => (
                          <option
                            key={value.code}
                            value={value.code}
                            selected={idriData.net_tech && idriData.net_tech === value.code}
                          >
                            {value.tech}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col xs={12} md={3}>
                    <div className='animated text-truncate'>
                      <strong>{t('workingMode')}</strong>
                    </div>
                    <Form.Select
                      id='net'
                      required
                      // defaultValue={
                      //   typeof idriData.modeup === 'number'
                      //     ? idriData.modeup
                      //     : typeof idriData.wkmode === 'number'
                      //     ? idriData.wkmode
                      //     : ''
                      // }
                      value={typeof idriData.wkmode === 'number' ? idriData.wkmode : ''}
                      onChange={(e) => {
                        updateIdriData({
                          wkmode: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value='' disabled>
                        {t('selectMode')}...
                      </option>
                      {idriData.wkmode_options &&
                        Object.entries(idriData.wkmode_options).map(([id, value]) => (
                          <option
                            key={id}
                            value={id}
                            selected={idriData.wkmode && idriData.wkmode === id}
                          >
                            {value}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  {idriData.wkmode === 2 && (
                    <>
                      <Col xs={12} md={3}>
                        <div className='animated text-truncate'>
                          <strong>{t('tiempoDormir')}</strong>
                        </div>
                        <Form.Control
                          required
                          type='number'
                          min={2}
                          // min={localStorage.getItem('profile') === 'admi' ? 2 : 5}
                          max={1439}
                          // defaultValue={
                          //   typeof idriData.tsleepup === 'number'
                          //     ? idriData.tsleepup
                          //     : idriData.tsleep
                          // }
                          value={idriData.tsleep}
                          // value={
                          //   typeof idriData.tsleepup === 'number'
                          //     ? idriData.tsleepup
                          //     : idriData.tsleep
                          // }
                          onChange={(e) => {
                            updateIdriData({
                              tsleep: parseInt(e.target.value),
                            });
                          }}
                          onBlur={(e) =>
                            updateIdriData({
                              tsleep: minMax(
                                parseInt(e.target.value),
                                2,
                                // localStorage.getItem('profile') === 'admi' ? 2 : 5,
                                1439
                              ),
                            })
                          }
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='animated text-truncate'>
                          <strong>{t('tiempoOC')}</strong>
                        </div>
                        <Form.Control
                          required
                          type='number'
                          min={localStorage.getItem('profile') === 'admi' ? 1 : 15}
                          max={86399}
                          // defaultValue={
                          //   typeof idriData.tioup === 'number' ? idriData.tioup : idriData.tio
                          // }
                          value={idriData.tio}
                          // value={typeof idriData.tioup === 'number' ? idriData.tioup : idriData.tio}
                          onChange={(e) => {
                            updateIdriData({
                              tio: parseInt(e.target.value),
                            });
                          }}
                          onBlur={(e) =>
                            updateIdriData({
                              tio: minMax(
                                parseInt(e.target.value),
                                localStorage.getItem('profile') === 'admi' ? 1 : 15,
                                86399
                              ),
                            })
                          }
                        />
                      </Col>
                    </>
                  )}
                  {idriData.wkmode === 0 && (
                    <Col xs={12} md={3}>
                      <div className='animated text-truncate'>
                        <strong>{t('delayPolling')}</strong>
                      </div>
{/*                       {localStorage.getItem('profile') === 'admi' ? (
 */}                        <Form.Control
                          required
                          placeholder={t('enterTime') + '...'}
                          type='number'
                          /* min={0}
                          max={255} */
                          value={idriData.delay_polling ? idriData.delay_polling : 0}
                          onChange={(e) => {
                            updateIdriData({
                              delay_polling: parseInt(e.target.value),
                            });
                          }}
                        />
                      {/* ) : (
                        <Form.Select
                          required
                          value={idriData.net_check_minutes ? idriData.net_check_minutes : ''}
                          onChange={(e) => {
                            updateIdriData({
                              net_check_minutes: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value='' disabled>
                            {t('selectTime')}...
                          </option>
                          {checkNet.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </Form.Select>
                      )} */}
                    </Col>
                  )}
                </Row>
                <Col className='text-center my-2'>
                  <Button
                    // type='submit'
                    type='button'
                    className='btn-sm'
                    onClick={(e) => postMain(e)}
                  >
                    {t('sendMain')}
                  </Button>
                </Col>
                <hr className='mt-2' />
                {/* </> */}
                <Button className='d-none dark' type='submit' id='submitConfig'></Button>
              </Form>
            )}
            <Col xs={12} className='text-center mt-2'>
              <h4>
                <strong>
                  <u>{t('entradasSensores')}</u>
                </strong>
              </h4>
            </Col>
            {sensorsList &&
              Object.keys(sensorsList).length > 0 &&
              Object.keys(sensorsList).map((key) => (
                <Sensor key={'sensor' + key} pos={key} v_idri={props.v_idri} />
              ))}
            {sensorsList &&
              Object.keys(sensorsList).length < (parseFloat(props.v_idri) >= 3 ? 80 : 8) && (
                <Col id='addSensor' className='text-center my-2'>
                  <Button type='button' className='btn-sm dark' onClick={addIn}>
                    {t('addSensor')}
                  </Button>
                </Col>
              )}
            <Row className='mt-3'>
              <Col className='text-center'>
                <h4>
                  <strong>
                    <u>{t('salidasActuadores')}</u>
                  </strong>
                </h4>
              </Col>
              {parseFloat(idriInitData.v_idri) >= 3 && (
                <div className='d-flex justify-content-center pb-2 '>
                  <h6 className='d-flex align-items-center m-0'>{t('autoMaster')}</h6>
                  <Form.Check
                    // disabled
                    disabled={!autoUse}
                    id='autoUse'
                    className='d-flex align-items-center ms-3 pb-1'
                    type='switch'
                    checked={idriData.master_en}
                    onChange={(e) => {
                      updateIdriData({ master_en: e.target.checked });
                    }}
                    // style={{ transform: 'scale(1.2)' }}
                    // value={autoUse}
                    // onChange={(e) => {
                    //   setAutoUse(e.target.checked);
                    // }}
                    // onBlur={(e) => console.log(idriData.master_en)}
                  />
                </div>
              )}
            </Row>
            {actuatorsList &&
              Object.keys(actuatorsList).length > 0 &&
              Object.entries(actuatorsList).map(([key, value]) => (
                <Actuador key={'actuator' + key} pos={key} />
              ))}
            {actuatorsList &&
              Object.keys(actuatorsList).length < (parseFloat(props.v_idri) >= 3 ? 80 : 8) && (
                <Col id='addActuator' className='text-center my-2'>
                  <Button type='button' className='btn-sm dark' onClick={addOut}>
                    {t('addSalida')}
                  </Button>
                </Col>
              )}
            <Row id='error' className='mt-5 mb-1'>
              {/* <Col xs={12}>
                {error && (
                  <Col className='text-center alert alert-danger'>{error}</Col>
                )}
                {msg && (
                  <Col className='text-center alert alert-success bg-transparent border-white'>
                    {msg}
                  </Col>
                )}
              </Col> */}
              <Col className='text-center'>
                <Button
                  type='submit'
                  name='commit'
                  id='newInstBtn'
                  className='btn btn-secondary text-uppercase'
                  onClick={(e) =>
                    Object.keys(idri).length > 0 && idri.wkmode !== 0
                      ? window.confirm(t('inaccessible')) && submitForm(e)
                      : submitForm(e)
                  }
                >
                  {t('configNewInstalacion')}
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </>
  );
};

export default IdriConfig;
