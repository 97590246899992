import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dict from './diccionario.json';
import { registerLocale } from 'react-datepicker';
import { es, enGB, fr } from 'date-fns/locale';

const languages = ['es', 'en', 'fr'];

axios
  .get(`${process.env.REACT_APP_API_SRT}/private2/dictionary?language=all`)
  .then((res) => {
    res.data.data
      ? localStorage.setItem('dictionary', JSON.parse(res.data.data))
      : localStorage.setItem('dictionary', JSON.stringify(dict));
    let resources = localStorage.dictionary ? JSON.parse(localStorage.dictionary) : dict;
    let language;

    if (localStorage.getItem('language')) {
      language = localStorage.getItem('language');
      if (!languages.includes(language)) {
        language = 'en';
        localStorage.setItem('language', 'en');
      }
    } else {
      language = 'en';
      localStorage.setItem('language', 'en');
    }

    i18n.init({
      resources,
      lng: language,
    });
  })
  .catch((error) => {
    console.log(error);
  });

i18n.use(initReactI18next).init({
  react: { useSuspense: false },
});

registerLocale('es', es);
registerLocale('en', enGB);
registerLocale('fr', fr);

export default i18n;
