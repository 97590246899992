/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import {
  FaEdit,
  FaInfoCircle,
  FaMapMarkedAlt,
  FaQuestionCircle,
  FaSignal,
  FaTrashAlt,
  FaUserCog,
} from 'react-icons/fa';
import { CgMenuGridR } from 'react-icons/cg';
import { FiAlertTriangle, FiArrowLeftCircle, FiSettings } from 'react-icons/fi';
import { GiNightSleep } from 'react-icons/gi';
import { HiRefresh } from 'react-icons/hi';
import { HiQueueList } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import ModalGenerico from '../../../home/Modal_generico';
import EditModal from '../../modals/EditModal';
import Select from 'react-select';
import { useEffect } from 'react';
import scaleDecimal from '../../../../support_func/scaleDecimal';
import ConfigModal from '../../modals/ConfigModal';
import Permissions from '../../menuViews/all/Permissions';
import Timer from '../../../../support_func/Timer';
import OrdersModal from '../../modals/OrdersModal';

const SensorsControl = (props) => {
  const { t } = useTranslation();

  const idri = useSelector((state) => state.idri);
  const markers = useSelector((state) => state.markers);

  const [edited, setEdited] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [ordersModal, setOrdersModal] = useState(false);
  const [permissionsModal, setPermissionsModal] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [autoUseDis, setAutoUseDis] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [idriStatus, setIdriStatus] = useState({});
  const [list, setList] = useState([]);
  const [key, setKey] = useState(0);

  const getIdriId = (serial, ret) => {
    return Object.entries(markers).map(([key, value]) => {
      // eslint-disable-next-line eqeqeq
      if (serial == value.ghatorserial) {
        if (ret) {
          return value.ghatorid;
        }
        props.getIdri(false, value.ghatorserial, value.ghatorid);
      }
    });
  };

  const editCloseHandler = () => {
    if (edited) {
      if (window.confirm(t('datosEliminados'))) {
        setEditModal(false);
        props.getIdri(null, idri.ghatorserial, idri.ghatorid);
        setEdited(false);
      }
    } else {
      setEditModal(false);
    }
  };

  useEffect(() => {
    if (markers && Object.keys(markers).length > 0) {
      const newList = Object.entries(markers).map(([key, value]) => ({
        value: value.ghatorserial,
        label: value.ghatorname + ' (' + value.ghatorserial + ')',
        isDisabled: localStorage.getItem('profile') !== 'admi' && !value.discharged,
      }));

      setList(newList);
    }
  }, [markers]);

  useEffect(() => {
    props.setAutoUse(idri.master_en);
    let arr = [];
    let slaves = [];
    idri.actuators &&
      idri.actuators.map((actuador) => {
        slaves = slaves.concat(actuador.slaves);
      });

    idri.actuators.map((actuador) => {
      if (actuador.status === 1 && slaves.includes(actuador.idactuator)) {
        arr.push(true);
      }
    });
    arr.length > 0 ? setAutoUseDis(true) : setAutoUseDis(false);

    const listObj = list.find((item) => item.value === idri.ghatorserial);
    if (listObj) {
      listObj.label = idri.ghatorname + ' (' + idri.ghatorserial + ')';
      setList([...list]);
      setKey((prevKey) => prevKey + 1);
    }
  }, [idri]);

  const [showTooltip, setShowTooltip] = useState(false);
  const handleToggle = (show) => {
    setShowTooltip(show);
  };
  const myTooltip = (
    <Tooltip className='p-2 text'>
      {parseInt(
        Math.abs(
          moment.duration(moment()).asMinutes() -
            moment.duration(moment(idri.last_read_date)).asMinutes()
        ) / idri.tsleep
      ) > 0 && (
        <Row className='p-2'>
          <Col>
            {t('connectionsFailed')}:{' '}
            {parseInt(
              Math.abs(
                moment.duration(moment()).asMinutes() -
                  moment.duration(moment(idri.last_read_date)).asMinutes()
              ) / idri.tsleep
            )}
          </Col>
        </Row>
      )}
      <Row className='p-2'>
        {t('nextConnection')}:
        <Timer
          min={
            moment.duration(moment()).asMinutes() -
              moment.duration(moment(idri.last_read_date)).asMinutes() >
            idri.tsleep
              ? idri.tsleep -
                (Math.abs(
                  moment.duration(moment()).asMinutes() -
                    moment.duration(moment(idri.last_read_date)).asMinutes()
                ) %
                  idri.tsleep)
              : idri.tsleep -
                (moment.duration(moment()).asMinutes() -
                  moment.duration(moment(idri.last_read_date)).asMinutes())
          }
          sec={0}
        />
      </Row>
    </Tooltip>
  );

  // console.log(list);

  return (
    <Table bordered className='mb-0 test'>
      <thead>
        {/* <tr className='dark text-center '> */}
        <tr style={{backgroundColor: '#111', color: 'white'}} className='text-center'>
          <th colSpan={2} className='lh-sm position-relative'>
            <>
              <p className='fw-normal lh-sm my-0 position-relative'>
                ({idri.ghatorserial}/f{idri.v_firmware}/p
                {idri.ghatorv}){' '}
                <OverlayTrigger
                  trigger={'click'}
                  rootClose
                  // rootCloseEvent='mousedown'
                  placement={'left'}
                  overlay={
                    <Popover>
                      <Popover.Body>
                        {/* <Row> */}
                        <IconContext.Provider value={{ size: '1.1em', color: 'dodgerblue' }}>
                          {parseFloat(idri.ghatorv) >= 3 && (
                            <>
                              <OverlayTrigger
                                placement='bottom'
                                // show={autoUseDis}
                                overlay={
                                  autoUseDis || idri.wkmode === 2 ? (
                                    <Tooltip show={autoUseDis || idri.wkmode === 2}>
                                      {autoUseDis
                                        ? t('noCambiarUsoAutomatico')
                                        : t('noChangeInSleep')}
                                    </Tooltip>
                                  ) : (
                                    <span></span>
                                  )
                                }
                              >
                                <Col xs={12} className='py-1 testSvg d-flex'>
                                  <Form.Check
                                    disabled={
                                      !idri.user_permissions.action ||
                                      autoUseDis ||
                                      idri.wkmode === 2
                                    }
                                    type='switch'
                                    id='autoUse'
                                    checked={props.autoUse}
                                    onChange={(e) => {
                                      props.setAutoUse(e.target.checked);
                                    }}
                                    onBlur={(e) =>
                                      e.target.checked !== idri.master_en &&
                                      props.masterEn(e, e.target.checked)
                                    }
                                  />
                                  <span className=''>
                                    {t('autoMaster').charAt(0).toUpperCase() +
                                      t('autoMaster').slice(1)}
                                  </span>
                                </Col>
                              </OverlayTrigger>
                              {/* )} */}
                            </>
                          )}

                          {localStorage.getItem('menu') !== 'newIdris' && (
                            <>
                              <Col xs={12} className='py-1 testSvg'>
                                <FiSettings
                                  type='button'
                                  className={`${
                                    !idri.user_permissions.edit && 'disabledCustom'
                                  } ms-1`}
                                  onClick={(e) => {
                                    if (idri.user_permissions.edit) {
                                      window.confirm(t('cambiosAfectaran')) && setConfigModal(true);
                                    }
                                  }}
                                />
                                <span className='ms-3'>{t('configDispositivo')}</span>
                              </Col>
                              <Col xs={12} className='py-1 testSvg'>
                                <FaEdit
                                  type='button'
                                  className={`${
                                    !idri.user_permissions.edit && 'disabledCustom'
                                  } ms-1`}
                                  onClick={(e) => {
                                    if (idri.user_permissions.edit) {
                                      setEditModal(true);
                                      document.getElementById('myCheck') &&
                                        document.getElementById('myCheck').click();
                                    }
                                  }}
                                />
                                <span className='ms-3'>{t('editRecursos')}</span>
                              </Col>
                              <Col xs={12} className='py-1 testSvg'>
                                <FaUserCog
                                  type='button'
                                  className={`${
                                    !idri.user_permissions.edit && 'disabledCustom'
                                  } ms-1`}
                                  onClick={(e) => {
                                    if (idri.user_permissions.edit) {
                                      setPermissionsModal(true);
                                      document.getElementById('myCheck') &&
                                        document.getElementById('myCheck').click();
                                    }
                                  }}
                                />
                                <span className='ms-3'>{t('editPermissions')}</span>
                              </Col>
                            </>
                          )}
                          {props.profiles && props.profiles.admi && (
                            <Col xs={12} className='py-1 testSvg'>
                              <FaTrashAlt
                                type='button'
                                color='red'
                                className='ms-1'
                                onClick={(e) =>
                                  window.confirm(t('delete')) ? setDeleteModal(true) : null
                                }
                              />
                              <span className='ms-3'>{t('eliminarIdri')}</span>
                            </Col>
                          )}
                          <Col xs={12} className='py-1 testSvg'>
                            <FaQuestionCircle type='button' className='ms-1' />{' '}
                            <span className='ms-2'>{t('ayuda')}</span>
                          </Col>
                        </IconContext.Provider>
                        {/* </Row> */}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    className='position-absolute top-0 end-0 p-0 additionalMenu white'
                    id='myCheck'
                  >
                    <IconContext.Provider
                      value={{
                        size: '1.6em',
                        color: 'black'
                      }}
                    >
                      <CgMenuGridR />
                    </IconContext.Provider>
                  </Button>
                </OverlayTrigger>
                <Modal
                  // size='sm'
                  show={deleteModal}
                  onHide={() => setDeleteModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('introducePassword')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form className='text-center' onSubmit={(e) => props.deleteIdri(e)}>
                      <Form.Control
                        required
                        minLength={8}
                        type='password'
                        autoComplete='off'
                        value={props.password}
                        onChange={(e) => props.setPassword(e.target.value)}
                      />
                      <Button variant='outline-danger' className='mt-3' size='sm' type='submit'>
                        {t('eliminarIdri')}
                      </Button>
                    </Form>
                  </Modal.Body>
                </Modal>
                <ModalGenerico
                  title={t('editPermissions')}
                  onClose={() => {
                    setPermissionsModal(false);
                    props.getIdri(null, idri.ghatorserial, idri.ghatorid);
                  }}
                  show={permissionsModal}
                >
                  <Permissions
                    ghatorid={idri.ghatorid}
                    ghatorserial={idri.ghatorserial}
                    ghatorname={idri.ghatorname}
                    closeModal={() => setPermissionsModal(false)}
                  />
                </ModalGenerico>
                <ModalGenerico
                  title={t('editRecursos')}
                  onClose={() => editCloseHandler()}
                  show={editModal}
                >
                  <EditModal
                    id={idri.ghatorid}
                    serial={idri.ghatorserial}
                    setEdited={setEdited}
                    getIdri={() => props.getIdri()}
                    refresh={() => props.refresh()}
                    closeModal={() => setEditModal(false)}
                  />
                </ModalGenerico>
                <ModalGenerico
                  title={t('configDispositivo') + ' ' + idri.ghatorserial}
                  // disabled={!idri.user_permissions.edit}
                  onClose={() => {
                    if (!idriStatus.inicialized) {
                      if (
                        window.confirm(
                          idri.wkmode !== 0 ? t('noInitRefresh') : t('noInitLostUsability')
                        )
                      ) {
                        setConfigModal(false);
                        props.getIdri(null, idri.ghatorserial, idri.ghatorid);
                      }
                    } else {
                      setConfigModal(false);
                      props.getIdri(null, idri.ghatorserial, idri.ghatorid);
                    }
                    // setConfigModal(false);
                    // props.getIdri(null, idri.idriserial, idri.ididri);
                  }}
                  show={configModal}
                >
                  {/* <EditModal
                    id={idri.ididri}
                    serial={idri.idriserial}
                    closeModal={() => setConfigModal(false)}
                  /> */}
                  {/* <IdriInit /> */}
                  <ConfigModal
                    id={idri.ghatorid}
                    serial={idri.ghatorserial}
                    v_idri={idri.ghatorv}
                    idriStatus={idriStatus}
                    setIdriStatus={setIdriStatus}
                    closeModal={() => {
                      setConfigModal(false);
                      props.getIdri(null, idri.ghatorserial, idri.idighatoriddri);
                    }}
                  />
                </ModalGenerico>
              </p>
              {((props.profiles && props.profiles.admi) ||
                localStorage.getItem('menu') === 'newIdris') && (
                <p className='fw-normal lh-sm my-0'>
                  ({idri.sim.simprovider}-{idri.sim.iccid}-{idri.sim.ip})
                </p>
              )}
              <p className=' lh-sm my-0 deviceText'>{idri.ghatorname}</p>
              {((props.profiles && props.profiles.admi) ||
                localStorage.getItem('menu') === 'newIdris') && (
                <a
                  target='_blank'
                  href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${idri.owner.email}`}
                  className='fw-normal lh-sm text-info text-decoration-none'
                  rel='noreferrer'
                >
                  {idri.owner.email}
                </a>
              )}
              <p className='fw-normal lh-sm my-0'>
                {t('ultimaLectura')}:{' '}
                {idri.last_read_date
                  ? moment(idri.last_read_date).format('L') +
                    ' ' +
                    moment(idri.last_read_date).format('LTS')
                  : t('noDisponible')}
              </p>
              <Row className='position-absolute bottom-0 start-0 m-2 text-end'>
                <Col xs={12} className='p-0 m-0'>
                  {idri.wkmode !== 0 && (
                    <IconContext.Provider
                      value={{
                        attr: { fill: 'yellow' },
                        size: '1.5em',
                      }}
                    >
                      <OverlayTrigger
                        placement='auto'
                        rootClose='true'
                        trigger={'click'}
                        overlay={myTooltip}
                        onToggle={handleToggle}
                      >
                        <span>
                          <GiNightSleep className='p-0 m-0 me-md-2' />
                        </span>
                      </OverlayTrigger>
                    </IconContext.Provider>
                  )}
                </Col>
              </Row>
              <Row className='position-absolute bottom-0 end-0 m-2 text-end'>
                <Col xs={12} className='p-0 m-0'>
                  {idri.signal_quality && (
                    <>
                      <svg width='0' height='0'>
                        <defs>
                          <linearGradient id='myGradient'>
                            <stop
                              offset={
                                (idri.signal_quality > 0 && idri.signal_quality <= 20 && '20%') ||
                                (idri.signal_quality > 20 && idri.signal_quality <= 40 && '40%') ||
                                (idri.signal_quality > 40 && idri.signal_quality <= 60 && '60%') ||
                                (idri.signal_quality > 60 && idri.signal_quality <= 80 && '80%') ||
                                (idri.signal_quality > 80 && idri.signal_quality <= 100 && '100%')
                              }
                              stopColor='green'
                            />
                            <stop offset='0%' stopColor='white' />
                          </linearGradient>
                        </defs>
                      </svg>
                      <IconContext.Provider
                        value={{
                          attr: { fill: "url('#myGradient')" },
                          size: '1.6em',
                        }}
                      >
                        <FaSignal className='p-0 m-0' />
                      </IconContext.Provider>
                    </>
                  )}
                </Col>
              </Row>
            </>
          </th>
        </tr>
        <tr className='lGray'>
          {props.page === 'idri' && list.length > 0 && (
            <td className='text-center py-0'>
              {props.profile === 'gamo' && t('misIdris')}
              {props.profile === 'delg' && t('delegado')}
              {props.profile === 'inst' && t('instalByMe')}
              {props.profile === 'admi' && t('todos')}
            </td>
          )}
        </tr>
        <tr>
          {props.page === 'idri' && list.length > 0 && (
            <td className='p-0'>
              <Select
                key={key}
                menuShouldScrollIntoView
                placeholder={t('selectIdris')}
                defaultValue={list.filter((option) => option.value === idri.ghatorserial)}
                isSearchable={true}
                options={list}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
                  option: (styles, { isFocused, isSelected }) => {
                    return {
                      ...styles,
                      backgroundColor: isFocused
                        ? 'rgba(0, 123, 255, 0.3)'
                        : isSelected && 'rgba(0, 0, 0, 0)',
                      color: isSelected && 'rgba(0, 0, 0)',
                    };
                  },
                }}
                onChange={(e) => {
                  getIdriId(e.value);
                  props.setControl('');
                  props.setData({
                    id: getIdriId(e.value, true).find((value) => {
                      return value;
                    }),
                    serial: e.value,
                  });
                }}
              />
            </td>
          )}
        </tr>
      </thead>
      <tbody className='text-center'>
        <tr className='lGray'>
          <td>
            <IconContext.Provider value={{ color: '#0066cc' }}>
              <Row className='justify-content-center'>
                {props.page === 'idri' && (
                  <OverlayTrigger
                    placement='top'
                    rootClose='true'
                    trigger={['hover', 'click', 'focus']}
                    overlay={<Tooltip>{t('dispositivos')}</Tooltip>}
                  >
                    <Col xs={1} className='p-0 mx-3 testSvg'>
                      <FaMapMarkedAlt type='button' onClick={() => props.setPage('map')} />
                    </Col>
                  </OverlayTrigger>
                )}
                {props.setFirstPage && (
                  <OverlayTrigger
                    placement='top'
                    rootClose='true'
                    trigger={['hover', 'click', 'focus']}
                    overlay={<Tooltip>{t('back')}</Tooltip>}
                  >
                    <Col xs={1} className='p-0 mx-3 testSvg'>
                      <FiArrowLeftCircle
                        type='button'
                        onClick={() => {
                          props.setFirstPage(!props.firstPage);
                          props.setToMap && props.setToMap(true);
                        }}
                      />
                    </Col>
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement='top'
                  rootClose='true'
                  trigger={['hover', 'click', 'focus']}
                  overlay={<Tooltip>{t('refresh')}</Tooltip>}
                >
                  <Col xs={1} className='p-0 mx-3 testSvg'>
                    <HiRefresh
                      type='button'
                      className={`${!idri.user_permissions.action && 'disabledCustom'}`}
                      onClick={(e) => {
                        if (idri.user_permissions.action) {
                          idri.wkmode !== 0
                            ? props.getIdri(null, idri.ghatorserial, idri.ghatorid)
                            : props.refresh();
                        }
                      }}
                    />
                  </Col>
                </OverlayTrigger>

                {parseFloat(idri.ghatorv) >= 3 &&
                  props.profiles &&
                  props.profiles.admi &&
                  idri.wkmode === 0 && (
                    <>
                      <OverlayTrigger
                        placement='top'
                        rootClose='true'
                        trigger={['hover', 'click', 'focus']}
                        overlay={<Tooltip>{t('refresh')}</Tooltip>}
                      >
                        <Col xs={1} className='p-0 mx-3 testSvg'>
                          <HiRefresh
                            type='button'
                            color='red'
                            className={`${!idri.user_permissions.action && 'disabledCustom'}`}
                            onClick={(e) => {
                              if (idri.user_permissions.action) {
                                idri.wkmode === 0
                                  ? props.cRefresh()
                                  : props.getIdri(null, idri.ghatorserial, idri.ghatorid);
                              }
                            }}
                          />
                        </Col>
                      </OverlayTrigger>
                    </>
                  )}
                {/* <OverlayTrigger
                  placement='top'
                  rootClose='true'
                  trigger={['hover', 'click', 'focus']}
                  overlay={<Tooltip>{t('info')}</Tooltip>}
                >
                  <Col xs={1} className='p-0 mx-3 testSvg'>
                    <FaInfoCircle type='button' />
                  </Col>
                </OverlayTrigger> */}
                {idri.wkmode !== 0 && (
                  <OverlayTrigger
                    placement='top'
                    rootClose='true'
                    trigger={['hover', 'click', 'focus']}
                    overlay={<Tooltip>{t('editOrders')}</Tooltip>}
                  >
                    <Col xs={1} className='p-0 mx-3 testSvg'>
                      <HiQueueList
                        type='button'
                        onClick={(e) => {
                          setOrdersModal(true);
                        }}
                      />
                    </Col>
                  </OverlayTrigger>
                )}

                <ModalGenerico
                  title={t('editOrders')}
                  onClose={() => {
                    setOrdersModal(false);
                  }}
                  show={ordersModal}
                >
                  <OrdersModal
                    id={idri.ghatorid}
                    serial={idri.ghatorserial}
                    closeModal={() => setOrdersModal(false)}
                  />
                </ModalGenerico>
              </Row>
            </IconContext.Provider>
          </td>
        </tr>
        <tr className='border-0'>
          <td className='border-0 p-0'>
            {/* <Row className='gx-0 strip'> */}
            <Row className='gx-0 '>
              {idri.bat_int_value && (
                <Col xs={6} className='text-center'>
                  {/* <Card border='primary' style={{ height: '100%' }}> */}
                  <Card style={{ height: '100%' }}>
                    <Card.Title className='my-0 '>
                      <div className=''>
                        {parseFloat(idri.ghatorv) >= 3 && idri.bat_int_alert && (
                          <OverlayTrigger
                            // placement={'right'}
                            overlay={
                              <Tooltip id='alertTooltip'>
                                <Col className='d-flex pe-3 '>
                                  <Button
                                    variant='link'
                                    className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                  ></Button>
                                  <Row>
                                    <Col
                                      xs={12}
                                      className='d-flex justify-content-center align-items-center'
                                    >
                                      <FiAlertTriangle
                                        className='dangerAnimation text-decoration-none text-danger me-1'
                                        fontSize='1em'
                                      />
                                      {t('alertaDisparada')}
                                    </Col>
                                    <Col xs={12}>
                                      {idri.bat_int_alert_start_date &&
                                        t('startDate') +
                                          ': ' +
                                          moment
                                            .utc(idri.bat_int_alert_start_date)
                                            .local()
                                            .format('DD-MM-YYYY, HH:mm:ss')}
                                    </Col>
                                  </Row>
                                </Col>
                              </Tooltip>
                            }
                          >
                            <Button
                              variant='link'
                              className='dangerAnimation border-0 py-0 px-1 text-decoration-none text-danger'
                            >
                              <FiAlertTriangle className='d-flex' fontSize='1em' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {t('batIn')}:
                      </div>
                    </Card.Title>
                    <Card.Text className='my-0'>{idri.bat_int_value} v</Card.Text>
                  </Card>
                </Col>
              )}
              {idri.bat_ext_value && (
                <Col xs={6} className='text-center'>
                  {/* <Card border='primary' style={{ height: '100%' }}> */}
                  <Card style={{ height: '100%' }}>
                    <Card.Title className='my-0 '>
                      <div className=''>
                        {parseFloat(idri.ghatorv) >= 3 && idri.bat_ext_alert && (
                          <OverlayTrigger
                            // placement={'right'}
                            overlay={
                              <Tooltip id='alertTooltip'>
                                <Col className='d-flex pe-3 '>
                                  <Button
                                    variant='link'
                                    className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                  ></Button>
                                  <Row>
                                    <Col
                                      xs={12}
                                      className='d-flex justify-content-center align-items-center'
                                    >
                                      <FiAlertTriangle
                                        className='dangerAnimation text-decoration-none text-danger me-1'
                                        fontSize='1em'
                                      />
                                      {t('alertaDisparada')}
                                    </Col>
                                    <Col xs={12}>
                                      {idri.bat_ext_alert_start_date &&
                                        t('startDate') +
                                          ': ' +
                                          moment
                                            .utc(idri.bat_ext_alert_start_date)
                                            .local()
                                            .format('DD-MM-YYYY, HH:mm:ss')}
                                    </Col>
                                  </Row>
                                </Col>
                              </Tooltip>
                            }
                          >
                            <Button
                              variant='link'
                              className='dangerAnimation border-0 py-0 px-1 text-decoration-none text-danger'
                            >
                              <FiAlertTriangle className='d-flex' fontSize='1em' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {t('batExt')}:
                      </div>
                    </Card.Title>
                    <Card.Text className='my-0'>{idri.bat_ext_value} v</Card.Text>
                  </Card>
                </Col>
              )}
              {idri.sys_temp && (
                <Col xs={6} className='text-center'>
                  {/* <Card border='primary' style={{ height: '100%' }}> */}
                  <Card style={{ height: '100%' }}>
                    <Card.Title className='my-0 '>
                      <div className=''>{t('tempSistem')}:</div>
                    </Card.Title>
                    <Card.Text className='my-0'>{idri.sys_temp} &deg;C</Card.Text>
                  </Card>
                </Col>
              )}

              {idri.sensors &&
                idri.sensors.none_fm_sensors.map((sensor, i) => (
                  <Col xs={6} className='text-center ' key={sensor.idsensor}>
                    {/* <Card
                      border='primary'
                      // bg={Boolean(i % 2) ? 'light' : 'dark'}
                      // isOdd={Boolean(i % 2)}
                      // className='strip'
                      style={{
                        height: '100%',
                        // background: `${i % 2 ? 'white' : 'gray'}`,
                      }}
                    > */}
                    <Card style={{ height: '100%' }}>
                      <Card.Title className='my-0 '>
                        <div className=''>
                          {sensor.name}
                          {parseFloat(idri.ghatorv) >= 3 &&
                            sensor.alerts.state_value_alert &&
                            sensor.alerts_config.state_value_alert_en && (
                              <OverlayTrigger
                                // placement={'right'}
                                overlay={
                                  <Tooltip id='alertTooltip'>
                                    <Col className='d-flex pe-3 '>
                                      <Button
                                        variant='link'
                                        className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                      >
                                        <FiAlertTriangle className='d-flex' fontSize='1em' />
                                      </Button>
                                      {sensor.kind_sensor.code === 1
                                        ? t('umbralMaxMin').toLowerCase()
                                        : t('activEstado').toLowerCase()}
                                    </Col>
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant='link'
                                  className='dangerAnimation border-0 py-0 px-1 text-decoration-none text-danger'
                                >
                                  <FiAlertTriangle className='d-flex' fontSize='1em' />
                                </Button>
                              </OverlayTrigger>
                            )}
                        </div>
                      </Card.Title>
                      {(sensor.status === true || sensor.status === false) && (
                        <Card.Text className='my-0'>
                          {sensor.status ? t('activado') : t('desactivado')}
                        </Card.Text>
                      )}
                      <Card.Text className='my-0'>
                        {sensor.kind_sensor.code !== 0 && sensor.kind_sensor.code !== 4
                          ? sensor.value !== null
                            ? sensor.value
                            : '0.0'
                          : ''}{' '}
                        {sensor.magnitude}
                      </Card.Text>
                    </Card>
                  </Col>
                ))}
            </Row>
          </td>
        </tr>
        <tr className='border-0'>
          <td className='border-0 p-0'>
            <hr className='hr-dark m-1' />
          </td>
        </tr>
        <tr className='border-0'>
          <td className='border-0 p-0'>
            <Row className='gx-0'>
              {idri.sensors.fm_sensors.map((sensor) => (
                <Col xs={6} className='text-center' key={sensor.idsensor}>
                  {/* <Card border='primary' style={{ height: '100%' }}> */}
                  <Card style={{ height: '100%' }}>
                    <Card.Title className='my-0 '>
                      <div className=''>
                        {sensor.name}
                        {parseFloat(idri.ghatorv) >= 3 &&
                          ((sensor.alerts.leak_alert && sensor.alerts_config.leak_en) ||
                            (sensor.alerts.noflow_alert && sensor.alerts_config.noflow_en) ||
                            (sensor.alerts.cmax_day_alert && sensor.alerts_config.cmax_day_en) ||
                            (sensor.alerts.cmin_day_alert && sensor.alerts_config.cmin_day_en) ||
                            (sensor.alerts.flow_alert && sensor.alerts_config.flow_value_en) ||
                            (sensor.alerts.cmax_month_alert &&
                              sensor.alerts_config.cmax_month_en)) && (
                            <OverlayTrigger
                              // placement={'right'}
                              overlay={
                                <Tooltip id='alertTooltip'>
                                  {sensor.alerts.leak_alert && sensor.alerts_config.leak_en && (
                                    <Col className='d-flex pe-3 '>
                                      <Button
                                        variant='link'
                                        className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                      >
                                        <FiAlertTriangle className='d-flex' fontSize='1em' />
                                      </Button>
                                      {t('alertFuga')}
                                    </Col>
                                  )}
                                  {sensor.alerts.noflow_alert && sensor.alerts_config.noflow_en && (
                                    <Col className='d-flex pe-3 '>
                                      <Button
                                        variant='link'
                                        className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                      >
                                        <FiAlertTriangle className='d-flex' fontSize='1em' />
                                      </Button>
                                      {t('noFlujo')}
                                    </Col>
                                  )}
                                  {sensor.alerts.cmax_day_alert &&
                                    sensor.alerts_config.cmax_day_en && (
                                      <Col className='d-flex pe-3 '>
                                        <Button
                                          variant='link'
                                          className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                        >
                                          <FiAlertTriangle className='d-flex' fontSize='1em' />
                                        </Button>
                                        {t('consumoMaxD')}
                                      </Col>
                                    )}
                                  {sensor.alerts.cmin_day_alert &&
                                    sensor.alerts_config.cmin_day_en && (
                                      <Col className='d-flex pe-3 '>
                                        <Button
                                          variant='link'
                                          className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                        >
                                          <FiAlertTriangle className='d-flex' fontSize='1em' />
                                        </Button>
                                        {t('consumoMinD')}
                                      </Col>
                                    )}
                                  {sensor.alerts.flow_alert &&
                                    sensor.alerts_config.flow_value_en && (
                                      <Col className='d-flex pe-3 '>
                                        <Button
                                          variant='link'
                                          className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                        >
                                          <FiAlertTriangle className='d-flex' fontSize='1em' />
                                        </Button>
                                        {t('alertFlujo')}
                                      </Col>
                                    )}
                                  {sensor.alerts.cmax_month_alert &&
                                    sensor.alerts_config.cmax_month_en && (
                                      <Col className='d-flex pe-3 '>
                                        <Button
                                          variant='link'
                                          className='dangerAnimation border-0 py-0 pe-2 text-decoration-none text-danger'
                                        >
                                          <FiAlertTriangle className='d-flex' fontSize='1em' />
                                        </Button>
                                        {t('consumoMaxM')}
                                      </Col>
                                    )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant='link'
                                className='dangerAnimation border-0 py-0 px-1 text-decoration-none text-danger'
                              >
                                <FiAlertTriangle className='d-flex' fontSize='1em' />
                              </Button>
                            </OverlayTrigger>
                          )}
                      </div>
                    </Card.Title>
                    <p className='my-0'>
                      {parseFloat(sensor.value).toFixed(scaleDecimal(sensor.scale))}{' '}
                      {sensor.magnitude}
                    </p>
                    {parseFloat(idri.ghatorv) >= 3 &&
                    <p className='my-0'>
                      {sensor.inst_flow !== null ? sensor.inst_flow : '0.00'} {sensor.magnitude}/h
                    </p>
                    }
                    <p className='my-0'>
                      {t('hoy')}:{' '}
                      {sensor.dayconsum && sensor.dayconsum !== null
                        ? parseFloat(sensor.dayconsum).toFixed(scaleDecimal(sensor.scale))
                        : '0.00'}{' '}
                      {sensor.magnitude}
                    </p>
                    <p className='my-0'>
                      {t('mes')}:{' '}
                      {sensor.monthconsum && sensor.monthconsum !== null
                        ? parseFloat(sensor.monthconsum).toFixed(scaleDecimal(sensor.scale))
                        : '0.00'}{' '}
                      {sensor.magnitude}
                    </p>
                  </Card>
                </Col>
              ))}
            </Row>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SensorsControl;
