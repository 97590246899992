const scrollToElement = (id, go, to, ms) => {
  go &&
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: to,
        });
      }
    }, ms);
};

export default scrollToElement;
