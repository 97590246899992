import isNumeric from "./isNumeric";

const isValidNif = (value) => {

    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var cifRexp = /^[A-HJ-NP-SU-W]{1}[0-9]{7}[A-Z0-9]{1}$/i;
    var str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str) && !cifRexp.test(str)) {return false}

    if (nifRexp.test(str) || nieRexp.test(str)) {
        var nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;

        if (validChars.charAt(charIndex) === letter) return true;

        

    } else if (cifRexp.test(str)) {

        //Quitamos el primer caracter y el ultimo digito
        var valueCif=str.substr(1,str.length-2);        

        var suma=0; 
        //Sumamos las cifras pares de la cadena
        for(var i=1;i<valueCif.length;i=i+2) {
            suma=suma+parseInt(valueCif.substr(i,1));
        }        

        var suma2=0;
    
        //Sumamos las cifras impares de la cadena
        for(i=0;i<valueCif.length;i=i+2) {
            var result=parseInt(valueCif.substr(i,1))*2;
            // eslint-disable-next-line
            if(String(result).length==1) {
                // Un solo caracter
                suma2=suma2+parseInt(result);
            } else {
                // Dos caracteres. Los sumamos...
                suma2=suma2+parseInt(String(result).substr(0,1))+parseInt(String(result).substr(1,1));
            }
        }        

        // Sumamos las dos sumas que hemos realizado
        suma=suma+suma2;
        var unidad = String(suma).substr(String(suma).length - 1, 1);
    
        unidad=10-parseInt(unidad);
    
        var primerCaracter=str.substr(0,1).toUpperCase();

        var lastchar=str.substr(str.length-1,1);
        var lastcharchar=lastchar;
        if(isNumeric(lastchar)) {
        
            lastcharchar=String.fromCharCode(64+parseInt(lastchar));

        }
        
        if(primerCaracter.match(/^[FJKNPQRSUVW]$/)) {
        
            //Empieza por .... Comparamos la ultima letra
            // eslint-disable-next-line
            if(String.fromCharCode(64+unidad).toUpperCase()==lastcharchar) {
                return true;
            }

        } else if(primerCaracter.match(/^[ABCDEFGHLM]$/)) {

            //Se revisa que el ultimo valor coincida con el calculo
            // eslint-disable-next-line
            if(unidad==10) { unidad=0; }
            // eslint-disable-next-line    
            if(str.substr(str.length-1,1)==String(unidad)) { return true; }
        } 

    }

    return false;
    
}

export default isValidNif;