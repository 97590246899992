const checkScale = (obj, id, actuator) => {
  let sensors = [];
  Object.values(obj.sensors.fm_sensors).forEach((s) => sensors.push(s));
  Object.values(obj.sensors.none_fm_sensors).forEach((s) => sensors.push(s));

  if (actuator) {
    let idsensor = obj.actuators.find((a) => a.idactuator === id)?.fluid?.related_fm?.idsensor;
    if (!idsensor) {
      return;
    }
    return sensors
      .map((sensor) => {
        if (sensor.idsensor === idsensor) {
          return sensor.scale;
        }
      })
      .find((value) => {
        return value;
      });
  }

  return sensors
    .map((sensor) => {
      if (sensor.idsensor === id) {
        return sensor.scale;
      }
    })
    .find((value) => {
      return value;
    });
};

export default checkScale;
