import { useNavigate } from 'react-router-dom';
import { Col, Container, Image, Nav, Navbar as Navigation, Row } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import variables from '../../scss/variables.scss';

const Navbar = ({ isLoggedIn }) => {
  const { t, i18n } = useTranslation();
  const go_to = useNavigate();
  const [expanded, setExpanded] = useState(false);

  //user login checking
  const handleAcceso = () => {
    if (isLoggedIn) {
      go_to('/inicio');
    }
  };

  //language change
  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  };

  //scroll navbar style change
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    document.addEventListener('scroll', () => {
      setScroll(window.scrollY > 100);
    });
  });

  return (
    <Navigation
      className={`fixed-top ${scroll && 'navbar-shrink'} `}
      expanded={expanded}
      expand='lg'
      id='mainNav'
    >
      <Container>
        <Navigation.Brand>
          <Link
            id='page-top'
            to='page-top'
            smooth='easeInQuad'
            offset={-70}
            duration={700}
            className='p-0 m-o text-decoration-none'
            onClick={() => setExpanded(false)}
          >
            <Image
              src={variables.logo_v1_1 && variables.logo_v1_1.slice(1, -1)}
              alt='brand logo'
              width='60'
            ></Image>
          </Link>
        </Navigation.Brand>

        <Navigation.Toggle aria-controls='navbarResponsive' onClick={() => setExpanded(!expanded)}>
          {t('menu')}
          <FontAwesomeIcon className='mx-1' icon={faBars} />
          <i className='fas fa-bars'></i>
        </Navigation.Toggle>
        <Navigation.Collapse id='navbarResponsive'>
          <Nav className='text-uppercase mx-auto'>
            {/* <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='red'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => setExpanded(false)}
              >
                {t('instaladores')}
              </Link>
            </Nav.Link> */}
            {/* <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='quees'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => setExpanded(false)}
              >
                {t('queEs')}
              </Link>
            </Nav.Link> */}
            {/* <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='portfolio'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => setExpanded(false)}
              >
                {t('ejemplos')}
              </Link>
            </Nav.Link> */}
            <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='acceso'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => {
                  setExpanded(false);
                  handleAcceso();
                }}
              >
                {t('acceso')}
              </Link>
            </Nav.Link>
            {/* <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='ayuda'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => setExpanded(false)}
              >
                {t('ayuda')}
              </Link>
            </Nav.Link> */}
            <Nav.Link as='li' className='nav-item'>
              <Link
                activeClass='active'
                to='contact'
                spy={true}
                smooth='easeInQuad'
                offset={-70}
                duration={700}
                className='nav-link'
                onClick={() => setExpanded(false)}
              >
                {t('contacto')}
              </Link>
            </Nav.Link>
          </Nav>
          <Nav>
            <Row className='text-center'>
              <Col xs={4} lg={4}>
                <Flags.ES className='langBtn' onClick={() => changeLanguage('es')} />
              </Col>
              <Col xs={4} lg={4}>
                <Flags.GB className='langBtn' onClick={() => changeLanguage('en')} />
              </Col>
              <Col xs={4} lg={4}>
                <Flags.FR className='langBtn' onClick={() => changeLanguage('fr')} />
              </Col>
            </Row>
          </Nav>
        </Navigation.Collapse>
      </Container>
    </Navigation>
  );
};

export default Navbar;
