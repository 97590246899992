export const USER = 'USER';

export const IDRI_INIT_DATA = 'IDRI_INIT_DATA';
export const IDRI_DATA = 'IDRI_DATA';

export const ADD_SENSOR = 'ADD_SENSOR';
export const DEL_SENSOR = 'DEL_SENSOR';

export const ADD_ACTUATOR = 'ADD_ACTUATOR';
export const DEL_ACTUATOR = 'DEL_ACTUATOR';

export const IDRI = 'IDRI';

export const MARKERS = 'MARKERS';

export const CHANGED = 'CHANGED';

export const ASYNC_CONTROL = 'ASYNC_CONTROL';

export const ASYNC_COUNTER = 'ASYNC_COUNTER';
