import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalGenerico from '../../home/Modal_generico';
import MapSrt from './MapSrt';
import Calibration from './Calibration';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';
import { BiLink } from 'react-icons/bi';
import { BsSpeedometer } from 'react-icons/bs';
import scrollToElement from '../../../support_func/scrollToElement';

const EditModal = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [idriInfo, setIdriInfo] = useState({});
  const [coord, setCoord] = useState({ lat: '--', lng: '--' });
  const [mapModal, setMapModal] = useState(false);
  const [calibration, setCalibration] = useState({ factor: '--', correction: '--', index: null, sensor: null });
  const [calibrationModal, setCalibrationModal] = useState(false);
  const [linked, setLinked] = useState([]);
  const [slaves, setSlaves] = useState([]);

  // console.log(idriInfo);
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idri = useSelector((state) => state.idri);

  //get device data
  const getInfo = () => {
    setIsPending(true);
    setMsg(null);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghatorinf?ghatorid=${
          idriInitData.ghatorid ? idriInitData.ghatorid : props.id
        }&ghatorserial=${idriInitData.ghatorserial ? idriInitData.ghatorserial : props.serial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setIdriInfo(res.data.ghatorinf);
          setIdriInfo({
            ...res.data.ghatorinf,
            ghatorid: parseInt(res.data.ghatorinf.ghatorid),
            ghatorcoor: [
              parseFloat(res.data.ghatorinf.ghatorcoor[0]),
              parseFloat(res.data.ghatorinf.ghatorcoor[1]),
            ],
          });
          setIsPending(false);
          res.data.ghatorinf.user_permissions.edit !== true && window.alert(t(`error12`));
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //send changed data to server
  const postInfo = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idriInfo.ghatorid,
      ghatorserial: idriInfo.ghatorserial,
      ghatorname: idriInfo.ghatorname,
      ghatorcoor: [parseFloat(idriInfo.ghatorcoor[0]), parseFloat(idriInfo.ghatorcoor[1])],
      sensors: idriInfo.sensors.map((sensor) => ({
        idsensor: sensor.idsensor,
        name: sensor.name,
        brand: sensor.brand,
        model: sensor.model,
        main: sensor.main,
        customcode: sensor.main && sensor.customcode ? sensor.customcode : '',
        factor: sensor.factor,
        correction: sensor.correction,
      })),
      actuators: idriInfo.actuators.map((actuator) => ({
        idactuator: actuator.idactuator,
        name: actuator.name,
        brand: actuator.brand,
        model: actuator.model,
      })),
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/ghatorinf`, body, config)
      .then(async (res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // idri.user_permissions.action && props.refresh();
          setIsPending(false);
          await setError(t('enviadoExito'));
          props.setEdited(false);
          props.getIdri();
          getInfo();
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  //update an sensor
  const updateSensor = (index, sensor, obj) => {
    let newArr = [...idriInfo.sensors];
    newArr[index] = { ...sensor, [Object.keys(obj)]: Object.values(obj)[0] };
    setIdriInfo({ ...idriInfo, sensors: newArr });
    props.setEdited(true);
  };
  //update an actuator
  const updateActuator = (index, actuator, obj) => {
    let newArr = [...idriInfo.actuators];
    newArr[index] = { ...actuator, [Object.keys(obj)]: Object.values(obj)[0] };
    setIdriInfo({ ...idriInfo, actuators: newArr });
    props.setEdited(true);
  };
  //update an coordenates
  useEffect(() => {
    setIdriInfo({
      ...idriInfo,
      ghatorcoor: [parseFloat(coord.lat).toFixed(5), parseFloat(coord.lng).toFixed(5)],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coord]);

  useEffect(() => {
    if (calibration.index && calibration.sensor) {
      let newArr = [...idriInfo.sensors];
      newArr[calibration.index] = { ...calibration.sensor, factor: calibration.factor, correction: calibration.correction };
      setIdriInfo({ ...idriInfo, sensors: newArr });
    }
  }, [calibration]);

  //first page load get instalation data
  useEffect(() => {
    getInfo();

    let related = [];
    idri.sensors.fm_sensors.forEach((s) => {
      s.related_actuators.forEach((a) =>
        related.push({ [a.idactuator]: { idsensor: s.idsensor, ninput: s.ninput, name: s.name } })
      );
    });
    setLinked(related.flat());

    let masterSlaves = [];
    idri.actuators.forEach((a) => {
      a.slaves.forEach((s) =>
        masterSlaves.push({ [s]: { idactuator: a.idactuator, noutput: a.noutput, name: a.name } })
      );
    });
    setSlaves(masterSlaves.flat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //auto hide error message
  // if (error || msg) {
  //   scrollToElement('errorMsg', true, 'center', 300);
  //   setTimeout(() => {
  //     setError(null);
  //     setMsg(null);
  //   }, 10000);
  // }
  useEffect(() => {
    msg !== null && window.alert(msg);
    error !== null && window.alert(error);
    setMsg(null);
    setError(null);
  }, [error, msg]);

  return (
    <div>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>
            <span>{t('connect')}...</span>
          </div>
        </div>
      ) : (
        <>
          {idriInfo && (
            <Form
              // className='text-start'
              onSubmit={(e) => postInfo(e)}
              className={`text-start lh-1 ${
                idriInfo.user_permissions && idriInfo.user_permissions.edit !== true
                  ? 'disabledCustom'
                  : null
              }`}
            >
              <strong>{t('nombreCampo')}</strong>
              <Form.Control
                type='text'
                maxLength='30'
                value={idriInfo.ghatorname}
                onChange={(e) => setIdriInfo({ ...idriInfo, ghatorname: e.target.value })}
              />
              <Row className='my-2 text-md-start text-center align-items-center'>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={6}>
                      {t('latitud')}:{' '}
                      {idriInfo.ghatorcoor && Number(idriInfo.ghatorcoor[0]).toFixed(5)}
                    </Col>
                    <Col xs={6}>
                      {t('longitud')}:{' '}
                      {idriInfo.ghatorcoor && Number(idriInfo.ghatorcoor[1]).toFixed(5)}
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={6} className='px-4'>
                  <Row>
                    <Button
                      type='button'
                      className='btn-sm dark'
                      data-toggle='modal'
                      onClick={() => setMapModal(true)}
                    >
                      {t('ubicacion')}
                    </Button>
                  </Row>
                </Col>

                <ModalGenerico
                  className='bg-secondary'
                  title={t('pulsaParaCoords')}
                  onClose={() => setMapModal(false)}
                  show={mapModal}
                >
                  <MapSrt
                    coords={idriInfo.ghatorcoor}
                    setCoord={setCoord}
                    setMapModal={setMapModal}
                    setEdited={props.setEdited}
                    // closeModal={() => setMapModal(false)}
                  />
                </ModalGenerico>
              </Row>
              <h4 className='my-2fw-bold text-md-start text-center'>
                <u>{t('sensCont')}</u>
              </h4>
              {idriInfo.sensors &&
                idriInfo.sensors.map((sensor, i) => (
                  <Card key={i} className='p-1'>
                    <Card.Title className='mb-0 mt-1 colorGreen'>
                      {t('entrada')} #{sensor.ninput}
                      {sensor.kind_sensor.code === 0
                        ? ' - ' + t('digital')
                        : sensor.kind_sensor.code === 1
                        ? ' - ' + t('tension')
                        : sensor.kind_sensor.code === 2
                        ? ' - ' + t('corriente')
                        : sensor.kind_sensor.code === 3
                        ? ' - ' + t('contador')
                        : sensor.kind_sensor.code === 4
                        ? ' - ' + t('seguridad')
                        : null
                      }
                      
                    </Card.Title>
                    {sensor.kind_sensor.normal_state !== null && (
                      <Row>
                        <div className='d-flex align-items-center'>
                          <strong className='pe-0'>{t('normalState')}: </strong>
                          {sensor.kind_sensor.normal_state ? (
                            <span className='text-success ps-1'>{t('abierto')}</span>
                          ) : (
                            <span className='text-danger ps-1'>{t('cerrado')}</span>
                          )}
                        </div>
                      </Row>
                    )}

                    <Row>
                      <Col xs={4} className='pe-1'>
                        <strong>{t('nombre').toLowerCase()}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={sensor.name}
                          onChange={(e) => updateSensor(i, sensor, { name: e.target.value })}
                        />
                      </Col>
                      <Col xs={4} className='px-1'>
                        <strong>{t('marca')}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={sensor.brand}
                          onChange={(e) => updateSensor(i, sensor, { brand: e.target.value })}
                        />
                      </Col>
                      <Col xs={4} className='ps-1'>
                        <strong>{t('modelo')}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={sensor.model}
                          onChange={(e) => updateSensor(i, sensor, { model: e.target.value })}
                        />
                      </Col>
                    </Row>
                    {sensor.kind_sensor.code === 3 && (
                      <Row>
                        <Col xs={sensor.main ? 4 : 12} md={4} className='pe-1 text-center'>
                          <strong>{t('highlight')}</strong>
                          <Form.Check
                            className='py-0'
                            type='checkbox'
                            checked={sensor.main}
                            onChange={(e) => updateSensor(i, sensor, { main: e.target.checked })}
                          />
                        </Col>
                        {sensor.main && (
                          <Col xs={sensor.main ? 8 : 12} md={4} className='ps-1 pe-md-1'>
                            <strong>{t('codigoContador')}</strong>
                            <Form.Control
                              required={sensor.main}
                              type='text'
                              value={sensor.customcode}
                              onChange={(e) =>
                                updateSensor(i, sensor, {
                                  customcode: e.target.value,
                                })
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    )}

                    {parseFloat(idriInfo.ghatorv) >= 3 && (sensor.kind_sensor.code === 1 || sensor.kind_sensor.code === 2) && (                      
                      <Row className='my-2 text-md-start text-center align-items-center'>
                        {(sensor.factor && sensor.correction) && (
                          <Col xs={12} md={6}>
                            <Row>
                              <Col xs={6}>
                                {t('factor')}:{' '}
                                {sensor.factor}
                              </Col>
                              <Col xs={6}>
                                {t('correction')}:{' '}
                                {sensor.correction}
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col xs={12} md={6} className='px-4'>
                          <Row>
                            <Button
                              type='button'
                              className='btn-sm dark'
                              data-toggle='modal'
                              onClick={() => {
                                setCalibration({factor: sensor.factor, correction: sensor.correction, index: i, sensor: sensor});
                                setCalibrationModal(true);
                              }}
                            >
                              {t('calibration')}
                            </Button>
                          </Row>
                        </Col>
                        
                      </Row>
                    )}
                  </Card>
              ))}

              <ModalGenerico
                className='bg-secondary'
                title={t('introduceCalib')}
                onClose={() => setCalibrationModal(false)}
                show={calibrationModal}
              >
                <Calibration
                  factor={calibration.factor}
                  correction={calibration.correction}
                  index = {calibration.index}
                  sensor = {calibration.sensor}
                  setCalibration={setCalibration}
                  setCalibrationModal={setCalibrationModal}
                  setEdited={props.setEdited}
                />
              </ModalGenerico>


              <h4 className='my-2fw-bold text-md-start text-center'>
                <u>{t('sectoresAct')}</u>
              </h4>
              {idriInfo.actuators &&
                idriInfo.actuators.map((actuator, i) => (
                  <Card key={i} className='p-1'>
                    <Card.Title className='mb-0 mt-1 colorBlue'>
                      {t('salida')} #{actuator.noutput}
                      {actuator.kind_actuator.related_to
                        ? ' - ' + actuator.kind_actuator.related_to
                        : null}{' '}
                    </Card.Title>
                    <Card.Title className='my-0 colorOrange'>
                      {linked.find((a) => a[actuator.id] !== undefined) && (
                        <>
                          <BsSpeedometer className='m-0 me-1' />
                          {linked.find((a) => a[actuator.id] !== undefined)[actuator.id].name}
                        </>
                      )}
                    </Card.Title>
                    <Card.Title className='my-0 colorDarkOrange'>
                      {slaves.find((s) => s[actuator.id] !== undefined) && (
                        <>
                          <BiLink className='m-0 me-1' />
                          {slaves.find((s) => s[actuator.id] !== undefined)[actuator.id].name}
                        </>
                      )}
                    </Card.Title>
                    <Row>
                      {actuator.kind_actuator.normal_state !== null && (
                        <Col xs={12} md={4}>
                          <div className='d-flex align-items-center'>
                            <strong className='pe-0'>{t('normalState')}: </strong>
                            {actuator.kind_actuator.normal_state ? (
                              <span className='text-success ps-1'>{t('abierto')}</span>
                            ) : (
                              <span className='text-danger ps-1'>{t('cerrado')}</span>
                            )}
                          </div>
                        </Col>
                      )}
                      {actuator.kind_actuator.category !== null && (
                        <Col xs={12} md={4} className='ps-md-1'>
                          <div className='d-flex align-items-center'>
                            <strong className='pe-0'>{t('actType')}: </strong>
                            {actuator.kind_actuator.category &&
                            actuator.kind_actuator.category === 'neutral' ? (
                              <span className='ps-1'>{t('neutro')}</span>
                            ) : (
                              <span className='ps-1'>{t('libre')}</span>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={4} className='pe-1'>
                        <strong>{t('nombre').toLowerCase()}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={actuator.name}
                          onChange={(e) => updateActuator(i, actuator, { name: e.target.value })}
                        />
                      </Col>
                      <Col xs={4} className='px-1'>
                        <strong>{t('marca')}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={actuator.brand}
                          onChange={(e) => updateActuator(i, actuator, { brand: e.target.value })}
                        />
                      </Col>
                      <Col xs={4} className='ps-1'>
                        <strong>{t('modelo')}</strong>
                        <Form.Control
                          type='text'
                          maxLength='18'
                          value={actuator.model}
                          onChange={(e) => updateActuator(i, actuator, { model: e.target.value })}
                        />
                      </Col>
                    </Row>
                  </Card>
                ))}
              <Row className='text-center mt-3' id='errorMsg'>
                {/* <Col xs={12}>
                  {error ? (
                    <Col className='text-center alert alert-danger'>{error}</Col>
                  ) : (
                    msg && (
                      <Col className='text-center alert alert-success bg-transparent border-white'>
                        {msg}
                      </Col>
                    )
                  )}
                </Col> */}
                <Col>
                  <Button className='btn dark text-uppercase' type='submit'>
                    {t('modificar')}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default EditModal;
