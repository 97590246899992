import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseSelect, { components } from 'react-select';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import floatWithDec from '../../../../support_func/floatWithDec';
import minMax from '../../../../support_func/minMax';
import { useSelector } from 'react-redux';
import checkScale from '../../../../support_func/checkScale';

const Action = (props) => {
  let index = props.index;
  let action = props.action;
  let sensorsList = props.sensorsList;
  let actions = props.actions;
  let setActions = props.setActions;
  let forceUpdate = props.forceUpdate;
  const { t } = useTranslation();

  const actionOption = [
    {
      kind: 0,
      label: t('changeState'),
    },
    {
      kind: 1,
      label: t('active'),
    },
  ];
  const idri = useSelector((state) => state.idri);

  const selectStyles =
    window.innerWidth < 577
      ? {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          valueContainer: (base, state) => ({
            ...base,
            padding: '0',
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            padding: '0',
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
        }
      : {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
        };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  const Option = (props) => {
    return (
      <>
        <components.Option className={props.isDisabled && 'text-danger'} {...props} />
        {props.isDisabled && (
          <span className='text-danger dangerAnimation m-0 p-0'>{t('autoOC')}</span>
        )}
      </>
    );
  };

  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, []);

  // console.log(sensorsList);
  return (
    <Card className='p-0 mb-1' border='danger'>
      <Row className='text-center mx-0'>
        <Col xs={12} md={4} className='p-0'>
          <Select
            required
            menuShouldScrollIntoView
            placeholder={t('selectActuator')}
            // isClearable
            isSearchable={true}
            components={{ Option }}
            options={sensorsList.filter(
              (s) =>
                (s.kind === 8 || s.kind === 4) &&
                !Object.values(actions)
                  .map((i) => i?.actuator?.id)
                  .includes(s.id)
            )}
            isOptionDisabled={(option) => option.disabled}
            value={action.actuator ? action.actuator : ''}
            styles={selectStyles}
            onChange={(e) =>
              setActions({
                ...actions,
                [index]: { ...actions[index], actuator: e, disconnection: '' },
              })
            }
          />
        </Col>
        <Col xs={12} md={4} className='p-0'>
          <Select
            required
            menuShouldScrollIntoView
            placeholder={t('action')}
            // isClearable
            isSearchable={true}
            options={[
              {
                kind: 0,
                label: t('deactivate'),
              },
              {
                kind: 1,
                label: t('activate'),
              },
            ]}
            value={action.action ? action.action : ''}
            styles={selectStyles}
            onChange={(e) =>
              setActions({
                ...actions,
                [index]: {
                  ...actions[index],
                  action: e,
                  disconnection:
                    action.actuator.kind === 4 ||
                    (action.actuator.kind === 8 &&
                      action.actuator.state === false &&
                      e.kind === 0) ||
                    (action.actuator.kind === 8 && action.actuator.state === true && e.kind === 0)
                      ? actionOption.find((x) => x.kind === 1)
                      : '',
                  duration: '',
                  volume: '',
                  flow: '',
                },
              })
            }
          />
        </Col>
        <Col xs={12} md={4} className='p-0'>
          <Select
            required
            menuShouldScrollIntoView
            isDisabled={!action.action}
            // placeholder={t('deactControl')}
            placeholder={<span className='text-truncate'>{t('deactControl')}</span>}
            // isClearable
            isSearchable={true}
            options={
              action.actuator &&
              action.action &&
              actionOption.filter((o) =>
                (action.actuator.kind === 4 &&
                  action.actuator.state === true &&
                  action.action.kind === 0) ||
                (action.actuator.kind === 4 &&
                  action.actuator.state === false &&
                  action.action.kind === 0) ||
                (action.actuator.kind === 8 &&
                  action.actuator.state === false &&
                  action.action.kind === 0) ||
                (action.actuator.kind === 8 &&
                  action.actuator.state === true &&
                  action.action.kind === 0)
                  ? o.kind === 1
                  : o.kind !== -1
              )
            }
            // options={actionOption}
            value={action.disconnection ? action.disconnection : ''}
            styles={selectStyles}
            onChange={(e) =>
              setActions({
                ...actions,
                [index]: {
                  ...actions[index],
                  disconnection: e,
                  duration: '',
                  volume: '',
                  flow: '',
                },
              })
            }
          />
        </Col>
      </Row>
      {action.disconnection && action.disconnection.kind === 0 && (
        <Row className='mt-1 mx-0 align-items-end'>
          <Col xs={4} className='p-0'>
            <span className=''>{t('deactTime')} (s)</span>
            <Form.Control
              className='text-center'
              required={!action.duration && !action.volume && !action.flow}
              value={action.duration ? action.duration : ''}
              type='number'
              min={idri.master_en ? action.actuator.gap ?? 0 : 0}
              onChange={(e) =>
                setActions({
                  ...actions,
                  [index]: {
                    ...actions[index],
                    duration: floatWithDec(minMax(e.target.value, 0)),
                  },
                })
              }
              onBlur={(e) =>
                idri.master_en &&
                action.duration < action.actuator.gap &&
                setActions({
                  ...actions,
                  [index]: {
                    ...actions[index],
                    duration: minMax(e.target.value, action.actuator.gap ?? 0),
                  },
                })
              }
            />
          </Col>
          <Col xs={4} className='p-0'>
            <span className=''>{t('deactVolume')}</span>
            <Form.Control
              className='text-center'
              disabled={
                (action.actuator.kind === 8 &&
                  action.actuator.state === true &&
                  action.action.kind === 1) ||
                action.actuator.kind === 4
              }
              required={!action.duration && !action.volume && !action.flow}
              value={action.volume ? action.volume : ''}
              type='number'
              min={0}
              step={0.001}
              onChange={(e) =>
                setActions({
                  ...actions,
                  [index]: {
                    ...actions[index],
                    volume: floatWithDec(
                      e.target.value,
                      checkScale(idri, action.actuator.id, true)
                    ),
                  },
                })
              }
            />
          </Col>
          <Col xs={4} className='p-0'>
            <span className=''>{t('deactFlow')}</span>
            <Form.Control
              className='text-center'
              disabled={
                (action.actuator.kind === 8 &&
                  action.actuator.state === true &&
                  action.action.kind === 1) ||
                action.actuator.kind === 4
              }
              required={!action.duration && !action.volume && !action.flow}
              value={action.flow ? action.flow : ''}
              type='number'
              min={0}
              step={0.001}
              onChange={(e) =>
                setActions({
                  ...actions,
                  [index]: {
                    ...actions[index],
                    flow: floatWithDec(e.target.value, checkScale(idri, action.actuator.id, true)),
                  },
                })
              }
            />
          </Col>
        </Row>
      )}
      {parseInt(index) >= 1 && (
        <Col className='my-1'>
          <Button
            variant='outline-danger'
            size='sm'
            className='px-1 py-0 ruleBtn'
            onClick={() => {
              delete actions[index];
              forceUpdate((n) => !n);
            }}
          >
            {t('actionDelete')}
          </Button>
        </Col>
      )}
    </Card>
  );
};

export default Action;
