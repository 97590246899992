/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import Select from 'react-select';
// import isValidIban from '../../../../support_func/isValidIban';
import isValidNif from '../../../../support_func/isValidNif';
import ModalGenerico from '../../../home/Modal_generico';
import NewUser from '../../modals/NewUser';
import IdrisMap from '../idris/IdrisMap';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import IdriManage from '../idris/IdriManage';
import Bank from '../../modals/Bank';
// import ChangeEmail from '../../modals/ChangeEmail';
import Invocing from '../../modals/Invocing';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';
import selectStyles from '../../../../support_func/selectStyles';

const UsersManagement = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});
  const [userBank, setUserBank] = useState();
  // const [newUserBank, setNewUserBank] = useState(false);
  // const [userEmployer, setUserEmployer] = useState();
  const [usersList, setUsersList] = useState();
  // const [entitiesList, setEntitiesList] = useState();
  // const [selectedEntity, setSelectedEntity] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [profilesList, setProfilesList] = useState();
  const [selectedProfile, setSelectedProfile] = useState();
  const [adsressRequired, setAdsressRequired] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [newUserEmail, setNewUserEmail] = useState();
  const [idriManage, setIdriManage] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [invoicingModal, setInvoicingModal] = useState(false);
  const [toMap, setToMap] = useState(false);

  const profiles = props.profiles.profiles;
  // const permissionsOnUser = props.profiles.permissionsonuser;

  // const [page, setPage] = useState('map');
  // const [data, setData] = useState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getUsers = (email, order, obj) => {
    setIsPending(true);
    // !isPendingError &&
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/users`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(res.data);
          let uList = [];
          res.data.users.map((user) => {
            uList.push({
              iduser: user.iduser,
              email: user.email,
              label:
                (user.name && user.lastname ? user.lastname + ' ' + user.name + ' - ' : '') +
                (user.email ? user.email : '') +
                (user.nif ? ' - ' + user.nif : ''),
            });
          });
          setUsersList(uList);
          // let eList = [];
          // res.data.entities.map((entity) => {
          //   eList.push({
          //     id: entity.id,
          //     label:
          //       (entity.name ? entity.name : '') +
          //       (entity.taxid ? ' - ' + entity.taxid : ''),
          //   });
          // });
          // setEntitiesList(eList);
          email &&
            res.data.users.forEach((user) => {
              user.email === email && getUser(user.iduser, user.email, true);
            });
          setIsPending(false);
          // order && getUser(obj.iduser, obj.email);
          order && setSelectedUser(obj);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getUser = (id, email, newUser) => {
    setIsPending(true);
    // setSelectedEntity('');
    // !isPendingError &&
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/user?iduser=${id}&email=${email}`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setUser(res.data.user);
          newUser &&
            setSelectedUser({
              iduser: res.data.user.data.iduser,
              email: res.data.user.data.email,
              label:
                (res.data.user.data.name && res.data.user.data.lastname
                  ? res.data.user.data.lastname + ' ' + res.data.user.data.name + ' - '
                  : '') +
                (res.data.user.data.email ? res.data.user.data.email : '') +
                (res.data.user.data.nif ? ' - ' + res.data.user.data.nif : ''),
            });

          // Object.values(entitiesList).forEach((e) => {
          //   if (
          //     res.data.user.data.employer_id &&
          //     e.id === res.data.user.data.employer_id
          //   ) {
          //     setSelectedEntity({
          //       id: e.id,
          //       label: e.label,
          //     });
          //   }
          // });
          // Object.values(entitiesList).forEach((e) => {
          //   if (
          //     !res.data.user.data.employer_id &&
          //     res.data.user.profiles.comm &&
          //     e.id === res.data.user.profiles.comm.entityid
          //   ) {
          //     setSelectedEntity({
          //       id: e.id,
          //       label: e.label,
          //     });
          //   }
          // });

          setUserBank();
          setNewUserEmail();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
        setUser({});
        setUserData({});
        setSelectedUser('');
      });
  };

  const postUser = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ...userData,
      iduser: userData.iduser,
      email: userData.email,
    };
    delete body.id;
    // delete body.action;

    if (
      (body.address && body.zip && body.city && body.province && body.country) ||
      (!body.address && !body.zip && !body.city && !body.province && !body.country)
    ) {
      setAdsressRequired(false);
      if (isValidNif(body.nif)) {
        axios
          .patch(`${process.env.REACT_APP_API_SRT}/v2/user`, body, config)
          .then((res) => {
            // console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.code === '0') {
              setError(t('modificated') + ' ' + t('success'));
              getUsers(null, true, {
                iduser: body.iduser,
                email: body.email,
                label:
                  (body.name && body.lastname ? body.lastname + ' ' + body.name + ' - ' : '') +
                  (body.email ? body.email : '') +
                  (body.nif ? ' - ' + body.nif : ''),
              });
              // getUser(body.iduser);
              // setSelectedUser({
              //   iduser: body.iduser,
              //   label:
              //     (body.name && body.lastname
              //       ? body.lastname + ' ' + body.name + ' - '
              //       : '') +
              //     (body.email ? body.email : '') +
              //     (body.nif ? ' - ' + body.nif : ''),
              // });
              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('problem'));
            }
            setIsPending(false);
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setError(t('nifIncorecto'));
        setIsPending(false);
      }
    } else {
      setAdsressRequired(true);
      setError(t('address'));
      setIsPending(false);
    }
  };

  const delUser = (id, email) => {
    // e.preventDefault();
    setIsPending(true);
    // let body = { iduser: id, email: email };
    // console.log(body);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/deluser?iduser=${id}&email=${email}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          getUsers();
          setUser({});
          setUserData({});
          setSelectedUser('');
          setError(t('user') + ' ' + t('deleted') + ' ' + t('success'));
          // window.location.reload();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const changeEmail = (e) => {
    setIsPending(true);
    e.preventDefault();
    if (!newEmail) {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      let lang = localStorage.getItem('language');
      let host = window.location.host;
      // let noIp = 'http://srtnicole.hopto.org:8000';
      axios
        .get(
          `${process.env.REACT_APP_API_SRT}/v2/useremailchange?iduser=${selectedUser.iduser}&email=${selectedUser.email}&newemail=${newEmail}&language=${lang}&urlemailchange=${host}`,
          config
        )
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            window.alert(t('emailSent'));
            setNewEmail();
            setChangeEmailModal(false);
            setIsPending(false);
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    selectedUser && selectedUser.iduser && getUser(selectedUser.iduser, selectedUser.email);
  }, [selectedUser]);

  useEffect(() => {
    let list = [];
    // console.log(user);
    user &&
      Object.keys(user).length > 0 &&
      Object.entries(user.profiles).map(([profile, nIdris]) => {
        profile !== 'admi' &&
          list.push({
            profile: profile,
            label: profile + ' - idris: ' + nIdris.n_ghators,
          });
      });
    setProfilesList(list);
    let permissions = {};
    if (user && user.profiles && 'gamo' in user.profiles) {
      permissions = user.profiles.gamo;
      delete permissions.n_ghators;
    }
    user &&
      user.data &&
      setUserData({
        ...user.data,
        // isentityemployer: user.data.employer_id ? true : false,
        // iscommmanager: user.profiles.comm ? true : false,
        // ...user.profiles.comm,
        owner_permissions: permissions,
      });
  }, [user]);

  useEffect(() => {
    newUserEmail && !newUserModal && getUsers(newUserEmail);
  }, [newUserEmail, newUserModal]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  useEffect(() => {
    setIdriManage(false);
    toMap && scrollToElement('selectMap', true, 'start', 300);

    setToMap(false);
  }, [props.firstPage]);

  useEffect(() => {
    scrollToElement('selectMap', true, 'start', 300);
  }, [selectedProfile]);

  useEffect(() => {
    textLineAnimation();
  });
  // console.log(user.permissionsonuser);

  return (
    <Container className='text'>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : idriManage ? (
        <IdriManage
          ghator={idriManage}
          // setData={setData}
          // profile={profile}
          profiles={profiles}
          firstPage={props.firstPage}
          setFirstPage={props.setFirstPage}
          setToMap={setToMap}
          // page={page}
          // setPage={setPage}
        />
      ) : (
        usersList && (
          <Row id='userMng'>
            <h5 className='text-center '>{t('userManage')}</h5>
            <Col
              xs={
                Object.keys(userData).length > 0 &&
                user.permissionsonuser &&
                user.permissionsonuser.delete
                  ? 6
                  : 12
              }
              md={
                Object.keys(userData).length > 0 &&
                user.permissionsonuser &&
                user.permissionsonuser.delete
                  ? 3
                  : 6
              }
              className='d-grid mt-2 mt-md-0'
              style={{
                minHeight: 35,
              }}
            >
              <Button
                // variant='primary'
                // size='lg'
                className='py-0 dark'
                onClick={() => setNewUserModal(true)}
              >
                {t('userNew')}
              </Button>
              <ModalGenerico
                title={t('userNew')}
                onClose={() => {
                  setNewUserModal(false);
                  getUsers(newUserEmail);
                }}
                show={newUserModal}
              >
                <NewUser
                  // id={idri.ididri}
                  // serial={idri.idriserial}
                  // entitiesList={entitiesList}
                  setNewUserEmail={setNewUserEmail}
                  closeModal={() => setNewUserModal(false)}
                />
              </ModalGenerico>
            </Col>
            {Object.keys(userData).length > 0 &&
              user.permissionsonuser &&
              user.permissionsonuser.delete && (
                <Col xs={6} md={3} className='d-grid mt-2 mt-md-0'>
                  <Button
                    // variant='primary'
                    // size='lg'
                    id='delUser'
                    className='py-0 dark'
                    onClick={() =>
                      window.confirm(
                        `${t('seguroDelUser')} ${userData.lastname + ' ' + userData.name} ${t(
                          'withId'
                        )}: ${userData.iduser}`
                      )
                        ? delUser(userData.iduser, userData.email)
                        : null
                    }
                  >
                    {`${t('userDel')} ${t('withId')}: ${userData.iduser}`}
                  </Button>
                  <ModalGenerico
                    title={t('userNew')}
                    onClose={() => {
                      setNewUserModal(false);
                      // getUsers(newUserEmail);
                    }}
                    show={newUserModal}
                  >
                    <NewUser
                      // id={idri.ididri}
                      // serial={idri.idriserial}
                      setNewUserEmail={setNewUserEmail}
                      closeModal={() => {
                        // console.log(newUserEmail);
                        // getUsers(newUserEmail);
                        setNewUserModal(false);
                      }}
                    />
                  </ModalGenerico>
                </Col>
              )}
            <Col
              xs={12}
              md={
                Object.keys(userData).length > 0 &&
                user.permissionsonuser &&
                user.permissionsonuser.delete
                  ? 6
                  : 6
              }
            >
              <Select
                menuShouldScrollIntoView
                placeholder={t('userSelect')}
                isSearchable={true}
                options={usersList}
                // defaultValue={defOption}
                value={selectedUser ? selectedUser : ''}
                // defaultInputValue='defaultInputValue'
                menuPortalTarget={document.body}
                className='mt-1'
                id='selectUser'
                styles={selectStyles}
                onChange={(e) => {
                  setUser({});
                  setSelectedProfile('');
                  setUserBank();
                  // setUserEmployer();
                  setSelectedUser(e);
                  // console.log(e);
                }}
              />
            </Col>

            <Col xs={12}>
              {Object.keys(userData).length > 0 && (
                <Accordion
                  alwaysOpen
                  defaultActiveKey={
                    // userBank && userEmployer
                    //   ? ['0', '1', '2']
                    //   : userBank && !userEmployer
                    //   ? ['0', '1']
                    //   : !userBank && userEmployer
                    //   ? ['0', '2']
                    ['0']
                  }
                  id='userData'
                  className='my-2'
                  // onSelect={(e) => console.log(e)}
                >
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>{t('userData')}</Accordion.Header>
                    <Accordion.Body>
                      {user.permissionsonuser && user.permissionsonuser.get_data ? (
                        <>
                          <Form id='userDataForm' onSubmit={(e) => postUser(e)}>
                            <Row className='px-0'>
                              <Col xs={6} md={3}>
                                <strong className=''>id</strong>
                                <Form.Control
                                  disabled
                                  className='pe-none'
                                  value={userData.iduser}
                                />
                              </Col>
                              {'admi' in profiles && (
                                <Col xs={6} md={3}>
                                  <div className='animated text-truncate'>
                                    <strong className=''>{t('createdAt')}</strong>
                                  </div>
                                  <Form.Control
                                    disabled
                                    className='pe-none'
                                    value={
                                      userData.created_at
                                        ? moment(userData.created_at).format('DD-MM-YYYY, HH:mm:ss')
                                        : ''
                                    }
                                  />
                                </Col>
                              )}
                              {'admi' in profiles && (
                                <Col xs={6} md={3}>
                                  <div className='animated text-truncate'>
                                    <strong className=''>{t('activatedAt')}</strong>
                                  </div>
                                  <Form.Control
                                    disabled
                                    className='pe-none'
                                    value={
                                      userData.activated_at
                                        ? moment(userData.activated_at).format(
                                            'DD-MM-YYYY, HH:mm:ss'
                                          )
                                        : t('notAcivated')
                                    }
                                  />
                                </Col>
                              )}
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('lang')}</strong>
                                </div>
                                <Form.Select
                                  value={userData.language ? userData.language : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      language: e.target.value,
                                    })
                                  }
                                >
                                  <option key='' value='' disabled>
                                    {t('selectIdioma')}...
                                  </option>
                                  <option key='es' value='es'>
                                    {t('es')}
                                  </option>
                                  <option key='en' value='en'>
                                    {t('en')}
                                  </option>
                                  <option key='fr' value='fr'>
                                    {t('fr')}
                                  </option>
                                </Form.Select>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('nombre').toLowerCase()}</strong>
                                </div>
                                <Form.Control
                                  type='name'
                                  maxLength={50}
                                  value={userData.name ? userData.name : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      name: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={12} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('lastname')}</strong>
                                </div>
                                <Form.Control
                                  type='surname'
                                  maxLength={100}
                                  value={userData.lastname ? userData.lastname : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      lastname: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={12} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('email')}</strong>
                                </div>
                                <Form.Control
                                  type='email'
                                  disabled
                                  value={userData.email ? userData.email : ''}
                                  // onChange={(e) =>
                                  //   setUserData({ ...userData, email: e.target.value })
                                  // }
                                />
                              </Col>
                              <Col xs={12} md={3} className='d-grid pt-2 align-items-end'>
                                <Button
                                  // variant='primary'
                                  // size='lg'
                                  className='py-md-1 dark'
                                  onClick={() => setChangeEmailModal(true)}
                                >
                                  {t('changeEmail')}
                                </Button>
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('phone')}</strong>
                                </div>
                                <Form.Control
                                  type='phone'
                                  maxLength={30}
                                  value={userData.phone ? userData.phone : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      phone: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('nif')}</strong>
                                </div>
                                <Form.Control
                                  type='text'
                                  value={userData.nif ? userData.nif.toUpperCase() : ''}
                                  onChange={(e) =>
                                    setUserData({ ...userData, nif: e.target.value })
                                  }
                                />
                              </Col>
                              <Col xs={12} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('direccion')}</strong>
                                </div>
                                <Form.Control
                                  required={adsressRequired}
                                  type='text'
                                  maxLength={200}
                                  value={userData.address ? userData.address : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      address: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('city')}</strong>
                                </div>
                                <Form.Control
                                  required={adsressRequired}
                                  type='text'
                                  maxLength={50}
                                  value={userData.city ? userData.city : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      city: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('zip')}</strong>
                                </div>
                                <Form.Control
                                  required={adsressRequired}
                                  type='number'
                                  maxLength={20}
                                  value={userData.zip ? userData.zip : ''}
                                  onChange={(e) =>
                                    setUserData({ ...userData, zip: e.target.value })
                                  }
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('province')}</strong>
                                </div>
                                <Form.Control
                                  required={adsressRequired}
                                  type='text'
                                  maxLength={50}
                                  value={userData.province ? userData.province : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      province: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <div className='animated text-truncate'>
                                  <strong className=''>{t('country')}</strong>
                                </div>
                                <Form.Control
                                  required={adsressRequired}
                                  type='text'
                                  maxLength={50}
                                  value={userData.country ? userData.country : ''}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      country: e.target.value,
                                    })
                                  }
                                />
                              </Col>

                              {(user.permissionsonuser.get_bank ||
                                user.permissionsonuser.get_invoicedata) && <Row className=''></Row>}
                              {user.permissionsonuser && user.permissionsonuser.get_bank && (
                                <Col xs={12} md={3} className='d-grid py-2'>
                                  <Button
                                    // variant='primary'
                                    // size='lg'
                                    className='py-md-1 dark'
                                    onClick={() => setBankModal(true)}
                                  >
                                    {t('bank')}
                                  </Button>
                                </Col>
                              )}
                              {user.permissionsonuser && user.permissionsonuser.get_invoicedata && (
                                <Col xs={12} md={3} className='d-grid py-2 '>
                                  <Button
                                    disabled={!user.permissionsonuser.get_invoicedata}
                                    // variant='primary'
                                    // size='lg'
                                    className='py-md-1 dark'
                                    onClick={() => setInvoicingModal(true)}
                                  >
                                    {t('invoicingData')}
                                  </Button>
                                </Col>
                              )}

                              <Row className='my-2 ps-4 pe-0 '>
                                {'activated' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('activado')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.activated}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          activated: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {'admi' in profiles && 'blocked_use' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('blocked')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.blocked_use}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          blocked_use: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {'admi' in profiles && 'micro2e' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('admin')}</strong>
                                    </div>
                                    <Form.Check
                                      disabled
                                      type='checkbox'
                                      checked={userData.micro2e}
                                      // onChange={(e) =>
                                      //   setUserData({
                                      //     ...userData,
                                      //     micro2e: e.target.checked,
                                      //   })
                                      // }
                                    />
                                  </Col>
                                )}
                                {'advertising' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('advertising')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.advertising}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          advertising: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {'admi' in profiles && 'invoicing' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('invoicing')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.invoicing}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          invoicing: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {'admi' in profiles && 'legal_notice_aprov' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('legal')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.legal_notice_aprov}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          legal_notice_aprov: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}

                                {'admi' in profiles && 'free_account' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('freeAccount')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.free_account}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          free_account: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {'admi' in profiles && 'was_free_account' in userData && (
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('wasFree')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={userData.was_free_account}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          was_free_account: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                )}
                                {/* {'admi' in profiles &&
                                  'free_install_entemployee' in userData && (
                                    <Col
                                      xs={12}
                                      md={4}
                                      className='text-center border rounded-2 px-0 px-md-2'
                                    >
                                      <strong className=''>{t('entEmployee')}</strong>
                                      <Form.Check
                                        type='checkbox'
                                        checked={userData.free_install_entemployee}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userData,
                                            free_install_entemployee:
                                              e.target.checked,
                                          })
                                        }
                                      />
                                    </Col>
                                  )} */}
                              </Row>

                              {!profiles.admi && 'gamo' in user.profiles && (
                                <Row className='ps-4 pe-0'>
                                  <Col xs={12} className='text-center'>
                                    <h5 className='fw-normal'>{t('permissions')}</h5>
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('dataView')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.view
                                          ? userData.owner_permissions.view
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            view: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('edit').toLowerCase()}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.edit
                                          ? userData.owner_permissions.edit
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            edit: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('manual')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.action
                                          ? userData.owner_permissions.action
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            action: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('progs')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.program
                                          ? userData.owner_permissions.program
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            program: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('alerts')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.alerts
                                          ? userData.owner_permissions.alerts
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            alerts: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('rules')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.rules
                                          ? userData.owner_permissions.rules
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            rules: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={2}
                                    className='text-center border rounded-2 px-0 px-md-2'
                                  >
                                    <div className='animated text-truncate'>
                                      <strong className=''>{t('historical')}</strong>
                                    </div>
                                    <Form.Check
                                      type='checkbox'
                                      checked={
                                        userData.owner_permissions &&
                                        userData.owner_permissions.historic
                                          ? userData.owner_permissions.historic
                                          : false
                                      }
                                      onChange={(e) =>
                                        setUserData({
                                          ...userData,
                                          owner_permissions: {
                                            ...userData.owner_permissions,
                                            historic: e.target.checked,
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                </Row>
                              )}

                              {/* <Row className='text-center mt-2'> */}
                              <Col className='text-center mt-2'>
                                <Button
                                  disabled={!user.permissionsonuser.edit_data}
                                  type='submit'
                                  form='userDataForm'
                                  // variant='primary'
                                  // size='lg'
                                  className='py-md-1 dark'
                                  // onClick={(e) => postUser(e)}
                                >
                                  {t('modificar').toUpperCase()} {t('userData').toUpperCase()}
                                </Button>
                              </Col>
                              {/* </Row> */}
                            </Row>
                          </Form>

                          <Modal
                            show={changeEmailModal}
                            onHide={() => {
                              setChangeEmailModal(false);
                              setNewEmail();
                            }}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{t('changeEmail')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form id='emailChangeForm' onSubmit={(e) => changeEmail(e)}>
                                <Row className='px-0'>
                                  <Col xs={12}>
                                    <strong className=''>{t('newEmail')}</strong>
                                    <Form.Control
                                      required
                                      type='email'
                                      value={newEmail ? newEmail : ''}
                                      onChange={(e) => setNewEmail(e.target.value)}
                                    />
                                  </Col>
                                </Row>
                                <Row className='text-center mt-3'>
                                  <Col>
                                    <Button
                                      type='submit'
                                      form='emailChangeForm'
                                      // variant='primary'
                                      // size='lg'
                                      className='py-md-1 dark'
                                    >
                                      {t('change').toUpperCase()}
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </Modal.Body>
                          </Modal>
                          <ModalGenerico
                            title={t('bank')}
                            onClose={() => {
                              setBankModal(false);
                            }}
                            show={bankModal}
                          >
                            <Bank
                              iduser={userData.iduser}
                              email={userData.email}
                              closeModal={() => setBankModal(false)}
                            />
                          </ModalGenerico>
                          <ModalGenerico
                            title={t('factura')}
                            onClose={() => {
                              setInvoicingModal(false);
                            }}
                            show={invoicingModal}
                          >
                            <Invocing
                              iduser={userData.iduser}
                              email={userData.email}
                              editInvoice={user.permissionsonuser.edit_invoicedata}
                              delInvoice={user.permissionsonuser.del_invoicedata}
                              closeModal={() => setInvoicingModal(false)}
                            />
                          </ModalGenerico>
                        </>
                      ) : (
                        <h5>{t('noPermission')}</h5>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </Col>
            {profilesList && profilesList.length > 0 && Object.keys(userData).length > 0 && (
              <>
                {/* <Col xs={12} md={6}></Col> */}
                <Col xs={12} className='text-center'>
                  <strong className=''>{t('gestInstals')}</strong>
                  <Select
                    menuShouldScrollIntoView
                    placeholder={t('userProfile')}
                    isSearchable={false}
                    menuPlacement='top'
                    options={profilesList}
                    // defaultValue={defOption}
                    value={selectedProfile ? selectedProfile : ''}
                    menuPortalTarget={document.body}
                    className='mt-1'
                    id='selectProfile'
                    styles={selectStyles}
                    onChange={(e) => {
                      setSelectedProfile(e);
                    }}
                  />
                </Col>
              </>
            )}
            <Col xs={12}>
              {selectedProfile && (
                <IdrisMap
                  profile={selectedProfile.profile ? selectedProfile.profile : null}
                  mng={true}
                  iduser={user.data.iduser}
                  email={user.data.email}
                  setIdriManage={setIdriManage}
                />
              )}
            </Col>
          </Row>
        )
      )}
    </Container>
  );
};

export default UsersManagement;
