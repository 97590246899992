/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  FaDivide,
  FaEquals,
  FaGreaterThan,
  FaGreaterThanEqual,
  FaLessThan,
  FaLessThanEqual,
  FaMinus,
  FaNotEqual,
  FaPlus,
  FaTimes,
} from 'react-icons/fa';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import floatWithDec from '../../../../support_func/floatWithDec';
import { useSelector } from 'react-redux';
import checkScale from '../../../../support_func/checkScale';

const Condition = (props) => {
  let index = props.index;
  let condition = props.condition;
  let sensorsList = props.sensorsList;
  let conditions = props.conditions;
  let setConditions = props.setConditions;
  let forceUpdate = props.forceUpdate;
  const { t } = useTranslation();

  const [showArit, setShowArit] = useState(false);
  const [showArit2, setShowArit2] = useState(false);
  const [arList, setArList] = useState([
    {
      value: 0,
      label: (
        <div>
          <FaPlus />
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div>
          <FaMinus />
        </div>
      ),
    },
    /*     {
      value: 2,
      label: (
        <div>
          <FaTimes />
        </div>
      ),
    },
    {
      value: 3,
      label: (
        <div>
          <FaDivide />
        </div>
      ),
    },
    {
      value: 4,
      label: (
        <div>
          <FaMinus style={{ transform: 'rotate(90deg)' }} />
          <FaMinus style={{ transform: 'rotate(90deg)' }} />
        </div>
      ),
    }, */
  ]);
  const [logList, setLogList] = useState([
    {
      value: 0,
      label: (
        <div>
          <FaEquals />
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div>
          <FaNotEqual />
        </div>
      ),
    },
    {
      value: 2,
      label: (
        <div>
          <FaLessThanEqual />
        </div>
      ),
    },
    {
      value: 3,
      label: (
        <div>
          <FaGreaterThanEqual />
        </div>
      ),
    },
    {
      value: 4,
      label: (
        <div>
          <FaLessThan />
        </div>
      ),
    },
    {
      value: 5,
      label: (
        <div>
          <FaGreaterThan />
        </div>
      ),
    },
  ]);
  const idri = useSelector((state) => state.idri);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };
  const selectStyles =
    window.innerWidth < 577
      ? {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          valueContainer: (base, state) => ({
            ...base,
            padding: '0',
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            padding: '0',
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),

          // control: (base) => ({
          //   ...base,
          //   height: '3.2em',
          //   minHeight: '3.2em',
          // }),
        }
      : {
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),

          // control: (base) => ({
          //   ...base,
          //   height: '2.65em',
          //   minHeight: '2.65em',
          // }),
        };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  useEffect(() => {
    condition.math &&
      Object.values(condition.math).length > 0 &&
      condition.math[0].value !== null &&
      setShowArit({ ...showArit, [index]: true });
    condition.math2 &&
      Object.values(condition.math2).length > 0 &&
      condition.math2[0].value !== null &&
      setShowArit2({ ...showArit2, [index]: true });
  }, []);

  // useEffect(() => {
  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, []);

  // console.log(condition);
  // console.log(condition.operator1[0]?.id);
  // console.log(conditions);

  return (
    <Card className='p-0' border='primary'>
      <Row className='text-center justify-content-center mx-0 px-0'>
        <Col xs={condition.operator1 && condition.operator1[0].kind === 5 ? 4 : 12} className='p-0'>
          <Select
            menuShouldScrollIntoView
            placeholder={t('selectOperator')}
            required
            // isClearable
            isSearchable={true}
            options={sensorsList.filter((s) => s.kind !== 5 && s.kind !== 6 && s.kind !== 7)}
            value={condition.operator1 ? condition.operator1[0] : ''}
            styles={selectStyles}
            onChange={(e) => {
              setConditions({
                ...conditions,
                [index]: {
                  operator1: { 0: e },
                  math: {},
                  operator2: { 0: {} },
                  math2: {},
                },
              });
            }}
          />
        </Col>
        {condition.operator1 && condition.operator1[0].kind === 5 && (
          <>
            <Col xs={4} className='p-0'>
              <Form.Control
                className='text-center valueInput'
                required
                placeholder={t('value')}
                type='number'
                min={0}
                step={checkScale(idri, condition.operator1[0]?.id)}
                value={condition.operator1[0].value}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      operator1: {
                        ...conditions[index].operator1,
                        0: {
                          ...conditions[index].operator1[0],
                          value: floatWithDec(
                            e.target.value,
                            checkScale(idri, condition.operator1[0]?.id)
                          ),
                        },
                      },
                    },
                  })
                }
              />
            </Col>
            <Col xs={4} className='p-0'>
              <Select
                menuShouldScrollIntoView
                placeholder={t('operatorAsoc')}
                required
                // isClearable
                isSearchable={true}
                options={sensorsList.filter((s) => s.kind === 0 || s.kind === 1 || s.kind === 2)}
                value={condition.operator1 ? condition.operator1[0].id : ''}
                styles={selectStyles}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      operator1: {
                        ...conditions[index].operator1,
                        0: {
                          ...conditions[index].operator1[0],
                          id: e.id,
                        },
                      },
                    },
                  })
                }
              />
            </Col>
          </>
        )}
      </Row>
      {condition.operator1 &&
        condition.operator1[0].kind !== 3 &&
        condition.operator1[0].kind !== 4 &&
        condition.operator1[0].kind !== 8 &&
        showArit[index] && (
          <Row className='text-center justify-content-center mx-0 px-0 position-relative'>
            <Col xs={3} md={6} className='p-0'>
              <Select
                menuShouldScrollIntoView
                placeholder={t('arithmetic')}
                // isClearable
                isSearchable={false}
                options={arList}
                value={
                  condition.math[
                    Object.keys(condition.math).length === 0
                      ? 0
                      : Object.keys(condition.math).length - 1
                  ]
                    ? condition.math[
                        Object.keys(condition.math).length === 0
                          ? 0
                          : Object.keys(condition.math).length - 1
                      ]
                    : ''
                }
                styles={selectStyles}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      math: {
                        ...conditions[index].math,
                        [Object.keys(condition.math).length === 0
                          ? 0
                          : Object.keys(condition.math).length - 1]: e,
                      },
                    },
                  })
                }
              />
            </Col>
            <div className='position-absolute top-50 p-0'>
              <Button
                variant='outline-danger'
                size='sm'
                className='position-absolute end-0  translate-middle-y'
                onClick={(e) => {
                  showArit[index] &&
                    setConditions({
                      ...conditions,
                      [index]: {
                        ...conditions[index],
                        operator1: {
                          ...conditions[index].operator1,
                          [Object.keys(condition.operator1).length === 1
                            ? 1
                            : Object.keys(condition.operator1).length - 1]: '',
                        },
                        math: {},
                      },
                    });
                  setShowArit({ ...showArit, [index]: false });
                }}
              >
                X
              </Button>
            </div>
          </Row>
        )}
      {condition.operator1 &&
        showArit[index] &&
        condition.operator1[0].kind !== 3 &&
        condition.operator1[0].kind !== 4 &&
        condition.operator1[0].kind !== 8 && (
          <Row className='text-center justify-content-center mx-0 px-0'>
            <Col
              xs={
                condition.operator1 &&
                condition.operator1[
                  Object.keys(condition.operator1).length === 1
                    ? 1
                    : Object.keys(condition.operator1).length - 1
                ] &&
                condition.operator1[
                  Object.keys(condition.operator1).length === 1
                    ? 1
                    : Object.keys(condition.operator1).length - 1
                ].kind === 5
                  ? 4
                  : 12
              }
              className='p-0'
            >
              <Select
                menuShouldScrollIntoView
                placeholder={t('arithmeticAsoc')}
                // isClearable
                isSearchable={false}
                options={
                  condition.operator1[0].kind === 0
                    ? sensorsList.filter((s) => s.kind === 0 || s.kind === 5)
                    : condition.operator1[0].kind === 1
                    ? sensorsList.filter((s) => s.kind === 1 || s.kind === 5)
                    : condition.operator1[0].kind === 2
                    ? sensorsList.filter((s) => s.kind === 2 || s.kind === 5)
                    : []
                }
                value={
                  condition.operator1
                    ? condition.operator1[
                        Object.keys(condition.operator1).length === 1
                          ? 1
                          : Object.keys(condition.operator1).length - 1
                      ]
                    : ''
                }
                styles={selectStyles}
                onChange={(e) => {
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      operator1: {
                        ...conditions[index].operator1,
                        [Object.keys(condition.operator1).length === 1
                          ? 1
                          : Object.keys(condition.operator1).length - 1]: e,
                      },
                    },
                  });
                }}
              />
            </Col>
            {condition.operator1 &&
              condition.operator1[
                Object.keys(condition.operator1).length === 1
                  ? 1
                  : Object.keys(condition.operator1).length - 1
              ] &&
              condition.operator1[
                Object.keys(condition.operator1).length === 1
                  ? 1
                  : Object.keys(condition.operator1).length - 1
              ].kind === 5 && (
                <>
                  <Col xs={4} className='p-0'>
                    <Form.Control
                      className='text-center valueInput'
                      required
                      placeholder={t('value')}
                      type='number'
                      min={0}
                      step={checkScale(idri, condition.operator1[0]?.id)}
                      value={floatWithDec(
                        condition.operator1[
                          Object.keys(condition.operator1).length === 1
                            ? 1
                            : Object.keys(condition.operator1).length - 1
                        ].value,
                        checkScale(idri, condition.operator1[0]?.id)
                      )}
                      onChange={(e) =>
                        setConditions({
                          ...conditions,
                          [index]: {
                            ...conditions[index],
                            operator1: {
                              ...conditions[index].operator1,
                              [Object.keys(condition.operator1).length === 1
                                ? 1
                                : Object.keys(condition.operator1).length - 1]: {
                                ...conditions[index].operator1[
                                  Object.keys(condition.operator1).length === 1
                                    ? 1
                                    : Object.keys(condition.operator1).length - 1
                                ],
                                value: e.target.value,
                              },
                            },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={4} className='p-0'>
                    <Select
                      menuShouldScrollIntoView
                      placeholder={t('operatorAsoc')}
                      required
                      // isClearable
                      isSearchable={true}
                      options={
                        condition.operator1[0].kind === 0
                          ? sensorsList.filter((s) => s.kind === 0)
                          : condition.operator1[0].kind === 1
                          ? sensorsList.filter((s) => s.kind === 1)
                          : condition.operator1[0].kind === 2
                          ? sensorsList.filter((s) => s.kind === 2)
                          : []
                      }
                      value={
                        condition.operator1
                          ? condition.operator1[
                              Object.keys(condition.operator1).length === 1
                                ? 1
                                : Object.keys(condition.operator1).length - 1
                            ].id
                          : ''
                      }
                      styles={selectStyles}
                      onChange={(e) =>
                        setConditions({
                          ...conditions,
                          [index]: {
                            ...conditions[index],
                            operator1: {
                              ...conditions[index].operator1,
                              [Object.keys(condition.operator1).length === 1
                                ? 1
                                : Object.keys(condition.operator1).length - 1]: {
                                ...conditions[index].operator1[
                                  Object.keys(condition.operator1).length === 1
                                    ? 1
                                    : Object.keys(condition.operator1).length - 1
                                ],
                                id: e,
                              },
                            },
                          },
                        })
                      }
                    />
                  </Col>
                </>
              )}
          </Row>
        )}
      {condition.operator1 && (
        <Row className='text-center justify-content-center align-items-center mx-0 px-0 position-relative'>
          {props.hide &&
            !showArit[index] &&
            condition.operator1[0].kind !== 3 &&
            condition.operator1[0].kind !== 4 &&
            condition.operator1[0].kind !== 8 && (
              <div className='position-absolute'>
                <Form.Check
                  type='checkbox'
                  checked={showArit[index]}
                  // label={t('arithmetic')}
                  label={
                    <>
                      <FaPlus />
                      <FaMinus />
                      {/* <FaTimes />
                      <FaDivide /> */}
                    </>
                  }
                  className='position-absolute translate-middle-y pt-1 pt-md-0'
                  onChange={(e) => {
                    setShowArit({ ...showArit, [index]: e.target.checked });
                    setConditions({
                      ...conditions,
                      [index]: {
                        ...conditions[index],
                        math: {},
                      },
                    });
                  }}
                />
              </div>
            )}
          <Col xs={3} md={6} className='p-0'>
            <Select
              menuShouldScrollIntoView
              required
              placeholder={t('logic')}
              // isClearable
              isSearchable={false}
              options={
                condition.operator1[0].kind === 3 ||
                condition.operator1[0].kind === 4 ||
                condition.operator1[0].kind === 8
                  ? logList.filter((log) => log.value < 2)
                  : logList
              }
              value={condition.logic ? condition.logic : ''}
              styles={selectStyles}
              onChange={(e) =>
                setConditions({
                  ...conditions,
                  [index]: { ...conditions[index], logic: e },
                })
              }
            />
          </Col>
          {props.hide &&
            condition.operator2[0].kind !== 5 &&
            !showArit2[index] &&
            condition.operator1[0].kind !== 3 &&
            condition.operator1[0].kind !== 4 &&
            condition.operator1[0].kind !== 8 && (
              <div className='position-absolute end-0'>
                <Form.Check
                  type='checkbox'
                  reverse
                  checked={showArit2[index]}
                  // label={t('arithmetic')}
                  label={
                    <>
                      <FaPlus />
                      <FaMinus />
                      {/* <FaTimes />
                      <FaDivide /> */}
                    </>
                  }
                  className='position-absolute end-0 translate-middle-y me-3 pt-1 pt-md-0'
                  onChange={(e) => {
                    setShowArit2({ ...showArit2, [index]: e.target.checked });
                    setConditions({
                      ...conditions,
                      [index]: {
                        ...conditions[index],
                        math2: {},
                      },
                    });
                  }}
                />
              </div>
            )}
        </Row>
      )}
      {condition.operator1 && (
        <Row className='text-center justify-content-center mx-0 px-0'>
          <Col
            xs={condition.operator2 && condition.operator2[0].kind === 5 ? 4 : 12}
            className='p-0'
          >
            <Select
              menuShouldScrollIntoView
              placeholder={t('selectOperator')}
              required
              // isClearable
              isSearchable={true}
              options={
                condition.operator1[0].kind === 0
                  ? sensorsList.filter((s) =>
                      showArit2[index]
                        ? s.type !== 'co' && (s.kind === 0 || s.kind === 5)
                        : s.kind === 0 || s.kind === 5
                    )
                  : condition.operator1[0].kind === 1
                  ? sensorsList.filter((s) =>
                      showArit2[index]
                        ? s.type !== 'co' && (s.kind === 1 || s.kind === 5)
                        : s.kind === 1 || s.kind === 5
                    )
                  : condition.operator1[0].kind === 2
                  ? sensorsList.filter((s) =>
                      showArit2[index]
                        ? s.type !== 'co' && (s.kind === 2 || s.kind === 5)
                        : s.kind === 2 || s.kind === 5
                    )
                  : condition.operator1[0].kind === 3 ||
                    condition.operator1[0].kind === 4 ||
                    condition.operator1[0].kind === 8
                  ? sensorsList.filter((s) =>
                      showArit2[index]
                        ? s.type !== 'co' && (s.kind === 6 || s.kind === 7)
                        : s.kind === 6 || s.kind === 7
                    )
                  : []
              }
              value={
                condition.operator2 &&
                Object.keys(condition.operator2[0]).length > 0 &&
                condition.operator2[0]
              }
              styles={selectStyles}
              onChange={(e) => {
                setConditions({
                  ...conditions,
                  [index]: { ...conditions[index], operator2: { 0: e } },
                });
              }}
            />
          </Col>
          {condition.operator2 && condition.operator2[0].kind === 5 && (
            <>
              <Col xs={4} className='p-0'>
                <Form.Control
                  className='text-center valueInput3'
                  required
                  placeholder={t('value')}
                  type='number'
                  min={0}
                  step={checkScale(idri, condition.operator1[0]?.id)}
                  value={condition.operator2[0].value}
                  onChange={(e) =>
                    setConditions({
                      ...conditions,
                      [index]: {
                        ...conditions[index],
                        operator2: {
                          ...conditions[index].operator2,
                          0: {
                            ...conditions[index].operator2[0],
                            value: floatWithDec(
                              e.target.value,
                              checkScale(idri, condition.operator1[0]?.id)
                            ),
                          },
                        },
                      },
                    })
                  }
                />
              </Col>
              <Col xs={4} className='p-0'>
                <Select
                  menuShouldScrollIntoView
                  placeholder={t('operatorAsoc')}
                  required
                  // isClearable
                  isSearchable={true}
                  options={
                    condition.operator1[0].kind === 0
                      ? sensorsList.filter((s) => s.kind === 0)
                      : condition.operator1[0].kind === 1
                      ? sensorsList.filter((s) => s.kind === 1)
                      : condition.operator1[0].kind === 2
                      ? sensorsList.filter((s) => s.kind === 2)
                      : []
                  }
                  value={condition.operator2 ? condition.operator2[0].id : ''}
                  styles={selectStyles}
                  onChange={(e) =>
                    setConditions({
                      ...conditions,
                      [index]: {
                        ...conditions[index],
                        operator2: {
                          ...conditions[index].operator2,
                          0: {
                            ...conditions[index].operator2[0],
                            id: e,
                          },
                        },
                      },
                    })
                  }
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {condition.operator1 &&
        condition.operator1[0].kind !== 3 &&
        condition.operator1[0].kind !== 4 &&
        condition.operator1[0].kind !== 8 &&
        showArit2[index] && (
          <Row className='text-center justify-content-center mx-0 px-0 position-relative'>
            <Col xs={3} md={6} className='p-0'>
              <Select
                menuShouldScrollIntoView
                placeholder={t('arithmetic')}
                // isClearable
                isSearchable={false}
                options={arList}
                value={
                  condition.math2[
                    Object.keys(condition.math2).length === 0
                      ? 0
                      : Object.keys(condition.math2).length - 1
                  ]
                    ? condition.math2[
                        Object.keys(condition.math2).length === 0
                          ? 0
                          : Object.keys(condition.math2).length - 1
                      ]
                    : ''
                }
                styles={selectStyles}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      math2: {
                        ...conditions[index].math2,
                        [Object.keys(condition.math2).length === 0
                          ? 0
                          : Object.keys(condition.math2).length - 1]: e,
                      },
                    },
                  })
                }
              />
            </Col>
            <div className='position-absolute top-50 p-0'>
              {console.log(conditions[index])}
              <Button
                variant='outline-danger'
                size='sm'
                className='position-absolute end-0 translate-middle-y'
                onClick={(e) => {
                  // console.log(
                  //   conditions[index].operator2[
                  //     Object.keys(condition.operator2).length === 1
                  //       ? 1
                  //       : Object.keys(condition.operator2).length - 1
                  //   ]
                  // );
                  delete conditions[index].operator2[
                    Object.keys(condition.operator2).length === 1
                      ? 1
                      : Object.keys(condition.operator2).length - 1
                  ];
                  // showArit2[index] &&
                  //   setConditions({
                  //     ...conditions,
                  //     [index]: {
                  //       ...conditions[index],
                  //       operator2: {
                  //         ...conditions[index].operator2,
                  //         // [Object.keys(condition.operator2).length === 1
                  //         //   ? 1
                  //         //   : Object.keys(condition.operator2).length - 1]: {},
                  //       },
                  //     },
                  //   });

                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      math2: {},
                    },
                  });
                  setShowArit2({ ...showArit2, [index]: false });
                }}
              >
                X
              </Button>
            </div>
          </Row>
        )}
      {condition.operator2 &&
        showArit2[index] &&
        condition.operator2[0].kind !== 3 &&
        condition.operator2[0].kind !== 4 &&
        condition.operator2[0].kind !== 8 && (
          <Row className='text-center justify-content-center mx-0 px-0'>
            <Col
              xs={
                condition.operator2 &&
                condition.operator2[
                  Object.keys(condition.operator2).length === 1
                    ? 1
                    : Object.keys(condition.operator2).length - 1
                ] &&
                condition.operator2[
                  Object.keys(condition.operator2).length === 1
                    ? 1
                    : Object.keys(condition.operator2).length - 1
                ].kind === 5
                  ? 4
                  : 12
              }
              className='p-0'
            >
              <Select
                menuShouldScrollIntoView
                placeholder={t('arithmeticAsoc')}
                // isClearable
                isSearchable={false}
                options={
                  condition.operator1[0].kind === 0
                    ? sensorsList.filter((s) => s.kind === 0 || s.kind === 5)
                    : condition.operator1[0].kind === 1
                    ? sensorsList.filter((s) => s.kind === 1 || s.kind === 5)
                    : condition.operator1[0].kind === 2
                    ? sensorsList.filter((s) => s.kind === 2 || s.kind === 5)
                    : []
                }
                value={
                  condition.operator2
                    ? condition.operator2[
                        Object.keys(condition.operator2).length === 1
                          ? 1
                          : Object.keys(condition.operator2).length - 1
                      ]
                    : ''
                }
                styles={selectStyles}
                onChange={(e) => {
                  setConditions({
                    ...conditions,
                    [index]: {
                      ...conditions[index],
                      operator2: {
                        ...conditions[index].operator2,
                        [Object.keys(condition.operator2).length === 1
                          ? 1
                          : Object.keys(condition.operator2).length - 1]: e,
                      },
                    },
                  });
                }}
              />
            </Col>
            {condition.operator2 &&
              condition.operator2[
                Object.keys(condition.operator2).length === 1
                  ? 1
                  : Object.keys(condition.operator2).length - 1
              ] &&
              condition.operator2[
                Object.keys(condition.operator2).length === 1
                  ? 1
                  : Object.keys(condition.operator2).length - 1
              ].kind === 5 && (
                <>
                  <Col xs={4} className='p-0'>
                    <Form.Control
                      className='text-center valueInput'
                      required
                      placeholder={t('value')}
                      type='number'
                      min={0}
                      step={checkScale(idri, condition.operator1[0]?.id)}
                      value={floatWithDec(
                        condition.operator2[
                          Object.keys(condition.operator2).length === 1
                            ? 1
                            : Object.keys(condition.operator2).length - 1
                        ].value,
                        checkScale(idri, condition.operator1[0]?.id)
                      )}
                      onChange={(e) =>
                        setConditions({
                          ...conditions,
                          [index]: {
                            ...conditions[index],
                            operator2: {
                              ...conditions[index].operator2,
                              [Object.keys(condition.operator2).length === 1
                                ? 1
                                : Object.keys(condition.operator2).length - 1]: {
                                ...conditions[index].operator2[
                                  Object.keys(condition.operator2).length === 1
                                    ? 1
                                    : Object.keys(condition.operator2).length - 1
                                ],
                                value: e.target.value,
                              },
                            },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={4} className='p-0'>
                    <Select
                      menuShouldScrollIntoView
                      placeholder={t('operatorAsoc')}
                      required
                      // isClearable
                      isSearchable={true}
                      options={
                        condition.operator2[0].kind === 0
                          ? sensorsList.filter((s) => s.kind === 0)
                          : condition.operator2[0].kind === 1
                          ? sensorsList.filter((s) => s.kind === 1)
                          : condition.operator2[0].kind === 2
                          ? sensorsList.filter((s) => s.kind === 2)
                          : condition.operator2[0].kind === 5
                          ? sensorsList.filter((s) => s.kind === condition.operator1[0].kind)
                          : []
                      }
                      value={
                        condition.operator2
                          ? condition.operator2[
                              Object.keys(condition.operator2).length === 1
                                ? 1
                                : Object.keys(condition.operator2).length - 1
                            ].id
                          : ''
                      }
                      styles={selectStyles}
                      onChange={(e) =>
                        setConditions({
                          ...conditions,
                          [index]: {
                            ...conditions[index],
                            operator2: {
                              ...conditions[index].operator2,
                              [Object.keys(condition.operator2).length === 1
                                ? 1
                                : Object.keys(condition.operator2).length - 1]: {
                                ...conditions[index].operator2[
                                  Object.keys(condition.operator2).length === 1
                                    ? 1
                                    : Object.keys(condition.operator2).length - 1
                                ],
                                id: e,
                              },
                            },
                          },
                        })
                      }
                    />
                  </Col>
                </>
              )}
          </Row>
        )}
      {parseInt(index) >= 1 && (
        <Col className='my-1'>
          <Button
            variant='outline-danger'
            size='sm'
            className='px-1 py-0 ruleBtn'
            onClick={() => {
              delete conditions[index];
              forceUpdate((n) => !n);
            }}
          >
            {t('conditionDelete')}
          </Button>
        </Col>
      )}
    </Card>
  );
};

export default Condition;
