import React, { useEffect, useState } from 'react';
import { AttributionControl, MapContainer, Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Icon, latLngBounds } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import axios from 'axios';
const Map = () => {
  const { t } = useTranslation();
  const position = [39.5, -0.7];

  const [bounds, setBounds] = useState([]);
  const [instaladoresData, setInstaladoresData] = useState({});
  const [workers, setWorkers] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  let icon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  //hiding google map attributes
  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      setTimeout(function () {
        let gAttr = document.getElementsByClassName('leaflet-control-attribution')[1];
        if (gAttr) {
          gAttr.style.display = 'none';
        }
      }, i * 1000);
    }
    instaladoresList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //installer display functions
  const instaladoresList = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_SRT}/private2/tasnetwork`);
      setInstaladoresData(data.data);
      const bounds = latLngBounds([0, 0]);
      data.data.tanetwork.forEach((worker) => {
        bounds.extend(worker.coordenadas);
      });
      setBounds(bounds);
      setWorkers(data.data.tanetwork);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      setError(err.message);
      console.log(error);
    }
  };

  //displaying the visible area of the map
  const SetView = () => {
    const map = useMap();
    map.fitBounds(bounds, {
      padding: [50, 50],
    });
    return null;
  };

  return (
    <MapContainer style={{ height: '70vh' }} center={position} zoom={10} attributionControl={false}>
      <ReactLeafletGoogleLayer
        attribution={t('mapAttribution')}
        apiKey={process.env.REACT_APP_MAP_API_KEY}
        type={'hybrid'}
      />
      {!isPending &&
        (!instaladoresData.status ? (
          <Marker position={position} opacity={0}>
            <Tooltip className='' direction='center' opacity={0.9} permanent>
              {t('mapNoDisp')}
            </Tooltip>
          </Marker>
        ) : (
          <>
            {/* worker markers */}
            {workers.map((worker) => (
              <Marker key={worker.id} position={worker.coordenadas} icon={icon}>
                <Popup>
                  <Col className='mx-2 px-3'>
                    <Row>{worker.ciudad}</Row>
                    <Row className=' fw-bold'>{worker.razon}</Row>
                    <Row className='text-decoration-underline fw-bold text-danger'>
                      {t('mapZona')}
                    </Row>
                    <Row className='text-primary'>{worker.zonatext}</Row>
                    <Row className='fw-bold'>{t('mapContact')}:</Row>
                    <Row>{worker.contacto}</Row>
                    <Row className='fw-bold'>{t('mapPhones')}:</Row>
                    <Row>
                      <a className='text-decoration-none px-0' href={'tel:' + worker.tlf1}>
                        {worker.tlf1}
                      </a>
                    </Row>
                    <Row>
                      <a className='text-decoration-none px-0' href={'tel:' + worker.tlf2}>
                        {worker.tlf2}
                      </a>
                    </Row>
                    <Row className='fw-bold'>{t('mapEmail')}:</Row>
                    <Row>
                      <a className='text-decoration-none px-0' href={'mailto:' + worker.email1}>
                        {worker.email1}
                      </a>
                    </Row>
                    <Row>
                      <a className='text-decoration-none px-0' href={'mailto:' + worker.email2}>
                        {worker.email2}
                      </a>
                    </Row>
                    <Row className='fw-bold'>{t('mapAddress')}:</Row>
                    <Row>{worker.direccion}</Row>
                    <Row>{worker.cp}</Row>
                    <Row>{worker.ciudad}</Row>
                    <Row>{worker.provincia}</Row>
                  </Col>
                </Popup>
                <Tooltip permanent>{worker.razon}</Tooltip>
              </Marker>
            ))}
            <SetView />
          </>
        ))}
      <AttributionControl prefix={false} />
    </MapContainer>
  );
};

export default Map;
