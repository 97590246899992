import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import unavailable from '../../../../support_func/unavailable';
// import translation from './diccionario.json';

const TranslationTable = () => {
  // localStorage.setItem('dictionary', JSON.stringify(translation));

  const token = localStorage.getItem('autoAcceso');
  const [dictionary, setDictionary] = useState();
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [rowData, setRowData] = useState({ key: '', es: '', en: '', fr: '' });
  const [query, setQuery] = useState('');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //language dictionaries
  let es = dictionary ? dictionary.es.translation : '';
  let en = dictionary ? dictionary.en.translation : '';
  let fr = dictionary ? dictionary.fr.translation : '';

  //get dictionary from server
  const getDictionary = async () => {
    setIsPending(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_API_SRT}/private2/dictionary?language=all`)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          setIsPending(false);
          if (res.data.code === '0') {
            setMsg('Diccionario recibido del servidor');
            localStorage.setItem('dictionary', JSON.parse(res.data.data));
            setDictionary(JSON.parse(localStorage.dictionary));
          } else {
            setError('Algo salió mal, inténtalo de nuevo más tarde');
          }
        });
    } catch (err) {
      setIsPending(false);
      console.log(err.response.data);
      let code = err.response.data.code;
      if (code === '1') {
        setError('Acceso no autorizado');
      } else if (code === '2') {
        setError('Configuración de idioma no válida');
      } else if (code === '3') {
        setError('Parámetro faltante o vacío');
      } else {
        setError('Algo salió mal, inténtalo de nuevo más tarde');
      }
    }
  };
  //save dictionary on the server
  const postDictionary = async () => {
    setIsPending(true);
    try {
      let pst = {
        jsonstring: JSON.stringify(localStorage.dictionary),
        language: 'all',
      };
      await axios
        .post(`${process.env.REACT_APP_API_SRT}/private2/dictionary`, pst, config)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          setIsPending(false);
          if (res.data.code === '0') {
            setMsg('Diccionario actualizado');
            setTimeout(() => {
              getDictionary();
            }, 3000);
          } else {
            setError('Algo salió mal, inténtalo de nuevo más tarde');
          }
        });
    } catch (err) {
      setIsPending(false);
      console.log(err.response.data);
      let code = err.response.data.code;
      if (code === '1') {
        setError('Acceso no autorizado');
      } else if (code === '2') {
        setError('Configuración de idioma no válida');
      } else if (code === '3') {
        setError('Parámetro faltante o vacío');
      } else {
        setError('Algo salió mal, inténtalo de nuevo más tarde');
      }
    }
  };
  //get editable element
  const getElem = (es, en, fr) => {
    return Object.keys(es)
      .filter((key) => key.includes(query))
      .map(function (value) {
        return (
          <tr
            key={value}
            onClick={() =>
              setRowData({
                key: value,
                es: es[value],
                en: en[value] ? en[value] : '',
                fr: fr[value] ? fr[value] : '',
              })
            }
          >
            <td className='fw-bold position-relative' style={{ paddingLeft: '30px' }}>
              <Button
                variant='outline-danger'
                size='sm'
                className='position-absolute top-0 start-0'
                onClick={(e) => deleteKey(e, value)}
              >
                X
              </Button>
              {value}
            </td>
            <td>{es[value]}</td>
            <td>{en ? en[value] : ''}</td>
            <td>{fr ? fr[value] : ''}</td>
          </tr>
        );
      });
  };
  //add new string to dictionary
  const handleSubmit = (e, lang, key, value) => {
    e.preventDefault();
    let newValue = JSON.parse(localStorage.dictionary);
    newValue[lang].translation[key] = value;
    localStorage.setItem('dictionary', JSON.stringify(newValue));
    setDictionary(JSON.parse(localStorage.dictionary));
  };
  //add new dictionary key
  const addKey = (e, key) => {
    e.preventDefault();
    let main = JSON.parse(localStorage.dictionary);
    let obj = main.es.translation;
    obj[key] = '';
    localStorage.setItem('dictionary', JSON.stringify(main));
    setDictionary(JSON.parse(localStorage.dictionary));
  };
  //remove key from dictionary
  const deleteKey = (e, key) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Estas seguro que quieres borrarlo?') === true) {
      let main = JSON.parse(localStorage.dictionary);
      let obj = main.es.translation;
      delete obj[key];
      localStorage.setItem('dictionary', JSON.stringify(main));
      setDictionary(JSON.parse(localStorage.dictionary));
    } else {
      return;
    }
  };

  //hide message after time expires
  if (msg) {
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  }

  //get dictionary on page load
  useEffect(() => {
    getDictionary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=''>
      {dictionary && dictionary.es && (
        <>
          <Table bordered hover size='sm'>
            <thead>
              <tr>
                <th className='text-center' style={{ width: '5vw' }}>
                  Clave
                </th>
                <th>{rowData.key}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>ES</td>
                <td>
                  <Form onSubmit={(e) => handleSubmit(e, 'es', rowData.key, e.target[0].value)}>
                    <InputGroup>
                      <FormControl
                        as='textarea'
                        style={{ height: '30px' }}
                        value={rowData.es}
                        onChange={(e) => setRowData({ ...rowData, es: e.target.value })}
                      />
                      <Button type='submit' variant='outline-success' size='sm'>
                        Guardar
                      </Button>
                    </InputGroup>
                  </Form>
                </td>
              </tr>
              <tr>
                <td className='text-center'>EN</td>
                <td>
                  <Form onSubmit={(e) => handleSubmit(e, 'en', rowData.key, e.target[0].value)}>
                    <InputGroup>
                      <FormControl
                        as='textarea'
                        style={{ height: '30px' }}
                        value={rowData.en}
                        onChange={(e) => setRowData({ ...rowData, en: e.target.value })}
                      />
                      <Button type='submit' variant='outline-success' size='sm'>
                        Guardar
                      </Button>
                    </InputGroup>
                  </Form>
                </td>
              </tr>
              <tr>
                <td className='text-center'>FR</td>
                <td>
                  <Form onSubmit={(e) => handleSubmit(e, 'fr', rowData.key, e.target[0].value)}>
                    <InputGroup>
                      <FormControl
                        as='textarea'
                        style={{ height: '30px' }}
                        value={rowData.fr}
                        onChange={(e) => setRowData({ ...rowData, fr: e.target.value })}
                      />
                      <Button type='submit' variant='outline-success' size='sm'>
                        Guardar
                      </Button>
                    </InputGroup>
                  </Form>
                </td>
              </tr>
            </tbody>
          </Table>
          <Row className='mx-1 mb-3'>
            <Col md={6}>
              <Row>
                <Button variant='success' onClick={postDictionary}>
                  Enviar nuevo diccionario al servidor
                </Button>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Button variant='danger' className='me-auto' onClick={getDictionary}>
                  Eliminar cambios locales
                </Button>
              </Row>
            </Col>
            <Col xs={12}>
              {(error && <Col className='text-center alert alert-warning'>{error}</Col>) ||
                (msg && <Col className='text-center alert alert-success'>{msg}</Col>)}
            </Col>
          </Row>
          {isPending && (
            <div className='working_apis'>
              <div className='text-working-apis'>en proceso...</div>
            </div>
          )}
          <Form onSubmit={(e) => addKey(e, query)}>
            <InputGroup className='mb-1' onChange={(e) => setQuery(e.target.value)}>
              <Button type='submit' variant='outline-success' size='sm' style={{ width: '80px' }}>
                Add
              </Button>
              <FormControl placeholder='buscar clave' style={{ height: '30px' }} />
            </InputGroup>
          </Form>
          <div className='scroll'>
            <Table striped bordered hover className='table-sm text-center transTable px-auto'>
              <thead>
                <tr>
                  <th>Clave</th>
                  <th>Español</th>
                  <th>Inglés</th>
                  <th>Francés</th>
                </tr>
              </thead>
              <tbody>{getElem(es, en, fr)}</tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default TranslationTable;
