/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BiSend } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';
import unavailable from '../../../../support_func/unavailable';
import errorHandler from '../../../../support_func/errorHandler';
import Select from 'react-select';
import { useRef } from 'react';

const createContentStateFromHTML = (html) => {
  const contentBlocks = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlocks.contentBlocks,
    contentBlocks.entityMap
  );
  return EditorState.createWithContent(contentState);
};
const GlobalAlert = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [alerts, setAlerts] = useState([]);
  // const [push, setPush] = useState([]);
  const [pushTitle, setPushTitle] = useState('');
  const [pushMsg, setPushMsg] = useState('');
  const [edited, setEdited] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [devicesList, setDevicesList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    createContentStateFromHTML(alerts.find((a) => a.edit)?.message ?? '<div></div>')
  );
  const firstSelectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectStyle =
    window.innerWidth < 577
      ? {
          option: (styles, { isFocused, isSelected, isDisabled }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isDisabled ? 'rgb(140, 140, 140)' : isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0',
          }),
        }
      : {
          option: (styles, { isFocused, isSelected, isDisabled }) => {
            return {
              ...styles,
              backgroundColor: isFocused
                ? 'rgba(0, 123, 255, 0.3)'
                : isSelected && 'rgba(0, 0, 0, 0)',
              color: isDisabled ? 'rgb(140, 140, 140)' : isSelected && 'rgba(0, 0, 0)',
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
        };

  const handleGetHTML = () => {
    const currentContentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(currentContentState);
    const contentState = convertFromRaw(rawContentState);
    const html = stateToHTML(contentState);
    setAlerts(
      alerts.map((alert) =>
        alert.id === alerts.find((a) => a.edit).id ? { ...alert, message: html } : alert
      )
    );
    setEdited(true);
  };

  const getGlobalAlert = async () => {
    setIsPending(true);

    axios
      .get(`${process.env.REACT_APP_API_SRT}/private2/gwarning`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          let alertsRes = res.data.gwarnings.map((a) => {
            return { ...a, edit: false };
          });

          setAlerts(alertsRes);
          setIsPending(false);
        } else {
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postGlobalAlert = (e, toUpdate, toDelete) => {
    if (e) {
      e.preventDefault();
    }
    // dispatch(setStatus('loading'));
    let body = toDelete
      ? {
          idgwarnings: [toUpdate],
        }
      : {
          idgwarning: toUpdate,
          message: alerts.find((a) => a.id === toUpdate).message,
          active: alerts.find((a) => a.id === toUpdate).active ?? false,
        };
    console.log(body);
    if (toDelete && (body.idgwarnings === undefined || body.idgwarnings.includes(undefined))) {
      setAlerts(alerts.slice(0, -1));
      // dispatch(setStatus('loaded'));
    } else if (toDelete && typeof body.idgwarnings === 'object') {
      console.log('DELETE');
      axios
        .delete(`${process.env.REACT_APP_API_SRT}/private2/gwarning`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: body,
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.code === '0') {
            await setError(t('alertDeleted'));
            // await dispatch(setStatus({ status: 'error', code: null, error: t('success') }));
            await getGlobalAlert();
          } else {
            // dispatch(setStatus({ status: 'error', code: null, error: t('problem') }));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else if (body.idgwarning) {
      console.log('PATCH');
      axios
        .patch(`${process.env.REACT_APP_API_SRT}/private2/gwarning`, body, config)
        .then(async (res) => {
          console.log(res.data);
          if (res.data.code === '0') {
            // await dispatch(setStatus({ status: 'error', code: null, error: t('success') }));
            await setError(t('alertUpdated'));
            await getGlobalAlert();
          } else {
            // dispatch(setStatus({ status: 'error', code: null, error: t('problem') }));
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      console.log('POST');
      axios
        .post(`${process.env.REACT_APP_API_SRT}/private2/gwarning`, body, config)
        .then(async (res) => {
          console.log(res.data);
          if (res.data.code === '0') {
            // await dispatch(setStatus({ status: 'error', code: null, error: t('success') }));
            await setError(t('alertSaved'));
            await getGlobalAlert();
          } else {
            // dispatch(setStatus({ status: 'error', code: null, error: t('problem') }));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  const getUsers = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/users`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(res.data);
          setUsersList(res.data.users);
          setAllUsersList(res.data.users);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getProfiles = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/profilelist`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(res.data);
          let prList = [];
          prList.push({ code: 'all' });
          prList.push(...res.data.profiles);
          setProfilesList(prList);
          // setSelectedProfile({ code: 'all' });
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getProfileUsers = (profile) => {
    setIsPending(true);
    if (profile === 'all') {
      getUsers();
    } else {
      axios
        .get(`${process.env.REACT_APP_API_SRT}/v2/profileusers?profile=${profile}`, config)
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            // console.log(res.data);
            setUsersList(res.data.users);
            setIsPending(false);
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
          setIsPending(false);
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  const getDeviceUsers = (id, serial) => {
    setIsPending(true);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghatorusers?idghator=${id}&ghatorserial=${serial}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          let usList = [];
          usList.push({
            email: t('owner').charAt(0).toUpperCase() + t('owner').slice(1) + ':',
            disabled: true,
          });
          usList.push(res.data.owner);
          if (res.data.delegated.length > 0) {
            usList.push({ email: t('delegated') + ':', disabled: true });
            usList.concat(res.data.delegated);
          }
          setUsersList(usList);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getDevices = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/ghators?profile=admi`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // console.log(res.data);
          setDevicesList(res.data.ghators);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postGlobalPush = (e) => {
    e.preventDefault();
    // dispatch(setStatus('loading'));
    if (selectedOption.length > 0) {
      let body = {
        emails: selectedOption,
        title: pushTitle,
        message: pushMsg,
      };
      console.log(body);
      axios
        .post(`${process.env.REACT_APP_API_SRT}/private2/sendnotification`, body, config)
        .then(async (res) => {
          console.log(res.data);
          setError(t('pushSended'));
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setError(t('selectUsers'));
    }
  };

  const copyToClipboard = () => {
    let text = selectedOption.map((u) => {
      return allUsersList
        .map((aul) => {
          if (aul.email === u) {
            return (
              (aul.name ? aul.name : '') +
              (aul.lastname ? ' ' + aul.lastname + ' ' : aul.name ? ' ' : '') +
              '<' +
              aul.email +
              '>'
            ).replace(/[()]/g, '');
          }
        })
        .filter((item) => item !== undefined)
        .join(', ');
    });
    console.log(text);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard successfully');
        setError(t('copied'));
      })
      .catch((err) => {
        console.error('Error copying text to clipboard:', err);
        setError(t('errorCopy') + ': ' + text);
      });
  };

  useEffect(() => {
    if (edited) {
      postGlobalAlert(null, alerts.find((a) => a.edit).id);
      setEdited(false);
    }
  }, [edited]);

  useEffect(() => {
    getGlobalAlert();
    getUsers();
    getProfiles();
    getDevices();
  }, []);

  useEffect(() => {
    setEditorState(
      createContentStateFromHTML(alerts.find((a) => a?.edit)?.message ?? '<div></div>')
    );
  }, [alerts]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Accordion
      defaultActiveKey={
        localStorage.getItem('galerts') ? JSON.parse(localStorage.getItem('galerts')) : '0'
      }
      alwaysOpen
      id='acc'
      onSelect={(e) => {
        localStorage.setItem('galerts', JSON.stringify(e));
      }}
    >
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <Table bordered className='mb-0'>
            <thead>
              <tr className='dark text-center'>
                <th colSpan={2} className='lh-sm'>
                  <p className='fw-normal my-0 px-4'>{t('globalAlerts')}</p>
                </th>
              </tr>
            </thead>
          </Table>
        </Accordion.Header>
        <Accordion.Body className='p-0'>
          {alerts
            .map((a) => {
              return a.edit;
            })
            .some(Boolean) && (
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'remove',
                  'history',
                ],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                },
                list: {
                  inDropdown: false,
                  options: ['unordered', 'ordered'],
                },
                link: {
                  inDropdown: false,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: '_self',
                  options: ['link', 'unlink'],
                  linkCallback: undefined,
                },
                embedded: {
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: false,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
                history: {
                  inDropdown: false,
                  options: ['undo', 'redo'],
                },
              }}
            />
          )}

          <Table bordered striped className='mb-0' id='firmTable'>
            <thead>
              <tr className='text-center'>
                <th colSpan={5} className='text-center align-middle p-0'>
                  {t('alertsList')}
                </th>
              </tr>
              <tr>
                <td colSpan={5} className='p-0'>
                  <hr className='m-0 p-0 hr-dark' />
                </td>
              </tr>
              <tr className='text-center'>
                <th
                  className='text-center align-middle px-0 text-truncate'
                  style={{ maxWidth: '10vw' }}
                >
                  {t('activeAlert')}
                </th>
                <th className='text-center align-middle px-0'>{t('alertMessage')}</th>
                <th
                  className='text-center align-middle px-0 text-truncate'
                  style={{ maxWidth: '10vw' }}
                >
                  {t('editAlert')}
                </th>
                <th
                  className='text-center align-middle px-0 text-truncate'
                  style={{ maxWidth: '10vw' }}
                >
                  {t('sendChanges')}
                </th>
                <th
                  className='text-center align-middle px-0 text-truncate'
                  style={{ maxWidth: '10vw' }}
                >
                  {t('deleteAlert')}
                </th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((a) => (
                <tr key={a.id ?? Math.random()}>
                  <td className='text-center align-middle p-0'>
                    <Form.Check
                      type='checkbox'
                      disabled={!a.edit}
                      checked={a.active}
                      onChange={(e) =>
                        setAlerts(
                          alerts.map((alert) =>
                            alert.id === a.id ? { ...alert, active: !alert.active } : alert
                          )
                        )
                      }
                    />
                  </td>
                  <td
                    className='text-center align-middle p-0 text-truncate'
                    style={{ maxWidth: '40vw' }}
                  >
                    {a.edit ? t('editing') : a.message}
                  </td>
                  <td className='p-0'>
                    <div className='d-grid'>
                      <Button
                        className='p-1 rounded-0'
                        variant='outline-primary'
                        disabled={
                          alerts
                            .map((a) => {
                              return a.edit;
                            })
                            .some(Boolean) && !a.edit
                        }
                        onClick={() => {
                          if (a.edit) {
                            window.confirm(t('noSaved')) &&
                              setAlerts(
                                alerts.map((alert) =>
                                  alert.id === a.id ? { ...alert, edit: !alert.edit } : alert
                                )
                              );
                          } else {
                            setAlerts(
                              alerts.map((alert) =>
                                alert.id === a.id ? { ...alert, edit: !alert.edit } : alert
                              )
                            );
                          }
                        }}
                      >
                        <FiEdit />
                      </Button>
                    </div>
                  </td>
                  <td className='p-0'>
                    <div className='d-grid'>
                      <Button
                        disabled={!a.edit}
                        className='p-1 rounded-0'
                        variant='outline-success'
                        onClick={(e) => handleGetHTML()}
                      >
                        <BiSend />
                      </Button>
                    </div>
                  </td>
                  <td className='p-0'>
                    <div className='d-grid'>
                      <Button
                        className='p-1 rounded-0'
                        variant='outline-danger'
                        disabled={alerts
                          .map((a) => {
                            return a.edit;
                          })
                          .some(Boolean)}
                        onClick={(e) =>
                          window.confirm(t('seguro')) && postGlobalAlert(e, a.id, true)
                        }
                      >
                        <MdDelete />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className='justify-content-center'>
            <Col xs={6} className='text-center'>
              <Button
                variant='primary'
                className='my-2'
                disabled={alerts
                  .map((a) => {
                    return a.edit;
                  })
                  .some(Boolean)}
                onClick={() =>
                  setAlerts((prevItems) => [...prevItems, { enable: false, message: '' }])
                }
              >
                {t('addAlert')}
              </Button>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header>
          <Table bordered className='mb-0'>
            <thead>
              <tr className='dark text-center'>
                <th colSpan={2} className='lh-sm'>
                  <p className='fw-normal my-0 px-4'>{t('globalPush')}</p>
                </th>
              </tr>
            </thead>
          </Table>
        </Accordion.Header>
        <Accordion.Body className='p-2'>
          <Form onSubmit={(e) => postGlobalPush(e)}>
            <Row className='my-2'>
              <Col md={6} xs={12} onClick={() => setIsOpen(true)}>
                <Select
                  menuShouldScrollIntoView
                  placeholder={t('userSelect')}
                  isSearchable
                  menuIsOpen={isOpen}
                  ref={firstSelectRef}
                  options={Object.values(usersList).map((u) => {
                    return {
                      label:
                        (u.name ? u.name + ' ' + (u.lastname ? u.lastname : '') + ' - ' : '') +
                        (u.nif ? u.nif + ' - ' : '') +
                        u.email,
                      value: u.email,
                      id: u.iduser,
                      fullname: u.name + ' ' + u.lastname,
                      disabled: selectedOption.includes(u.email) || u?.disabled,
                    };
                  })}
                  isOptionDisabled={(option) => option.disabled}
                  // .filter((o) => !selectedOption.includes(o.value))}
                  // value={Object.keys(assignedUser).length > 0 ? assignedUser : null}
                  styles={selectStyle}
                  // onClick={() => setIsOpen(true)}
                  // openMenuOnClick={true}
                  onBlur={() => {
                    setIsOpen(false);
                    // console.log(firstSelectRef.current);
                    // firstSelectRef.current.setValue('');
                  }}
                  onChange={(e) => setSelectedOption([...selectedOption, e.value])}
                />
              </Col>
              <Col className='d-block d-md-none'>
                <hr className='my-2 hr-dark' />
              </Col>
              <Col md={3} xs={12}>
                <Select
                  menuShouldScrollIntoView
                  placeholder={t('profileSelect')}
                  isSearchable
                  options={Object.values(profilesList).map((p) => {
                    return {
                      label: t(`${p.code}`),
                      value: p.code,
                    };
                  })}
                  value={Object.keys(selectedProfile).length > 0 ? selectedProfile : ''}
                  styles={selectStyle}
                  onChange={(e) => {
                    setSelectedProfile(e);
                    setSelectedDevice('');
                    getProfileUsers(e.value);
                    firstSelectRef.current.openMenu();
                    setIsOpen(true);
                  }}
                />
              </Col>
              <Col md={3} xs={12}>
                <Select
                  menuShouldScrollIntoView
                  placeholder={t('deviceSelect')}
                  isSearchable
                  options={Object.values(devicesList).map((d) => {
                    return {
                      label: d.ghatorname + ' - ' + d.ghatorserial,
                      value: d.ghatorserial,
                      id: d.ghatorid,
                      serial: d.ghatorserial,
                    };
                  })}
                  value={Object.keys(selectedDevice).length > 0 ? selectedDevice : ''}
                  styles={selectStyle}
                  onChange={(e) => {
                    setSelectedDevice(e);
                    setSelectedProfile('');
                    getDeviceUsers(e.id, e.serial);
                    firstSelectRef.current.openMenu();
                    setIsOpen(true);
                  }}
                />
              </Col>
              {selectedOption.length > 0 && (
                <Col xs={12} className='mt-2'>
                  <Card className='text-center pb-1'>
                    <Card.Title>{t('users')}:</Card.Title>
                    <Row className='p-0 m-0'>
                      {selectedOption?.map((email, index) => {
                        return (
                          <Col xs={12} md={4} key={index}>
                            {email}{' '}
                            <Button
                              variant='outline-danger'
                              size='sm'
                              className='p-0 px-1 lineH'
                              onClick={() => {
                                const newSelectedOption = [...selectedOption];
                                newSelectedOption.splice(index, 1);
                                setSelectedOption(newSelectedOption);
                              }}
                            >
                              <span className='fw-bold'>x</span>
                            </Button>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
            <hr className='my-2 hr-dark' />
            <Row className='m-0 p-0'>
              <Col xs={12} className='px-0'>
                <Card.Title className='text-center pb-2'>{t('sendPushNotification')}</Card.Title>
                <Row className='justify-content-center'>
                  <Col md={4} xs={12}>
                    <Form.Control
                      required
                      type='text'
                      autoComplete='off'
                      placeholder={t('pushTitle')}
                      value={pushTitle}
                      onChange={(e) => setPushTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={8} xs={12}>
                    <Form.Control
                      required
                      type='text'
                      autoComplete='off'
                      placeholder={t('pushMsg')}
                      value={pushMsg}
                      onChange={(e) => setPushMsg(e.target.value)}
                    />
                  </Col>
                  <Col xs={6} className='text-center'>
                    <Button variant='primary' className='my-2' type='submit'>
                      {t('sendPush')}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <hr className='my-0 hr-dark' />
              <Col xs={12} className='mt-2'>
                <Card.Title className='text-center pb-2'>{t('copyUserEmails')}</Card.Title>
                <Row className='justify-content-center'>
                  <Col xs={6} className='text-center'>
                    <Button variant='primary' className='my-2' onClick={() => copyToClipboard()}>
                      {t('copyEmails')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default GlobalAlert;
