/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiSelectMultiple } from 'react-icons/bi';
import { FaTrashAlt } from 'react-icons/fa';
import { IoMdSwap } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IDRI } from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import RuleConfig from './RuleConfig';
import moment from 'moment';
import { FiAlertTriangle } from 'react-icons/fi';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';

const IdriRules = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useDispatch();

  const idri = useSelector((state) => state.idri);
  const [runAsync, setRunAsync] = useState(false);
  const [block, setBlock] = useState(false);
  const [editing, setEditing] = useState(null);
  const [checkRules, setCheckRules] = useState([]);

  const [rules, setRules] = useState([]);

  // const [storedRules, setStoredRules] = useState(
  //   localStorage.getItem(`rules-${idri.idriserial}`)
  //     ? JSON.parse(localStorage.getItem(`rules-${idri.idriserial}`))
  //     : []
  // );

  let counter = null;
  const checkAsync = (id, progList, del, modify) => {
    if (counter === 0) {
      getAsync(id, progList, del, modify);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id, progList, del, modify);
      }, 2000);
    }
  };
  //get async response from device
  const getAsync = (id, progList, del, modify) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          if (res.data.found === true && res.data.response.result === true) {
            localStorage.removeItem(`newRule-${idri.ghatorserial}`);
            getIdri(null, progList, del, modify);
            // setIsPending(false);
            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdri(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              getIdri(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id, progList, del, modify);
          } else if (res.data.found === false) {
            getIdri(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getIdri = (errorAsync, progList, del, modify) => {
    setIsPending(true);
    setError(null);
    setEditing(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        // console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          errorAsync && setError(errorAsync);
          del === 'del'
            ? progList &&
              setError(t('rule') + ' #' + progList + ' ' + t('deleted') + ' ' + t('success'))
            : progList &&
              setError(
                t('rule') +
                  ' #' +
                  progList +
                  ` ${modify ? t('modificated') : t('installed')} ${t('success')}`
              );
          dispatch({
            type: IDRI,
            payload: res.data.ghator,
          });
          // setIdri(res.data.idri);
          // setNProg(null);
          setCheckRules([]);
          setIsPending(false);
          modify
            ? scrollToElement(`id_${progList}`, true, 'start', 300)
            : scrollToElement('control', true, 'start', 300);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const onDragEnd = (result) => {
    // console.log(result);
    // console.log(Array.from(rules));
    const newItems = Array.from(rules);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setRules(newItems);
  };

  const addRule = (e, rules) => {
    e && e.preventDefault();
    console.log(rules);
    // console.log(rules.conditions);
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      idrule: rules.data.idrule ? rules.data.idrule : null,
      // name: rules.data.name,
      priority:
        rules.priority && Object.keys(rules.priority).length === 0
          ? 1
          : rules.priority.position.kind === 0
          ? rules.priority.rule
            ? rules.priority.rule.index
            : 1
          : rules.priority.rule
          ? rules.priority.rule.index + 1
          : 1,
      ruleconditions: {
        conditions: Object.entries(rules.conditions).map(([key, c]) => {
          return {
            logic1: {
              operating: Object.entries(c.operator1)
                .map(([key, o]) => {
                  if (o.type) {
                    return {
                      [o.type]:
                        o.type === 'co'
                          ? [
                              o.value === 'true' ? true : o.value === 'false' ? false : o.value,
                              o.id.id,
                            ]
                          : o.id
                          ? o.id
                          : o.value,
                    };
                  }
                })
                .filter((o) => {
                  if (o) {
                    return o;
                  }
                }),
              operators:
                c.math && Object.keys(c.math).length > 0 && c.math[0].value !== null
                  ? Object.values(c.math).map((m) => {
                      return m.value;
                    })
                  : [],
            },
            comparoperator: c.logic.value,
            logic2: {
              operating: Object.entries(c.operator2)
                .map(([key, o]) => {
                  if (o.type) {
                    return {
                      [o.type]:
                        o.type === 'co'
                          ? [
                              o.value === 'true' ? true : o.value === 'false' ? false : o.value,
                              o.id ? o.id.id : null,
                            ]
                          : o.id
                          ? o.id
                          : o.value,
                    };
                  }
                })
                .filter((o) => {
                  if (o) {
                    return o;
                  }
                }),
              operators:
                c.math2 && Object.keys(c.math2).length > 0 && c.math2[0].value !== null
                  ? Object.values(c.math2).map((m) => {
                      return m.value;
                    })
                  : [],
            },
          };
        }),
        logicoperators:
          Object.keys(rules.conditions).length > 1
            ? Object.keys(rules.conditions)
                .map((c) => {
                  return 0;
                })
                .slice(1)
            : [],
      },

      ruleactions: 
        !rules.noactions
          ? 
            Object.entries(rules.actions).map(([key, a]) => {
              return {
                idactuator: a.actuator.id,
                action: a.action.kind,
                duration: a.duration ? parseFloat(a.duration) : '',
                afmv: a.volume ? parseFloat(a.volume) : '',
                afv: a.flow ? parseFloat(a.flow) : '',
              };
            })
          :
            []

    };
    // console.log(rules.conditions);
    // console.log(JSON.stringify(rules.conditions));
    // newRule &&
    // localStorage.setItem(
    //   `newrule-${idri.idriserial}`,
    //   JSON.stringify({
    //     conditions: rules.conditions,
    //   })
    // );

    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/setrule`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          // setEditProg(false);
          setBlock(false);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            // setNProg(res.data.nprogram);
            setTimeout(() => {
              // !newRule
              //   ? checkAsync(res.data.asyncid, res.data.nprogram, null, 'mod')
              //   : checkAsync(res.data.asyncid, res.data.nprogram, null, null);
              checkAsync(res.data.asyncid, null, null, null);
            }, 4000);
          }
          // setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  const delRule = (rule) => {
    // e.preventDefault();
    setIsPending(true);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      rules: typeof rule === 'object' ? rule : [rule],
    };
    console.log(body);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/delrules`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          // console.log(res.data.array_nprogram_delete);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            // setNProg(res.data.nprogram);
            setTimeout(() => {
              checkAsync(res.data.asyncid, res.data.array_nprogram_delete, 'del');
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  const enableRule = (e, rule, state) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    // console.log(nPr);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      rules: rule
        ? [
            {
              idrule: rule,
              enable: !state,
            },
          ]
        : checkRules.map((p) => ({
            idrule: Object.values(idri.rules)
              .map((rule) => {
                if (p === rule.idrule) {
                  return rule.idrule;
                }
              })
              .find((value) => {
                return value;
              }),
            enable: !Object.values(idri.rules)
              .map((rule) => {
                if (p === rule.idrule) {
                  return rule.enable;
                }
              })
              .find((value) => {
                return value;
              }),
          })),
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/rulable`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            // setNProg(res.data.nprogram);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  // console.log(checkRules);

  // const allRules = (e) => {
  //   e && e.preventDefault();
  //   setIsPending(true);
  //   setError(null);
  //   let body = {
  //     ididri: idri.ididri,
  //     idriserial: idri.idriserial,
  //     enable: !idri.all_rules_disabled,
  //   };
  //   console.log(body);
  //   axios
  //     .post(`${process.env.REACT_APP_API_SRT}/v2/rulablall`, body, config)
  //     .then((res) => {
  //       if (res.data.code === '0') {
  //         console.log(res.data);
  //         counter = 0;
  //         if (counter === 0) {
  //           setRunAsync(true);
  //           setTimeout(() => {
  //             checkAsync(res.data.asyncid);
  //           }, 4000);
  //         }
  //       } else {
  //         setIsPending(false);
  //         setError(t('problem'));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);
  //       let code = err.response.data.code;
  //       if (code === '1') {
  //         setIsPending(false);
  //         localStorage.removeItem('autoAcceso');
  //         alert(t('sessionExpired'));
  //         window.location.href = '/';
  //       } else {
  //         setIsPending(false);
  //         setError(
  //           t(`error${err.response.data.msg_code}`)
  //             ? t(`error${err.response.data.msg_code}`)
  //             : t('errorUnknown')
  //         );
  //       }
  //     });
  // };

  const checkId = (rules) => {
    return rules.map((p) =>
      Object.values(idri.rules)
        .map((rule) => {
          if (rule.idrule === p) {
            return rule.priority;
          }
        })
        .find((value) => {
          return value;
        })
    );
  };

  useEffect(() => {
    // const getRules = () => {
    let rulesList = [];
    // for (let i = 0; i < storedRules.length; i++) {
    Object.values(idri.rules).map((r) => {
      // if (storedRules[i] === r.priority) {
      rulesList.push(r);
      // }
    });
    // }
    setRules(rulesList);
    // };
    // if (storedRules && storedRules.length === 0) {
    //   localStorage.setItem(
    //     `rules-${idri.idriserial}`,
    //     JSON.stringify(
    //       idri.rules.map((rule) => {
    //         return rule.priority;
    //       })
    //     )
    //   );
    //   setStoredRules(JSON.parse(localStorage.getItem(`rules-${idri.idriserial}`)));
    //   getRules();
    // }
    // if (storedRules.length < Object.keys(idri.rules).length) {
    //   Object.values(idri.rules).map(
    //     (r) => !storedRules.includes(r.priority) && storedRules.unshift(r.priority)
    //   );
    //   getRules();
    // } else {
    // getRules();
    // }
  }, [idri]);
  // console.log(storedRules);
  // useEffect(() => {
  // console.log(prog);
  // rules &&
  //   rules.length > 0 &&
  //   localStorage.setItem(
  //     `rules-${idri.idriserial}`,
  //     JSON.stringify(
  //       rules &&
  //         rules.map((rule) => {
  //           return rule.priority;
  //         })
  //     )
  //   );
  // rules &&
  //   setStoredRules(
  //     rules.map((rule) => {
  //       return rule.priority;
  //     })
  //   );
  // }, [rules]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(rules);

  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      ) : (
        <>
          {idri.rules_mismatch && (
            <Card border='danger' className='rounded-0 mb-0'>
              <Row className='text-center px-0 mx-0'>
                <Col
                  xs={9}
                  className='d-flex align-items-center justify-content-center  alert-danger'
                >
                  <Row className='fw-bold'>
                    <Col xs={12}>
                      {t('mismatchRules')}
                      <FiAlertTriangle className='mx-1 dangerAnimation' fontSize='1.2em' />
                    </Col>

                    {idri.rules_mismatch_sent_alert_date && (
                      <Col xs={12}>
                        {t('startDate') +
                          ': ' +
                          moment
                            .utc(idri.rules_mismatch_sent_alert_date)
                            .local()
                            .format('DD-MM-YYYY, HH:mm:ss')}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={3} className='d-grid px-0'>
                  <Button
                    size='sm'
                    className='rounded-0'
                    variant='danger'
                    onClick={(e) => props.resendRules(e)}
                  >
                    {t('update')}
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
          <Accordion
            defaultActiveKey={
              localStorage.getItem(`openRules-${idri.ghatorserial}`)
                ? JSON.parse(localStorage.getItem(`openRules-${idri.ghatorserial}`))
                : null
            }
            alwaysOpen
            className={`acc my-2 ${!idri.rules_mismatch && 'mt-0'}`}
            // className={`my-2 ${!idri.user_permissions.program && 'disabledCustom'}`}
            onSelect={(e) => {
              localStorage.setItem(`openRules-${idri.ghatorserial}`, JSON.stringify(e));
            }}
          >
            <Accordion.Item id='newRule' eventKey='0'>
              <Accordion.Header
                onClick={(e) => {
                  scrollToElement('newRule', true, 'start', 300);
                }}
              >
                <Table bordered className='mb-0' id='alert'>
                  <thead>
                    <tr className='primary text-center'>
                      <th colSpan={2} className='lh-sm'>
                        <p className='fw-normal lh-sm my-0'>{t('ruleNew')}</p>
                      </th>
                    </tr>
                  </thead>
                </Table>
              </Accordion.Header>
              <Accordion.Body
                // className='text-center p-1'
                className={`text-center p-1 ${
                  (!idri.user_permissions.edit || !idri.user_permissions.rules) && 'disabledCustom'
                }`}
              >
                <RuleConfig
                  key='newRule'
                  id='newRule'
                  block={block}
                  new={true}
                  list={idri.rules}
                  addRule={addRule}
                  noactions={false}
                  // sendProg={sendProg}
                  // setSendProg={setSendProg}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id='rules' eventKey='1'>
              <Accordion.Header
                onClick={(e) => {
                  scrollToElement('rules', true, 'start', 300);
                }}
              >
                <Table bordered className='mb-0' id='alert'>
                  <thead>
                    <tr className='primary text-center'>
                      <th colSpan={2} className='lh-sm'>
                        <p className='fw-normal lh-sm my-0'>{t('ruleList')}</p>
                      </th>
                    </tr>
                  </thead>
                </Table>
              </Accordion.Header>
              <Accordion.Body
                // className='text-center p-0'
                className={`text-center p-0 ${
                  (!idri.user_permissions.edit || !idri.user_permissions.rules) && 'disabledCustom'
                }`}
              >
                {/* {idri.rules && Object.keys(idri.rules).length > 0 ? ( */}
                {idri.rules && Object.keys(idri.rules).length > 0 ? (
                  // <DragDropContext onDragEnd={onDragEnd}>
                  //   <Droppable droppableId='droppable'>
                  //     {(provided, snapshot) => (
                  //       <div
                  //         ref={provided.innerRef}
                  //         // style={getListStyle(snapshot.isDraggingOver)}
                  //       >
                  rules &&
                  Object.entries(rules).map(([index, rule]) => (
                    // <Draggable
                    //   key={rule.priority}
                    //   draggableId={rule.priority.toString()}
                    //   index={parseInt(index)}
                    // >
                    //   {(provided, snapshot) => (
                    //     <div
                    //       ref={provided.innerRef}
                    //       {...provided.draggableProps}
                    //       {...provided.dragHandleProps}
                    //       style={getItemStyle(
                    //         snapshot.isDragging,
                    //         provided.draggableProps.style,
                    //         rule.active
                    //       )}
                    //       className='strip'
                    //     >
                    <Row
                      key={index}
                      className='mx-0 px-0 strip'
                      style={{
                        background: rule.active && 'rgb(230, 240, 230)',
                      }}
                    >
                      <Col
                        xs={1}
                        className='d-flex justify-content-center align-items-center px-0 bg-secondary bg-opacity-10 rounded-0 rounded-start'
                      >
                        <span className=' fw-bold'>{rule.priority}</span>
                      </Col>
                      <Col xs={11}>
                        <RuleConfig
                          key={`rule_${rule.priority}`}
                          list={rules}
                          block={block}
                          setBlock={setBlock}
                          editing={editing}
                          setEditing={setEditing}
                          rule={rule}
                          addRule={addRule}
                          delRule={delRule}
                          enableRule={enableRule}
                          checkRules={checkRules}
                          setCheckRules={setCheckRules}
                          getIdri={getIdri}
                          noactions={rule.ruleactions.length === 0}
                        />
                      </Col>
                    </Row>
                  ))
                ) : (
                  //     </div>
                  // )}
                  // </Draggable>
                  // ))}
                  // {provided.placeholder}
                  //       </div>
                  //     )}
                  //   </Droppable>
                  // </DragDropContext>
                  <Col className='text-danger text-center'>{t('noRules')}</Col>
                )}
                {/* {idri.rules && Object.keys(idri.rules).length > 0 && (
                <Col xs={12} className='my-3'>
                  <Button onClick={(e) => {}}>{t('saveOrder')}</Button>
                </Col>
              )} */}
                {checkRules.length >= 1 && (
                  <Card className='position-fixed top-0 end-0 me-2'>
                    <Card.Body className='p-1'>
                      <Card.Title className=' text-center'>{t('controlGrupo')}</Card.Title>
                      <Button
                        variant='outline-secondary'
                        className='p-0 px-1 mx-3'
                        onClick={(e) =>
                          setCheckRules(
                            Object.values(idri.rules).map((r) => {
                              return r.idrule;
                            }).length === checkRules.length
                              ? []
                              : Object.values(idri.rules).map((r) => {
                                  return r.idrule;
                                })
                          )
                        }
                      >
                        <BiSelectMultiple type='button' />
                      </Button>
                      <Button
                        variant='outline-primary'
                        className='p-0 px-1 mx-3'
                        onClick={(e) => enableRule(e)}
                      >
                        <IoMdSwap type='button' />
                      </Button>
                      <Button
                        variant='outline-danger'
                        // size='sm'
                        className='p-0 px-1 mx-3'
                        onClick={(e) =>
                          window.confirm(t('delRules') + ' #' + checkId(checkRules) + '?')
                            ? delRule(checkRules)
                            : null
                        }
                      >
                        <FaTrashAlt type='button' />
                      </Button>
                    </Card.Body>
                  </Card>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </>
  );
};

export default IdriRules;
