import { t } from 'i18next';

const errorHandler = (error, setIsPending, setError, noReload, closeModal, setError12) => {
  console.log(error);
  let code = error.response.data.code;
  if (code === '1') {
    setIsPending && setIsPending(false);
    localStorage.removeItem('autoAcceso');
    alert(t('sessionExpired'));
    !noReload && (window.location.href = '/');
  } else if (code === '5' && error.response.data.msg_code === '12') {
    setError12 && setError12(true);
    setError(t(`error${error.response.data.msg_code}`));
  } else if (code === '10' && error.response.data.msg_code === '36') {
    window.location.href = '/maintenance';
  } else {
    setIsPending && setIsPending(false);
    setError(
      t(`error${error.response.data.msg_code}`)
        ? t(`error${error.response.data.msg_code}`)
        : t('errorUnknown')
    );
    closeModal && closeModal();
  }
};

export default errorHandler;
