const checkNotification = async () => {
  let notification = await navigator.serviceWorker
    .getRegistration()
    .then((registration) => {
      return registration.pushManager.getSubscription().then((subscription) => {
        return subscription;
      });
    });
  return notification;
};

export default checkNotification;
