/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { MapContainer, Marker, useMapEvents } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import { useTranslation } from 'react-i18next';

const MapSrt = (props) => {
  const { t } = useTranslation();
  const position = [39.5, -0.7];
  const [coords, setCoords] = useState({ lat: '', lng: '' });
  const [get, setGet] = useState(false);

  let icon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  const GetCoords = () => {
    setGet(false);
    const map = useMapEvents({
      locationfound(e) {
        console.log(e.latlng);
        if (props.coords) {
          let coor =
            'lat' in props.coords
              ? props.coords
              : {
                  lat: parseFloat(props.coords[0]),
                  lng: parseFloat(props.coords[1]),
                };
          setCoords(coor);
          map.flyTo(coor, 14);
        } else {
          setCoords(e.latlng);
          map.flyTo(e.latlng, 14);
        }
        // props.setCoord({ lat: e.latlng.lat, lng: e.latlng.lng });
        setGet(true);
        // setCounter(1);
      },
      // click(e) {
      //   setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
      //   props.setCoord({ lat: e.latlng.lat, lng: e.latlng.lng });
      // },
    });

    map.locate();

    return null;
  };

  const GetCoordsClick = () => {
    const map = useMapEvents({
      click(e) {
        setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
        // props.setCoord({ lat: e.latlng.lat, lng: e.latlng.lng });
      },
    });
    return null;
  };

  // console.log(props)
  return (
    <>
      <MapContainer
        id='initMap'
        style={{ height: '50vh' }}
        center={position}
        zoom={10}
        attributionControl={false}
        className='noAttributes'
      >
        {!get && <GetCoords />}

        <GetCoordsClick />
        <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_MAP_API_KEY} type={'hybrid'} />
        {coords && <Marker position={coords} icon={icon}></Marker>}
      </MapContainer>
      <Row className='justify-content-center my-1'>
        <Col xs={12} md={3} className=''>
          {t('latitud')}: {typeof coords.lat == 'string' ? '--' : coords.lat.toFixed(5)}
        </Col>
        <Col xs={12} md={3} className=''>
          {t('longitud')}: {typeof coords.lng == 'string' ? '--' : coords.lng.toFixed(5)}
        </Col>
      </Row>
      <Col className='mt-3'>
        <Button
          className='btn-sm dark'
          onClick={() => {
            props.setMapModal(false);
            props.setCoord({ lat: coords.lat, lng: coords.lng });
            props.setEdited && props.setEdited(true);
          }}
        >
          {t('aceptar')}
        </Button>
      </Col>
    </>
  );
};

export default MapSrt;
