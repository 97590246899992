import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ModalGenerico = (props) => {
  const { t } = useTranslation();

  if (!props.show) {
    return null;
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onClose}
        size='lg'
        className='text text-center px-0'
        // dialogClassName='modal-90w'
      >
        <div className=' border border-3 border-dark'>
          <Modal.Header>
            <Modal.Title className='text-uppercase mx-auto '>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.children}</Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant='secondary' onClick={props.onClose}>
              <FontAwesomeIcon className='me-1' icon={faTimes} />
              {t('cerrar')}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default ModalGenerico;
