/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CHANGED } from '../../../redux/constants/idriConstants';
import IdriConfig from '../components/newInstalation/IdriConfig';
import IdriInit from '../components/newInstalation/IdriInit';

const ConfigModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [menu, setMenu] = useState('init');
  const [menuChange, setMenuChange] = useState(false);
  const changed = useSelector((state) => state.changed);

  useEffect(() => {
    document.getElementById('myCheck') && document.getElementById('myCheck').click();
    dispatch({
      type: CHANGED,
      payload: { ...changed, config: false },
    });
  }, []);

  //   console.log(props)
  return (
    <>
      <Row className='mb-2 cHeader'>
        <Col>
          {t('altaInst')}:{' '}
          <strong>
            {props.idriStatus.discharged ? t('realizada') : t('pendiente')}
          </strong>
        </Col>
        <Col>
          {t('estadoInst')}:{' '}
          <strong>
            {props.idriStatus.inicialized ? t('inicializado') : t('noInicializado')}{' '}
          </strong>
        </Col>
      </Row>
      <Tabs
        // id='tabs'
        activeKey={menu}
        onSelect={(e) => {
          setMenu(e);
          setMenuChange(!menuChange);
        }}
        className='mb-3'
        justify
      >
        <Tab eventKey='init' title={t('inicio')}>
          {menu === 'init' && (
            <IdriInit
              id={props.id}
              serial={props.serial}
              v_idri={props.v_idri}
              setIdriStatus={props.setIdriStatus}
              closeModal={() => props.closeModal()}
            />
          )}
        </Tab>
        <Tab eventKey='config' title={t('configurar')}>
          {menu === 'config' && (
            <IdriConfig
              id={props.id}
              serial={props.serial}
              v_idri={props.v_idri}
              setIdriStatus={props.setIdriStatus}
              closeModal={() => props.closeModal()}
            />
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default ConfigModal;
