/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Accordion, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';

const FirmwareManagement = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [firmwares, setFirmwares] = useState([]);
  const [data, setData] = useState({});
  const [file, setFile] = useState(null);
  const [key, setKey] = useState(0);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const UploadFirmware = async (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('version', data.version);
    formData.append('v_ghator', data.v_ghator);
    formData.append('date', moment(data.date).format('DD-MM-yyyy'));

    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/uploadfirm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setData({});
          setFile(null);
          setKey(key + 1);
          getFirmwares(true);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getFirmwares = (added) => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/availablefirm`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setFirmwares(res.data.firmwares);
          added && setError(t('uploaded') + ' ' + t('success'));
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const delFirmware = (version) => {
    setIsPending(true);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/delfirm`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { versions: [version] },
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setError(t('firmware') + ' ' + t('deleted') + ' ' + t('success'));
          getFirmwares();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    getFirmwares();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Container className='text'>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}
      <Accordion defaultActiveKey='0' className='acc' alwaysOpen>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='primary text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal lh-sm my-0'>{t('uploadFile')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={UploadFirmware}>
              <Row className='px-0'>
                <Col xs={12} md={4}>
                  <strong className=''>{t('firmVersion')}</strong>
                  <Form.Control
                    required
                    type='text'
                    value={data.version ? data.version : ''}
                    onChange={(e) =>
                      setData({
                        ...data,
                        version: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={12} md={4}>
                  <strong className=''>{t('deviceVersion')}</strong>
                  <Form.Control
                    required
                    type='text'
                    value={data.v_ghator ? data.v_ghator : ''}
                    onChange={(e) =>
                      setData({
                        ...data,
                        v_ghator: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={12} md={4}>
                  <strong className=''>{t('firmDate')}</strong>
                  <DatePicker
                    required
                    locale={localStorage.getItem('language')}
                    customInput={<Form.Control />}
                    dateFormat='dd-MM-yyyy'
                    selected={data.date ? data.date : ''}
                    onChange={(date) =>
                      setData({
                        ...data,
                        date: date,
                      })
                    }
                    maxDate={moment()._d}
                    withPortal
                  />
                </Col>
                <Col xs={12}>
                  <strong className=''>{t('selectFile')}:</strong>
                  <InputGroup>
                    <Form.Control
                      required
                      type='file'
                      className=''
                      key={key}
                      onChange={handleFileChange}
                    />
                    <Button disabled={!file} variant='primary' className='' type='submit'>
                      {t('upload').toUpperCase()}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='primary text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal lh-sm my-0'>{t('availableFirmwares')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            {firmwares.map((firm, i) => (
              <>
                {i > 0 && <hr className='hr-dark d-block d-md-none mt-2 mb-0' />}
                <Row key={firm.version} className='px-0'>
                  <Col xs={12} md={4}>
                    <strong className=''>{t('firmVersion')}</strong>
                    <Form.Control readOnly type='text' value={firm.version ? firm.version : ''} />
                  </Col>
                  <Col xs={12} md={4}>
                    <strong className=''>{t('deviceVersion')}</strong>
                    <Form.Control readOnly type='text' value={firm.v_ghator ? firm.v_ghator : ''} />
                  </Col>
                  <Col xs={12} md={4} className='d-grid mt-2 mt-md-4'>
                    <Button
                      id='delUser'
                      className=' dark'
                      onClick={() =>
                        window.confirm(t('delFirm')) ? delFirmware(firm.version) : null
                      }
                    >
                      {t('firmwareDel')}
                    </Button>
                  </Col>
                </Row>
              </>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default FirmwareManagement;
