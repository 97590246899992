/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_ACTUATOR,
  ADD_SENSOR,
  CHANGED,
  DEL_SENSOR,
} from '../../../../redux/constants/idriConstants';
import roundNearest from '../../../../support_func/roundNearest';
import scaleDecimal from '../../../../support_func/scaleDecimal';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import scrollToElement from '../../../../support_func/scrollToElement';
import minMax from '../../../../support_func/minMax';

const Sensor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idriData = useSelector((state) => state.idriData);
  const sensorsList = useSelector((state) => state.sensorsList);
  const actuatorsList = useSelector((state) => state.actuatorsList);
  const changed = useSelector((state) => state.changed);
  const idri = useSelector((state) => state.idri);

  const key = parseInt(props.pos);
  const [maxCont, setmaxCont] = useState(false);
  const [kindSensors, setKindSensors] = useState({});
  const scalesAllowed = [
    0.001, 0.002, 0.004, 0.005, 0.008, 0.01, 0.02, 0.025, 0.04, 0.05, 0.1, 0.125, 0.2, 0.25, 0.5, 1,
  ];

  //update an sensor in redux store
  const updateSensor = (obj) => {
    //create object with changed values
    let sensor = sensorsList[key] || {};
    sensor = {
      ...sensor,
      [Object.keys(obj)]: Object.values(obj)[0],
    };

    //update actuator association with sensor input
    let main = actuatorsList || {};
    Object.keys(obj)[0] === 'ninput' &&
      Object.entries(actuatorsList).forEach(([i, a]) => {
        if (a.lfminput === sensorsList[key].ninput) {
          let actuator = actuatorsList[i] || {};
          actuator = {
            ...actuator,
            lfminput: typeof Object.values(obj)[0] !== 'string' && Object.values(obj)[0],
          };
          main = { ...main, [i]: actuator };
        }
      });
    Object.keys(obj)[0] === 'ninput' &&
      dispatch({
        type: ADD_ACTUATOR,
        payload: main,
      });

    //update sensor in redux store
    dispatch({
      type: ADD_SENSOR,
      payload: { ...sensorsList, [key]: sensor },
    });

    //update sensor input select when sensor type is changed
    if (parseFloat(props.v_idri ? props.v_idri : idriInitData.v_idri) < 3) {
      Object.keys(obj)[0] === 'kind' &&
        contadores.includes(String(Object.values(obj)[0])) &&
        sensorsList[key].ninput !== 7 &&
        sensorsList[key].ninput !== 8 &&
        (document.getElementById(`mainIn_${key}`).value = '');
    }

    //update actuator association select when sensor type is changed
    Object.keys(obj)[0] === 'kind' &&
      Object.entries(actuatorsList).forEach(([i, a]) => {
        if (a.lfminput === sensorsList[key].ninput) {
          let actuator = actuatorsList[i] || {};
          actuator = { ...actuator, lfminput: '' };
          main = { ...main, [i]: actuator };
          dispatch({
            type: ADD_ACTUATOR,
            payload: main,
          });
        }
      });

    //sensor change checking
    if (!changed.config || changed.config === false) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: true },
      });
    }
  };

  // removing an sensor from a redux store
  const deleteSensor = () => {
    //remove asociation in actuators
    let main = actuatorsList || {};
    Object.entries(actuatorsList).forEach(([i, a]) => {
      if (a.lfminput === sensorsList[key].ninput) {
        let actuator = actuatorsList[i] || {};
        actuator = { ...actuator, lfminput: '' };
        main = { ...main, [i]: actuator };
      }
    });
    dispatch({
      type: ADD_ACTUATOR,
      payload: main,
    });

    //remove sensor from redux store
    delete sensorsList[key];
    dispatch({
      type: DEL_SENSOR,
      payload: { ...sensorsList },
    });
    if (!changed.config || changed.config === false) {
      dispatch({
        type: CHANGED,
        payload: { ...changed, config: true },
      });
    }
  };

  //sensor input control
  useEffect(() => {
    //enable sensor input option
    let min = sensorsList[key].kind && contadores.includes(String(sensorsList[key].kind)) ? 7 : 1;
    let max =
      sensorsList[key].kind && contadores.includes(String(sensorsList[key].kind))
        ? document.getElementsByClassName(`entrada${key}`).length + 6
        : document.getElementsByClassName(`entrada${key}`).length;
    for (let index = min; index <= max; index++) {
      document.getElementById(`in_${index}_${key}`) &&
        (document.getElementById(`in_${index}_${key}`).disabled = false);
    }
    //input option disable when some sensor is selected
    Object.keys(sensorsList).forEach(
      (i) =>
        sensorsList[i].ninput &&
        document.getElementById(`in_${sensorsList[i].ninput}_${key}`) &&
        (document.getElementById(`in_${sensorsList[i].ninput}_${key}`).disabled = true)
    );

    //counters amount control
    if (parseFloat(props.v_idri ? props.v_idri : idriInitData.v_idri) < 3) {
      let cont = [];
      Object.keys(sensorsList).length !== 0 &&
        Object.values(sensorsList).forEach((sensor) => {
          contadores.includes(String(sensor.kind)) && cont.push(sensor);
        });
      if (cont.length === 2) {
        setmaxCont(true);
      } else {
        setmaxCont(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsList]);

  //submit behavior
  const submit = (e) => {
    e.preventDefault();
  };

  //counters check
  const contadores = [];
  Object.entries(idriData.kindsensors).map(
    ([key, value]) => value.kind_code === 3 && contadores.push(key)
  );
  //pressure check
  const presion = [];
  Object.entries(idriData.kindsensors).map(
    ([key, value]) => (value.kind_code === 1 || value.kind_code === 2) && presion.push(key)
  );

  // const minMax = (elem, value, min, max) => {
  //   let res = Math.max(min, Math.min(max, Number(value)));
  //   updateSensor({
  //     [elem]: res,
  //   });
  // };

  const closest = (arr, val) =>
    arr.reduce((prev, curr) => {
      return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
    });

  const scaleDecimalsCheck = (value) => {
    const strValue = String(value).split('.');
    try {
      // if (value < 0.001) {
      //   return 0.001;
      // } else
      if (strValue[1] && strValue[1].length > 3) {
        return parseFloat(value).toFixed(3);
      } else {
        return value;
      }
    } finally {
      scaleFocusOut();
    }
  };

  const valueDecCheck = (value) => {
    const strValue = String(value).split('.');
    if (value && strValue[1] && strValue[1].length > scaleDecimal(sensorsList[key].scale)) {
      return parseFloat(value).toFixed(
        scaleDecimal(
          sensorsList[key].scale
            ? sensorsList[key].scale
            : idriData.kindsensors[sensorsList[key].kind].default_scale
        )
      );
    } else {
      return value;
    }
  };

  const scaleFocusOut = () => {
    let sensor = sensorsList[key];
    let actuators = actuatorsList;
    if (sensor.value) {
      // let newValue = closest(valuesAllowed, value);
      sensor.value = parseFloat(sensor.value).toFixed(scaleDecimal(sensorsList[key].scale));
    }
    if (sensor.leak_threshold) {
      sensor.leak_threshold = parseFloat(sensor.leak_threshold).toFixed(
        scaleDecimal(sensorsList[key].scale)
      );
    }
    if (sensor.cmax_day_threshold) {
      sensor.cmax_day_threshold = parseFloat(sensor.cmax_day_threshold).toFixed(
        scaleDecimal(sensorsList[key].scale)
      );
    }
    dispatch({
      type: ADD_SENSOR,
      payload: { ...sensorsList, [key]: sensor },
    });

    // sensorsList[key].ninput

    Object.entries(actuators).forEach(([keyAct, act]) => {
      if (act.lfminput === sensor.ninput) {
        if (act.flow) {
          act.flow = parseFloat(act.flow).toFixed(scaleDecimal(sensor.scale));
          dispatch({
            type: ADD_ACTUATOR,
            payload: { ...actuatorsList, [keyAct]: act },
          });
        }
      }
    });
  };

  useEffect(() => {
    if (idriData && idriData.kindsensors && Object.keys(idriData.kindsensors).length > 0) {
      let kindSensorsList = {};
      Object.entries(idriData.kindsensors).map(([i, value]) => {
        kindSensorsList[i] = {};
        if (value.kind_code === 0) {
          kindSensorsList[i].value =
            value.kind +
            ' - ' +
            t(`kindDesc${value.kind_code}`) +
            ' - ' +
            (value.normal_state ? t('abierto') : t('cerrado'));
        }
        if (value.kind_code === 1) {
          kindSensorsList[i].value =
            value.kind +
            ' - ' +
            t(`kindDesc${value.kind_code}`) +
            ' (' +
            value.analog_output_range +
            ')' +
            ' - ' +
            value.magnitude +
            ' (' +
            parseInt(value.min_value) +
            '/' +
            parseInt(value.max_value) +
            ') - ' +
            value.input_voltage;
        }
        if (value.kind_code === 2) {
          kindSensorsList[i].value =
            value.kind +
            ' - ' +
            t(`kindDesc${value.kind_code}`) +
            ' (' +
            value.analog_output_range +
            ')' +
            ' - ' +
            value.magnitude +
            ' (' +
            parseInt(value.min_value) +
            '/' +
            parseInt(value.max_value) +
            ') - ' +
            value.input_voltage;
        }
        if (value.kind_code === 3) {
          kindSensorsList[i].value =
            value.kind +
            ' - ' +
            t(`kindDesc${value.kind_code}`) +
            ' - ' +
            value.default_scale +
            ' - ' +
            value.magnitude;
        }
        if (value.kind_code === 4) {
          kindSensorsList[i].value =
            value.kind +
            ' - ' +
            t(`kindDesc${value.kind_code}`) +
            ' - ' +
            (value.normal_state ? t('abierto') : t('cerrado'));
        }
      });
      setKindSensors(kindSensorsList);
    }
  }, [idriData.kindsensors]);

  useEffect(() => {
    textLineAnimation();
  });

  return (
    <Card key={props.key} className='p-2 cardGreen'>
      <Form
        id={`entradas_list_${key}`}
        name='Form'
        onSubmit={(e) => {
          submit(e);
        }}
      >
        <Row>
          <Form.Control
            disabled
            className='visually-hidden'
            value={sensorsList[key].id ? sensorsList[key].id : ''}
          ></Form.Control>
          <Col xs={12} md={3}>
            <strong>{t('entrada')}</strong>
            <Form.Select
              required
              id={`mainIn_${key}`}
              value={sensorsList[key].ninput ? sensorsList[key].ninput : ''}
              onChange={(e) => {
                updateSensor({ ninput: parseInt(e.target.value) });
                scrollToElement(`entradas_list_${key}`, true, 'center', 300);
              }}
            >
              {parseFloat(props.v_idri ? props.v_idri : idriInitData.v_idri) < 3
                ? Array.apply(null, Array(9)).map((x, i) => {
                    if (
                      sensorsList[key].kind &&
                      contadores.includes(String(sensorsList[key].kind))
                    ) {
                      return (
                        <>
                          {i === 0 && (
                            <option
                              key={i}
                              value=''
                              selected={sensorsList[key].ninput && sensorsList[key].ninput === ''}
                              disabled
                            >
                              {t('selectEntrada')}...
                            </option>
                          )}
                          {i > 6 && (
                            <option
                              key={i}
                              id={`in_${i}_${key}`}
                              className={`entrada${key}`}
                              selected={sensorsList[key].ninput && sensorsList[key].ninput === i}
                              value={i}
                            >
                              E{i}
                            </option>
                          )}
                        </>
                      );
                    } else {
                      return (
                        <>
                          {i === 0 && (
                            <option
                              key={i}
                              value=''
                              selected={sensorsList[key].ninput && sensorsList[key].ninput === ''}
                              disabled
                            >
                              {t('selectEntrada')}...
                            </option>
                          )}
                          {i > 0 && (
                            <option
                              key={i}
                              id={`in_${i}_${key}`}
                              className={`entrada${key}`}
                              selected={sensorsList[key].ninput && sensorsList[key].ninput === i}
                              value={i}
                            >
                              E{i}
                            </option>
                          )}
                        </>
                      );
                    }
                  })
                : Array.apply(null, Array(81)).map((x, i) => (
                    <>
                      {i === 0 && (
                        <option
                          key={i}
                          value=''
                          selected={sensorsList[key].ninput && sensorsList[key].ninput === ''}
                          disabled
                        >
                          {t('selectEntrada')}...
                        </option>
                      )}
                      {i > 0 && (
                        <option
                          key={i}
                          id={`in_${i}_${key}`}
                          className={`entrada${key}`}
                          selected={sensorsList[key].ninput && sensorsList[key].ninput === i}
                          value={i}
                        >
                          E{i}
                        </option>
                      )}
                    </>
                  ))}
            </Form.Select>
          </Col>
          {sensorsList[key].ninput && (
            <>
              <Col xs={12} md={3}>
                <div className='animated text-truncate'>
                  <strong>{t('nombreSensor')}</strong>
                </div>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={sensorsList[key].name ? sensorsList[key].name : ''}
                  onChange={(e) => updateSensor({ name: e.target.value })}
                />
              </Col>

              <Col xs={12} md={3}>
                <strong>{t('marca')}</strong>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={sensorsList[key].brand ? sensorsList[key].brand : ''}
                  onChange={(e) => updateSensor({ brand: e.target.value })}
                />
              </Col>

              <Col xs={12} md={3}>
                <strong>{t('modelo')}</strong>
                <Form.Control
                  required
                  type='text'
                  maxLength='18'
                  value={sensorsList[key].model ? sensorsList[key].model : ''}
                  onChange={(e) => updateSensor({ model: e.target.value })}
                />
              </Col>

              <Col xs={12} md={6}>
                <strong>{t('tipoSensor')}</strong>
                <Form.Select
                  required
                  disabled={sensorsList[key].id}
                  value={sensorsList[key].kind ? sensorsList[key].kind : ''}
                  onChange={(e) => {
                    updateSensor({ kind: parseInt(e.target.value) });
                    scrollToElement(`entradas_list_${key}`, true, 'center', 300);
                  }}
                >
                  <option
                    value=''
                    selected={sensorsList[key].kind && sensorsList[key].kind === ''}
                    disabled
                  >
                    {t('selectTipoSensor')}...
                  </option>

                  {kindSensors &&
                    Object.keys(kindSensors).length > 0 &&
                    Object.entries(kindSensors).map(([i, value]) => (
                      <option
                        key={i}
                        value={i}
                        selected={sensorsList[key].kind && sensorsList[key].kind === i}
                        disabled={
                          contadores.includes(String(sensorsList[key].kind)) &&
                          // value.kind === 'contador' &&
                          maxCont
                        }
                      >
                        {value.value}
                        {/* {value.kind +
                          ' - ' +
                          (value.magnitude
                            ? value.magnitude
                            : value.normal_state
                            ? t('abierto')
                            : t('cerrado'))}
                        {value.max_value !== null &&
                          value.min_value !== null &&
                          ' - ' + value.max_value + '-' + value.min_value} */}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              {/* {console.log(sensorsList[key].kind)} */}
              {/* {console.log(idriData.kindsensors[sensorsList[key].kind].kind_code)} */}
              {sensorsList[key].kind &&
              idriData.kindsensors[sensorsList[key].kind] &&
              idriData.kindsensors[sensorsList[key].kind].kind_code &&
              (idriData.kindsensors[sensorsList[key].kind].kind_code === 1 ||
                idriData.kindsensors[sensorsList[key].kind].kind_code === 3) ? (
                <>
                  {(idriData.kindsensors[sensorsList[key].kind].kind_code === 3 ||
                    parseFloat(props.v_idri ? props.v_idri : idriInitData.v_idri) < 3) && (
                    <Col xs={12} md={3}>
                      <strong>{t('escala')}</strong>
                      <Form.Control
                        required
                        type='number'
                        min='0'
                        step='any'
                        // step={
                        //   sensorsList[key].scale?sensorsList[key].scale:'any'
                        // }
                        value={
                          sensorsList[key].scale
                            ? parseFloat(sensorsList[key].scale)
                            : parseFloat(sensorsList[key].scale) === 0
                            ? 0
                            : idriData.kindsensors[sensorsList[key].kind].default_scale
                        }
                        onChange={(e) =>
                          updateSensor({
                            scale: parseFloat(scaleDecimalsCheck(e.target.value)),
                          })
                        }
                        onBlur={() => {
                          String(parseFloat(sensorsList[key].scale).toFixed(7)).split('.')[1] &&
                          String(parseFloat(sensorsList[key].scale).toFixed(7)).split('.')[1]
                            .length > 3
                            ? updateSensor({
                                scale: parseFloat(
                                  scaleDecimalsCheck(
                                    parseFloat(closest(scalesAllowed, sensorsList[key].scale))
                                  )
                                ),
                              })
                            : scaleFocusOut();
                        }}
                      />
                    </Col>
                  )}
                  {contadores.includes(String(sensorsList[key].kind)) && (
                    <>
                      <Col xs={12} md={3}>
                        <strong>
                          {contadores.includes(String(sensorsList[key].kind)) &&
                            idriData.kindsensors[sensorsList[key].kind].magnitude &&
                            t('valor') +
                              ' (' +
                              idriData.kindsensors[sensorsList[key].kind].magnitude +
                              ')'}
                        </strong>
                        <Form.Control
                          required
                          type='number'
                          min={0}
                          value={
                            sensorsList[key].value
                              ? parseFloat(sensorsList[key].value)
                              : parseFloat(sensorsList[key].value) === 0
                              ? 0
                              : ''
                          }
                          step={
                            sensorsList[key].scale
                              ? parseFloat(sensorsList[key].scale)
                              : idriData.kindsensors[sensorsList[key].kind].default_scale
                          }
                          onChange={(e) =>
                            updateSensor({
                              value: parseFloat(valueDecCheck(e.target.value)),
                            })
                          }
                          onBlur={() => {
                            updateSensor({
                              value: valueDecCheck(
                                roundNearest(
                                  sensorsList[key].value,
                                  sensorsList[key].scale
                                    ? parseFloat(sensorsList[key].scale)
                                    : idriData.kindsensors[sensorsList[key].kind].default_scale
                                )
                              ),
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='animated text-truncate'>
                          <strong>
                            {t('consumoMaxD')} (
                            {idriData.kindsensors[sensorsList[key].kind].magnitude})
                          </strong>
                        </div>
                        <Form.Control
                          required
                          type='number'
                          min={
                            sensorsList[key].scale
                              ? parseFloat(sensorsList[key].scale)
                              : idriData.kindsensors[sensorsList[key].kind].default_scale
                          }
                          step={
                            sensorsList[key].scale
                              ? parseFloat(sensorsList[key].scale)
                              : idriData.kindsensors[sensorsList[key].kind].default_scale
                          }
                          value={
                            sensorsList[key].cmax_day_threshold
                              ? parseFloat(sensorsList[key].cmax_day_threshold)
                              : 100
                          }
                          onChange={(e) =>
                            updateSensor({
                              cmax_day_threshold: parseFloat(valueDecCheck(e.target.value)),
                            })
                          }
                          onBlur={() => {
                            updateSensor({
                              cmax_day_threshold: valueDecCheck(
                                roundNearest(
                                  sensorsList[key].cmax_day_threshold,
                                  sensorsList[key].scale
                                    ? parseFloat(sensorsList[key].scale)
                                    : idriData.kindsensors[sensorsList[key].kind].default_scale
                                )
                              ),
                            });
                          }}
                        />
                      </Col>
                      {/* {idriData.community && (
                        <> */}
                      <Col xs={12} md={3} className='text-center'>
                        <strong>{t('highlight')}</strong>
                        <Form.Check
                          className='mt-2'
                          type='checkbox'
                          checked={sensorsList[key].main ? sensorsList[key].main : false}
                          onChange={(e) => {
                            updateSensor({ main: e.target.checked });
                          }}
                        />
                      </Col>
                      {sensorsList[key].main && (
                        <Col xs={12} md={3}>
                          <strong>{t('codigoContador')}</strong>
                          <Form.Control
                            required={sensorsList[key].main === true}
                            type='text'
                            // maxLength='20'
                            value={sensorsList[key].customcode ? sensorsList[key].customcode : ''}
                            onChange={(e) => updateSensor({ customcode: e.target.value })}
                          />
                        </Col>
                      )}
                      {/* //   </> */}
                      {/* // )} */}

                      {parseFloat(props.v_idri ? props.v_idri : idriInitData.v_idri) >= 3 && (
                        <>
                          <Row className='mx-0 mt-2'>
                            <Card className='p-0'>
                              <Card.Header className='p-0 text-center cHeader'>
                                <strong>{t('fuga')}</strong>
                              </Card.Header>
                              <Card.Body className='p-1'>
                                <Row>
                                  <Col xs={12} md={3}>
                                    <div className='animated text-truncate'>
                                      <strong>
                                        {t('umbral')} (
                                        {idriData.kindsensors[sensorsList[key].kind].magnitude})
                                      </strong>
                                    </div>
                                    <Form.Control
                                      required
                                      type='number'
                                      min={
                                        sensorsList[key].scale
                                          ? parseFloat(sensorsList[key].scale)
                                          : idriData.kindsensors[sensorsList[key].kind]
                                              .default_scale
                                      }
                                      value={sensorsList[key].leak_threshold}
                                      step={
                                        sensorsList[key].scale
                                          ? parseFloat(sensorsList[key].scale)
                                          : idriData.kindsensors[sensorsList[key].kind]
                                              .default_scale
                                      }
                                      // step={
                                      //   sensorsList[key].scale
                                      //     ? sensorsList[key].scale
                                      //     : 'any'
                                      // }

                                      onChange={(e) =>
                                        updateSensor({
                                          leak_threshold: valueDecCheck(e.target.value),
                                        })
                                      }
                                      onBlur={(e) =>
                                        sensorsList[key].leak_threshold &&
                                        updateSensor({
                                          leak_threshold: valueDecCheck(
                                            roundNearest(
                                              sensorsList[key].leak_threshold,
                                              sensorsList[key].scale
                                                ? parseFloat(sensorsList[key].scale)
                                                : idriData.kindsensors[sensorsList[key].kind]
                                                    .default_scale
                                            )
                                          ),
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div className='animated text-truncate'>
                                      <strong>{t('resetTime')}</strong>
                                    </div>
                                    <Form.Control
                                      required
                                      type='number'
                                      min={sensorsList[key].leak_reset_t_min}
                                      max={sensorsList[key].leak_reset_t_max}
                                      value={sensorsList[key].leak_reset_t}
                                      onChange={(e) =>
                                        updateSensor({
                                          leak_reset_t: minMax(
                                            parseInt(e.target.value),
                                            0,
                                            sensorsList[key].leak_reset_t_max
                                          ),
                                        })
                                      }
                                      onBlur={(e) =>
                                        updateSensor({
                                          leak_reset_t: minMax(
                                            sensorsList[key].leak_reset_t,
                                            sensorsList[key].leak_reset_t_min,
                                            sensorsList[key].leak_reset_t_max
                                          ),
                                        })
                                      }
                                    />
                                  </Col>
                                  <Col xs={12} md={3} className='text-center'>
                                    <div className='animated text-truncate'>
                                      <strong>{t('autoClose')}</strong>
                                    </div>
                                    <Form.Check
                                      className='mt-2'
                                      type='checkbox'
                                      checked={sensorsList[key].leak_close_en ?? false}
                                      onChange={(e) =>
                                        updateSensor({
                                          leak_close_en: e.target.checked,
                                        })
                                      }
                                    />
                                  </Col>
                                  {sensorsList[key].leak_close_en && (
                                    <Row className='mx-0 p-0'>
                                      <Col xs={12} md={3}>
                                        <div className='animated text-truncate'>
                                          <strong>{t('closeTry')}</strong>
                                        </div>
                                        <Form.Control
                                          required
                                          type='number'
                                          min={sensorsList[key].leak_close_n_min}
                                          max={sensorsList[key].leak_close_n_max}
                                          value={sensorsList[key].leak_close_n}
                                          onChange={(e) =>
                                            updateSensor({
                                              leak_close_n: minMax(
                                                parseInt(e.target.value),
                                                0,
                                                sensorsList[key].leak_close_n_max
                                              ),
                                            })
                                          }
                                          onBlur={(e) =>
                                            updateSensor({
                                              leak_close_n: minMax(
                                                parseInt(e.target.value),
                                                sensorsList[key].leak_close_n_min,
                                                sensorsList[key].leak_close_n_max
                                              ),
                                            })
                                          }
                                        />
                                      </Col>
                                      <Col xs={12} md={3}>
                                        <div className='animated text-truncate'>
                                          <strong>{t('tryTime')}</strong>
                                        </div>
                                        <Form.Control
                                          required
                                          // disabled={
                                          //   !sensorsList[key].leak_close_n ||
                                          //   sensorsList[key].leak_close_n < 2
                                          // }
                                          type='number'
                                          min={sensorsList[key].leak_close_delay_min}
                                          max={sensorsList[key].leak_close_delay_max}
                                          value={sensorsList[key].leak_close_delay}
                                          onChange={(e) =>
                                            updateSensor({
                                              leak_close_delay: minMax(
                                                parseInt(e.target.value),
                                                0,
                                                sensorsList[key].leak_close_delay_max
                                              ),
                                            })
                                          }
                                          onBlur={(e) =>
                                            updateSensor({
                                              leak_close_delay: minMax(
                                                parseInt(e.target.value),
                                                sensorsList[key].leak_close_delay_min,
                                                sensorsList[key].leak_close_delay_max
                                              ),
                                            })
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Row>
                              </Card.Body>
                            </Card>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </>
          )}
        </Row>
        <Col className='text-end mt-2'>
          <Button
            className='d-none'
            variant='outline-success'
            size='sm'
            type='submit'
            id={`submitBtnSens_${key}`}
          >
            V
          </Button>
          <Button
            variant='outline-danger'
            size='sm'
            onClick={() =>
              idri && idri.discharged && sensorsList[key].id
                ? window.confirm(t('seguroDelSen'))
                  ? deleteSensor()
                  : null
                : deleteSensor()
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Form>
    </Card>
  );
};

export default Sensor;
