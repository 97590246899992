/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isValidNif from '../../../support_func/isValidNif';
import Flags from 'country-flag-icons/react/3x2';
import i18next from 'i18next';
// import Select from 'react-select';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../support_func/FixRequiredSelect';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const NewUser = (props) => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});
  // const [selectedEntity, setSelectedEntity] = useState();
  const [adsressRequired, setAdsressRequired] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const go_to = useNavigate();

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18next.changeLanguage(lang);
  };

  const postNewUser = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let host = window.location.host;
    // let noIp = 'http://srtnicole.hopto.org:8000/urlaccountactiv';
    let body = {
      ...userData,
      invocing: true,
      // entityid: selectedEntity && selectedEntity.id ? selectedEntity.id : '',
      urlaccountactiv: 'https://' + host + '/urlaccountactiv',
    };
    console.log(body);
    if (
      (body.address && body.zip && body.city && body.province && body.country) ||
      (!body.address && !body.zip && !body.city && !body.province && !body.country)
    ) {
      if (isValidNif(body.nif)) {
        axios
          .post(`${process.env.REACT_APP_API_SRT}/v2/user`, body, config)
          .then((res) => {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.status === 'ok') {
              props.setNewUserEmail(body.email);
              setError(t('userCreated'));
              props.closeModal();
              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('errorMensaje'));
            }
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setError(t('nifIncorecto'));
        setIsPending(false);
      }
    } else {
      setAdsressRequired(true);
      setError(t('address'));
      setIsPending(false);
    }
  };

  const activateNewUser = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      token: window.location.pathname.split('/')[2],
      email: userData.email,
      password: userData.password,
      nif: userData.nif,
      name: userData.name,
      lastname: userData.lastname,
      phone: userData.phone,
      address: userData.address,
      zip: userData.zip,
      city: userData.city,
      province: userData.province,
      country: userData.country,
      // language: userData.language,
    };
    console.log(body);
    if (
      (body.address && body.zip && body.city && body.province && body.country) ||
      (!body.address && !body.zip && !body.city && !body.province && !body.country)
    ) {
      if (freeAccountCheck() ? isValidNif(body.nif) : true) {
        axios
          .post(`${process.env.REACT_APP_API_SRT}/v2/accountactivation`, body, config)
          .then((res) => {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.status === 'ok') {
              localStorage.setItem('menu', 'help');
              localStorage.setItem('autoAcceso', res.data.token);
              localStorage.setItem('id', res.data.iduser);
              localStorage.setItem('email', res.data.email);
              if (!['es', 'en', 'fr'].includes(res.data.language)) {
                localStorage.setItem('language', 'en');
              } else {
                localStorage.setItem('language', res.data.language);
              }
              i18n.changeLanguage(localStorage.getItem('language'));
              // setError('cuenta activada con exito');
              setIsPending(false);
              go_to('/inicio');
            } else {
              setIsPending(false);
              setError(t('errorMensaje'));
            }
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setError(t('nifIncorecto'));
        setIsPending(false);
      }
    } else {
      setAdsressRequired(true);
      setError(t('address'));
      setIsPending(false);
    }
  };

  const freeAccountCheck = () => {
    if (
      (window.location.pathname.split('/')[1] === 'urlaccountactiv' &&
        userData.free_account &&
        userData.free_account === 'true') ||
      window.location.pathname.split('/')[1] === 'inicio'
    ) {
      return false;
    } else {
      return true;
    }
  };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  useEffect(() => {
    setUserData({
      //   iduser: userData.id,
      name: searchParams.get('name') ? searchParams.get('name') : '',
      lastname: searchParams.get('lastname') ? searchParams.get('lastname') : '',
      email: searchParams.get('email') ? searchParams.get('email') : '',
      nif: '',
      phone: searchParams.get('phone') ? searchParams.get('phone') : '',
      address: searchParams.get('address') ? searchParams.get('address') : '',
      zip: searchParams.get('zip') ? searchParams.get('zip') : '',
      city: searchParams.get('city') ? searchParams.get('city') : '',
      province: searchParams.get('province') ? searchParams.get('province') : '',
      country: searchParams.get('country') ? searchParams.get('country') : '',
      // iscommmanager: false,
      // isentityemployer: false,
      freeidriallowed: false,
      view: false,
      action: false,
      program: false,
      alerts: false,
      rules: false,
      historic: false,
      edit: false,
      free_account: searchParams.get('freeaccount') ? searchParams.get('freeaccount') : '',
      language: searchParams.get('language') ? searchParams.get('language') : '',
    });
    searchParams.get('language') && localStorage.setItem('language', searchParams.get('language'));
  }, []);

  //   console.log(window.location.pathname.split('/')[1] === 'accountactive');
  // console.log('list');
  // console.log(props.entitiesList);
  // console.log('render');

  return (
    <>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}

      <Container>
        {window.location.pathname.split('/')[1] === 'urlaccountactiv' && (
          <>
            <div className='text-center'>
              <div
                // src='/images/logos/logo_sisritel_punto_128x128.png'
                className='mx-auto logo128'
              />
              <h5 className='text-center my-3'>{t('reviewData')}</h5>
            </div>
            <Col
              xs={2}
              md={1}
              className='d-md-flex text-center me-md-3 mt-3 position-absolute top-0 end-0'
            >
              <Col xs={12} md={4}>
                <Flags.ES className='langBtn' onClick={() => changeLanguage('es')} />
              </Col>
              <Col xs={12} md={4}>
                <Flags.GB className='langBtn' onClick={() => changeLanguage('en')} />
              </Col>
              <Col xs={12} md={4}>
                <Flags.FR className='langBtn' onClick={() => changeLanguage('fr')} />
              </Col>
            </Col>
          </>
        )}
        <Form
          onSubmit={(e) =>
            window.location.pathname.split('/')[1] === 'urlaccountactiv'
              ? activateNewUser(e)
              : postNewUser(e)
          }
        >
          <Row className='px-0'>
            <Col xs={12} md={3}>
              <strong>{t('nombre').toLowerCase()}</strong>
              <Form.Control
                required
                type='name'
                maxLength={50}
                value={userData.name ? userData.name : ''}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              />
            </Col>
            <Col xs={12} md={3}>
              <strong>{t('lastname')}</strong>
              <Form.Control
                required
                type='surname'
                maxLength={100}
                value={userData.lastname ? userData.lastname : ''}
                onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
              />
            </Col>
            {/* <Col xs={12} md={3}>
              <strong>{t('email')}</strong>
              <Form.Control
                disabled={
                  window.location.pathname.split('/')[1] === 'urlaccountactiv'
                }
                required
                type='email'
                value={userData.email ? userData.email : ''}
                onChange={(e) =>
                  window.location.pathname.split('/')[1] === 'urlaccountactiv'
                    ? null
                    : setUserData({ ...userData, email: e.target.value })
                }
              />
            </Col> */}
            <Col xs={6} md={3}>
              <strong>{t('phone')}</strong>
              <Form.Control
                required={freeAccountCheck()}
                type='phone'
                maxLength={30}
                value={userData.phone ? userData.phone : ''}
                onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
              />
            </Col>
            <Col xs={6} md={3}>
              <strong>nif</strong>
              <Form.Control
                required={freeAccountCheck()}
                type='text'
                value={userData.nif ? userData.nif.toUpperCase() : ''}
                onChange={(e) => setUserData({ ...userData, nif: e.target.value })}
              />
            </Col>
            <Col xs={12} md={3}>
              <strong>{t('direccion')}</strong>
              <Form.Control
                required={adsressRequired || freeAccountCheck()}
                type='text'
                maxLength={200}
                value={userData.address ? userData.address : ''}
                onChange={(e) => setUserData({ ...userData, address: e.target.value })}
              />
            </Col>
            <Col xs={6} md={3}>
              <strong>{t('city')}</strong>
              <Form.Control
                required={adsressRequired || freeAccountCheck()}
                type='text'
                maxLength={50}
                value={userData.city ? userData.city : ''}
                onChange={(e) => setUserData({ ...userData, city: e.target.value })}
              />
            </Col>
            <Col xs={6} md={3}>
              <strong>{t('zip')}</strong>
              <Form.Control
                required={adsressRequired || freeAccountCheck()}
                type='number'
                maxLength={20}
                value={userData.zip ? userData.zip : ''}
                onChange={(e) => setUserData({ ...userData, zip: e.target.value })}
              />
            </Col>
            <Col xs={6} md={3}>
              <strong>{t('province')}</strong>
              <Form.Control
                required={adsressRequired || freeAccountCheck()}
                type='text'
                maxLength={50}
                value={userData.province ? userData.province : ''}
                onChange={(e) => setUserData({ ...userData, province: e.target.value })}
              />
            </Col>
            <Col xs={6} md={3}>
              <strong>{t('country')}</strong>
              <Form.Control
                required={adsressRequired || freeAccountCheck()}
                type='text'
                maxLength={50}
                value={userData.country ? userData.country : ''}
                onChange={(e) => setUserData({ ...userData, country: e.target.value })}
              />
            </Col>
            <Col xs={12} md={3}>
              <strong>{t('email')}</strong>
              <Form.Control
                disabled={window.location.pathname.split('/')[1] === 'urlaccountactiv'}
                required
                type='email'
                value={userData.email ? userData.email : ''}
                onChange={(e) =>
                  window.location.pathname.split('/')[1] === 'urlaccountactiv'
                    ? null
                    : setUserData({ ...userData, email: e.target.value })
                }
              />
            </Col>
            {window.location.pathname.split('/')[1] === 'urlaccountactiv' ? (
              // <Row className='mt-2 pe-0'>
              <Col xs={12} md={3} className='pe-0 pe-md-2'>
                <strong>{t('password').toLowerCase()}</strong> <span>({t('min8')})</span>
                <Form.Control
                  required
                  type='password'
                  autoComplete='new-password'
                  // maxLength={30}
                  value={userData.password ? userData.password : ''}
                  onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={3}>
                  <strong>{t('lang')}</strong>
                  <Form.Select
                    required
                    value={userData.language ? userData.language : ''}
                    onChange={(e) => setUserData({ ...userData, language: e.target.value })}
                  >
                    <option key='' value='' disabled>
                      {t('selectIdioma')}...
                    </option>
                    <option key='es' value='es'>
                      {t('es')}
                    </option>
                    <option key='en' value='en'>
                      {t('en')}
                    </option>
                    <option key='fr' value='fr'>
                      {t('fr')}
                    </option>
                  </Form.Select>
                </Col>
                {/* <hr className='my-2' />
                <Row className='mx-0 px-0 d-flex align-items-end'>
                  <Col xs={12}>
                    <h5 className='fw-normal'>{t('entAsoc')}</h5>
                  </Col> */}
                {/* {props.entitiesList && (
                    <Col xs={12} md={6}>
                      <Select menuShouldScrollIntoView
                        placeholder={t('entSelect')}
                        required={
                          userData.isentityemployer === true ||
                          userData.iscommmanager === true
                        }
                        isClearable
                        isSearchable={true}
                        options={props.entitiesList}
                        value={selectedEntity ? selectedEntity : ''}
                        menuPortalTarget={document.body}
                        id='selectEntity'
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            backgroundColor: 'white',
                          }),
                          option: (styles, { isFocused, isSelected }) => {
                            return {
                              ...styles,
                              backgroundColor: isFocused
                                ? 'rgba(0, 123, 255, 0.3)'
                                : isSelected && 'rgba(0, 0, 0, 0)',
                              color: isSelected && 'rgba(0, 0, 0)',
                            };
                          },
                        }}
                        onChange={(e) => {
                          setSelectedEntity(e);
                        }}
                      />
                    </Col>
                  )} */}

                {/* <Col xs={12} md={3}>
                    <strong>{t('entEmployee')}</strong>
                    <Form.Check
                      type='checkbox'
                      checked={
                        userData.isentityemployer ? userData.isentityemployer : false
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          isentityemployer: e.target.checked,
                        })
                      }
                    />
                  </Col> */}
                {/* <Col xs={12} md={3}>
                    <strong>{t('entManager')}</strong>
                    <Form.Check
                      type='checkbox'
                      checked={
                        userData.iscommmanager ? userData.iscommmanager : false
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          iscommmanager: e.target.checked,
                        })
                      }
                    />
                  </Col> */}
                {/* {userData.isentityemployer && (
                    <Col xs={6} md={6} className='text-center'>
                      <strong>{t('freeidriAllowed')}</strong>
                      <Form.Check
                        // required={userData.isentityemployer ? true : false}
                        type='checkbox'
                        checked={
                          userData.freeidriallowed ? userData.freeidriallowed : false
                        }
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            freeidriallowed: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  )} */}
                {/* {userData.iscommmanager && (
                    <Col xs={12} md={6}>
                      <strong>{t('accessLevel')}</strong>
                      <Form.Select
                        required={userData.iscommmanager ? true : false}
                        value={userData.access_level ? userData.access_level : ''}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            access_level: parseInt(e.target.value),
                          })
                        }
                      >
                        <option key='' value='' disabled>
                          {t('accessLevelSelect')}...
                        </option>
                        <option key={1} value={1}>
                          1
                        </option>
                        <option key={2} value={2}>
                          2
                        </option>
                      </Form.Select>
                    </Col>
                  )} */}

                {/* <hr className='my-2' /> */}

                {/* {userData.iscommmanager && (
                    <Row className='ps-4 pe-0'>
                      <Col xs={12}>
                        <h5 className='fw-normal'>{t('permissions')}</h5>
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('dataView')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.view ? userData.view : false}
                          onChange={(e) =>
                            userData.edit === true
                              ? setUserData({
                                  ...userData,
                                  view: true,
                                })
                              : setUserData({
                                  ...userData,
                                  view: e.target.checked,
                                })
                          }
                        />
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('edit').toLowerCase()}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.edit ? userData.edit : false}
                          onChange={(e) =>
                            e.target.checked === true
                              ? setUserData({
                                  ...userData,
                                  edit: e.target.checked,
                                  view: true,
                                  action: true,
                                })
                              : setUserData({
                                  ...userData,
                                  edit: e.target.checked,
                                })
                          }
                        />
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('manual')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.action ? userData.action : false}
                          onChange={(e) =>
                            userData.edit === true
                              ? setUserData({
                                  ...userData,
                                  action: true,
                                })
                              : setUserData({
                                  ...userData,
                                  action: e.target.checked,
                                })
                          }
                        />
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('progs')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.program ? userData.program : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              program: e.target.checked,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('alerts')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.alerts ? userData.alerts : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              alerts: e.target.checked,
                              rules: e.target.checked,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={3} className='text-center border rounded-2'>
                        <strong>{t('rules')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.rules ? userData.rules : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              rules: e.target.checked,
                              alerts: e.target.checked,
                            })
                          }
                        />
                      </Col>
                      <Col xs={12} md={3} className='text-center border rounded-2'>
                        <strong>{t('historical')}</strong>
                        <Form.Check
                          type='checkbox'
                          checked={userData.historic ? userData.historic : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              historic: e.target.checked,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  )} */}
                {/* </Row> */}
              </>
            )}

            {/* <Row className=' mt-3'> */}
            <Col xs={12} className='mt-3 d-flex justify-content-center'>
              <Button
                // variant='primary'
                size='lg'
                type='submit'
                className='py-md-1 dark'
                onClick={(e) => setAdsressRequired(false)}
              >
                {window.location.pathname.split('/')[1] === 'urlaccountactiv'
                  ? t('confirmData').toUpperCase()
                  : t('createUser').toUpperCase()}
                {/* {'crear nuevo usuario'.toUpperCase()} */}
              </Button>
            </Col>
            {/* </Row> */}
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default NewUser;
