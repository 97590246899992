/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';
import { BsPauseCircle, BsPlayCircle } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { useSelector } from 'react-redux';
import scaleDecimal from '../../../../support_func/scaleDecimal';

const ProgramConfig = (props) => {
  const { t } = useTranslation();

  const [createdProg, setCreatedProg] = useState({});
  let prog = props.prog;
  const [hStart, setHStart] = useState(prog ? prog.starttime : '--:--');
  const [hFin, setHFin] = useState(prog ? prog.endtime : '--:--');
  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [newProgram, setNewProgram] = useState({});
  const [, forceUpdate] = useState(0);

  const idri = useSelector((state) => state.idri);


  const finBiggerStart = (fin) => {
    let timeArray = hStart && hStart.split(':');
    if (timeArray[1] == 59) {
      timeArray[0] =
        parseInt(timeArray[0]) + 1 < 10
          ? String('0' + (parseInt(timeArray[0]) + 1))
          : parseInt(timeArray[0]) + 1;
      timeArray[1] = '00';
    } else {
      timeArray[1] =
        parseInt(timeArray[1]) + 1 < 10
          ? String('0' + (parseInt(timeArray[1]) + 1))
          : parseInt(timeArray[1]) + 1;
    }
    fin <= hStart ? setHFin(String(timeArray[0] + ':' + timeArray[1])) : setHFin(fin);
  };

  const weekDays = (days) => {
    let arrDays = [];
    let daysFromString = typeof days === 'string' && days.split('');
    for (let i = 0; i < 7; i++) {
      daysFromString[i] == 1 && arrDays.push(i + 1);
    }
    return arrDays;
  };

  const toBinary = (arr) => {
    let bin = [0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        bin[arr[i] - 1] = 1;
      }
    }
    return bin.join('');
  };

  const addActuators = (arr) => {
    let act = [];
    if (!prog) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
          act[i] = {
            idactuator: arr[i],
            action: 1,
            afmv:
              newProgram.actuators &&
              newProgram.actuators
                .map((a) => {
                  if (a.idactuator === arr[i]) {
                    return a.afmv;
                  }
                })
                .find((value) => {
                  return value;
                }),
            afv:
              newProgram.actuators &&
              newProgram.actuators
                .map((a) => {
                  if (a.idactuator === arr[i]) {
                    return a.afv;
                  }
                })
                .find((value) => {
                  return value;
                }),
          };
        }
      }
      return act;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
          act[i] = {
            idactuator: arr[i],
            action: 1,
            afmv:
              createdProg.actuators &&
              createdProg.actuators
                .map((a) => {
                  if (a.idactuator === arr[i]) {
                    return a.afmv;
                  }
                })
                .find((value) => {
                  return value;
                }),
            afv:
              createdProg.actuators &&
              createdProg.actuators
                .map((a) => {
                  if (a.idactuator === arr[i]) {
                    return a.afv;
                  }
                })
                .find((value) => {
                  return value;
                }),
          };
        }
      }

      return act;
    }
  };

  const addSeconds = (time) => {
    if (!time.split(':')[2]) {
      setHStart(time + ':00');
    }
  };

  const checkScale = (idsensor) => {
    return Object.values(idri.sensors.fm_sensors)
      .map((sensor) => {
        if (sensor.idsensor === idsensor) {
          return sensor.scale;
        }
      })
      .find((value) => {
        return value;
      });
  };

  const checkVolume = (idact, type) => {
    if (props.id === 'new' && newProgram && newProgram.actuators) {
      return newProgram.actuators
        .map((act) => {
          if (act.idactuator === idact) {
            return act[type];
          }
        })
        .find((value) => {
          return value;
        });
    } else {
      return (
        createdProg &&
        createdProg.actuators &&
        createdProg.actuators.length > 0 &&
        createdProg.actuators
          .map((act) => {
            if (act.idactuator === idact) {
              return act[type];
            }
          })
          .find((value) => {
            return value;
          })
      );
    }
  };

  const changeVolume = (idAct, type, value, idsensor) => {
    let updatedProgram = prog ? createdProg : newProgram;

    const strValue = String(value).split('.');
    if (strValue[1] && strValue[1].length > scaleDecimal(checkScale(idsensor))) {
      updatedProgram.actuators &&
        updatedProgram.actuators.map((a) => {
          if (a.idactuator === idAct) {
            a[type] = isNaN(value)
              ? ''
              : parseFloat(value).toFixed(scaleDecimal(checkScale(idsensor)));
          }
        });
    } else {
      updatedProgram.actuators &&
        updatedProgram.actuators.map((a) => {
          if (a.idactuator === idAct) {
            a[type] = isNaN(value) ? '' : value;
          }
        });
    }

    prog ? setCreatedProg(updatedProgram) : setNewProgram(updatedProgram);
    forceUpdate((n) => !n);
  };

  useEffect(() => {
    setBlock(props.block);
    // props.prog && setProgram(props.prog);
  }, [props]);

  useEffect(() => {
    // El dispositivo no comprueba programas durante los últimos 20 segundos del día
    let sTime = hStart;
    let arrayStartTime = sTime && sTime.split(':');
    if (parseFloat(idri.ghatorv) >= 3 && arrayStartTime.length >= 2) {
      if (arrayStartTime[0] === '23' && arrayStartTime[1] === '59') {
        sTime = '23:59:00'
      } else if (arrayStartTime[0] === '00' && arrayStartTime[1] === '00') {
        sTime = '00:01:00'
      }
    };
    // parseFloat(idri.v_idri) < 3 &&
    //   hStart !== '--:--' &&
    //   hStart >= hFin &&
    //   finBiggerStart(hFin);
    prog
      ? setCreatedProg({ ...createdProg, starttime: sTime })
      : setNewProgram({ ...newProgram, starttime: sTime });
  }, [hStart]);

  useEffect(() => {
    hStart === '23:59' && hFin === '23:59' && setHFin('--:--');
    parseFloat(idri.ghatorv) < 3 && prog
      ? setCreatedProg({ ...createdProg, endtime: hFin })
      : setNewProgram({ ...newProgram, endtime: hFin });
  }, [hFin]);

  useEffect(() => {
    props.setSendProg && props.setSendProg(prog ? createdProg : newProgram);
    prog
      ? createdProg && createdProg.starttime && setHStart(createdProg.starttime)
      : newProgram && newProgram.starttime && setHStart(newProgram.starttime);
    prog
      ? createdProg && createdProg.endtime && setHFin(createdProg.endtime)
      : newProgram && newProgram.endtime && setHFin(newProgram.endtime);
  }, [newProgram, createdProg]);

  // useEffect(() => {
  // console.log(createdProg);
  // }, [createdProg]);

  useEffect(() => {
    if (prog && Object.keys(prog).length > 0) {
      // setCreatedProg(prog);
      parseFloat(idri.ghatorv) < 3
        ? setCreatedProg({
            idprogram: prog.idprogram,
            nprograma: prog.nprograma,
            starttime: prog.starttime,
            endtime: prog.endtime,
            daysweek: prog.daysweek
              ? prog.daysweek
                  .split('')
                  .map((n, i) => {
                    if (parseInt(n) === 1) {
                      return i + 1;
                    }
                  })
                  .filter((x) => x !== undefined)
              : '',
            actuators: prog.actuators,
          })
        : setCreatedProg({
            idprogram: prog.idprogram,
            nprograma: prog.nprograma,
            starttime: prog.starttime,
            duration: prog.duration,
            h: parseInt(prog.duration < 3600 ? 0 : prog.duration / 3600),
            m: parseInt((prog.duration % 3600) / 60 < 0 ? 0 : (prog.duration % 3600) / 60),
            s: parseInt(prog.duration % 60),
            daysweek: prog.daysweek
              ? prog.daysweek
                  .split('')
                  .map((n, i) => {
                    if (parseInt(n) === 1) {
                      return i + 1;
                    }
                  })
                  .filter((x) => x !== undefined)
              : '',
            actuators: prog.actuators,
            // ? prog.actuators.map((act) => ({
            //     idactuator: act.idactuator,
            //     action: act.action,
            //     afmv: prog.afmv,
            //     afv: prog.afv,
            //     is_master: prog.is_master,
            //     name: prog.name,
            //     normal_state: prog.normal_state,
            //   }))
            // : null,
            active: prog.active,
            enable: prog.enable,
          });
    }
    // setProgram(createdProg);
    // forceUpdate((n) => !n);
  }, [prog, edit]);

  useEffect(() => {
    if (
      props.id === 'new' &&
      localStorage.getItem(`newprog-${idri.ghatorserial}`) &&
      Object.keys(JSON.parse(localStorage.getItem(`newprog-${idri.ghatorserial}`))).length > 0
    ) {
      setNewProgram(JSON.parse(localStorage.getItem(`newprog-${idri.ghatorserial}`)));
      //
    }
  }, []);

  useEffect(() => {
    setNewProgram({});
  }, [idri]);

  let input = useRef(null);
  const resetFocus = () => {
    props.getIdri();
    // input.focus(); // focus
    // input.blur(); // removing focus
  };

  return (
    <div
      id='progConfig'
      className={`${((block && !prog) || (prog && !edit)) && 'dis'} position-relative py-1 px-3`}
    >
      {createdProg && Object.keys(createdProg).length > 0 ? (
        <>
          <Row className='align-items-end active' id={`id_${createdProg.nprograma}`}>
            <Col
              xs={12}
              lg={{ span: 4, order: 2 }}
              className={`text-center align-self-start ${
                parseFloat(idri.ghatorv) >= 3
                  ? !createdProg.enable && 'text-danger dangerAnimation'
                  : idri.all_programs_disabled && 'text-danger dangerAnimation'
              }`}
            >
              <Row className='m-0 p-0'>
                <Col xs={12} className='m-0 fw-bold'>
                  {t('programa')} #{createdProg.nprograma}
                </Col>
                <Col xs={12}>
                  {parseFloat(idri.ghatorv) >= 3 ? (
                    createdProg.enable ? (
                      <span className='text-success habilitado'>{t('abilitado')}</span>
                    ) : (
                      <span className='text-danger'>{t('disabilitado')}</span>
                    )
                  ) : idri.all_programs_disabled ? (
                    <span className='text-danger'>{t('disabilitado')}</span>
                  ) : (
                    <span className='text-success habilitado'>{t('abilitado')}</span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={{ span: 4, order: 1 }}
              className={`${createdProg && !edit && 'dis'} text-center`}
            >
              <Col className='m-0 fw-bold text-success'>{t('horaInicio')}</Col>
              <Col className='mb-0'>
                <Form.Control
                  required
                  type='time'
                  ref={(r) => (input = r)}
                  step={`${parseFloat(idri.ghatorv) < 3 ? '0' : '1'}`}
                  className='text-center p-0 p-md-2'
                  value={hStart}
                  onChange={(e) => {
                    setHStart(e.target.value);
                    // setCreatedProg({ ...createdProg, starttime: hStart });
                  }}
                  // onBlur={(e) =>
                  //   parseFloat(idri.v_idri) >= 3 && addSeconds(e.target.value)
                  // }
                  onBlur={(e) => {
                    parseFloat(idri.ghatorv) < 3 &&
                      hStart !== '--:--' &&
                      hStart >= hFin &&
                      finBiggerStart(hFin);
                  }}
                />
              </Col>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={{ span: 4, order: 3 }}
              className={`${createdProg && !edit && 'dis'} mt-1 text-center`}
            >
              <Col className='m-0 fw-bold text-danger margin'>
                {parseFloat(idri.ghatorv) < 3 ? t('horaFin') : t('duration')}
              </Col>
              <Col className='my-0'>
                {parseFloat(idri.ghatorv) < 3 ? (
                  <Form.Control
                    required
                    type='time'
                    id='hFin'
                    className='text-center p-0 p-md-2'
                    value={hFin}
                    onChange={(e) => setHFin(e.target.value)}
                    onBlur={(e) => finBiggerStart(e.target.value)}
                  />
                ) : (
                  <Row className='align-items-end mx-0 mt-0 mb-2'>
                    <Col xs={4} className='px-1'>
                      <span>{t('horas')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        min={1}
                        placeholder=''
                        value={
                          createdProg.h ?? ''
                          // ? createdProg.h
                          // : parseInt(createdProg.duration / 3600) > 0
                          // ? parseInt(createdProg.duration / 3600)
                          // : ''
                        }
                        onChange={
                          (e) => setCreatedProg({ ...createdProg, h: parseInt(e.target.value) })
                          // editProg('duration', {
                          //   h: e.target.value,
                          // })
                        }
                      />
                    </Col>
                    <Col xs={4} className='px-1'>
                      <span>{t('minutos')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        min={1}
                        placeholder=''
                        value={
                          createdProg.m ?? ''
                          // ? createdProg.m
                          // : (createdProg.duration % 3600) / 60 > 0
                          // ? parseInt((createdProg.duration % 3600) / 60)
                          // : ''
                        }
                        onChange={
                          (e) => setCreatedProg({ ...createdProg, m: parseInt(e.target.value) })
                          // editProg('duration', {
                          //   m: e.target.value,
                          // })
                        }
                      />
                    </Col>
                    <Col xs={4} className='px-1'>
                      <span>{t('segundos')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        min={1}
                        placeholder=''
                        value={
                          createdProg.s ?? ''
                          // ? createdProg.s
                          // : createdProg.duration % 60 > 0
                          // ? parseInt(createdProg.duration % 60)
                          // : ''
                        }
                        onChange={
                          (e) => setCreatedProg({ ...createdProg, s: parseInt(e.target.value) })
                          // editProg('duration', {
                          //   s: e.target.value,
                          // })
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Col>
          </Row>
          <Row className='text-center'>
            <Col className='mb-0 fw-bold'>{t('diasSemana')}</Col>
            <ToggleButtonGroup
              type='checkbox'
              value={
                typeof createdProg.daysweek === 'string'
                  ? weekDays(createdProg.daysweek)
                  : createdProg.daysweek
              }
              key={`checkDaysKey_${createdProg.nprograma}`}
              id={`checkDays_${createdProg.nprograma}`}
              className='checkDays mb-2 justify-content-center'
              onChange={(e) => setCreatedProg({ ...createdProg, daysweek: toBinary(e) })}
              // para evitar problemas en el marcado en iphone
              onClick={(e) => {
                if (!e.target.checked) {e.target.blur()};                
              }}
              // Fin para evitar problemas en el marcado en iphone
            >
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={1}
                id={`${createdProg.nprograma}_tbg-check-1`}
              >
                {t('lunes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={2}
                id={`${createdProg.nprograma}_tbg-check-2`}
              >
                {t('martes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={3}
                id={`${createdProg.nprograma}_tbg-check-3`}
              >
                {t('miercoles')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={4}
                id={`${createdProg.nprograma}_tbg-check-4`}
              >
                {t('jueves')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={5}
                id={`${createdProg.nprograma}_tbg-check-5`}
              >
                {t('viernes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={6}
                id={`${createdProg.nprograma}_tbg-check-6`}
              >
                {t('sabado')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={7}
                id={`${createdProg.nprograma}_tbg-check-7`}
              >
                {t('domingo')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Row>
          <Row className='text-center'>
            <p className='mb-0 fw-bold'>{t('actuadores').toLowerCase()}</p>
            <ToggleButtonGroup
              type='checkbox'
              value={createdProg.actuators.map((act) => {
                return act.idactuator;
              })}
              key={`checkActuatorsKey_${createdProg.nprograma}`}
              id={`checkActuators_${createdProg.nprograma}`}
              className='checkAct mx-0 row'
              style={{ width: '100%' }}
              // onChange={(e) => editProg('actuators', e)}
              onChange={(e) => setCreatedProg({ ...createdProg, actuators: addActuators(e) })}
              // para evitar problemas en el marcado en iphone
              onClick={(e) => {
                if (e.target.type == "checkbox") {
                  if (!e.target.checked) {
                    var idval = e.target.id
                    var label = null
                    var labels = e.target.parentNode.getElementsByTagName('label');
                    for( var i = 0; i < labels.length; i++ ) {
                        if (labels[i].htmlFor == idval)
                          {
                            label = labels[i];
                            break;  
                            }
                    }
                    if(label != null) {label.blur()}
                  }                
                }
              }}
              // FIN para evitar problemas en el marcado en iphone
            >
              {Object.values(idri.actuators).map((actuator) => (
                <ToggleButton
                  as='Card'
                  key={actuator.idactuator}
                  disabled={idri.master_en && actuator.is_master}
                  variant='outline-primary'
                  className='m-1 col-4 col-md-2 px-1'
                  value={actuator.idactuator}
                  id={`${createdProg.nprograma}_act${actuator.idactuator}`}
                >
                  <Row className='m-0'>
                    <Col xs={12} className='d-flex justify-content-center px-0'>
                      <span className='d-flex align-items-center text-break'>{actuator.name}</span>
                      <div className='mark bg-white text-black'>
                        {actuator.kind.normal_state === false ? ' ' + t('close') : ' ' + t('open')}
                      </div>
                    </Col>
                    {idri.master_en && actuator.is_master ? (
                      <p className='text-danger dangerAnimation m-0 p-0'>{t('autoOC')}</p>
                    ) : (
                      createdProg.actuators &&
                      createdProg.actuators.length > 0 &&
                      createdProg.actuators
                        .map((act) => {
                          return act.idactuator;
                        })
                        .includes(actuator.idactuator) &&
                      parseFloat(idri.ghatorv) >= 3 &&
                      actuator.fluid &&
                      Object.keys(actuator.fluid).length > 0 &&
                      actuator.kind.normal_state === false && (
                        <Row className='p-0 m-0 align-items-end'>
                          <Col xs={actuator.fluid.flow ? 6 : 12} className='px-0'>
                            <p className='fw-normal lh-px mb-0'>{t('contadorAsociado')}</p>
                            <Form.Control
                              className='text-center p-0'
                              type='number'
                              value={checkVolume(actuator.idactuator, 'afmv')}
                              min='0'
                              // max='255'
                              step={checkScale(actuator.fluid.related_fm.idsensor)}
                              onChange={(e) => {
                                changeVolume(
                                  actuator.idactuator,
                                  'afmv',
                                  e.target.value,
                                  actuator.fluid.related_fm.idsensor
                                );
                              }}
                              onBlur={(e) =>
                                e.target.value !== '' &&
                                e.target.value < checkScale(actuator.fluid.related_fm.idsensor)
                                  ? changeVolume(
                                      actuator.idactuator,
                                      'afmv',
                                      parseFloat(checkScale(actuator.fluid.related_fm.idsensor)),
                                      actuator.fluid.related_fm.idsensor
                                    )
                                  : null
                              }
                            />
                          </Col>
                          {/* <Col xs={1}></Col> */}
                          {actuator.fluid.flow && (
                            <Col xs={6} className='px-0'>
                              <p className='fw-normal '>{t('caudal')}</p>
                              <Form.Control
                                className='text-center p-0'
                                type='number'
                                value={checkVolume(actuator.idactuator, 'afv')}
                                min='0'
                                // max='255'
                                step={checkScale(actuator.fluid.related_fm.idsensor)}
                                onChange={(e) => {
                                  changeVolume(
                                    actuator.idactuator,
                                    'afv',
                                    e.target.value,
                                    actuator.fluid.related_fm.idsensor
                                  );
                                }}
                                onBlur={(e) =>
                                  e.target.value !== '' &&
                                  e.target.value < checkScale(actuator.fluid.related_fm.idsensor)
                                    ? changeVolume(
                                        actuator.idactuator,
                                        'afv',
                                        parseFloat(checkScale(actuator.fluid.related_fm.idsensor)),
                                        actuator.fluid.related_fm.idsensor
                                      )
                                    : null
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      )
                    )}
                  </Row>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Row>

          {edit && (
            <Col className='text-center'>
              <Button className='mt-2' onClick={(e) => props.editProgram(e, false)}>
                {t('modProg')}
              </Button>
            </Col>
          )}

          <Col
            className={`${
              block && props.editing !== props.prog.idprogram ? 'dis' : 'active'
            } d-flex justify-content-end align-items-center mt-2`}
          >
            {parseFloat(idri.ghatorv) >= 3 && (
              <Form.Check
                type='checkbox'
                checked={
                  typeof props.checkProgs === 'object' &&
                  props.checkProgs.includes(createdProg.nprograma)
                }
                className='ps-3 me-auto'
                style={{ transform: 'scale(1.5)' }}
                onChange={(e) =>
                  e.target.checked === true
                    ? props.setCheckProgs([...props.checkProgs, createdProg.nprograma])
                    : props.setCheckProgs(
                        props.checkProgs.filter((item) => {
                          return item !== createdProg.nprograma;
                        })
                      )
                }
              />
            )}
            {parseFloat(idri.ghatorv) >= 3 && (
              <OverlayTrigger
                placement='top'
                rootClose='true'
                overlay={
                  <Tooltip>{createdProg.enable ? t('suspProg') : t('abilitarProg')}</Tooltip>
                }
              >
                <Button
                  variant={createdProg.enable ? 'outline-danger' : 'outline-success'}
                  className='p-0 px-1 mx-3'
                  onClick={(e) =>
                    window.confirm(
                      t('seguro') +
                        ` ${createdProg.enable ? t('suspender') : t('abilitar')} ${t(
                          'programa'
                        )} #` +
                        createdProg.nprograma
                    )
                      ? props.enableProgram(e, createdProg.nprograma)
                      : null
                  }
                >
                  {createdProg.enable ? (
                    <BsPauseCircle type='button' />
                  ) : (
                    <BsPlayCircle type='button' />
                  )}
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement='top'
              rootClose='true'
              overlay={<Tooltip>{t('editProg')}</Tooltip>}
            >
              <Button
                variant='outline-primary'
                active={edit}
                // size='sm'
                className='p-0 px-1 mx-3'
                onClick={(e) => {
                  if (edit === true && window.confirm(t('cancelModProg'))) {
                    // setProg(props.prog);
                    resetFocus();
                    props.setEditing(null);
                    setEdit(!edit);
                  } else {
                    edit === false && props.setEditing(props.prog.idprogram);
                    edit === false && setEdit(!edit);
                  }
                  props.setBlock(edit === true ? false : true);
                }}
              >
                <BiEdit type='button' />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              rootClose='true'
              overlay={<Tooltip>{t('delProg')}</Tooltip>}
            >
              <Button
                variant='outline-danger'
                // size='sm'
                className='p-0 px-1 mx-3'
                onClick={(e) =>
                  window.confirm(t('seguroDelProg') + ' #' + createdProg.nprograma + '?')
                    ? props.delProgram(createdProg.idprogram)
                    : null
                }
              >
                <FaTrashAlt type='button' />
              </Button>
            </OverlayTrigger>
          </Col>
          <div className='active position-absolute top-50 end-0 translate-middle-y'>
            <MdOutlineDragIndicator type='button' className='drag pe-none' />
          </div>
        </>
      ) : (
        <>
          <Row className='align-items-end active'>
            <Col xs={12} md={6} className={`${(block || (prog && !edit)) && 'dis'} text-center`}>
              <Col className='m-0 fw-bold text-success'>{t('horaInicio')}</Col>
              <Col className='mb-0'>
                <Form.Control
                  required
                  type='time'
                  step={`${parseFloat(idri.ghatorv) < 3 ? '0' : '1'}`}
                  className='text-center p-0 p-md-2'
                  value={hStart}
                  onChange={(e) => setHStart(e.target.value)}
                  onBlur={(e) => {
                    parseFloat(idri.ghatorv) >= 3 && addSeconds(e.target.value);
                    parseFloat(idri.ghatorv) < 3 &&
                      hStart !== '--:--' &&
                      hStart >= hFin &&
                      finBiggerStart(hFin);
                  }}
                />
              </Col>
            </Col>
            <Col xs={12} md={6} className={`${(block || (prog && !edit)) && 'dis'} text-center`}>
              <Col className='m-0 mt-1 fw-bold text-danger margin'>
                {parseFloat(idri.ghatorv) < 3 ? t('horaFin') : t('duration')}
              </Col>
              <Col className='mb-0'>
                {parseFloat(idri.ghatorv) < 3 ? (
                  <Form.Control
                    required
                    type='time'
                    id='hFin'
                    className='text-center p-0 p-md-2'
                    value={hFin}
                    onChange={(e) => setHFin(e.target.value)}
                    onBlur={(e) => finBiggerStart(e.target.value)}
                  />
                ) : (
                  <Row className='align-items-end mx-0'>
                    <Col xs={4} className='px-1'>
                      <span>{t('horas')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        min={1}
                        placeholder=''
                        value={newProgram && newProgram.h ? newProgram.h : ''}
                        onChange={(e) =>
                          setNewProgram({ ...newProgram, h: parseInt(e.target.value) })
                        }
                      />
                    </Col>
                    <Col xs={4} className='px-1'>
                      <span>{t('minutos')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        placeholder=''
                        min={1}
                        value={newProgram && newProgram.m ? newProgram.m : ''}
                        onChange={(e) =>
                          setNewProgram({ ...newProgram, m: parseInt(e.target.value) })
                        }
                      />
                    </Col>
                    <Col xs={4} className='px-1'>
                      <span>{t('segundos')}</span>
                      <Form.Control
                        className='text-center p-0 p-lg-2'
                        type='number'
                        min={1}
                        placeholder=''
                        value={newProgram && newProgram.s ? newProgram.s : ''}
                        onChange={(e) =>
                          setNewProgram({ ...newProgram, s: parseInt(e.target.value) })
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Col>
          </Row>
          <Row className='text-center mt-1'>
            <Col className='mb-0 fw-bold'>{t('diasSemana')}</Col>
            <ToggleButtonGroup
              type='checkbox'
              value={newProgram && newProgram.daysweek && weekDays(newProgram.daysweek)}
              id='checkDays'
              key='checkDays'
              className='checkDays mb-2 justify-content-center'
              onChange={(e) => setNewProgram({ ...newProgram, daysweek: toBinary(e) })}
              // para evitar problemas en el marcado en iphone
              onClick={(e) => {
                if (!e.target.checked) {e.target.blur()};
              }}
              // FIN para evitar problemas en el marcado en iphone             
            >
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={1}
                id='new_tbg-check-1'
              >
                {t('lunes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={2}
                id='new_tbg-check-2'
              >
                {t('martes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={3}
                id='new_tbg-check-3'
              >
                {t('miercoles')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={4}
                id='new_tbg-check-4'
              >
                {t('jueves')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={5}
                id='new_tbg-check-5'
              >
                {t('viernes')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={6}
                id='new_tbg-check-6'
              >
                {t('sabado')}
              </ToggleButton>
              <ToggleButton
                variant='outline-primary'
                className='mx-md-1 mx-0 px-0 py-3'
                value={7}
                id='new_tbg-check-7'
              >
                {t('domingo')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Row>
          <Row className='text-center'>
            <p className='mb-0 fw-bold'>{t('actuadores').toLowerCase()}</p>

            <ToggleButtonGroup
              type='checkbox'
              value={
                newProgram &&
                newProgram.actuators &&
                newProgram.actuators.map((a) => {
                  return a.idactuator;
                })
              }
              id='checkActuators'
              key='checkActuators'
              className='checkAct mx-0 row'
              style={{ width: '100%' }}
              onChange={(e) => {
                setNewProgram({ ...newProgram, actuators: addActuators(e) });
              }}
              // para evitar problemas en el marcado en iphone
              onClick={(e) => {
                if (e.target.type == "checkbox") {
                  if (!e.target.checked) {
                    var idval = e.target.id
                    var label = null
                    var labels = e.target.parentNode.getElementsByTagName('label');
                    for( var i = 0; i < labels.length; i++ ) {
                        if (labels[i].htmlFor == idval)
                          {
                            label = labels[i];
                            break;  
                            }
                    }
                    if(label != null) {label.blur()}
                  }                
                }
              }}
              //FIN para evitar problemas en el marcado en iphone
            >
              {Object.values(idri.actuators).map((actuator) => (
                <ToggleButton
                  key={actuator.idactuator}
                  disabled={idri.master_en && actuator.is_master}
                  variant='outline-primary'
                  className='m-1 col-4 col-md-2 px-1'
                  value={actuator.idactuator}
                  id={`new_${actuator.idactuator}`}
                >
                  <Row className='m-0'>
                    <Col xs={12} className='d-flex justify-content-center px-0'>
                      <span className='d-flex align-items-center text-break'>{actuator.name}</span>{' '}
                      <div className='mark bg-white text-black'>
                        {actuator.kind.normal_state === false ? ' ' + t('close') : ' ' + t('open')}
                      </div>
                    </Col>
                    {idri.master_en && actuator.is_master ? (
                      <p className='text-danger dangerAnimation m-0 p-0'>{t('autoOC')}</p>
                    ) : (
                      // program &&
                      // Object.keys(program).length > 0 &&
                      newProgram.actuators &&
                      newProgram.actuators.length > 0 &&
                      newProgram.actuators
                        .map((act) => {
                          return act.idactuator;
                        })
                        .includes(actuator.idactuator) &&
                      parseFloat(idri.ghatorv) >= 3 &&
                      actuator.fluid &&
                      Object.keys(actuator.fluid).length > 0 &&
                      actuator.kind.normal_state === false && (
                        <Row className='p-0 m-0 align-items-end'>
                          <Col xs={actuator.fluid.flow ? 6 : 12} className='px-0'>
                            <p className='fw-normal lh-px mb-0'>{t('contadorAsociado')}</p>
                            <Form.Control
                              className='text-center p-0'
                              type='number'
                              value={checkVolume(actuator.idactuator, 'afmv')}
                              min='0'
                              // max='255'
                              step={checkScale(actuator.fluid.related_fm.idsensor)}
                              onChange={(e) => {
                                changeVolume(
                                  actuator.idactuator,
                                  'afmv',
                                  e.target.value,
                                  actuator.fluid.related_fm.idsensor
                                );
                              }}
                              onBlur={(e) =>
                                e.target.value !== '' &&
                                e.target.value < checkScale(actuator.fluid.related_fm.idsensor)
                                  ? changeVolume(
                                      actuator.idactuator,
                                      'afmv',
                                      parseFloat(checkScale(actuator.fluid.related_fm.idsensor)),
                                      actuator.fluid.related_fm.idsensor
                                    )
                                  : null
                              }
                            />
                          </Col>
                          {actuator.fluid.flow && (
                            <Col xs={6} className='px-0'>
                              <p className='fw-normal '>{t('caudal')}</p>
                              <Form.Control
                                className='text-center p-0'
                                type='number'
                                value={checkVolume(actuator.idactuator, 'afv')}
                                min='0'
                                step={checkScale(actuator.fluid.related_fm.idsensor)}
                                // max='255'
                                onChange={(e) => {
                                  changeVolume(
                                    actuator.idactuator,
                                    'afv',
                                    e.target.value,
                                    actuator.fluid.related_fm.idsensor
                                  );
                                }}
                                onBlur={(e) =>
                                  e.target.value !== '' &&
                                  e.target.value < checkScale(actuator.fluid.related_fm.idsensor)
                                    ? changeVolume(
                                        actuator.idactuator,
                                        'afv',
                                        parseFloat(checkScale(actuator.fluid.related_fm.idsensor)),
                                        actuator.fluid.related_fm.idsensor
                                      )
                                    : null
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      )
                    )}
                  </Row>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProgramConfig;
