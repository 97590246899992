import {
  ADD_ACTUATOR,
  ADD_SENSOR,
  DEL_SENSOR,
  DEL_ACTUATOR,
  IDRI_DATA,
  IDRI_INIT_DATA,
  IDRI,
  CHANGED,
  MARKERS,
  USER,
  ASYNC_CONTROL,
  ASYNC_COUNTER,
} from '../constants/idriConstants';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER:
      return action.payload;
    default:
      return state;
  }
};
export const idriInitDataReducer = (state = {}, action) => {
  switch (action.type) {
    case IDRI_INIT_DATA:
      return action.payload;
    default:
      return state;
  }
};
export const idriDataReducer = (state = {}, action) => {
  switch (action.type) {
    case IDRI_DATA:
      return action.payload;
    default:
      return state;
  }
};
export const sensorsListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SENSOR:
      return action.payload;
    case DEL_SENSOR:
      return action.payload;
    default:
      return state;
  }
};
export const actuatorsListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ACTUATOR:
      return action.payload;
    case DEL_ACTUATOR:
      return action.payload;
    default:
      return state;
  }
};
export const idriReducer = (state = {}, action) => {
  switch (action.type) {
    case IDRI:
      return action.payload;
    default:
      return state;
  }
};

export const markersReducer = (state = {}, action) => {
  switch (action.type) {
    case MARKERS:
      return action.payload;
    default:
      return state;
  }
};

export const changedReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGED:
      return action.payload;
    default:
      return state;
  }
};

export const asyncReducer = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_CONTROL:
      return action.payload;
    default:
      return state;
  }
};

export const asyncCounterReducer = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_COUNTER:
      return action.payload;
    default:
      return state;
  }
};
