/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGED, IDRI_INIT_DATA } from '../../../../redux/constants/idriConstants';
import IdriInit from '../../components/newInstalation/IdriInit';
import IdriConfig from '../../components/newInstalation/IdriConfig';
import TestInstalation from '../../components/newInstalation/TestInstalation';
import NewIdri from '../../components/newInstalation/NewIdri';
import { FaTrashAlt } from 'react-icons/fa';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';

const NuevoIdri = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // const [initInst, setInitInst] = useState({ idriserial: '', control: '' });
  const [page, setPage] = useState('create');
  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] = useState(null);
  const [discharge, setDischarge] = useState(false);
  const [appLink, setAppLink] = useState('');
  const dispatch = useDispatch();
  //redux hooks
  const idriInitData = useSelector((state) => state.idriInitData);
  const idriData = useSelector((state) => state.idriData);
  // const idri = useSelector((state) => state.idri);
  const changed = useSelector((state) => state.changed);
  const idri = useSelector((state) => state.idri);

  //delete device
  const deleteIdri = (e, pass) => {
    e.preventDefault();
    setIsPending(true);
    localStorage.removeItem('stage');
    axios
      .delete(
        `${process.env.REACT_APP_API_SRT}/v2/removeidri?ididri=${idriInitData.ididri}&idriserial=${idriInitData.idriserial}&password=${pass}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setPassword(null);
          localStorage.removeItem(`init-${idriInitData.idriserial}`);
          localStorage.removeItem(`zonaBatTec-${idriInitData.idriserial}`);
          localStorage.removeItem(`sensConf-${idriInitData.idriserial}`);
          localStorage.removeItem(`actConf-${idriInitData.idriserial}`);
          localStorage.removeItem(`newprog-${idriInitData.idriserial}`);
          localStorage.removeItem(`progs-${idriInitData.idriserial}`);
          localStorage.removeItem(`openProgs-${idriInitData.idriserial}`);
          localStorage.removeItem(`openAlerts-${idriInitData.idriserial}`);
          window.location.reload();
          // setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
        setPassword(null);
      });
  };
  //give alta to device
  const alta = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let host = window.location.host;
    let activate = '/urlaccountactiv';
    let init = '/inicio';
    // let noIp = 'http://srtnicole.hopto.org:8000';
    let body = {
      ididri: idriInitData.ididri,
      idriserial: idriInitData.idriserial,
      urlaccountactiv: 'https://' + host + activate,
      urlfront: 'https://' + host + init,
      id_linked_sys: appLink ? appLink : '',
    };
    setDischarge(false);
    dispatch({
      type: CHANGED,
      payload: { ...changed, edit: false, config: false },
    });
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/newidridischarge`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.status === 'ok') {
          setIsPending(false);
          setPage('create');
          setError(t('emailSent'));
        } else {
          setIsPending(false);
          setError(t('errorMensaje'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };
  //check if form was edited
  const editCheck = (to) => {
    if (page === 'init') {
      return changed.edit && window.confirm(t('datosNoEnviados'))
        ? dispatch({
            type: CHANGED,
            payload: { ...changed, edit: false },
          }) &&
            (to === 'config'
              ? setPage('newSensors')
              : to === 'test'
              ? setPage('testInst')
              : to === 'create'
              ? setPage('create') && localStorage.removeItem('stage')
              : null)
        : !changed.edit
        ? to === 'config'
          ? setPage('newSensors')
          : to === 'test'
          ? setPage('testInst')
          : to === 'create'
          ? setPage('create') && localStorage.removeItem('stage')
          : null
        : null;
    } else if (page === 'newSensors') {
      return changed.config && window.confirm(t('datosNoEnviados'))
        ? dispatch({
            type: CHANGED,
            payload: { ...changed, config: false },
          }) &&
            (to === 'edit'
              ? setPage('init')
              : to === 'test'
              ? setPage('testInst')
              : to === 'create'
              ? setPage('create') && localStorage.removeItem('stage')
              : null)
        : !changed.config
        ? to === 'edit'
          ? setPage('init')
          : to === 'test'
          ? setPage('testInst')
          : to === 'create'
          ? setPage('create') && localStorage.removeItem('stage')
          : null
        : null;
    } else if (page === 'testInst') {
      return changed.test && window.confirm(t('datosNoEnviados'))
        ? dispatch({
            type: CHANGED,
            payload: { ...changed, config: false },
          }) &&
            (to === 'edit'
              ? setPage('init')
              : to === 'config'
              ? setPage('newSensors')
              : to === 'create'
              ? setPage('create') && localStorage.removeItem('stage')
              : null)
        : !changed.config
        ? to === 'edit'
          ? setPage('init')
          : to === 'config'
          ? setPage('newSensors')
          : to === 'create'
          ? setPage('create') && localStorage.removeItem('stage')
          : null
        : null;
    }
  };

  const getIdriInitData = (id, serial) => {
    setIsPending(true);
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/editidri?ididri=${id}&idriserial=${serial}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          //store data in redux storage
          dispatch({
            type: IDRI_INIT_DATA,
            payload: { ...idriInitData, ...res.data },
          });
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    setPage('create');
  }, [props.firstPage]);

  useEffect(() => {
    setModalShow(false);
    setPassword(null);
  }, [page]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Container className='checkValidation pt-1'>
      {/* {process.env.REACT_APP_ENV === 'dev' && (
        <>
          <Button
            variant='outline-success'
            size='sm'
            onClick={() => {
              getIdriInitData(217, 81);
              getIdriData(217, 81);
              localStorage.setItem('stage', ['init', 217, 81]);
            }}
          >
            Get 81
          </Button>
          <Button
            variant='outline-success'
            size='sm'
            onClick={() => {
              getIdriInitData(216, 605);
              getIdriData(216, 605);
              localStorage.setItem('stage', ['init', 216, 605]);
            }}
          >
            Get 605
          </Button>
          <Button
            variant='outline-success'
            size='sm'
            onClick={() => {
              getIdriInitData(215, 5001);
              getIdriData(215, 5001);
              localStorage.setItem('stage', ['init', 215, 5001]);
            }}
          >
            Get 5001
          </Button>
          <Button
            variant='outline-success'
            size='sm'
            onClick={() => {
              getIdriInitData(218, 5002);
              getIdriData(218, 5002);
              getIdri(218, 5002);
              localStorage.setItem('stage', ['init', 218, 5002]);
            }}
          >
            Get 5002
          </Button>
          <Button variant='outline-info' size='sm' onClick={(e) => goConfig(e)}>
            Reg {idriInitData.idriserial}
          </Button>
          <Button variant='outline-warning' size='sm' onClick={(e) => postIdri(e)}>
            Conf {idriInitData.idriserial}
          </Button>
          <Button variant='outline-danger' size='sm' onClick={(e) => deleteIdri(e)}>
            Del {idriInitData.idriserial}
          </Button>
        </>
      )} */}

      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : (
        <div className='position-relative text'>
          {/* {Object.keys(idriInitData).length === 0 && (
            <Row>
              <h5 className='text-center'>{t('indiqueSerie')}</h5>
              <Form className='my-3' onSubmit={(e) => checkIdri(e)}>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>
                        <strong>{t('numeroSerie')}</strong>
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='serial'
                        required
                        onChange={(e) =>
                          setInitInst({
                            ...initInst,
                            idriserial: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        <strong>{t('numeroControlador')}</strong>
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='control'
                        required
                        onChange={(e) =>
                          setInitInst({
                            ...initInst,
                            control: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Col className='mt-3'>
                  <Button
                    id='boton_nueva_instalacion'
                    type='submit'
                    className='btn-sm btn-primary'
                  >
                    {t('empezarConfig')}
                  </Button>
                </Col>
              </Form>
              <Col xs={12}>
                {error && (
                  <Col className='text-center alert alert-danger'>{error}</Col>
                )}
              </Col>
            </Row>
          )} */}

          {Object.keys(idriInitData).length !== 0 && (
            <>
              <Pagination className='justify-content-center'>
                {page !== 'create' && (
                  <>
                    <OverlayTrigger
                      placement='bottom'
                      rootClose='true'
                      overlay={<Tooltip>{t('nuevoDisp')}</Tooltip>}
                    >
                      <Pagination.Item
                        active={page === 'create'}
                        onClick={() => {
                          editCheck('create');
                        }}
                        // style={{ width: '21vw' }}
                        className='text-center'
                      >
                        <div className=''>{t('nuevo')}</div>
                      </Pagination.Item>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='bottom'
                      rootClose='true'
                      overlay={<Tooltip>{t('inicioDespositivo')}</Tooltip>}
                    >
                      <Pagination.Item
                        active={page === 'init'}
                        onClick={() => editCheck('edit')}
                        // style={{ width: '21vw' }}
                        className='text-center'
                      >
                        <div className=''>{t('inicio')}</div>
                      </Pagination.Item>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement='bottom'
                      rootClose='true'
                      overlay={<Tooltip>{t('configDispositivo')}</Tooltip>}
                    >
                      <Pagination.Item
                        active={page === 'newSensors'}
                        disabled={!idriInitData.sim || (idriInitData.sim && !idriInitData.sim.ip)}
                        value='newSensors'
                        onClick={() => editCheck('config')}
                        // style={{ width: '21vw' }}
                        className='text-center'
                      >
                        <div className=''>{t('configurar')}</div>
                      </Pagination.Item>
                    </OverlayTrigger>

                    {(idriInitData.inicialized || idriData.inicialized) && (
                      <>
                        <OverlayTrigger
                          placement='bottom'
                          rootClose='true'
                          overlay={<Tooltip>{t('testDispositivo')}</Tooltip>}
                        >
                          <Pagination.Item
                            active={page === 'testInst'}
                            // disabled={Object.keys(idriData).length === 0}
                            value='testInst'
                            onClick={() => editCheck('test')}
                            // style={{ width: '21vw' }}
                            className='text-center'
                          >
                            <div className=''>{t('test')}</div>
                          </Pagination.Item>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement='bottom'
                          rootClose='true'
                          overlay={<Tooltip>{t('darAlta')}</Tooltip>}
                        >
                          <Pagination.Item
                            active={page === 'final'}
                            // disabled={Object.keys(idriData).length === 0}
                            value='final'
                            onClick={
                              (e) => setDischarge(true)
                              // window.confirm(t('seguroDarAlta')) ? alta(e) : null
                            }
                            // style={{ width: '21vw', height: '100%' }}
                            className='text-center'
                          >
                            <div className=''>{t('alta')}</div>
                          </Pagination.Item>
                        </OverlayTrigger>
                      </>
                    )}
                  </>
                )}

                <Modal show={discharge} onClose={() => setDischarge(false)} centered>
                  <Modal.Header className='d-flex justify-content-center'>
                    <Modal.Title className=' text-center'>{t('darAlta')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      onSubmit={(e) => {
                        setPage('create');
                        alta(e);
                      }}
                    >
                      <Row className='text-center'>{t('seguroDarAlta')}</Row>
                      <Row className='text-center mt-3'>
                        {parseFloat(idriInitData.v_idri) >= 3 && (
                          <Col xs={12} className='mb-3'>
                            <strong>{t('addLink')}</strong>
                            <Form.Control
                              required={appLink !== ''}
                              type='email'
                              autoComplete='off'
                              value={appLink}
                              onChange={(e) => setAppLink(e.target.value)}
                            />
                          </Col>
                        )}
                        <Col xs={6} className='d-grid gap-2'>
                          <Button
                            variant='primary'
                            size='lg'
                            type='submit'
                            className=' py-md-1'
                            // onClick={(e) => {
                            // alta(e);
                            // setDischarge(false);
                            // }}
                          >
                            {t('yes')}
                          </Button>
                        </Col>
                        <Col xs={6} className='d-grid gap-2'>
                          <Button
                            variant='primary'
                            size='lg'
                            className=' py-md-1'
                            onClick={() => setDischarge(false)}
                          >
                            {t('no')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Modal.Body>
                </Modal>
              </Pagination>
              {(idriInitData.sim || Object.keys(idriData).length !== 0) &&
                page !== 'testInst' &&
                page !== 'create' && (
                  <Row className='text-center m-0'>
                    <Col>
                      <OverlayTrigger
                        placement='bottom'
                        rootClose='true'
                        overlay={
                          <Tooltip>
                            {t('eliminarIdri')} {idriInitData.idriserial}
                          </Tooltip>
                        }
                      >
                        <Button
                          variant='outline-danger'
                          // size='sm'
                          id='delBtn'
                          className='p-0 px-1'
                          onClick={(e) => (window.confirm(t('delete')) ? setModalShow(true) : null)}
                        >
                          <FaTrashAlt type='button' />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    <Modal
                      // size='sm'
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('introducePassword')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form className='text-center' onSubmit={(e) => deleteIdri(e, password)}>
                          <Form.Control
                            required
                            minLength={8}
                            type='password'
                            autoComplete='off'
                            value={password ?? ''}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Button variant='outline-danger' className='mt-3' size='sm' type='submit'>
                            {t('eliminarIdri')}
                          </Button>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </Row>
                )}

              {page !== 'create' && (
                <>
                  {/* {(idriInitData.sim || Object.keys(idriData).length !== 0) &&
                    // page !== 'testInst' && 
                    (
                      <Row className='text-center m-0'>
                        <Col>
                          <Button
                            variant='outline-danger'
                            size='sm'
                            id='delBtn'
                            className='p-0 px-1'
                            onClick={(e) =>
                              window.confirm(t('delete')) ? deleteIdri(e) : null
                            }
                          >
                            {t('eliminarIdri')} {idriInitData.idriserial}
                          </Button>
                        </Col>
                      </Row>
                    )} */}
                  <Col xs={12} className='text-center'>
                    {(idriInitData.installation_date || idriData.installation_date) && (
                      <Row className='mt-2 mx-1 cHeader'>
                        <Col>
                          #{t('serieIdri')}:{' '}
                          <strong>
                            {idriInitData.idriserial
                              ? idriInitData.idriserial
                              : idriData.idriserial
                              ? idriData.idriserial
                              : idri.idriserial && idri.idriserial}
                          </strong>
                        </Col>
                        <Col>
                          {t('altaInst')}:{' '}
                          <strong>{idriData.discharged ? t('realizada') : t('pendiente')}</strong>
                        </Col>
                        <Col>
                          {' '}
                          {t('fechaInst')}:{' '}
                          <strong>
                            {idriInitData.installation_date
                              ? idriInitData.installation_date
                              : idriData.installation_date
                              ? idriData.installation_date
                              : idri.installation_date && idri.installation_date}{' '}
                          </strong>
                        </Col>
                        <Col>
                          {t('estadoInst')}:{' '}
                          <strong>
                            {(page === 'init' && idriInitData.inicialized) ||
                            (page === 'newSensors' && idriData.inicialized) ||
                            (page === 'testInst' && idri.inicialized)
                              ? // (idriInitData.inicialized && idriData.inicialized)
                                t('inicializado')
                              : t('noInicializado')}{' '}
                          </strong>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <hr className='my-2' />
                </>
              )}
            </>
          )}
          {page === 'create' && <NewIdri setPage={setPage} />}
          {page === 'init' && <IdriInit profiles={props.profiles} setPage={setPage} />}
          {page === 'newSensors' && (
            <IdriConfig setPage={setPage} getIdriInitData={getIdriInitData} />
          )}
          {page === 'testInst' && <TestInstalation setPage={setPage} />}
        </div>
      )}
    </Container>
  );
};

export default NuevoIdri;
