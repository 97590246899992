import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import isValidNif from '../../../support_func/isValidNif';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';

const ResetPassword = () => {
  const { t } = useTranslation();
  const recaptchaRef = useRef('reset_password_recaptcha');

  const [language, setLanguage] = useState('');
  let lang = localStorage.getItem('language');
  let host = window.location.host;

  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    } else {
      setLanguage('es');
    }
  }, [lang]);

  const initialState = {
    email: '',
    nif: '',
    captchatoken: '',
    // captchaip: 'check',
    urlchangeform: '',
    language: '',
  };
  const [resetPassData, setResetPassData] = useState(initialState);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [key, setKey] = useState(Date.now());

  const resetPass = (e) => {
    setIsPending(true);
    setError(null);

    e.preventDefault();

    if (resetPassData.captchatoken && resetPassData.captchatoken !== '') {
      if (resetPassData.nif && !isValidNif(resetPassData.nif)) {
        setIsPending(false);
        setResetPassData({ ...resetPassData, captchatoken: '' });
        recaptchaRef.current.reset();
        setTimeout(() => {
          setError(t('nifInvalid'));
        }, 10);
      } else {
        let email = resetPassData.email && `?email=${resetPassData.email}`;
        let captchatoken =
          resetPassData.captchatoken && `&captchatoken=${resetPassData.captchatoken}`;
        // let captchaip = resetPassData.captchaip && `&captchaip=${host}`;
        // let nif = resetPassData.nif && `&nif=${resetPassData.nif}`;

        let data =
          email +
          captchatoken +
          // captchaip +
          // nif +
          `&urlchangeform=https://${host}` +
          `&language=${language}`;
        axios
          .get(`${process.env.REACT_APP_API_SRT}/v2/forgotpwd` + data)
          .then((res) => {
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.status === 'ok') {
              setIsPending(false);
              setResetPassData(initialState);
              recaptchaRef.current.reset();
              setMsg(t('solicitud'));
              setTimeout(() => {
                setMsg(null);
              }, 5000);
            } else {
              setIsPending(false);
              setResetPassData(initialState);
              recaptchaRef.current.reset();
              setError(t('errorMensaje'));
            }
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      }
    } else {
      setIsPending(false);
      setTimeout(() => {
        setError(t('noRobot'));
      }, 10);
    }
  };

  useEffect(() => {
    setKey(Date.now());
  }, [localStorage.getItem('language')]);

  return (
    <div className='modal-body'>
      <Container>
        <Row>
          <Col>
            <h5 className=' text-muted'>{t('sistemaEnviara')}</h5>
            <h5 className='' style={{ color: 'red' }}>
              {t('intrDatos')}
            </h5>
          </Col>
        </Row>

        <div id='password_reset_form_container'>
          <Form id='password_reset_form' onSubmit={(e) => resetPass(e)}>
            <Row>
              <Col className='mt-3'>
                <Form.Group>
                  <Form.Label htmlFor='password_reset_email'>
                    <strong>{t('emailTu')}</strong>
                  </Form.Label>
                  <Form.Control
                    required
                    type='email'
                    id='password_reset_email'
                    autoComplete='off'
                    value={resetPassData.email}
                    onChange={(e) =>
                      setResetPassData({
                        ...resetPassData,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col className='mt-3'>
                <Form.Group>
                  <Form.Label htmlFor='password_reset_nif'>
                    <strong>NIF </strong>
                    <span className=''>({t('grat')})</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='password_reset_nif'
                    autoComplete='off'
                    value={resetPassData.nif}
                    onChange={(e) =>
                      setResetPassData({
                        ...resetPassData,
                        nif: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <Row className='mt-4'>
              <ReCAPTCHA
                id='captcha'
                key={key}
                hl={localStorage.getItem('language')}
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                onChange={(e) =>
                  setResetPassData({
                    ...resetPassData,
                    captchatoken: e,
                  })
                }
              />
            </Row>
            <Col>
              <Button type='submit' id='sendMessageButton' className='btn dark text-uppercase mt-3'>
                {t('enviar')}
              </Button>
            </Col>
          </Form>
          {isPending && (
            <div className='working_apis'>
              <div className='text-working-apis'>{t('comprobando')}...</div>
            </div>
          )}
          {(error && <Col className='text-center alert alert-warning mt-3'>{error}</Col>) ||
            (msg && <Col className='text-center alert alert-success mt-3'>{msg}</Col>)}
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
