import scaleDecimal from './scaleDecimal';

const floatWithDec = (num, dec) => {
  if (scaleDecimal(num) > scaleDecimal(dec)) {
    return parseFloat(num).toFixed(scaleDecimal(dec));
  } else {
    return num;
  }
};

export default floatWithDec;
