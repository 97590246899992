/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { urlB64ToUint8Array } from '../../../../support_func/urlB64ToUint8Array';
import checkNotification from '../../../../support_func/checkNotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';
import { HiRefresh } from 'react-icons/hi';
import Timer from '../../../../support_func/Timer';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import { CgCopy } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const Settings = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [push, setPush] = useState({});
  const [webhook, setWebhook] = useState({});
  const [devices, setDevices] = useState([]);
  const [lastFirm, setLastFirm] = useState({});
  const [behalfedUsers, setBehalfedUsers] = useState([]);
  const [runAsync, setRunAsync] = useState(false);
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [defaultUser, setDefaultUser] = useState();
  const [newApiKeyDesc, setNewApiKeyDesc] = useState('');
  const [editApiKeyDesc, setEditApiKeyDesc] = useState(false);
  const [editedApiKeyDesc, setEditedApiKeyDesc] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [apiKeyModal, setApiKeyModal] = useState('');
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [apiKeysDelete, setApiKeysDelete] = useState([]);

  const userStore = useSelector((state) => state.user);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const checkPush = async () => {
    if ('Notification' in window) {
      await Notification.requestPermission(function (status) {
        checkNotification().then((val) => {
          setPush({
            ...push,
            browser: 'Notification' in window ? t('supported') : t('unsupported'),
            status:
              status === 'granted'
                ? t('grantedNot')
                : status === 'denied'
                ? t('denied')
                : t('default'),
            receive: val ? t('receiving') : t('notReceiving'),
          });
        });
      });
    }
  };

  const getNotifications = async () => {
    setIsPending(true);
    localStorage.removeItem(`pushRemind-${localStorage.getItem('id')}`);
    let notification = null;
    if ('Notification' in window) {
      await Notification.requestPermission(function (status) {
        notification = status;
        console.log(t('notificationStatus'), status);
      });

      if (notification === 'granted') {
        // setIsPending(true);
        const createSubscription = await navigator.serviceWorker
          .getRegistration()
          .then((registration) => {
            // console.log(registration);
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
            });
          });
        console.log('created subscription: ', createSubscription);
        // in production we would send it directly to our server and not store it on the window
        window.mySubscription = createSubscription;

        if (!window.mySubscription) {
          setError(t('noSubscription'));
          return;
        }
        const body = {
          endpoint: window.mySubscription.toJSON().endpoint,
          p256dh: window.mySubscription.toJSON().keys.p256dh,
          auth: window.mySubscription.toJSON().keys.auth,
          subscribe: true,
          // expirationTime: window.mySubscription.toJSON().expirationTime,
          // email: localStorage.getItem('email'),
        };
        console.log(body);
        await axios
          .post(
            `${process.env.REACT_APP_API_SRT}/private2/webpushsubs
              `,
            body,
            config
          )
          .then((res) => {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.code === '0') {
              setError(t('subscriptionSuccess'));
              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('problem'));
            }
            setIsPending(false);
          })
          .catch((err) => {
            console.log(err.response.data);
            navigator.serviceWorker.ready.then((reg) => {
              reg.pushManager.getSubscription().then((subscription) => {
                subscription
                  .unsubscribe()
                  .then((successful) => {
                    console.log(t('unSubscribeSuccess'));
                    localStorage.setItem(
                      `pushRemind-${localStorage.getItem('id')}`,
                      moment(moment()).add(6, 'months')
                    );
                    window.location.href = '/inicio';
                  })
                  .catch((e) => {
                    console.log(e);
                    setIsPending(false);
                    setError(t('problem'));
                  });
              });
            });
            let code = err.response.data.code;
            if (code === '1') {
              setIsPending(false);
              localStorage.removeItem('autoAcceso');
              alert(t('sessionExpired'));
              window.location.href = '/';
            } else {
              setIsPending(false);
              setError(
                t(`error${err.response.data.msg_code}`)
                  ? t(`error${err.response.data.msg_code}`)
                  : t('errorUnknown')
              );
            }
          });
      }
    } else {
      setError(t('notificationNoSup'));
    }
    checkNotification().then((val) =>
      props.setNotifications({ ...props.notifications, data: val })
    );
  };

  const unSubscribePush = async () => {
    setIsPending(true);
    const createSubscription = await navigator.serviceWorker.ready.then((reg) => {
      return reg.pushManager.getSubscription();
    });
    window.mySubscription = createSubscription;
    console.log(window.mySubscription);
    let body = {
      endpoint: window.mySubscription.toJSON().endpoint,
      p256dh: window.mySubscription.toJSON().keys.p256dh,
      auth: window.mySubscription.toJSON().keys.auth,
      subscribe: false,
      // expirationTime: window.mySubscription.toJSON().expirationTime,
      // email: localStorage.getItem('email'),
    };
    navigator.serviceWorker.ready.then((reg) => {
      reg.pushManager.getSubscription().then((subscription) => {
        subscription
          .unsubscribe()
          .then((successful) => {
            // console.log(successful);
            axios
              .post(
                `${process.env.REACT_APP_API_SRT}/private2/webpushsubs
                  `,
                body,
                config
              )
              .then((res) => {
                console.log(res.data);
                if (!res.data.code) {
                  unavailable(true);
                }
                if (res.data.code === '0') {
                  localStorage.setItem(
                    `pushRemind-${localStorage.getItem('id')}`,
                    moment(moment()).add(6, 'months')
                  );
                  setError(t('unSubscribeSuccess'));
                  setIsPending(false);
                  window.location.href = '/inicio';
                } else {
                  setIsPending(false);
                  setError(t('problem'));
                }
                setIsPending(false);
              })
              .catch((err) => {
                console.log(err.response.data);
                setError(t('unSubscribeSuccess'));
                setIsPending(false);
                localStorage.setItem(
                  `pushRemind-${localStorage.getItem('id')}`,
                  moment(moment()).add(6, 'months')
                );
                window.location.href = '/inicio';
              });
          })
          .catch((e) => {
            console.log(e);
            setIsPending(false);
            setError(t('problem'));
          });
      });
    });
  };

  const postNot = async () => {
    setIsPending(true);

    const createSubscription = await navigator.serviceWorker.ready.then((reg) => {
      return reg.pushManager.getSubscription();
    });
    window.mySubscription = createSubscription;
    // console.log(window.mySubscription && window.mySubscription.toJSON().keys.auth);

    setError(null);
    let body = {
      emails: [localStorage.getItem('email')],
      title: 'title',
      message: 'body',
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/private2/sendnotification`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setError(t('success'));
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getWebhook = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/private2/webhookget`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setWebhook({
            url: res.data.user_webhook.data.url,
            apikey: res.data.user_webhook.data.apikey,
          });
        } else {
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postWebhook = async (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      url: webhook.url ? webhook.url : '',
      apikey: webhook.apikey ? webhook.apikey : '',
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/private2/webhookset`, body, config)
      .then((res) => {
        console.log(res);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setError(t('success'));
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getLastFirm = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/lastfirm`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setLastFirm(res.data.lastghatorfirm);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getDevices = () => {
    setIsPending(true);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghators?profile=${localStorage.getItem('profile')}`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          let idris = [];
          res.data.ghators.map((d) => {
            if (parseFloat(d.ghatorv) >= 3) {
              idris.push({
                ghatorname: d.ghatorname,
                ghatorid: d.ghatorid,
                ghatorserial: d.ghatorserial,
                ghatorv: d.ghatorv,
                v_firmware: d.v_firmware,
                v_firmware_update_trial: d.v_firmware_update_trial,
                fail_update_trial: d.fail_update_trial,
                last_read_error: d.last_read_error,
                wkmode: d.wkmode,
                discharged: d.discharged,
                inicialized: d.inicialized,
                user_discharge: d.user_discharge,
              });
            }
          });
          setDevices(idris);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getRefresh = (id, serial) => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/grefresh?ghatorid=${id}&ghatorserial=${serial}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          if (devices.length > 0 && devices.find((i) => i.ghatorid === id).wkmode === 2) {
            getDevices();
          } else {
            counter = 0;
            if (counter === 0) {
              setRunAsync(true);
              setTimeout(() => {
                checkAsync(res.data.asyncid, null);
              }, 2000);
            }
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  let counter = null;
  const checkAsync = (id, firmware) => {
    if (counter === 0) {
      getAsync(id, firmware);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id, firmware);
      }, 2000);
    }
  };
  const getAsync = (id, firmware) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          if (
            (firmware && res.data.found === true) ||
            (res.data.found === true && res.data.response.result === true)
          ) {
            getDevices();
            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            if (res.data.response.nicolecode_id === 5) {
              setIsPending(false);
              setError(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              setIsPending(false);
              setError(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id, firmware);
          } else if (res.data.found === false) {
            setIsPending(false);
            setError(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const updatefirmware = async (ids) => {
    setIsPending(true);

    let body = {
      ghatorserials: ids,
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/updatefirm`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          // if (
          //   devices.length > 0 &&
          //   devices.filter((i) => ids.includes(i.ididri)).every((i) => i.wkmode === 2)
          // ) {
          //   getDevices();
          // } else {
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setTimeout(() => {
              checkAsync(res.data.asyncid, true);
            }, 2000);
          }
          // }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getBehalf = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/onbehalf`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          res.data.behalfeds.forEach((u) => {
            u.default && setDefaultUser(u.iduser);
          });
          setBehalfedUsers(res.data.behalfeds);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const patchBehalf = (id) => {
    setIsPending(true);
    let body = {
      iduser: id,
      email: behalfedUsers
        .map((u) => {
          if (u.iduser === id) {
            return u.email;
          }
        })
        .find((value) => {
          return value;
        }),
      default: id ? true : false,
    };
    axios
      .patch(`${process.env.REACT_APP_API_SRT}/v2/onbehalf`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          res.data.behalfeds.forEach((u) => {
            u.default && setDefaultUser(u.iduser);
          });
          setBehalfedUsers(res.data.behalfeds);
          // setError(t('modificated'));
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const deleteBehalf = (e) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/onbehalf`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { idusers: checkedUsers },
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setError(t('deleted'));
          setCheckedUsers([]);
          getBehalf();
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getApiKey = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/private2/apikey`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          let apiKeysList = [];

          res.data.apikeys.forEach((a) => {
            apiKeysList.push({ ...a, edit: false });
          });

          setApiKeys(apiKeysList);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postApiKey = (e) => {
    e.preventDefault();
    setIsPending(true);
    let body = {
      description: newApiKeyDesc,
    };
    axios
      .post(`${process.env.REACT_APP_API_SRT}/private2/apikey`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setNewApiKeyDesc('');
          setApiKeyModal(res.data.apikey_token);
          setShowApiKeyModal(true);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const patchApiKey = (e, id, descr) => {
    e.preventDefault();
    setIsPending(true);
    let body = {
      idapikey: id,
      description: descr,
    };
    axios
      .patch(`${process.env.REACT_APP_API_SRT}/private2/apikey`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          getApiKey();
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const deleteApiKey = (e, id) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/private2/apikey`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { idapikeys: id ? [id] : apiKeysDelete },
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setError(t('deleted'));
          setApiKeysDelete([]);
          getApiKey();
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const filteredDevices = (dev) => {
    return dev
      .map((d) => {
        if (
          !Object.values(lastFirm)
            .map((f) => {
              return f.v_firmware;
            })
            .includes(d.v_firmware)
        ) {
          return d.ghatorserial;
        }
      })
      .filter((value) => {
        return value;
      });
  };

  const copyToClipboard = (text, type) => {
    console.log(text);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard successfully');
        type && type === 'api' ? setError(t('copiedApi')) : setError(t('copied'));
      })
      .catch((err) => {
        console.error('Error copying text to clipboard:', err);
        setError(t('errorCopy') + ': ' + text);
      });
  };

  useEffect(() => {
    // const userProfile = localStorage.getItem('profile');
    // if (userProfile) {
    //   if (userProfile === 'admi' && !userStore.profiles.admi) {
    //     localStorage.setItem('profile', 'gamo');
    //   } else if (userProfile === 'gamo' && userStore.profiles.admi) {
    //     localStorage.setItem('profile', 'admi');
    //   } else {
    //     localStorage.setItem('profile', 'gamo');
    //   }
    // }

    checkPush();
    checkNotification().then((val) =>
      props.setNotifications({ ...props.notifications, data: val })
    );
    getWebhook();
    getLastFirm();
    getDevices();
    getBehalf();
    getApiKey();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(isPending);

  return (
    <Container className='text'>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}:{' '}
                <Timer min={2} sec={0} waiting={true} close={() => setIsPending(false)} />
              </span>
            )}
          </div>
        </div>
      )}
      <Accordion
        defaultActiveKey={
          localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : '0'
        }
        alwaysOpen
        className='acc'
        onSelect={(e) => {
          localStorage.setItem('settings', JSON.stringify(e));
        }}
      >
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('notifications')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Row className='text-center'>
              <Col xs={12} md={4} className=''>
                <strong>{t('browserSupport')}</strong>: {push.browser}
              </Col>
              <Col xs={12} md={4} className=''>
                <strong>{t('browserPermission')}</strong>: {push.status}
              </Col>
              <Col xs={12} md={4} className=''>
                <strong>{t('pushStatus')}</strong>: {push.receive}
              </Col>
              <Col xs={12} className='mt-2'>
                {props.notifications.data ? (
                  <Button
                    size='lg'
                    className=' py-md-1 dark'
                    onClick={() => window.confirm(t('seguro')) && unSubscribePush()}
                  >
                    {t('unSubscribe')}
                  </Button>
                ) : (
                  <Button
                    // size='lg'
                    className={`py-md-1  ${props.notifications.data ? 'dark' : 'primary'}`}
                    onClick={() =>
                      props.notifications.data
                        ? setError(t('subscribed'))
                        : window.confirm(t('seguro')) && getNotifications()
                    }
                  >
                    {t('getNotifications')}
                  </Button>
                )}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('connectNot')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={(e) => postWebhook(e)}>
              <Row className='d-flex justify-content-center text-center'>
                <Col xs={12} md={6} className=''>
                  <strong>{t('appLink')}</strong>
                  <Form.Control
                    required
                    value={webhook.url ? webhook.url : ''}
                    onChange={(e) => setWebhook({ ...webhook, url: e.target.value })}
                  />
                </Col>
                <Col xs={12} md={6} className=''>
                  <strong>{t('apiKey')}</strong>
                  <Form.Control
                    value={webhook.apikey ? webhook.apikey : ''}
                    onChange={(e) => setWebhook({ ...webhook, apikey: e.target.value })}
                  />
                </Col>
                <Col xs={12} className='mt-3'>
                  <Button variant='primary' type='submit' className='py-md-1 '>
                    {t('connectApp')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('apiKeys')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='p-0'>
            <Row className='text-center'>
              <Col xs={12}>
                <strong>{t('createNewApiKey')}</strong>
              </Col>
            </Row>
            <Row className='d-flex justify-content-center text-center m-2 mt-0'>
              <Form onSubmit={(e) => postApiKey(e)}>
                <InputGroup className='noFocus'>
                  <Form.Control
                    className='rounded'
                    placeholder={t('newApiKeyDescription')}
                    value={newApiKeyDesc}
                    onChange={(e) => setNewApiKeyDesc(e.target.value)}
                  />
                  <Button type='submit' variant='primary' className='w-sm-100 w-md-25 m-0 rounded'>
                    {t('newApiKey')}
                  </Button>
                </InputGroup>
              </Form>
            </Row>
            {apiKeys.length > 0 && (
              <>
                <Row className='text-center mt-2'>
                  <Col xs={12}>
                    <strong>{t('apiKeysList')}</strong>
                  </Col>
                </Row>
                <Card className='text-center mx-0 mx-md-3 mb-2 p-0'>
                  <Row className='d-flex justify-content-center text-center '>
                    {apiKeys.map((a, i) => (
                      <Col
                        key={i}
                        xs={12}
                        className={`${
                          apiKeys.length > 1 && apiKeys.length - 1 !== i && 'mb-2'
                        } noFocus`}
                      >
                        <Form key={a.id} onSubmit={(e) => patchApiKey(e, a.id, a.apikey_desc)}>
                          <InputGroup className=''>
                            <Form.Control
                              readOnly
                              className='w-sm-100 w-md-25 m-0 rounded'
                              value={a.apikey_token ?? ''}
                            />
                            <Form.Control
                              className={`${a.edit && 'inputFocus'} w-sm-100 w-md-40 m-0 rounded`}
                              readOnly={!a.edit}
                              value={a.apikey_desc ?? ''}
                              onChange={(e) => {
                                const updatedApiKeys = [
                                  ...apiKeys.slice(0, i),
                                  { ...apiKeys[i], apikey_desc: e.target.value },
                                  ...apiKeys.slice(i + 1),
                                ];
                                setApiKeys(updatedApiKeys);
                              }}
                            />
                            {a.edit ? (
                              <Button
                                variant='btn-outline-success'
                                className='btn-outline-success w-sm-50 w-md-25 m-0 rounded'
                                onClick={(e) => {
                                  const updatedApiKeys = [
                                    ...apiKeys.slice(0, i),
                                    { ...apiKeys[i], edit: false },
                                    ...apiKeys.slice(i + 1),
                                  ];
                                  setApiKeys(updatedApiKeys);
                                  patchApiKey(e, a.id, a.apikey_desc);
                                }}
                              >
                                {t('updateDescription')}
                              </Button>
                            ) : (
                              <Button
                                type='submit'
                                variant='btn-outline-primary'
                                className='btn-outline-primary w-sm-50 w-md-25 m-0 rounded'
                                disabled={apiKeys
                                  .map((a) => {
                                    return a.edit;
                                  })
                                  .includes(true)}
                                onClick={() => {
                                  const updatedApiKeys = [
                                    ...apiKeys.slice(0, i),
                                    { ...apiKeys[i], edit: true },
                                    ...apiKeys.slice(i + 1),
                                  ];
                                  setApiKeys(updatedApiKeys);
                                }}
                              >
                                {t('editDescription')}
                              </Button>
                            )}

                            {a.edit ? (
                              <Button
                                variant='btn-outline-secondary'
                                className='btn-outline-secondary w-sm-50 w-md-5 m-0 rounded'
                                onClick={() => getApiKey()}
                              >
                                X
                              </Button>
                            ) : (
                              <Button
                                variant='outline-danger'
                                className='w-sm-50 w-md-5 m-0 rounded'
                                disabled={apiKeys
                                  .map((a) => {
                                    return a.edit;
                                  })
                                  .includes(true)}
                                onClick={(e) =>
                                  window.confirm(t('seguro')) ? deleteApiKey(e, a.id) : null
                                }
                              >
                                <FaTrashAlt type='button' />
                              </Button>
                            )}
                          </InputGroup>
                        </Form>
                      </Col>
                    ))}
                  </Row>
                </Card>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('firmwareUpdate')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='p-0'>
            <Table bordered striped className='mb-0' id='firmTable'>
              <thead>
                <tr className='text-center'>
                  <th colSpan={5} className=' text-center align-middle p-0'>
                    {t('actualFirmwareList')}
                  </th>
                </tr>
                {Object.values(lastFirm).length > 0 &&
                  Object.values(lastFirm).map((f) => (
                    <tr key={f.v_firmware} className='text-center'>
                      <th colSpan={2} className=' text-center align-middle p-0'>
                        v{f.ghatorv}
                      </th>
                      <th colSpan={3} className=' text-center align-middle p-0'>
                        {f.v_firmware}
                      </th>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={6} className='p-0'>
                    <hr className='m-0 p-0 hr-dark' />
                  </td>
                </tr>
                <tr className='text-center'>
                  <th className=' text-center align-middle px-0'></th>
                  <th className=' text-center align-middle px-0'>{t('device')}</th>
                  <th className=' text-center align-middle px-0'>{t('version')}</th>
                  <th className=' text-center align-middle px-0'>{t('firmware')}</th>
                  <th className=' text-center align-middle px-0'>{t('update')}</th>
                </tr>
              </thead>
              <tbody>
                {devices.length > 0 &&
                  devices.map((d, i) => {
                    if (
                      !Object.values(lastFirm)
                        .map((f) => {
                          return f.v_firmware;
                        })
                        .includes(d.v_firmware)
                    ) {
                      return (
                        <tr key={d.ghatorserial} className='text-center'>
                          <td className=' text-center align-middle p-1'>
                            <Form.Check
                              type='checkbox'
                              checked={checkedDevices.includes(d.ghatorserial)}
                              disabled={
                                (!userStore.profiles.admi &&
                                  (!d.discharged || !d.inicialized || !d.user_discharge)) ||
                                (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                (d.v_firmware_update_trial &&
                                  d.fail_update_trial &&
                                  d.last_read_error) ||
                                lastFirm.length === 0
                              }
                              // disabled={
                              //   (d.v_firmware_update_trial && !d.fail_update_trial) ||
                              //   (d.v_firmware_update_trial &&
                              //     d.fail_update_trial &&
                              //     d.last_read_error)
                              // }
                              onChange={(e) =>
                                setCheckedDevices((prevState) =>
                                  prevState.includes(d.ghatorserial)
                                    ? prevState.filter((item) => item !== d.ghatorserial)
                                    : [...prevState, d.ghatorserial]
                                )
                              }
                            />
                          </td>
                          <td className=' text-center align-middle p-0 text-truncate'>
                            {d.ghatorname ? d.ghatorname : ''}
                          </td>
                          <td className=' text-center align-middle p-0'>
                            v{d.ghatorv ? d.ghatorv : ''}
                          </td>
                          <td className=' text-center align-middle p-0'>
                            {d.v_firmware ? d.v_firmware : ''}
                          </td>
                          <td className='p-0'>
                            <OverlayTrigger
                              trigger={['hover', 'click']}
                              placement='top'
                              overlay={
                                // (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                d.v_firmware_update_trial &&
                                d.fail_update_trial &&
                                d.last_read_error ? (
                                  <Tooltip>{t('updateFailed')}</Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <Row>
                                {((d.v_firmware_update_trial && !d.fail_update_trial) ||
                                  (d.v_firmware_update_trial &&
                                    d.fail_update_trial &&
                                    d.last_read_error)) && (
                                  <Col className='d-grid pe-0'>
                                    <Button
                                      className=' p-1 rounded-0'
                                      variant='outline-primary'
                                      onClick={() => getRefresh(d.ghatorid, d.ghatorserial)}
                                    >
                                      <HiRefresh />
                                    </Button>
                                  </Col>
                                )}
                                <Col
                                  className={
                                    (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                    (d.v_firmware_update_trial &&
                                      d.fail_update_trial &&
                                      d.last_read_error)
                                      ? 'd-grid ps-0'
                                      : 'd-grid'
                                  }
                                >
                                  <Button
                                    className='py-md-2 rounded-0 '
                                    variant={
                                      (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                      (d.v_firmware_update_trial &&
                                        d.fail_update_trial &&
                                        d.last_read_error)
                                        ? 'outline-danger'
                                        : 'outline-success'
                                    }
                                    disabled={
                                      (!userStore.profiles.admi &&
                                        (!d.discharged || !d.inicialized || !d.user_discharge)) ||
                                      (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                      (d.v_firmware_update_trial &&
                                        d.fail_update_trial &&
                                        d.last_read_error) ||
                                      lastFirm.length === 0
                                    }
                                    onClick={() => updatefirmware([d.ghatorserial])}
                                  >
                                    <FaDownload type='button' />
                                  </Button>
                                </Col>
                              </Row>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    }
                  })}
                <tr>
                  <td colSpan={6} className='p-0'>
                    <hr className='m-0 p-0 hr-dark' />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className=' text-center align-middle p-0'>
                    <Form.Check
                      type='checkbox'
                      checked={
                        devices.length > 0 &&
                        filteredDevices(devices).length > 0 &&
                        filteredDevices(devices).every((v) => {
                          return checkedDevices.indexOf(v) >= 0;
                        })
                      }
                      onChange={(e) =>
                        setCheckedDevices(
                          e.target.checked
                            ? devices
                                .map((d) => {
                                  if (
                                    (!d.v_firmware_update_trial ||
                                      (d.v_firmware_update_trial &&
                                        d.fail_update_trial &&
                                        !d.last_read_error)) &&
                                    !Object.values(lastFirm)
                                      .map((f) => {
                                        return f.v_firmware;
                                      })
                                      .includes(d.v_firmware)
                                  ) {
                                    if (
                                      !(
                                        (!userStore.profiles.admi &&
                                          (!d.discharged || !d.inicialized || !d.user_discharge)) ||
                                        (d.v_firmware_update_trial && !d.fail_update_trial) ||
                                        (d.v_firmware_update_trial &&
                                          d.fail_update_trial &&
                                          d.last_read_error) ||
                                        lastFirm.length === 0
                                      )
                                    ) {
                                      return d.ghatorserial;
                                    }
                                  }
                                })
                                .filter((item) => typeof item === 'string')
                            : []
                        )
                      }
                    />
                  </td>
                  <td colSpan={3} className=' text-center align-middle p-0'></td>
                  <td className='p-0'>
                    <div className='d-grid'>
                      <Button
                        className='p-1 rounded-0'
                        variant='outline-success'
                        disabled={checkedDevices.length === 0 || lastFirm.length === 0}
                        onClick={() => updatefirmware(checkedDevices)}
                      >
                        <FaDownload type='button' />
                      </Button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('behalfedUsers')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='p-0'>
            <Table bordered responsive striped className='mb-0' id='firmTable'>
              <thead>
                <tr className='text-center'>
                  <th className=' text-center align-middle px-0'>
                    <Form.Check
                      type='checkbox'
                      checked={
                        behalfedUsers.length > 0 &&
                        behalfedUsers
                          .map((u) => {
                            return u.iduser;
                          })
                          .every((v) => {
                            return checkedUsers.indexOf(v) >= 0;
                          })
                      }
                      onChange={(e) =>
                        setCheckedUsers(
                          behalfedUsers.length === checkedUsers.length
                            ? []
                            : behalfedUsers.map((u) => {
                                return u.iduser;
                              })
                        )
                      }
                    />
                  </th>
                  <th className=' text-center align-middle px-0 '>{t('email')}</th>
                  <th className=' text-center align-middle px-0'>{t('nif')}</th>
                  <th className=' text-center align-middle px-0'>{t('default')}</th>
                </tr>
              </thead>
              <tbody>
                {behalfedUsers.length > 0 &&
                  behalfedUsers.map((u, i) => {
                    return (
                      <tr key={u.iduser} className='text-center'>
                        <td className=' text-center align-middle p-1'>
                          <Form.Check
                            type='checkbox'
                            checked={checkedUsers.includes(u.iduser)}
                            onChange={(e) =>
                              setCheckedUsers((prevState) =>
                                prevState.includes(u.iduser)
                                  ? prevState.filter((item) => item !== u.iduser)
                                  : [...prevState, u.iduser]
                              )
                            }
                          />
                        </td>
                        <td className=' text-center align-middle p-0 text-truncate trun'>
                          {u.email ? u.email : ''}
                        </td>
                        <td className=' text-center align-middle p-0'>{u.nif ? u.nif : ''}</td>
                        <td className=' text-center align-middle p-0'>
                          <Form.Check
                            type='checkbox'
                            checked={defaultUser && defaultUser === u.iduser ? true : false}
                            onChange={(e) => {
                              e.target.checked ? setDefaultUser(u.iduser) : setDefaultUser();
                              e.target.checked ? patchBehalf(u.iduser) : patchBehalf();
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={6} className='p-0'>
                    <hr className='m-0 p-0 hr-dark' />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className=' text-center align-middle p-0'>
                    <div className='d-grid'>
                      <Button
                        className=' p-1 rounded-0'
                        variant='outline-danger'
                        disabled={checkedUsers.length === 0}
                        onClick={(e) =>
                          window.confirm(t('seguroDelUser')) ? deleteBehalf(e) : null
                        }
                      >
                        <FaTrashAlt type='button' />
                      </Button>
                    </div>
                  </td>
                  <td colSpan={3} className=' text-center align-middle p-0'></td>
                  {/* <td className='p-0'>
                    <div className='d-grid'>
                      <Button
                        className=' p-1 rounded-0'
                        variant='outline-primary'
                        disabled={behalfedUsers.length === 0}
                        onClick={() => patchBehalf()}
                      >
                        {t('update')}
                      </Button>
                    </div>
                  </td> */}
                </tr>
              </tfoot>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Modal
        show={showApiKeyModal}
        backdrop='static'
        size='lg'
        onHide={() => {
          setShowApiKeyModal(false);
          getApiKey();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('saveApiKey')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='d-flex justify-content-center text-center'>
            <Col xs={12} className='mb-3'>
              <strong>{t('copySaveApiKey')}</strong>
            </Col>
            <Col xs={12} className='noFocus'>
              <InputGroup className='cursorCopy'>
                <Form.Control
                  readOnly
                  className='text-center rounded'
                  value={apiKeyModal}
                  onClick={() => copyToClipboard(apiKeyModal, 'api')}
                />
                <Button
                  variant='secondary'
                  className='w-sm-100 w-md-25 m-0 rounded'
                  onClick={() => copyToClipboard(apiKeyModal, 'api')}
                >
                  <CgCopy /> {t('copyApiKey')}
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {userStore.profiles.admi && (
        <Row className='mt-5 text-center'>
          <Col xs={4} className='d-grid'>
            <Button variant='primary' onClick={() => postNot()}>
              Notification test
            </Button>
          </Col>
          {/* <Col xs={6} className='d-grid'>
          <Button variant='primary' onClick={() => postWebhook()}>
            Webhook test
          </Button>
        </Col> */}
        </Row>
      )}
    </Container>
  );
};

export default Settings;
