/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import isValidNif from '../../../../support_func/isValidNif';
import { urlB64ToUint8Array } from '../../../../support_func/urlB64ToUint8Array';
import ModalGenerico from '../../../home/Modal_generico';
import Bank from '../../modals/Bank';
import ChangeEmail from '../../modals/ChangeEmail';
import ChangePassword from '../../modals/ChangePassword';
import Contract from '../../modals/Contract';
import FeeInfo from '../../modals/FeeInfo';
import Invocing from '../../modals/Invocing';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
// import NewCommunity from '../../modals/NewCommunity';

const MyProfile = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState();
  const [userData, setUserData] = useState({});
  const [adsressRequired, setAdsressRequired] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changepasswordModal, setChangepasswordModal] = useState(false);
  const [feeInfoModal, setFeeInfoModal] = useState(false);
  const [invoicingModal, setInvoicingModal] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  // const [notifications, setNotifications] = useState(null);

  // const [newCommunityModal, setNewCommunityModal] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getUser = (id, email) => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/user?iduser=${id}&email=${email}`, config)
      .then((res) => {
        console.log(res.data.user);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setUser(res.data.user);
          setUserData(res.data.user.data);
          //   setUserBank();
          //   setNewUserEmail();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postUser = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ...userData,
      iduser: userData.iduser,
      email: userData.email,
    };
    delete body.id;

    if (
      (body.address && body.zip && body.city && body.province && body.country) ||
      (!body.address && !body.zip && !body.city && !body.province && !body.country)
    ) {
      setAdsressRequired(false);
      if (isValidNif(body.nif)) {
        axios
          .patch(`${process.env.REACT_APP_API_SRT}/v2/user`, body, config)
          .then((res) => {
            // console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.code === '0') {
              setError(t('modificated') + ' ' + t('success'));

              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('problem'));
            }
            setIsPending(false);
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setError(t('nifIncorecto'));
        setIsPending(false);
      }
    } else {
      setAdsressRequired(true);
      setError(t('address'));
      setIsPending(false);
    }
  };

  const changeEmail = (e) => {
    setIsPending(true);
    e.preventDefault();
    if (!newEmail) {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      let lang = localStorage.getItem('language');
      let host = window.location.host;
      // let noIp = 'http://srtnicole.hopto.org:8000';
      axios
        .get(
          `${process.env.REACT_APP_API_SRT}/v2/useremailchange?iduser=${userData.iduser}&email=${userData.email}&newemail=${newEmail}&language=${lang}&urlemailchange=${host}`,
          config
        )
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            window.alert(t('emailSent'));
            setNewEmail();
            setChangeEmailModal(false);
            setIsPending(false);
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('id')) {
      getUser(localStorage.getItem('id'), localStorage.getItem('email'));
    } else {
      setError(t('userInitProblem'));
    }
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(notifications);
  // useEffect(() => {
  //   userData.email && postNot();
  // }, [userData]);

  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : (
        <Container className='text'>
          <h5 className='text-center '>{t('userData')}</h5>
          {userData && userData.email && (
            <Form onSubmit={(e) => postUser(e)}>
              <Row className='px-0'>
                <Col xs={12} md={6}>
                  <strong>{t('email')}</strong>
                  <Form.Control
                    disabled
                    type='email'
                    value={userData.email ? userData.email : ''}
                    //   onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  />
                </Col>
                <Col xs={12} md={6} className='d-grid pt-2 pt-md-4'>
                  <Button
                    disabled={!user.permissionsonuser.change_email}
                    // variant='primary'
                    size='lg'
                    className='py-md-1 dark'
                    onClick={() => setChangeEmailModal(true)}
                  >
                    {t('changeEmail')}
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <strong>{t('nombre')}</strong>
                  <Form.Control
                    type='name'
                    maxLength={50}
                    value={userData.name ? userData.name : ''}
                    onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <strong>{t('lastname')}</strong>
                  <Form.Control
                    type='surname'
                    maxLength={100}
                    value={userData.lastname ? userData.lastname : ''}
                    onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
                  />
                </Col>

                <Col xs={6} md={6}>
                  <strong>{t('phone')}</strong>
                  <Form.Control
                    type='phone'
                    maxLength={30}
                    value={userData.phone ? userData.phone : ''}
                    onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <strong>nif</strong>
                  <Form.Control
                    type='text'
                    value={userData.nif ? userData.nif.toUpperCase() : ''}
                    onChange={(e) => setUserData({ ...userData, nif: e.target.value })}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <strong>{t('direccion')}</strong>
                  <Form.Control
                    required={adsressRequired}
                    type='text'
                    maxLength={200}
                    value={userData.address ? userData.address : ''}
                    onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <strong>{t('city')}</strong>
                  <Form.Control
                    required={adsressRequired}
                    type='text'
                    maxLength={50}
                    value={userData.city ? userData.city : ''}
                    onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <strong>{t('zip')}</strong>
                  <Form.Control
                    required={adsressRequired}
                    type='number'
                    maxLength={20}
                    value={userData.zip ? userData.zip : ''}
                    onChange={(e) => setUserData({ ...userData, zip: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <strong>{t('province')}</strong>
                  <Form.Control
                    required={adsressRequired}
                    type='text'
                    maxLength={50}
                    value={userData.province ? userData.province : ''}
                    onChange={(e) => setUserData({ ...userData, province: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <strong>{t('country')}</strong>
                  <Form.Control
                    required={adsressRequired}
                    type='text'
                    maxLength={50}
                    value={userData.country ? userData.country : ''}
                    onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                  />
                </Col>
                {/* <Row className='text-center mt-3'> */}
                <Col xs={12} className='text-center mt-3'>
                  <Button
                    type='submit'
                    size='lg'
                    className='py-md-1 dark'
                    //   onClick={(e) => postUser(e)}
                  >
                    {t('save').toUpperCase()}
                  </Button>
                </Col>
                {/* </Row> */}
                <Card className='mt-3 pt-1 pt-md-0 pb-2'>
                  <Row>
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        // variant='primary'
                        size='lg'
                        className='py-md-1 dark'
                        onClick={() => setChangepasswordModal(true)}
                      >
                        {t('cambiarPass')}
                      </Button>
                    </Col>
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        // variant='primary'
                        size='lg'
                        className='py-md-1 dark'
                        onClick={() => setFeeInfoModal(true)}
                      >
                        {t('feeInfo')}
                      </Button>
                    </Col>
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        disabled={!user.permissionsonuser.get_invoicedata}
                        // variant='primary'
                        size='lg'
                        className='py-md-1 dark'
                        onClick={() => setInvoicingModal(true)}
                      >
                        {t('invoicingData')}
                      </Button>
                    </Col>
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        disabled={!user.permissionsonuser.get_bank}
                        // variant='primary'
                        size='lg'
                        className='py-md-1 dark'
                        onClick={() => setBankModal(true)}
                      >
                        {t('bank')}
                      </Button>
                    </Col>
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        // variant='primary'
                        size='lg'
                        className='py-md-1 dark'
                        // onClick={() => setContractModal(true)}
                      >
                        {t('contrato')}
                      </Button>
                    </Col>

                    {/* {(Object.keys(props.profiles.profiles).length === 0 ||
                    'admi' in props.profiles.profiles) && (
                    <Col xs={6} md={4} className='d-grid pt-2 pt-md-4'>
                      <Button
                        variant='primary'
                        size='lg'
                        className='py-md-1'
                        onClick={() => setNewCommunityModal(true)}
                      >
                        {t('commCreate')}
                      </Button>
                    </Col>
                  )} */}
                  </Row>
                </Card>
              </Row>
            </Form>
          )}

          {/* <Row>
            <h5 className='text-center text'>{t('userData')}</h5>

            <Col xs={6} md={4} className='d-grid mt-2'>
              <Button
                className='py-md-1 dark'
                onClick={() =>
                  window.confirm('get notifications?') && getNotifications()
                }
              >
                get notifications
              </Button>
            </Col>
          </Row> */}

          <Modal
            show={changeEmailModal}
            onHide={() => {
              setChangeEmailModal(false);
              setNewEmail();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t('changeEmail')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                id='emailChangeForm'
                onSubmit={(e) => window.confirm(t('seguro')) && changeEmail(e)}
              >
                <Row className='px-0'>
                  <Col xs={12}>
                    <strong>{t('newEmail')}</strong>
                    <Form.Control
                      required
                      type='email'
                      value={newEmail ? newEmail : ''}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className='text-center mt-3'>
                  <Col>
                    <Button
                      // disabled={!user.permissionsonuser.edit_data}
                      type='submit'
                      form='emailChangeForm'
                      // variant='primary'
                      size='lg'
                      className='py-md-1 dark'
                    >
                      {t('change').toUpperCase()}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
          <ModalGenerico
            title={t('cambiarPass')}
            onClose={() => {
              setChangepasswordModal(false);
            }}
            show={changepasswordModal}
          >
            <ChangePassword closeModal={() => setChangepasswordModal(false)} />
          </ModalGenerico>
          <ModalGenerico
            title={t('feeInfo')}
            onClose={() => {
              setFeeInfoModal(false);
            }}
            show={feeInfoModal}
          >
            <FeeInfo closeModal={() => setFeeInfoModal(false)} />
          </ModalGenerico>
          <ModalGenerico
            title={t('factura')}
            onClose={() => {
              setInvoicingModal(false);
            }}
            show={invoicingModal}
          >
            <Invocing
              editInvoice={user.permissionsonuser.edit_invoicedata}
              delInvoice={user.permissionsonuser.del_invoicedata}
              closeModal={() => setInvoicingModal(false)}
            />
          </ModalGenerico>
          <ModalGenerico
            title={t('bank')}
            onClose={() => {
              setBankModal(false);
            }}
            show={bankModal}
            getNotifications
          >
            <Bank
              iduser={userData.iduser}
              email={userData.email}
              editBank={user.permissionsonuser.edit_bank}
              closeModal={() => setBankModal(false)}
            />
          </ModalGenerico>
          <ModalGenerico
            title={t('contrato')}
            onClose={() => {
              setContractModal(false);
            }}
            show={contractModal}
          >
            <Contract closeModal={() => setContractModal(false)} />
          </ModalGenerico>
          {/* <ModalGenerico
            title={t('commCreate')}
            onClose={() => {
              setNewCommunityModal(false);
            }}
            show={newCommunityModal}
          >
            <NewCommunity closeModal={() => setNewCommunityModal(false)} />
          </ModalGenerico> */}
        </Container>
      )}
    </>
  );
};

export default MyProfile;
