import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Calibration = (props) => {
  const { t } = useTranslation();
  const [calib, setCalib] = useState({factor: props.factor === '' ? null : props.factor,
                                    correction: props.correction === '' ? null : props.correction });

  return (
    <>
        <Row className='px-0'>
            <Col xs={12} md={6}>
                <strong>{t('factor')}</strong>
                <Form.Control
                    required={true}
                    type='number'
                    value={calib.factor}
                    onChange={(e) => setCalib({ factor: e.target.value === '' ? null : e.target.value, correction: calib.correction })}
                />
            </Col>
            <Col xs={12} md={6}>
                <strong>{t('correction')}</strong>
                <Form.Control
                    required={true}
                    type='number'
                    value={calib.correction}
                    onChange={(e) => setCalib({ factor: calib.factor, correction: e.target.value === '' ? null : e.target.value })}
                />
            </Col>        
        </Row>
            
        <Col className='mt-3'>
            <Button
                className='btn-sm dark'
                onClick={() => {
                    console.log(calib.factor);
                    console.log(calib.correction);
                if ((calib.factor === null && calib.correction === null) || (calib.factor !== null && calib.correction !== null)) {
                    props.setCalibrationModal(false);
                    props.setCalibration({ factor: calib.factor, correction: calib.correction, index: props.index, sensor: props.sensor });                
                    props.setEdited && props.setEdited(true);
                } else {
                    alert(t('allOrAny'))
                }
                }}
            >
                {t('aceptar')}
            </Button>
        </Col>
        
        <Row className='px-0'>
            <Col className='mt-3'>
                {t('calibExplanation')}<br></br>
                <strong>{t('calibFormula')}</strong> 
            </Col>
        </Row>
    </>
  );
};

export default Calibration;