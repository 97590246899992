import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalGenerico from '../../home/Modal_generico';
import MapSrt from './MapSrt';
import errorHandler from '../../../support_func/errorHandler';
import unavailable from '../../../support_func/unavailable';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Timer from '../../../support_func/Timer';

const OrdersModal = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [initOrders, setInitOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [runAsync, setRunAsync] = useState(false);

  const idri = useSelector((state) => state.idri);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging && 'rgb(211, 211, 211)',
    boxShadow: isDragging && '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)',
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (result.destination && result.source) {
      const newItems = Array.from(orders);
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      setOrders(newItems);
    }
  };

  let counter = null;
  const checkAsync = (id) => {
    if (counter === 0) {
      getAsync(id);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id);
      }, 2000);
    }
  };
  const getAsync = (id) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          if (res.data.found === true && res.data.response.result === true) {
            counter = null;
            setInitOrders(res.data.response.payload.list);
            setOrders(res.data.response.payload.list);
            setIsPending(false);
          } else if (res.data.found === true && res.data.response.result === false) {
            setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              setError(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              setError(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id);
          } else if (res.data.found === false) {
            setIsPending(false);
            setError(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getOrders = () => {
    setIsPending(true);
    setMsg(null);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/sleeporders?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then(async (res) => {
        // console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          counter = 0;
          setRunAsync(true);
          checkAsync(res.data.asyncid);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const deleteOrders = (e, delOrder) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      orders: delOrder,
    };
    console.log(body);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/sleeporders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          counter = 0;
          setRunAsync(true);
          checkAsync(res.data.asyncid);
          setMsg(t('enviadoExito'));
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const patchOrders = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      orders: orders,
    };
    console.log(body);
    axios
      .patch(`${process.env.REACT_APP_API_SRT}/v2/sleeporders`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          counter = 0;
          setRunAsync(true);
          checkAsync(res.data.asyncid);
          // getOrders();
          setMsg(t('enviadoExito'));
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //auto hide error message
  (error || msg) &&
    setTimeout(() => {
      setError(null);
      setMsg(null);
    }, 10000);

  return (
    <div>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      ) : (
        <>
          {orders && (
            <Form onSubmit={(e) => patchOrders(e)}>
              {orders.length > 0 ? (
                <>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                          {orders.map((order, index) => (
                            <Draggable key={index} draggableId={String(index)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className='strip'
                                >
                                  <Row className='flex-nowrap m-0 m-1 gx-0'>
                                    <Col xs={1} className='text-center border-end'>
                                      {index + 1}
                                    </Col>
                                    <Col xs={1} className='text-center border-end'>
                                      <Form.Check
                                        className='noBoxShadow'
                                        type='checkbox'
                                        checked={selectedOrders.includes(order)}
                                        onChange={(e) =>
                                          setSelectedOrders((prevState) =>
                                            prevState.includes(order)
                                              ? prevState.filter((item) => item !== order)
                                              : [...prevState, order]
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col xs={9} className='text-center text-truncate'>
                                      {order.split('/')[4].split('.')[0]}
                                    </Col>
                                    <Col xs={1} className='text-center border-start ps-1 d-grid'>
                                      <Button
                                        variant='outline-danger'
                                        className=' px-1'
                                        size='sm'
                                        onClick={(e) => {
                                          window.confirm(t('seguro')) && deleteOrders(e, [order]);
                                        }}
                                      >
                                        X
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Col className='mt-3'>
                    <Button
                      type='submit'
                      className='dark text-uppercase'
                      disabled={initOrders === orders}
                    >
                      {t('changeOrders')}
                    </Button>
                  </Col>
                  <Col className='mt-2'>
                    {selectedOrders.length > 0 && (
                      <Button
                        variant='outline-danger'
                        className='text-uppercase'
                        onClick={(e) => {
                          window.confirm(t('seguro')) && deleteOrders(e, selectedOrders);
                        }}
                      >
                        {t('deleteSelectedOrders')}
                      </Button>
                    )}
                  </Col>
                </>
              ) : (
                <Col className='text-danger text-center'>{t('noOrders')}</Col>
              )}
              <Row className='text-center mt-3'>
                <Col xs={12}>
                  {error ? (
                    <Col className='text-center alert alert-danger'>{error}</Col>
                  ) : (
                    msg && (
                      <Col className='text-center alert alert-success bg-transparent border-white'>
                        {msg}
                      </Col>
                    )
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default OrdersModal;
