import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ModalGenerico from './Modal_generico';
import ResetPassword from './Modals/ResetPassword';
import errorHandler from '../../support_func/errorHandler';
import unavailable from '../../support_func/unavailable';

const Login = () => {
  const { t, i18n } = useTranslation();

  const initialState = { email: '', password: '' };
  const [inputState, setInputState] = useState(initialState);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [resetPass, setResetPass] = useState(false);

  const go_to = useNavigate();

  //sending user data for login
  const Submit = (e) => {
    setIsPending(true);
    e.preventDefault();
    if (
      (inputState.email && inputState.email === '') ||
      (inputState.password && inputState.password === '')
    ) {
      setIsPending(false);
      setError(t('camposVacios'));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/v2/login`, inputState)
        .then((res) => {
          console.log(res.data);
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            console.log(res.data);
            setIsPending(false);
            setInputState(initialState);
            localStorage.setItem('autoAcceso', res.data.token);
            localStorage.setItem('id', res.data.iduser);
            localStorage.setItem('email', res.data.email);
            if (!['es', 'en', 'fr'].includes(res.data.language)) {
              localStorage.setItem('language', 'en');
            } else {
              localStorage.setItem('language', res.data.language);
            }
            i18n.changeLanguage(localStorage.getItem('language'));
            go_to('/inicio');
          } else {
            setIsPending(false);
            setError(t('problem'));
          }
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError, true);
        });
    }
  };

  return (
    <section className='bg-secondary' id='acceso'>
      <Container>
        {isPending && (
          <div className='working_apis'>
            <div className='text-working-apis'> {t('comprobando')}...</div>
          </div>
        )}
        <Row>
          <Col className='text-center'>
            <h2 className='section-heading text-uppercase'>{t('accedeCuenta')}</h2>
            <h3 className='section-subheading' style={{ color: '#bbb' }}>
              ...{t('controlAgua')}...
            </h3>
          </Col>
        </Row>

        <Form onSubmit={(e) => Submit(e)}>
          <Row>
            <Col md={6} className='mb-4'>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label className='text-white'>Email</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setInputState({ ...inputState, email: e.target.value })}
                  name='email'
                  type='email'
                  autoComplete='email'
                  placeholder={t('introduceEmail')}
                />
                <Form.Text style={{ color: '#bbb' }}>{t('emailNoPublico')}</Form.Text>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId='formBasicPassword'>
                <Form.Label className='text-white'>{t('password')}</Form.Label>
                <Form.Control
                  required
                  minLength={8}
                  onChange={(e) => setInputState({ ...inputState, password: e.target.value })}
                  name='password'
                  type='password'
                  autoComplete='off'
                  placeholder={t('introducePassword')}
                />
              </Form.Group>
            </Col>

            <Col className='text-center mt-4'>
              <Button type='submit' className='btn dark btn-xl text-uppercase'>
                {t('accede')}
              </Button>
            </Col>
          </Row>
        </Form>
        {isPending && (
          <div className='working_apis'>
            <div className='text-working-apis'>{t('comprobando')}...</div>
          </div>
        )}
        {error && <Col className='text-center alert alert-warning mt-3'> {error}</Col>}
        <Col className='text-center' id='reset_password'>
          <Button variant='link' className='text-white' onClick={() => setResetPass(true)}>
            {t('noPassword')}...
          </Button>
          <ModalGenerico
            title={t('newPassword')}
            onClose={() => setResetPass(false)}
            show={resetPass}
          >
            <ResetPassword closeModal={() => setResetPass(false)} />
          </ModalGenerico>
        </Col>
      </Container>
    </section>
  );
};

export default Login;
