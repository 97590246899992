/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import Timer from '../../../../support_func/Timer';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import unavailable from '../../../../support_func/unavailable';
import errorHandler from '../../../../support_func/errorHandler';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ASYNC_CONTROL, ASYNC_COUNTER } from '../../../../redux/constants/idriConstants';

const ProgramsControl = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [runAsync, setRunAsync] = useState(false);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState({});
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [datesActivate, setDatesActivate] = useState({});
  const [endDate, setEndDate] = useState();
  const [devicesList, setDevicesList] = useState([]);
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user);
  const asyncControl = useSelector((state) => state.asyncControl);
  const asyncCounter = useSelector((state) => state.asyncCounter);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('autoAcceso')}`,
    },
  };

  const asyncCheck = (asyncid) => {
    dispatch((dispatch, getState) => {
      const { asyncCounter } = getState();
      dispatch({
        type: ASYNC_COUNTER,
        payload: {
          ...asyncCounter,
          asyncCounter: asyncCounter.asyncCounter + 1,
        },
      });
    });

    if (asyncid !== 'error') {
      dispatch((dispatch, getState) => {
        const { asyncControl } = getState();
        const updatedAsyncControl = Object.fromEntries(
          Object.entries(asyncControl).map(([device, data]) => {
            if (data.asyncid === asyncid) {
              return [device, { ...data, loading: false }];
            }
            return [device, data];
          })
        );
        dispatch({
          type: ASYNC_CONTROL,
          payload: updatedAsyncControl,
        });
      });
    }
    Object.keys(userStore.profiles).forEach(async (p) => {
      if (p === 'gamo' || p === 'delg' || p === 'admi') {
        getDevices(p);
      }
    });
  };

  const Input = React.forwardRef(({ id, disabled, value, onChange, onClick }, ref) => (
    <InputGroup className='noBoxShadow'>
      <Form.Control
        disabled={disabled}
        className='text-center'
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
      {value && (
        <Button
          variant='outline-secondary'
          className='py-0 px-1 p-md-2'
          onClick={() => {
            if (id) {
              setDatesActivate({ ...datesActivate, [id]: null });
            } else {
              setEndDate(null);
            }
          }}
        >
          <FaTimes />
        </Button>
      )}
    </InputGroup>
  ));

  const deviceRow = (device) => {
    return (
      <tr key={device.ghatorid} className='text-center'>
        <td className=' text-center align-middle p-1'>
          <Form.Check
            type='checkbox'
            disabled={
              device.all_programs_disabled === null ||
              (asyncControl[device.ghatorid] && asyncControl[device.ghatorid].loading)
            }
            checked={checkedDevices.includes(device.ghatorid)}
            onChange={(e) => {
              setCheckedDevices((prevState) =>
                prevState.includes(device.ghatorid)
                  ? prevState.filter((item) => item !== device.ghatorid)
                  : [...prevState, device.ghatorid]
              );
              !e.target.checked && setDatesActivate({ ...datesActivate, [device.ghatorid]: null });
            }}
          />
        </td>
        <td className='text-center align-middle px-0'>
          {asyncControl[device.ghatorid] && asyncControl[device.ghatorid].loading ? (
            <Spinner animation='grow' variant='warning' size='sm' />
          ) : (
            <Form.Check
              inline
              readOnly
              className='m-0'
              disabled={device.all_programs_disabled === null}
              checked={!device.all_programs_disabled}
              type='radio'
              id='stateCheck'
            />
          )}
        </td>

        <td className='text-center align-middle p-0 text-truncate trun'>
          {device.ghatorname + ' #' + device.ghatorserial}
        </td>
        <td className='text-center align-middle p-0'>
          {device.renable_all_programs_date
            ? moment.utc(device.renable_all_programs_date).local().format('DD-MM-YYYY HH:mm:ss')
            : ''}
        </td>
        <td className='text-center align-middle p-0'>
          <DatePicker
            required
            id={device.ghatorid}
            disabled={device.all_programs_disabled || !checkedDevices.includes(device.ghatorid)}
            locale={localStorage.getItem('language')}
            calendarStartDay={1}
            customInput={<Input />}
            dateFormat='dd/MM/yyyy H:mm'
            selected={
              !device.all_programs_disabled && checkedDevices.includes(device.ghatorid) && endDate
                ? endDate
                : datesActivate[device.ghatorid]
            }
            onChange={(date) => setDatesActivate({ ...datesActivate, [device.ghatorid]: date })}
            showTimeSelect
            timeFormat='H:mm'
            timeIntervals={10}
            minDate={moment().toDate()}
            minTime={
              datesActivate[device.ghatorid] &&
              !moment().isSame(moment(datesActivate[device.ghatorid]), 'day')
                ? moment().startOf('day')
                : moment().add(10, 'm').toDate()
            }
            maxTime={moment().endOf('day').toDate()}
            timeCaption={t('time')}
            withPortal
            onCalendarClose={() =>
              datesActivate[device.ghatorid] < moment().add(10, 'm').toDate() &&
              setDatesActivate({
                ...datesActivate,
                [device.ghatorid]: moment().add(10, 'm').toDate(),
              })
            }
          />
        </td>
      </tr>
    );
  };

  const getDevices = (profile) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/${profile === 'admi' ? 'ghators' : 'userghators'}?${
          profile === 'admi'
            ? 'profile=' + profile
            : 'iduser=' + userStore.usuarioid + '&email=' + userStore.email + '&profile=' + profile
        }`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setDevices((prevDevices) => ({ ...prevDevices, [profile]: res.data.ghators }));
          setEndDate(null);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getAsync = (id) => {
    let counter = 1;
    const async = (id) => {
      axios
        .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('autoAcceso')}` },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === '0') {
            if (response.data.found === true && response.data.response.result === true) {
              counter = null;
              asyncCheck(response.data.asyncid);
              // console.log('SUCCESS');
            } else if (response.data.found === true && response.data.response.result === false) {
              counter = null;
              if (response.data.response.nicolecode_id === 5) {
                asyncCheck(response.data.asyncid);
              } else {
                counter = null;
                asyncCheck(response.data.asyncid);
              }
            } else if (response.data.found === false && counter && counter < 6) {
              counter++;
              checkAsync(id);
            } else if (response.data.found === false) {
              asyncCheck(response.data.asyncid);
            }
          } else {
            counter = null;
            asyncCheck(response.data.asyncid);
          }
        })
        .catch(async (error) => {
          console.log(error.response);
          asyncCheck('error');
        });
    };
    const checkAsync = (id) => {
      if (counter !== null) {
        setTimeout(() => {
          async(id);
        }, 10000);
      }
    };

    if (typeof id === 'string') {
      checkAsync(id);
    }
    return;
  };

  const allProgram = (e) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    dispatch((dispatch, getState) => {
      const { asyncCounter } = getState();
      dispatch({
        type: ASYNC_COUNTER,
        payload: {
          ...asyncCounter,
          asyncQty: checkedDevices.filter((value) => Number.isFinite(value)).length,
        },
      });
    });

    for (let i = 0; i < devicesList.length; i++) {
      if (checkedDevices.includes(devicesList[i].ghatorid)) {
        let body = {
          ghatorid: devicesList[i].ghatorid,
          ghatorserial: devicesList[i].ghatorserial,
          enable: devicesList[i].all_programs_disabled,
          renable_date: endDate
            ? moment.utc(endDate).local().format('DD-MM-YYYY HH:mm:ss')
            : datesActivate[devicesList[i].ghatorid]
            ? moment
                .utc(datesActivate[devicesList[i].ghatorid])
                .local()
                .format('DD-MM-YYYY HH:mm:ss')
            : null,
        };
        console.log(body);
        axios
          .post(`${process.env.REACT_APP_API_SRT}/v2/prograblall`, body, config)
          .then((res) => {
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.code === '0') {
              console.log(res.data);

              dispatch((dispatch, getState) => {
                const { asyncControl } = getState();
                dispatch({
                  type: ASYNC_CONTROL,
                  payload: {
                    ...asyncControl,
                    [body.ghatorid]: { asyncid: res.data.asyncid, loading: true },
                  },
                });
              });
              getAsync(res.data.asyncid);
            } else {
              checkedDevices.length <= 1 && setIsPending(false);
              setError(t('problem'));
            }
          })
          .catch((error) => {
            if (checkedDevices.length > 1) {
              dispatch((dispatch, getState) => {
                const { asyncCounter } = getState();
                dispatch({
                  type: ASYNC_COUNTER,
                  payload: {
                    ...asyncCounter,
                    asyncCounter: asyncCounter.asyncCounter ?? 0 + 1,
                  },
                });
              });

              errorHandler(error, false, setError);
            } else {
              errorHandler(error, setIsPending, setError);
            }
          });
      }
      if (i + 1 === devicesList.length) {
        setTimeout(() => {
          setError(t('ordersSent'));
          setCheckedDevices([]);
          setDatesActivate({});
          setCheckedDevices([]);
          setIsPending(false);
          Object.keys(userStore.profiles).forEach(async (p) => {
            if (p === 'gamo' || p === 'delg' || p === 'admi') {
              getDevices(p);
            }
          });
        }, 500);
      }
    }
  };

  const filteredAdminDevices = () => {
    return devices.admi.filter((admiDevice) => {
      const gamoGhatorIds = devices.gamo ? devices.gamo.map((device) => device.ghatorid) : [];
      const delgGhatorIds = devices.delg ? devices.delg.map((device) => device.ghatorid) : [];
      return ![...gamoGhatorIds, ...delgGhatorIds].some((id) => id === admiDevice.ghatorid);
    });
  };

  useEffect(() => {
    Object.keys(userStore.profiles).forEach(async (p) => {
      if (p === 'gamo' || p === 'delg' || p === 'admi') {
        getDevices(p);
      }
    });
  }, []);

  useEffect(() => {
    let list = [];
    if (devices.gamo) {
      list = list.concat(devices.gamo);
    }
    if (devices.delg) {
      list = list.concat(devices.delg);
    }

    if (devices.admi) {
      list = list.concat(devices.admi);
    }

    const uniqueGhatorIds = new Set();
    const filteredDevices = list.filter((device) => {
      const isDuplicate = uniqueGhatorIds.has(device.ghatorid);
      uniqueGhatorIds.add(device.ghatorid);
      return !isDuplicate;
    });

    setDevicesList(filteredDevices);
  }, [devices]);

  useEffect(() => {
    if (asyncCounter.asyncQty !== 0 && asyncCounter.asyncQty === asyncCounter.asyncCounter) {
      dispatch({
        type: ASYNC_CONTROL,
        payload: {},
      });
      dispatch({
        type: ASYNC_COUNTER,
        payload: { asyncQty: 0, asyncCounter: 0 },
      });
      Object.keys(userStore.profiles).forEach(async (p) => {
        if (p === 'gamo' || p === 'delg' || p === 'admi') {
          getDevices(p);
        }
      });
    }
  }, [asyncCounter]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <Container className='text'>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      )}
      <Accordion
        defaultActiveKey={'0'}
        alwaysOpen
        className='acc'
        // onSelect={(e) => {
        //   localStorage.setItem('settings', JSON.stringify(e));
        // }}
      >
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <Table bordered className='mb-0'>
              <thead>
                <tr className='dark text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal  my-0 px-4'>{t('programsControl')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='p-0'>
            {Object.keys(devices).length > 0 && (
              <Table bordered responsive striped className='mb-0' id='firmTable'>
                <tbody>
                  {devices.gamo && (
                    <>
                      <tr className='text-center lGray'>
                        <th colSpan={5} className='text-center align-middle p-0'>
                          {t('owner')}
                        </th>
                      </tr>
                      <tr className='text-center'>
                        <th className='text-center align-middle p-0'></th>
                        <th className='text-center align-middle p-0'>{t('state')}</th>
                        <th className='text-center align-middle p-0 '>{t('device')}</th>
                        <th className='text-center align-middle p-0'>{t('activateDate')}</th>
                        <th className='text-center align-middle p-0'>{t('reactivateDate')}</th>
                      </tr>

                      {Object.values(devices.gamo).map((d) => deviceRow(d))}
                    </>
                  )}
                  {devices.delg && (
                    <>
                      <tr className='text-center lGray'>
                        <th colSpan={5} className='text-center align-middle p-0'>
                          {t('delegatedDevices')}
                        </th>
                      </tr>
                      <tr className='text-center'>
                        <th className='text-center align-middle p-0'></th>
                        <th className='text-center align-middle p-0'>{t('state')}</th>
                        <th className='text-center align-middle p-0 '>{t('device')}</th>
                        <th className='text-center align-middle p-0'>{t('activateDate')}</th>
                        <th className='text-center align-middle p-0'>{t('reactivateDate')}</th>
                      </tr>
                      {Object.values(devices.delg).map((d) => deviceRow(d))}
                    </>
                  )}
                  {devices.admi && filteredAdminDevices().length > 0 && (
                    <>
                      <tr className='text-center lGray'>
                        <th colSpan={5} className='text-center align-middle p-0'>
                          {t('admin')}
                        </th>
                      </tr>
                      <tr className='text-center'>
                        <th className='text-center align-middle p-0'></th>
                        <th className='text-center align-middle p-0'>{t('state')}</th>
                        <th className='text-center align-middle p-0 '>{t('device')}</th>
                        <th className='text-center align-middle p-0'>{t('activateDate')}</th>
                        <th className='text-center align-middle p-0'>{t('reactivateDate')}</th>
                      </tr>
                      {filteredAdminDevices().map((d) => deviceRow(d))}
                    </>
                  )}
                  <tr>
                    <td colSpan={6} className='p-0'>
                      <hr className='m-0 p-0 hr-dark' />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className='text-center lGray'>
                    <th colSpan={5} className='text-center align-middle p-0'>
                      {t('groupControl')}
                    </th>
                  </tr>
                  <tr>
                    <td className='text-center align-middle p-0'>
                      <div className='d-grid'>
                        <Form.Check
                          type='checkbox'
                          checked={
                            devicesList.length > 0 &&
                            !checkedDevices.every((value) => value === undefined) &&
                            devicesList
                              .map((d) => {
                                if (
                                  asyncControl[d.ghatorid]
                                    ? !asyncControl[d.ghatorid].loading
                                    : d.all_programs_disabled !== null
                                ) {
                                  return d.ghatorid;
                                }
                              })
                              .every((v) => {
                                return checkedDevices.indexOf(v) >= 0;
                              })
                          }
                          onChange={(e) =>
                            setCheckedDevices(
                              devicesList.length === checkedDevices.length
                                ? []
                                : devicesList.map((d) => {
                                    if (
                                      asyncControl[d.ghatorid]
                                        ? !asyncControl[d.ghatorid].loading
                                        : d.all_programs_disabled !== null
                                    ) {
                                      return d.ghatorid;
                                    }
                                  })
                            )
                          }
                        />
                      </div>
                    </td>
                    <td colSpan={3} className='d-none d-md-table-cell'></td>
                    <td
                      colSpan={2}
                      className='text-center align-middle p-0 d-table-cell d-md-none fw-bold'
                    >
                      {t('reactivateDate')}
                    </td>
                    <td colSpan={2} className='text-center align-middle p-0'>
                      <DatePicker
                        required
                        locale={localStorage.getItem('language')}
                        calendarStartDay={1}
                        customInput={<Input />}
                        dateFormat='dd/MM/yyyy H:mm'
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        showTimeSelect
                        timeFormat='H:mm'
                        timeIntervals={10}
                        minDate={moment().toDate()}
                        minTime={
                          endDate && !moment().isSame(moment(endDate), 'day')
                            ? moment().startOf('day')
                            : moment().add(10, 'm').toDate()
                        }
                        maxTime={moment().endOf('day').toDate()}
                        timeCaption={t('time')}
                        withPortal
                        onCalendarClose={() =>
                          endDate < moment().add(10, 'm').toDate() &&
                          setEndDate(moment().add(10, 'm').toDate())
                        }
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            )}

            <Row className='text-center my-3'>
              <Col xs={12}>
                <Button
                  disabled={checkedDevices.filter((value) => Number.isFinite(value)).length === 0}
                  variant='primary'
                  className='py-md-1'
                  onClick={(e) => window.confirm(t('seguro')) && allProgram()}
                >
                  {t('updateProgramsState')}
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default ProgramsControl;
