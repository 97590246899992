import { faInfoCircle, faQuestionCircle, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';

const Bienvenida = () => {
  return (
    <div className='text-center text'>
      <br></br>
      <div>
        <img src={'images/logos/logo_redhator_192x192.png'} alt='home' width='100px' />
      </div>
      <h4>¡Bienvenido!</h4>
      {/* <br></br>
      <div>
        Para resolver cualquier duda sobre el funcionamiento y uso de{' '}
        <strong>RedHator</strong> o sobre las características de los sistemas de
        agricultura de precisión, puedes visitar nuestra base de conocimiento '
        <strong>falsonet</strong>'<br></br>
      </div>
      <div className='text-center'>
        <a href='https://falsonet.com/' target={'_blank'} rel='noopener noreferrer'>
          <Image
            src={'images/logo_falsonet_sisritel_negro.png'}
            className='img-fluid d-block mx-auto'
            alt='falsonet'
            width='100'
          />
        </a>
      </div>
      <div
        className='text-center'
      >
        <a href='https://falsonet.com/' target='_blank' rel='noreferrer'>
          pulsa para ir a falsonet.com
        </a>
      </div>
      <br></br>
      <div>
        En las distintas pantallas de la app de Sisritel, además, encontrarás iconos
        de información <FontAwesomeIcon icon={faInfoCircle} />. Al pulsar sobre ellos
        accederás a distintos tipos de ayuda y explicaciones sobre el uso y
        funcionalidad de la aplicación, dependiendo de la pantalla en donde te
        encuentres.
      </div>
      <div>
        Para resolver cualquier indicencia en alguna de tus instalaciones, por favor
        contacta con el instalador que realizó el servicio de instalación:
        encontrarás sus datos de contacto en la pantalla de gestión de la instalación
        en particular, pulsando sobre el icono{' '}
        <FontAwesomeIcon icon={faQuestionCircle} /> que se encuentra bajo la
        cabecera.
      </div>
      <br></br>
      <div>
        <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}{' '}
        <strong>micro2engineering sl</strong>
      </div> */}
    </div>
  );
};

export default Bienvenida;
