import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll/modules';

const AvisoLegal = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className='modal-body '>
      <p className='item-intro text-muted text-center fst-italic'>{t('avisoLegal2')}</p>
      <p>{t('avisoLegal3')}</p>
      <p>{t('avisoLegal4')}</p>
      <p>
        <b>{t('avisoLegal5')}</b>
      </p>
      <p>
        <b>{t('avisoLegal6')}</b> {t('avisoLegal7')}
      </p>
      <p>
        <b>e-Mail</b>:{' '}
        <a
          href='/cdn-cgi/l/email-protection'
          className='__cf_email__'
          data-cfemail='5f363139301f32363c2d306d3a713c3032'
        >
          [email&#160;protected]
        </a>
      </p>
      <p>
        <b>{t('avisoLegal8')}</b> {t('avisoLegal9')}
      </p>

      <p>{t('avisoLegal10')}</p>

      <p>{t('avisoLegal11')}</p>

      <p>{t('avisoLegal12')}</p>

      <p>{t('avisoLegal13')}</p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal14')}</b>{' '}
      </p>
      <p>{t('avisoLegal15')}</p>
      <p>{t('avisoLegal16')}</p>
      <p>
        {t('avisoLegal17')}{' '}
        <Link
          className='contacto_section_link_close_modal'
          to='contact'
          smooth='easeInQuad'
          offset={-70}
          duration={700}
          onClick={() => closeModal()}
          style={{ color: '#007b' }}
        >
          {t('avisoLegal18')}
        </Link>{' '}
        {t('avisoLegal19')}
      </p>
      <p>{t('avisoLegal20')}</p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal21')}</b>{' '}
      </p>
      <p>{t('avisoLegal22')}</p>
      <p>
        {t('avisoLegal23')}{' '}
        <a
          href='https://fontawesome.com/'
          target='_blank'
          style={{ color: '#007b' }}
          rel='noreferrer'
        >
          fontawesome
        </a>{' '}
        {t('avisoLegal24')}{' '}
        <a
          href='https://creativecommons.org/licenses/by/4.0/'
          target='_blank'
          style={{ color: '#007b' }}
          rel='noreferrer'
        >
          "Creative Commons Attribution 4.0 International"
        </a>
      </p>
      <p>{t('avisoLegal25')}</p>
      <p>{t('avisoLegal26')}</p>
      <p>{t('avisoLegal27')}</p>
      <p>{t('avisoLegal28')}</p>
      <p>
        {t('avisoLegal29')}{' '}
        <Link
          className='contacto_section_link_close_modal'
          to='contact'
          smooth='easeInQuad'
          offset={-70}
          duration={700}
          onClick={() => closeModal()}
          style={{ color: '#007b' }}
        >
          {t('avisoLegal30')}
        </Link>
        .
      </p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal31')}</b>{' '}
      </p>
      <p>{t('avisoLegal32')}</p>
      <p>{t('avisoLegal33')}</p>
      <p>{t('avisoLegal34')}</p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal35')}</b>{' '}
      </p>
      <p>{t('avisoLegal36')}</p>
      <p>{t('avisoLegal37')}</p>
      <p>{t('avisoLegal38')}</p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal39')}</b>{' '}
      </p>
      <p>{t('avisoLegal40')}</p>
      <p>
        {t('avisoLegal41')}{' '}
        <Link
          className='contacto_section_link_close_modal'
          to='contact'
          smooth='easeInQuad'
          offset={-70}
          duration={700}
          onClick={() => closeModal()}
          style={{ color: '#007b' }}
        >
          {t('avisoLegal42')}
        </Link>{' '}
        {t('avisoLegal43')}
      </p>
      <p>{t('avisoLegal44')}</p>

      <p style={{ color: '#007b' }}>
        <b>{t('avisoLegal45')}</b>{' '}
      </p>
      <p>{t('avisoLegal46')}</p>
      <p>{t('avisoLegal47')}</p>
    </div>
  );
};

export default AvisoLegal;
