const selectStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (base) => ({ ...base, marginTop: 0, zIndex: 9999 }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? 'rgba(0, 123, 255, 0.3)' : isSelected && 'rgba(0, 0, 0, 0)',
      color: isSelected && 'rgba(0, 0, 0)',
    };
  },
};

export default selectStyles;
