/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Accordion, Button, Card, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiSelectMultiple } from 'react-icons/bi';
// import { BsPauseCircle, BsPlayCircle } from 'react-icons/bs';
import { FaCommentsDollar, FaTrashAlt } from 'react-icons/fa';
import { IoMdSwap } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { IDRI } from '../../../../redux/constants/idriConstants';
import Timer from '../../../../support_func/Timer';
import ProgramConfig from './ProgramConfig';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import scrollToElement from '../../../../support_func/scrollToElement';

const IdriPrograms = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useDispatch();
  const idri = useSelector((state) => state.idri);
  const [runAsync, setRunAsync] = useState(false);
  const [block, setBlock] = useState(false);
  const [editing, setEditing] = useState(null);
  const [sendProg, setSendProg] = useState({});
  const [editProg, setEditProg] = useState({});
  const [nProg, setNProg] = useState(null);
  const [checkProgs, setCheckProgs] = useState([]);

  let counter = null;
  const checkAsync = (id, progList, del, modify) => {
    if (counter === 0) {
      getAsync(id, progList, del, modify);
    } else if (counter !== null) {
      setTimeout(() => {
        getAsync(id, progList, del, modify);
      }, 2000);
    }
  };
  //get async response from device
  const getAsync = (id, progList, del, modify) => {
    setError(null);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/async?asyncid=${id}`, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);

          if (res.data.found === true && res.data.response.result === true) {
            localStorage.removeItem(`newprog-${idri.ghatorserial}`);
            getIdri(null, progList, del, modify);
            counter = null;
          } else if (res.data.found === true && res.data.response.result === false) {
            setIsPending(false);
            if (res.data.response.nicolecode_id === 5) {
              getIdri(
                t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  ? t(`asyncError5-${res.data.response.ghatorcode_id}`)
                  : t('errorUnknown')
              );
            } else {
              getIdri(
                t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
              );
            }
          } else if (res.data.found === false && counter < 50) {
            counter++;
            checkAsync(id, progList, del, modify);
          } else if (res.data.found === false) {
            getIdri(
              res.data.response && res.data.response.nicolecode_id
                ? t(`asyncError${res.data.response.nicolecode_id}`)
                  ? t(`asyncError${res.data.response.nicolecode_id}`)
                  : t('errorUnknown')
                : t('noConnection')
            );
            // setError(t('noConnection'));
            counter = null;
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getIdri = (errorAsync, progList, del, modify) => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/ghator?ghatorid=${idri.ghatorid}&ghatorserial=${idri.ghatorserial}`,
        config
      )
      .then((res) => {
        // console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          errorAsync && setError(errorAsync);
          del === 'del'
            ? progList &&
              setError(t('programa') + ' #' + progList + ' ' + t('deleted') + ' ' + t('success'))
            : progList &&
              setError(
                t('programa') +
                  ' #' +
                  progList +
                  ` ${modify ? t('modificated') : t('installed')} ${t('success')}`
              );
          dispatch({
            type: IDRI,
            payload: res.data.ghator,
          });
          // setIdri(res.data.idri);
          setNProg(null);
          setCheckProgs([]);
          setIsPending(false);
          modify
            ? scrollToElement(`id_${progList}`, true, 'start', 300)
            : scrollToElement('control', true, 'start', 300);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const checkParams = () => {
    let actList = [];
    let send = sendProg.actuators.map((a) => {
      return a.idactuator;
    });

    idri.actuators.map((idriAct) => {
      sendProg.actuators.map((a) => {
        if (send.includes(idriAct.idactuator) && idriAct.idactuator === a.idactuator) {
          actList.push({
            idactuator: a.idactuator,
            action: a.action ? 1 : 0,
            afmv: a.afmv,
            afv: a.afv,
          });
        }
      });
    });

    return actList;
  };

  // console.log(sendProg);
  const addProgram = (e, newProg) => {
    e && e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {};
    newProg && localStorage.setItem(`newprog-${idri.ghatorserial}`, JSON.stringify(sendProg));

    parseFloat(idri.ghatorv) < 3
      ? (body = {
          ghatorid: idri.ghatorid,
          ghatorserial: idri.ghatorserial,
          idprogram: sendProg.idprogram ? sendProg.idprogram : '',
          starttime: sendProg.starttime ? sendProg.starttime : null,
          endtime: sendProg.endtime ? sendProg.endtime : null,
          daysweek:
            sendProg.daysweek && typeof sendProg.daysweek === 'string'
              ? sendProg.daysweek
              : toBinary(sendProg.daysweek),
          actuators: sendProg.actuators
            ? sendProg.actuators.map((a) => ({
                action: a.action ? 1 : 0,
                idactuator: a.idactuator,
                // is_master:a.is_master,
                // name: a.name,
                // normal_state: a.normal_state
              }))
            : null,
        })
      : (body = {
          ghatorid: idri.ghatorid,
          ghatorserial: idri.ghatorserial,
          idprogram: sendProg.idprogram ? sendProg.idprogram : '',
          starttime: sendProg.starttime ? sendProg.starttime : '',
          // endtime: '',
          duration:
            parseInt(sendProg.h) > 0 || parseInt(sendProg.m) > 0 || parseInt(sendProg.s) > 0
              ? (parseInt(sendProg.h) > 0 ? parseInt(sendProg.h) * 60 * 60 : 0) +
                (parseInt(sendProg.m) > 0 ? parseInt(sendProg.m) * 60 : 0) +
                (parseInt(sendProg.s) > 0 ? parseInt(sendProg.s) : 0)
              : '',
          daysweek:
            sendProg.daysweek && typeof sendProg.daysweek === 'string'
              ? sendProg.daysweek
              : toBinary(sendProg.daysweek),
          actuators: sendProg.actuators ? checkParams() : null,
        });
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/setprogram`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setEditProg(false);
          setBlock(false);

          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setNProg(res.data.nprogram);
            setTimeout(() => {
              !newProg
                ? checkAsync(res.data.asyncid, res.data.nprogram, null, 'mod')
                : checkAsync(res.data.asyncid, res.data.nprogram, null, null);
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const enableProgram = (e, nPr) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    // console.log(nPr);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      programs: nPr
        ? Object.values(idri.programs)
            .map((prog) => {
              if (nPr === prog.nprograma) {
                console.log(prog.idprogram);
                return [{ idprogram: prog.idprogram, enable: !prog.enable }];
              }
            })
            .find((value) => {
              return value;
            })
        : checkProgs.map((p) => ({
            idprogram: Object.values(idri.programs)
              .map((prog) => {
                if (nPr && nPr === prog.nprograma) {
                  return prog.idprogram;
                } else if (prog.nprograma === p) {
                  return prog.idprogram;
                }
              })
              .find((value) => {
                return value;
              }),
            enable: !Object.values(idri.programs)
              .map((prog) => {
                if (prog.nprograma === p) {
                  return prog.enable;
                }
              })
              .find((value) => {
                return value;
              }),
          })),
    };
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/prograble`, body, config)
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setNProg(res.data.nprogram);
            setTimeout(() => {
              checkAsync(res.data.asyncid);
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const delProgram = (nPrograma) => {
    // e.preventDefault();
    setIsPending(true);
    let body = {
      ghatorid: idri.ghatorid,
      ghatorserial: idri.ghatorserial,
      programs: typeof nPrograma === 'object' ? nPrograma : [nPrograma],
    };
    console.log(body);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/delprograms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          // console.log(res.data.array_nprogram_delete);
          counter = 0;
          if (counter === 0) {
            setRunAsync(true);
            setNProg(res.data.nprogram);
            setTimeout(() => {
              checkAsync(res.data.asyncid, res.data.array_nprogram_delete, 'del');
            }, 4000);
          }
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const checkId = (programs) => {
    return programs.map((p) =>
      Object.values(idri.programs)
        .map((prog) => {
          if (prog.nprograma === p) {
            return prog.idprogram;
          }
        })
        .find((value) => {
          return value;
        })
    );
  };

  const getItemStyle = (isDragging, draggableStyle, active) => ({
    userSelect: 'none',

    background: isDragging
      ? 'rgb(211, 211, 211)'
      : active
      ? 'rgb(230, 240, 230)'
      : 'rgb(240, 240, 240)',
    boxShadow: isDragging && '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)',

    ...draggableStyle,
  });

  const [prog, setProg] = useState([]);

  const onDragEnd = (result) => {
    // console.log(result);
    // console.log(Array.from(prog));
    const newItems = Array.from(prog);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setProg(newItems);
  };

  const [storedProgs, setStoredProgs] = useState(
    localStorage.getItem(`progs-${idri.ghatorserial}`)
      ? JSON.parse(localStorage.getItem(`progs-${idri.ghatorserial}`))
      : []
  );

  const toBinary = (arr) => {
    // console.log(arr);
    if (arr) {
      let bin = [0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
          bin[arr[i] - 1] = 1;
        }
      }
      // console.log(bin);
      return bin.join('');
    }
  };

  // useEffect(() => {
  //   getIdri();
  // }, []);

  useEffect(() => {
    const getProgs = () => {
      let progsList = [];
      for (let i = 0; i < storedProgs.length; i++) {
        Object.values(idri.programs).map((pr) => {
          if (storedProgs[i] === pr.nprograma) {
            progsList.push(pr);
          }
        });
      }
      setProg(progsList);
      setNProg(null);
    };
    if (storedProgs && storedProgs.length === 0) {
      localStorage.setItem(
        `progs-${idri.ghatorserial}`,
        JSON.stringify(
          idri.programs.map((programa) => {
            return programa.nprograma;
          })
        )
      );
      setStoredProgs(JSON.parse(localStorage.getItem(`progs-${idri.ghatorserial}`)));
      getProgs();
    }
    if (storedProgs.length < Object.keys(idri.programs).length) {
      Object.values(idri.programs).map(
        (p) => !storedProgs.includes(p.nprograma) && storedProgs.unshift(p.nprograma)
      );
      getProgs();
    } else {
      getProgs();
    }
  }, [idri]);
  // console.log(storedProgs);
  useEffect(() => {
    // console.log(prog);
    prog &&
      prog.length > 0 &&
      localStorage.setItem(
        `progs-${idri.ghatorserial}`,
        JSON.stringify(
          prog &&
            prog.map((programa) => {
              return programa.nprograma;
            })
        )
      );
    prog &&
      setStoredProgs(
        prog.map((programa) => {
          return programa.nprograma;
        })
      );
  }, [prog]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  return (
    <>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis timer'>
            <span>{t('connect')}...</span>
            {runAsync && (
              <span className='d-flex justify-content-center align-items-center'>
                {t('tiempoEspera')}: <Timer min={2} sec={0} waiting={true} />
              </span>
            )}
          </div>
        </div>
      ) : (
        <Accordion
          defaultActiveKey={
            localStorage.getItem(`openProgs-${idri.ghatorserial}`)
              ? JSON.parse(localStorage.getItem(`openProgs-${idri.ghatorserial}`))
              : null
          }
          alwaysOpen
          className='acc my-2'
          // className={`my-2 ${!idri.user_permissions.program && 'disabledCustom'}`}
          onSelect={(e) => {
            localStorage.setItem(`openProgs-${idri.ghatorserial}`, JSON.stringify(e));
          }}
        >
          <Accordion.Item id='newProg' eventKey='0'>
            <Accordion.Header
              onClick={(e) => {
                scrollToElement('newProg', true, 'start', 300);
              }}
            >
              <Table bordered className='mb-0' id='alert'>
                <thead>
                  <tr className='primary text-center'>
                    <th colSpan={2} className='lh-sm'>
                      <p className='fw-normal lh-sm my-0'>{t('nuevaProg')}</p>
                    </th>
                  </tr>
                </thead>
              </Table>
            </Accordion.Header>
            <Accordion.Body
              // className='text-center p-0'
              className={`text-center p-0 ${
                (!idri.user_permissions.edit || !idri.user_permissions.program) && 'disabledCustom'
              }`}
            >
              <ProgramConfig
                key='new'
                id='new'
                block={block}
                // sendProg={sendProg}
                setSendProg={setSendProg}
              />
              <Col className={`text-center mb-2 ${block && 'dis'}`}>
                <Button className='dark' onClick={(e) => addProgram(e, true)}>
                  {t('addProg')}
                </Button>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item id='progs' eventKey='1'>
            <Accordion.Header
              onClick={(e) => {
                scrollToElement('progs', true, 'start', 300);
              }}
            >
              <Table bordered className='mb-0' id='alert'>
                <thead>
                  <tr className='primary text-center'>
                    <th colSpan={2} className='lh-sm'>
                      <p className='fw-normal lh-sm my-0'>{t('progsList')}</p>
                    </th>
                  </tr>
                </thead>
              </Table>
            </Accordion.Header>
            <Accordion.Body
              // className='p-0'
              className={`text-center p-0 ${
                (!idri.user_permissions.edit || !idri.user_permissions.program) && 'disabledCustom'
              }`}
            >
              {idri.programs && Object.keys(idri.programs).length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {prog &&
                          Object.entries(prog).map(([index, prog]) => (
                            <Draggable
                              key={prog.nprograma}
                              draggableId={prog.nprograma.toString()}
                              index={parseInt(index)}
                              isDragDisabled={editing == prog.idprogram}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    prog.active
                                  )}
                                  className='strip'
                                >
                                  <ProgramConfig
                                    key={`key_${prog.nprograma}`}
                                    id={`id_${prog.nprograma}`}
                                    prog={prog}
                                    block={block}
                                    setBlock={setBlock}
                                    editing={editing}
                                    setEditing={setEditing}
                                    delProgram={delProgram}
                                    checkProgs={checkProgs}
                                    setCheckProgs={setCheckProgs}
                                    enableProgram={enableProgram}
                                    setSendProg={setSendProg}
                                    editProgram={addProgram}
                                    getIdri={getIdri}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Col className='text-danger text-center'>{t('noProgs')}</Col>
              )}
              {checkProgs.length >= 1 && (
                <Card className='position-fixed top-0 end-0 me-2'>
                  <Card.Body className='p-1'>
                    <Card.Title className=' text-center'>{t('controlGrupo')}</Card.Title>
                    <Button
                      variant='outline-secondary'
                      className='p-0 px-1 mx-3 '
                      onClick={(e) =>
                        setCheckProgs(
                          Object.values(prog).map((p) => {
                            return p.nprograma;
                          }).length === checkProgs.length
                            ? []
                            : Object.values(prog).map((p) => {
                                return p.nprograma;
                              })
                        )
                      }
                    >
                      <BiSelectMultiple type='button' />
                    </Button>
                    <Button
                      variant='outline-primary'
                      className='p-0 px-1 mx-3'
                      onClick={(e) => enableProgram(e)}
                    >
                      <IoMdSwap type='button' />
                    </Button>
                    <Button
                      variant='outline-danger'
                      // size='sm'
                      className='p-0 px-1 mx-3'
                      onClick={(e) =>
                        window.confirm(t('delProgs') + ' #' + checkProgs + '?')
                          ? delProgram(checkId(checkProgs))
                          : null
                      }
                    >
                      <FaTrashAlt type='button' />
                    </Button>
                  </Card.Body>
                </Card>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default IdriPrograms;
