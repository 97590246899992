/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FixRequiredSelect from '../../../../support_func/FixRequiredSelect';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { addDays, addYears } from 'date-fns';
import axios from 'axios';
import textLineAnimation from '../../../../support_func/textLineAnimation';
import errorHandler from '../../../../support_func/errorHandler';
import unavailable from '../../../../support_func/unavailable';
import selectStyles from '../../../../support_func/selectStyles';

const Permissions = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('autoAcceso');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  // const [manDate, setManDate] = useState(moment()._d);
  // const [editDate, setEditDate] = useState(moment()._d);
  // const [forever, setForever] = useState({
  //   group: [false, false],
  //   individual: [false, false],
  //   delegated: [false, false],
  // });
  const [idrisList, setIdrisList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedIdris, setSelectedIdris] = useState([]);
  const [grantedList, setGrantedList] = useState([]);
  const [delegatedList, setDelegatedList] = useState([]);
  const [delegatedData, setDelegatedData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [individualData, setIndividualData] = useState({});
  const [grantedUser, setGrantedUser] = useState({});
  const [delegatedUser, setDelegatedUser] = useState({});

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const components = {
    DropdownIndicator: null,
  };

  const Select = (props) => <FixRequiredSelect {...props} SelectComponent={BaseSelect} />;

  const getAllIdris = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/ghators?profile=admi`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setIdrisList(
            res.data.ghators.map((ghator) => {
              return {
                ghatorid: ghator.ghatorid,
                ghatorserial: ghator.ghatorserial,
                value: ghator.ghatorid,
                label: '#' + ghator.ghatorserial + ' - ' + ghator.ghatorname,
              };
            })
          );
          setIsPending(false);

          // setDefOption(null);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getUsers = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/users`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setUsersList(res.data.users);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getGrantedIdris = (iduser, email) => {
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/grantedidris?iduser=${
          iduser ? iduser : localStorage.getItem('id')
        }&email=${email ? email : localStorage.getItem('email')}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          let gList = [];
          Object.entries(res.data.granted_idris).forEach(([id, idri]) => {
            props.ghatorname
              ? idri.ididri == props.ghatorid &&
                gList.push({
                  ...idri,
                  id: id,
                  value: id,
                  label: '#' + idri.idriserial + ' - ' + idri.idriname + ' -> ' + idri.granted_user,
                })
              : gList.push({
                  ...idri,
                  id: id,
                  value: id,
                  email: idri.delegating_user,
                  label: '#' + idri.idriserial + ' - ' + idri.idriname + ' -> ' + idri.granted_user,
                });
          });
          setGrantedList(gList);

          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getDelegatedIdris = (iduser, email) => {
    // console.log(iduser);
    // console.log(email);
    setIsPending(true);
    setError(null);
    axios
      .get(
        `${process.env.REACT_APP_API_SRT}/v2/delgidris?iduser=${
          iduser ? iduser : localStorage.getItem('id')
        }&email=${email ? email : localStorage.getItem('email')}`,
        config
      )
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          let dList = [];
          Object.entries(res.data.delg_idris).forEach(([id, idri]) => {
            dList.push({
              ...idri,
              id: id,
              iduser: idri.iduser,
              email: idri.granted_user,
              label: '#' + idri.idriserial + ' - ' + idri.idriname + ' -> ' + idri.delegating_user,
            });
          });
          setDelegatedList(dList);
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const postGrantIdris = (e, perm, group, individual, granted, delegated, id, email) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    // console.log(perm);
    // console.log(selectedIdris);
    let body = {
      idris_users:
        !props.ghatorname && group
          ? selectedIdris.map((idri) => {
              return {
                ...perm,
                management_end_date:
                  // group && forever.group[0]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   : individual && forever.individual[0]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   :
                  perm.management_end_date
                    ? moment(perm.management_end_date).format('DD-MM-yyyy')
                    : moment().format('DD-MM-yyyy'),
                edit_end_date:
                  // group && forever.group[1]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   : individual && forever.individual[1]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   :
                  perm.edit_end_date
                    ? moment(perm.edit_end_date).format('DD-MM-yyyy')
                    : moment().format('DD-MM-yyyy'),
                ididri: idri.ghatorid,
                idriserial: idri.ghatorserial,
                granted_users: individual
                  ? [perm.granted_user]
                  : perm.granted_users.map((user) => {
                      return user.value;
                    }),
              };
            })
          : [
              {
                ...perm,
                management_end_date:
                  // group && forever.group[0]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   : individual && forever.individual[0]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   :
                  perm.management_end_date
                    ? moment(perm.management_end_date).format('DD-MM-yyyy')
                    : moment().format('DD-MM-yyyy'),
                edit_end_date:
                  // group && forever.group[1]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   : individual && forever.individual[1]
                  //   ? moment(addYears(moment()._d, 100)).format('DD-MM-yyyy')
                  //   :
                  perm.edit_end_date
                    ? moment(perm.edit_end_date).format('DD-MM-yyyy')
                    : moment().format('DD-MM-yyyy'),
                ididri: perm.ididri ? perm.ididri : props.ghatorid,
                idriserial: perm.idriserial ? perm.idriserial : props.ghatorserial,
                granted_users: individual
                  ? [perm.granted_user]
                  : perm.granted_users.map((user) => {
                      return user.value;
                    }),
              },
            ],
    };
    // delete body.id;
    // delete body.idriname;
    // delete body.label;
    // delete body.delegating_user;
    // body.granted_user && delete body.granted_user;
    console.log(body);
    axios
      .post(`${process.env.REACT_APP_API_SRT}/v2/setgrantidris`, body, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setError(t('modificated') + ' ' + t('success'));
          group && getGrantedIdris();
          !individual && setIndividualData({});
          granted && getGrantedIdris(id, email);
          granted && setDelegatedUser({});
          granted && setDelegatedData({});
          delegated && getDelegatedIdris(id, email);
          delegated && setGrantedUser({});
          delegated && setIndividualData({});
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
        localStorage.getItem('profile') === 'admi'
          ? getAllIdris()
          : !props.ghatorname && getIdrisList();
        localStorage.getItem('profile') === 'admi' ? getUsers() : getGrantedIdris();
        getDelegatedIdris();
      });
  };

  const delPermissions = (e, id, individual) => {
    // e.preventDefault();
    setIsPending(true);
    let body = { idpermissions: [id] };
    console.log(body);
    axios
      .delete(`${process.env.REACT_APP_API_SRT}/v2/delpermissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
      .then((res) => {
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          console.log(res.data);
          setError(t('permDeleted'));
          individual && setIndividualData({});
          individual && getGrantedIdris();
          setDelegatedData({});
          getDelegatedIdris();
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  const getIdrisList = () => {
    setIsPending(true);
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/ghators?profile=gamo`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setIdrisList(
            res.data?.ghators.map((ghator) => {
              return {
                ghatorid: ghator.ghatorid,
                ghatorserial: ghator.ghatorserial,
                value: ghator.ghatorid,
                label: '#' + ghator.ghatorserial + ' - ' + ghator.ghatorname,
              };
            })
          );
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        // setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  };

  useEffect(() => {
    localStorage.getItem('profile') === 'admi'
      ? getAllIdris()
      : !props.ghatorname && getIdrisList();
    localStorage.getItem('profile') === 'admi' ? getUsers() : getGrantedIdris();
    getDelegatedIdris();
  }, []);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(
  //   moment(groupData.management_end_date && groupData.management_end_date)._d
  // );
  useEffect(() => {
    textLineAnimation();
  });
  return (
    <div className='text'>
      {isPending && (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      )}
      <Row xs={12} className='text-center'>
        <h5 className='text-center '>
          {props.ghatorname ? props.ghatorname + ' #' + props.ghatorserial : t('permissions')}
        </h5>
      </Row>
      <Accordion
        alwaysOpen
        className='acc'
        defaultActiveKey={
          localStorage.getItem('openPerm') ? JSON.parse(localStorage.getItem('openPerm')) : null
        }
        onSelect={(e) => {
          localStorage.setItem('openPerm', JSON.stringify(e));
        }}
      >
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <Table bordered className='mb-0' id=''>
              <thead>
                <tr className='primary text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal lh-sm my-0'>{t('groupAssignments')}</p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={(e) => postGrantIdris(e, groupData, true, false)}>
              <Row className='text-center'>
                {!props.ghatorname && (
                  <Col xs={12} md={6} className='text-center px-0 pb-2 pb-md-0 ps-md-0 pe-md-2'>
                    <Select
                      menuShouldScrollIntoView
                      required
                      placeholder={
                        localStorage.getItem('profile') === 'admi'
                          ? t('allDevices')
                          : t('myDevices')
                      }
                      isClearable
                      isMulti
                      isSearchable
                      options={idrisList}
                      value={selectedIdris}
                      styles={selectStyles}
                      onChange={(e) => setSelectedIdris(e)}
                    />
                  </Col>
                )}
                <Col
                  xs={12}
                  md={props.ghatorname ? 12 : 6}
                  className={`${props.ghatorname ? 'ps-md-0' : 'ps-md-2'} text-center px-0`}
                  // className={props.ghatorname ? 'text-center px-0' : 'text-center pe-0'}
                >
                  <CreatableSelect
                    required
                    isMulti
                    components={
                      Object.keys(grantedList).length === 0 &&
                      Object.keys(usersList).length === 0 &&
                      components
                    }
                    options={Object.values(
                      localStorage.getItem('profile') === 'admi'
                        ? usersList.map((u) => ({
                            label: u.granted_user || u.email,
                            value: u.granted_user || u.email,
                          }))
                        : localStorage.getItem('menu') === 'permis' &&
                            [...new Set(grantedList.map((u) => u.granted_user || u.email))].map(
                              (email) => ({ label: email, value: email })
                            )
                    )}
                    placeholder={t('usersToAllow')}
                    isClearable
                    isSearchable
                    // menuIsOpen={false}
                    value={groupData.granted_users ? groupData.granted_users : []}
                    styles={selectStyles}
                    onChange={(e) => {
                      setGroupData({ ...groupData, granted_users: e });
                      // setForever({
                      //   ...forever,
                      //   group: [false, false],
                      // });
                    }}
                  />
                </Col>
              </Row>
              {((selectedIdris &&
                selectedIdris.length > 0 &&
                groupData.granted_users &&
                groupData.granted_users.length > 0) ||
                (props.ghatorname &&
                  groupData.granted_users &&
                  groupData.granted_users.length > 0)) && (
                <>
                  <hr className='m-0 mt-3'></hr>
                  <Row className='mt-3 '>
                    <Col xs={12} md={8} className='d-flex align-items-stretch'>
                      <Row className='flex-fill'>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('dataView')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.view ? groupData.view : false}
                            onChange={(e) =>
                              setGroupData({
                                ...groupData,
                                view: e.target.checked,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              })
                            }
                          />
                        </Col>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('manual')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.action ? groupData.action : false}
                            onChange={(e) => {
                              setGroupData({
                                ...groupData,
                                action: e.target.checked,
                                view:
                                  e.target.checked && !groupData.view
                                    ? e.target.checked
                                    : groupData.view,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              });
                            }}
                          />
                        </Col>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('progs')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.program ? groupData.program : false}
                            onChange={(e) =>
                              setGroupData({
                                ...groupData,
                                program: e.target.checked,
                                view:
                                  e.target.checked && !groupData.view
                                    ? e.target.checked
                                    : groupData.view,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              })
                            }
                          />
                        </Col>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('alerts')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.alerts ? groupData.alerts : false}
                            onChange={(e) =>
                              setGroupData({
                                ...groupData,
                                alerts: e.target.checked,
                                view:
                                  e.target.checked && !groupData.view
                                    ? e.target.checked
                                    : groupData.view,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              })
                            }
                          />
                        </Col>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('rules')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.rules ? groupData.rules : false}
                            onChange={(e) =>
                              setGroupData({
                                ...groupData,
                                rules: e.target.checked,
                                view:
                                  e.target.checked && !groupData.view
                                    ? e.target.checked
                                    : groupData.view,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              })
                            }
                          />
                        </Col>
                        <Col xs={6} md={4} className='text-center border rounded-2'>
                          <div className='animated text-truncate text-center'>
                            <strong>{t('historical')}</strong>
                          </div>
                          <Form.Check
                            type='checkbox'
                            checked={groupData && groupData.historic ? groupData.historic : false}
                            onChange={(e) =>
                              setGroupData({
                                ...groupData,
                                historic: e.target.checked,
                                view:
                                  e.target.checked && !groupData.view
                                    ? e.target.checked
                                    : groupData.view,
                                management_end_date:
                                  e.target.checked && !groupData.management_end_date
                                    ? moment().add(3, 'days').format('yyyy-MM-DD')
                                    : groupData?.management_end_date,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className={`text-center border rounded-2 d-flex align-items-center ${
                        !groupData.action &&
                        !groupData.alerts &&
                        !groupData.historic &&
                        !groupData.program &&
                        !groupData.rules &&
                        !groupData.view &&
                        'disabledCustom'
                      }`}
                    >
                      <Col xs={12}>
                        {/* {!forever.group[0] && (
                        <> */}{' '}
                        <div className='animated text-truncate text-center'>
                          <strong>{t('managementEndDay')}</strong>
                        </div>
                        <DatePicker
                          required={
                            groupData.action ||
                            groupData.alerts ||
                            groupData.historic ||
                            groupData.program ||
                            groupData.rules ||
                            groupData.view
                          }
                          locale={localStorage.getItem('language')}
                          customInput={<Form.Control />}
                          className='text-center mb-2'
                          dateFormat='dd/MM/yyyy'
                          selected={
                            (groupData.action ||
                              groupData.alerts ||
                              groupData.historic ||
                              groupData.program ||
                              groupData.rules ||
                              groupData.view) &&
                            moment(groupData.management_end_date && groupData.management_end_date)
                              ._d
                          }
                          onChange={(date) =>
                            setGroupData({
                              ...groupData,
                              management_end_date: moment(date).format('yyyy-MM-DD'),
                            })
                          }
                          minDate={moment().add(1, 'days')._d}
                          withPortal
                          showYearDropdown
                          showMonthDropdown
                        />
                        {/* </> */}
                        {/* )} */}
                        {/* <div className='animated text-truncate text-center'><strong>{t('years')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.group[0]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            group: [e.target.checked, forever.group[1]],
                          })
                        }
                      /> */}
                      </Col>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col
                      xs={12}
                      md={8}
                      className='text-center border rounded-2 d-flex align-items-center'
                    >
                      <Col xs={12}>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('edit').toLowerCase()}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={groupData && groupData.edit ? groupData.edit : false}
                          onChange={(e) =>
                            setGroupData({
                              ...groupData,
                              edit: e.target.checked,
                              view:
                                e.target.checked && !groupData.view
                                  ? e.target.checked
                                  : groupData.view,
                              edit_end_date:
                                e.target.checked && !groupData.edit_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : groupData?.edit_end_date,
                            })
                          }
                        />
                      </Col>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className={`text-center border rounded-2 d-flex align-items-center ${
                        !groupData.edit && 'disabledCustom'
                      }`}
                    >
                      <Col xs={12}>
                        {/* {!forever.group[1] && (
                        <> */}
                        <div className='animated text-truncate text-center'>
                          <strong>{t('editEndDay')}</strong>
                        </div>
                        <DatePicker
                          required={groupData.edit}
                          locale={localStorage.getItem('language')}
                          customInput={<Form.Control />}
                          className='text-center mb-2'
                          dateFormat='dd/MM/yyyy'
                          selected={
                            groupData.edit &&
                            moment(groupData.edit_end_date && groupData.edit_end_date)._d
                          }
                          onChange={(date) =>
                            setGroupData({
                              ...groupData,
                              edit_end_date: moment(date).format('yyyy-MM-DD'),
                            })
                          }
                          minDate={moment().add(1, 'days')._d}
                          withPortal
                          showYearDropdown
                          showMonthDropdown
                        />
                        {/* </>
                      )} */}
                        {/* <div className='animated text-truncate text-center'><strong>{t('forever')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.group[1]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            group: [forever.group[0], e.target.checked],
                          })
                        }
                      /> */}
                      </Col>
                    </Col>
                  </Row>
                  <Row className='mt-3 d-flex justify-content-center'>
                    <Col xs={6} className='d-grid'>
                      <Button
                        variant='outline-primary'
                        size='sm'
                        type='submit'
                        // onClick={(e) => postGrantIdris(e, groupData, true, false)}
                      >
                        {t('modificar')}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            <Table bordered className='mb-0' id=''>
              <thead>
                <tr className='primary text-center'>
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal lh-sm my-0'>
                      {localStorage.getItem('profile') === 'admi'
                        ? t('granted')
                        : t('individualAssignments')}
                    </p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Row className=''>
              {localStorage.getItem('profile') === 'admi' ? (
                <>
                  <Col xs={12} md={6} className='text-center px-0 pb-2 pb-md-0 pe-md-2 ps-md-0'>
                    <Select
                      menuShouldScrollIntoView
                      required
                      placeholder={t('emisor')}
                      // isClearable
                      // isMulti
                      isSearchable
                      // options={grantedList}
                      options={
                        localStorage.getItem('profile') === 'admi'
                          ? Object.values(usersList).map((u) => {
                              return {
                                label: u.granted_user ? u.granted_user : u.email,
                                value: u.granted_user ? u.granted_user : u.email,
                                id: u.granted_user ? u.granted_user : u.iduser,
                              };
                            })
                          : grantedList
                      }
                      value={Object.keys(grantedUser).length > 0 ? grantedUser : null}
                      styles={selectStyles}
                      onChange={(e) => {
                        setGrantedUser(e);
                        getGrantedIdris(e.id, e.value);
                        setIndividualData({});
                        setGrantedList([]);
                        // setForever({
                        //   ...forever,
                        //   individual: [false, false],
                        // });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className='text-center px-0 ps-md-2 pe-md-0'>
                    <Select
                      menuShouldScrollIntoView
                      required
                      placeholder={t('receptor')}
                      // isClearable
                      // isMulti
                      isSearchable
                      options={grantedList}
                      value={Object.keys(individualData).length > 0 ? individualData : null}
                      styles={selectStyles}
                      onChange={(e) => {
                        setIndividualData(e);
                        // setForever({
                        //   ...forever,
                        //   individual: [false, false],
                        // });
                      }}
                    />
                  </Col>
                </>
              ) : (
                <Col xs={12} className='text-center px-0'>
                  <Select
                    menuShouldScrollIntoView
                    required
                    placeholder={t('userToAllow')}
                    // isClearable
                    // isMulti
                    isSearchable
                    // options={grantedList}
                    options={
                      localStorage.getItem('profile') === 'admi'
                        ? Object.values(usersList).map((u) => {
                            return {
                              label: u.granted_user ? u.granted_user : u.email,
                              value: u.granted_user ? u.granted_user : u.email,
                            };
                          })
                        : grantedList
                    }
                    value={Object.keys(individualData).length > 0 ? individualData : null}
                    styles={selectStyles}
                    onChange={(e) => {
                      setIndividualData(e);
                      // setForever({
                      //   ...forever,
                      //   individual: [false, false],
                      // });
                    }}
                  />
                </Col>
              )}
            </Row>
            {Object.keys(individualData).length > 0 && (
              <>
                <hr className='m-0 mt-3'></hr>
                <Row className='mt-3 '>
                  <Col xs={12} md={8} className='d-flex align-items-stretch'>
                    <Row className='flex-fill'>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('dataView')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.view ? individualData.view : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              view: e.target.checked,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('manual')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.action ? individualData.action : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              action: e.target.checked,
                              view:
                                e.target.checked && !individualData.view
                                  ? e.target.checked
                                  : individualData.view,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('progs')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.program
                              ? individualData.program
                              : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              program: e.target.checked,
                              view:
                                e.target.checked && !individualData.view
                                  ? e.target.checked
                                  : individualData.view,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('alerts')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.alerts ? individualData.alerts : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              alerts: e.target.checked,
                              view:
                                e.target.checked && !individualData.view
                                  ? e.target.checked
                                  : individualData.view,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('rules')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.rules ? individualData.rules : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              rules: e.target.checked,
                              view:
                                e.target.checked && !individualData.view
                                  ? e.target.checked
                                  : individualData.view,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('historical')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            individualData && individualData.historic
                              ? individualData.historic
                              : false
                          }
                          onChange={(e) =>
                            setIndividualData({
                              ...individualData,
                              historic: e.target.checked,
                              view:
                                e.target.checked && !individualData.view
                                  ? e.target.checked
                                  : individualData.view,
                              management_end_date:
                                e.target.checked && !individualData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : individualData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className={`text-center border rounded-2 d-flex align-items-center ${
                      !individualData.action &&
                      !individualData.alerts &&
                      !individualData.historic &&
                      !individualData.program &&
                      !individualData.rules &&
                      !individualData.view &&
                      'disabledCustom'
                    }`}
                  >
                    <Col xs={12}>
                      {/* {!forever.individual[0] && (
                        <> */}
                      <div className='animated text-truncate text-center'>
                        <strong>{t('managementEndDay')}</strong>
                      </div>
                      <DatePicker
                        required
                        locale={localStorage.getItem('language')}
                        customInput={<Form.Control />}
                        className='text-center mb-2'
                        dateFormat='dd/MM/yyyy'
                        selected={
                          (individualData.action ||
                            individualData.alerts ||
                            individualData.historic ||
                            individualData.program ||
                            individualData.rules ||
                            individualData.view) &&
                          moment(
                            individualData.management_end_date && individualData.management_end_date
                          )._d
                        }
                        onChange={(date) =>
                          setIndividualData({
                            ...individualData,
                            management_end_date: moment(date).format('yyyy-MM-DD'),
                          })
                        }
                        minDate={moment().add(1, 'days')._d}
                        withPortal
                        showYearDropdown
                        showMonthDropdown
                      />
                      {/* </>
                      )} */}
                      {/* <div className='animated text-truncate text-center'><strong>{t('forever')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.individual[0]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            individual: [e.target.checked, forever.individual[1]],
                          })
                        }
                      /> */}
                    </Col>
                  </Col>
                </Row>
                <Row className=''>
                  <Col
                    xs={12}
                    md={8}
                    className='text-center border rounded-2 d-flex align-items-center'
                  >
                    <Col xs={12}>
                      <div className='animated text-truncate text-center'>
                        <strong>{t('edit').toLowerCase()}</strong>
                      </div>
                      <Form.Check
                        type='checkbox'
                        checked={
                          individualData && individualData.edit ? individualData.edit : false
                        }
                        onChange={(e) =>
                          setIndividualData({
                            ...individualData,
                            edit: e.target.checked,
                            view:
                              e.target.checked && !individualData.view
                                ? e.target.checked
                                : individualData.view,
                            edit_end_date:
                              e.target.checked && !individualData.edit_end_date
                                ? moment().add(3, 'days').format('yyyy-MM-DD')
                                : individualData?.edit_end_date,
                          })
                        }
                      />
                    </Col>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className={`text-center border rounded-2 d-flex align-items-center ${
                      !individualData.edit && 'disabledCustom'
                    }`}
                  >
                    <Col xs={12}>
                      {/* {!forever.individual[1] && (
                        <> */}
                      <div className='animated text-truncate text-center'>
                        <strong>{t('editEndDay')}</strong>
                      </div>
                      <DatePicker
                        required
                        locale={localStorage.getItem('language')}
                        customInput={<Form.Control />}
                        className='text-center mb-2'
                        dateFormat='dd/MM/yyyy'
                        selected={
                          individualData.edit &&
                          moment(individualData.edit_end_date && individualData.edit_end_date)._d
                        }
                        onChange={(date) =>
                          setIndividualData({
                            ...individualData,
                            edit_end_date: moment(date).format('yyyy-MM-DD'),
                          })
                        }
                        minDate={moment().add(1, 'days')._d}
                        withPortal
                        showYearDropdown
                        showMonthDropdown
                      />
                      {/* </>
                      )} */}
                      {/* <div className='animated text-truncate text-center'><strong>{t('forever')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.individual[1]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            individual: [forever.individual[0], e.target.checked],
                          })
                        }
                      /> */}
                    </Col>
                  </Col>
                </Row>
                <Row className='mt-3 d-flex justify-content-center'>
                  <Col xs={6} className='d-grid ps-0'>
                    <Button
                      variant='outline-primary'
                      size='sm'
                      onClick={(e) =>
                        postGrantIdris(
                          e,
                          individualData,
                          false,
                          true,
                          true,
                          false,
                          grantedUser.id,
                          individualData.email
                        )
                      }
                    >
                      {t('modificar').toLocaleLowerCase()}
                    </Button>
                  </Col>
                  <Col xs={6} className='d-grid pe-0'>
                    <Button
                      variant='outline-danger'
                      size='sm'
                      onClick={(e) => delPermissions(e, individualData.id, true)}
                    >
                      {t('revoke')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <Table bordered className='mb-0' id=''>
              <thead>
                <tr
                  className={
                    localStorage.getItem('profile') === 'admi'
                      ? 'primary text-center'
                      : 'secondary text-center'
                  }
                >
                  <th colSpan={2} className='lh-sm'>
                    <p className='fw-normal lh-sm my-0'>
                      {localStorage.getItem('profile') === 'admi'
                        ? t('delegated')
                        : t('myPermissions')}
                    </p>
                  </th>
                </tr>
              </thead>
            </Table>
          </Accordion.Header>
          <Accordion.Body>
            <Row className=''>
              {localStorage.getItem('profile') === 'admi' ? (
                <>
                  <Col xs={12} md={6} className='text-center px-0 pb-2 pb-md-0 pe-md-2 ps-md-0'>
                    <Select
                      menuShouldScrollIntoView
                      required
                      placeholder={t('autorizado')}
                      // isClearable
                      // isMulti
                      isSearchable
                      // options={grantedList}
                      options={
                        localStorage.getItem('profile') === 'admi'
                          ? Object.values(usersList).map((u) => {
                              return {
                                label: u.granted_user ? u.granted_user : u.email,
                                value: u.granted_user ? u.granted_user : u.email,
                                id: u.granted_user ? u.granted_user : u.iduser,
                              };
                            })
                          : grantedList
                      }
                      value={Object.keys(delegatedUser).length > 0 ? delegatedUser : null}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDelegatedUser(e);
                        setDelegatedData({});
                        getDelegatedIdris(e.id, e.value);
                        // setForever({
                        //   ...forever,
                        //   individual: [false, false],
                        // });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className='text-center px-0 ps-md-2 pe-md-0'>
                    <Select
                      menuShouldScrollIntoView
                      required
                      placeholder={t('dispositivos').toLowerCase()}
                      // isClearable
                      // isMulti
                      isSearchable
                      options={delegatedList}
                      value={Object.keys(delegatedData).length > 0 ? delegatedData : null}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDelegatedData(e);
                        // setForever({
                        //   ...forever,
                        //   individual: [false, false],
                        // });
                      }}
                    />
                  </Col>
                </>
              ) : (
                <Col xs={12} className='text-center px-0'>
                  <Select
                    menuShouldScrollIntoView
                    required
                    placeholder={t('assignmentDevices')}
                    // isClearable
                    // isMulti
                    isSearchable
                    options={delegatedList}
                    value={Object.keys(delegatedData).length > 0 ? delegatedData : null}
                    styles={selectStyles}
                    onChange={(e) => {
                      setDelegatedData(e);
                      // setForever({
                      //   ...forever,
                      //   delegated: [false, false],
                      // });
                    }}
                  />
                </Col>
              )}
            </Row>
            {Object.keys(delegatedData).length > 0 && (
              <>
                <hr className='m-0 my-3'></hr>
                <Row
                  className={`${localStorage.getItem('profile') !== 'admi' && 'disabledCustom'}`}
                >
                  <Col xs={12} md={8} className='d-flex align-items-stretch'>
                    <Row className='flex-fill'>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('dataView')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={delegatedData && delegatedData.view ? delegatedData.view : false}
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              view: e.target.checked,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('manual')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            delegatedData && delegatedData.action ? delegatedData.action : false
                          }
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              action: e.target.checked,
                              view:
                                e.target.checked && !delegatedData.view
                                  ? e.target.checked
                                  : delegatedData.view,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('progs')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            delegatedData && delegatedData.program ? delegatedData.program : false
                          }
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              program: e.target.checked,
                              view:
                                e.target.checked && !delegatedData.view
                                  ? e.target.checked
                                  : delegatedData.view,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('alerts')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            delegatedData && delegatedData.alerts ? delegatedData.alerts : false
                          }
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              alerts: e.target.checked,
                              view:
                                e.target.checked && !delegatedData.view
                                  ? e.target.checked
                                  : delegatedData.view,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('rules')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            delegatedData && delegatedData.rules ? delegatedData.rules : false
                          }
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              rules: e.target.checked,
                              view:
                                e.target.checked && !delegatedData.view
                                  ? e.target.checked
                                  : delegatedData.view,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} md={4} className='text-center border rounded-2'>
                        <div className='animated text-truncate text-center'>
                          <strong>{t('historical')}</strong>
                        </div>
                        <Form.Check
                          type='checkbox'
                          checked={
                            delegatedData && delegatedData.historic ? delegatedData.historic : false
                          }
                          onChange={(e) =>
                            setDelegatedData({
                              ...delegatedData,
                              historic: e.target.checked,
                              view:
                                e.target.checked && !delegatedData.view
                                  ? e.target.checked
                                  : delegatedData.view,
                              management_end_date:
                                e.target.checked && !delegatedData.management_end_date
                                  ? moment().add(3, 'days').format('yyyy-MM-DD')
                                  : delegatedData?.management_end_date,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className={`text-center border rounded-2 d-flex align-items-center ${
                      !delegatedData.action &&
                      !delegatedData.alerts &&
                      !delegatedData.historic &&
                      !delegatedData.program &&
                      !delegatedData.rules &&
                      !delegatedData.view &&
                      'disabledCustom'
                    }`}
                  >
                    <Col xs={12}>
                      {/* {!forever.individual[0] && (
                        <> */}
                      <div className='animated text-truncate text-center'>
                        <strong>{t('managementEndDay')}</strong>
                      </div>
                      <DatePicker
                        required
                        locale={localStorage.getItem('language')}
                        customInput={<Form.Control />}
                        className='text-center mb-2'
                        dateFormat='dd/MM/yyyy'
                        selected={
                          (delegatedData.action ||
                            delegatedData.alerts ||
                            delegatedData.historic ||
                            delegatedData.program ||
                            delegatedData.rules ||
                            delegatedData.view) &&
                          moment(
                            delegatedData.management_end_date && delegatedData.management_end_date
                          )._d
                        }
                        onChange={(date) =>
                          setDelegatedData({
                            ...delegatedData,
                            management_end_date: moment(date).format('yyyy-MM-DD'),
                          })
                        }
                        minDate={moment().add(1, 'days')._d}
                        withPortal
                        showYearDropdown
                        showMonthDropdown
                      />
                      {/* </>
                      )} */}
                      {/* <div className='animated text-truncate text-center'><strong>{t('forever')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.individual[0]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            individual: [e.target.checked, forever.individual[1]],
                          })
                        }
                      /> */}
                    </Col>
                  </Col>
                </Row>
                <Row
                  className={`${localStorage.getItem('profile') !== 'admi' && 'disabledCustom'}`}
                >
                  <Col
                    xs={12}
                    md={8}
                    className='text-center border rounded-2 d-flex align-items-center'
                  >
                    <Col xs={12}>
                      <div className='animated text-truncate text-center'>
                        <strong>{t('edit').toLowerCase()}</strong>
                      </div>
                      <Form.Check
                        type='checkbox'
                        checked={delegatedData && delegatedData.edit ? delegatedData.edit : false}
                        onChange={(e) =>
                          setDelegatedData({
                            ...delegatedData,
                            edit: e.target.checked,
                            view:
                              e.target.checked && !delegatedData.view
                                ? e.target.checked
                                : delegatedData.view,
                            edit_end_date:
                              e.target.checked && !delegatedData.edit_end_date
                                ? moment().add(3, 'days').format('yyyy-MM-DD')
                                : delegatedData?.edit_end_date,
                          })
                        }
                      />
                    </Col>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className={`text-center border rounded-2 d-flex align-items-center ${
                      !delegatedData.edit && 'disabledCustom'
                    }`}
                  >
                    <Col xs={12}>
                      {/* {!forever.individual[1] && (
                        <> */}
                      <div className='animated text-truncate text-center'>
                        <strong>{t('editEndDay')}</strong>
                      </div>
                      <DatePicker
                        required
                        locale={localStorage.getItem('language')}
                        customInput={<Form.Control />}
                        className='text-center mb-2'
                        dateFormat='dd/MM/yyyy'
                        selected={
                          delegatedData.edit &&
                          moment(delegatedData.edit_end_date && delegatedData.edit_end_date)._d
                        }
                        onChange={(date) =>
                          setDelegatedData({
                            ...delegatedData,
                            edit_end_date: moment(date).format('yyyy-MM-DD'),
                          })
                        }
                        minDate={moment().add(1, 'days')._d}
                        withPortal
                        showYearDropdown
                        showMonthDropdown
                      />
                      {/* </>
                      )} */}
                      {/* <div className='animated text-truncate text-center'><strong>{t('forever')}</strong>
                      <Form.Check
                        type='checkbox'
                        checked={forever.individual[1]}
                        onChange={(e) =>
                          setForever({
                            ...forever,
                            individual: [forever.individual[0], e.target.checked],
                          })
                        }
                      /> */}
                    </Col>
                  </Col>
                </Row>
                <Row className='mt-3 d-flex justify-content-center'>
                  {localStorage.getItem('profile') === 'admi' && (
                    <Col xs={6} className='d-grid ps-0'>
                      <Button
                        variant='outline-primary'
                        size='sm'
                        onClick={(e) =>
                          postGrantIdris(
                            e,
                            delegatedData,
                            false,
                            true,
                            false,
                            true,
                            delegatedUser.id,
                            delegatedData.email
                          )
                        }
                      >
                        {t('modificar').toLocaleLowerCase()}
                      </Button>
                    </Col>
                  )}

                  <Col xs={6} className='d-grid pe-0'>
                    <Button
                      variant='outline-danger'
                      size='sm'
                      onClick={(e) => delPermissions(e, delegatedData.id, true)}
                    >
                      {t('revoke')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Permissions;
