/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ModalGenerico from './Modal_generico';
import AvisoLegal from './Modals/AvisoLegal';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import errorHandler from '../../support_func/errorHandler';
import unavailable from '../../support_func/unavailable';

const Contact = () => {
  const { t } = useTranslation();
  const recaptchaRef = useRef();

  const initialState = { name: '', email: '', message: '' };
  const [emailData, setEmailData] = useState(initialState);
  const [aviso, setAviso] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [key, setKey] = useState(Date.now());

  //send prepared e-mail
  const sendEmail = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let body = {
      ...emailData,
      captchatoken: captcha,
      language: localStorage.getItem('language'),
    };
    if (captcha && captcha !== '') {
      axios
        .post(`${process.env.REACT_APP_API_SRT}/private2/sendcontactform`, body)
        .then((res) => {
          if (!res.data.code) {
            unavailable(true);
          }
          if (res.data.code === '0') {
            setIsPending(false);
            setEmailData(initialState);
            setCaptcha('');
            recaptchaRef.current.reset();
            setMsg(t('mensajeEnviado'));
            setTimeout(() => {
              setMsg(null);
            }, 3000);
          } else {
            setIsPending(false);
            setCaptcha('');
            recaptchaRef.current.reset();
            setError(t('errorMensaje'));
          }
        })
        .catch((error) => {
          setCaptcha('');
          recaptchaRef.current.reset();
          errorHandler(error, setIsPending, setError);
        });
    } else {
      setIsPending(false);
      setTimeout(() => {
        setError(t('noRobot'));
      }, 10);
    }
  };

  useEffect(() => {
    setKey(Date.now());
  }, [localStorage.getItem('language')]);

  return (
    <section id='contact'>
      <Container>
        <Row>
          <Col className='text-center'>
            <h2 className='section-heading text-uppercase'>{t('contactanos')}</h2>
            <h3 className='section-subheading text-white'>...{t('cualquierCosa')}...</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form id='contactForm' onSubmit={(e) => sendEmail(e)}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label htmlFor='static_page_name' className='text-white'>
                      {t('nombre')}
                    </Form.Label>
                    <Form.Control
                      required
                      size='lg'
                      type='name'
                      placeholder={t('tuNombre')}
                      value={emailData.name}
                      onChange={(e) => setEmailData({ ...emailData, name: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor='static_page_email' className='text-white'>
                      Email
                    </Form.Label>
                    <Form.Control
                      required
                      size='lg'
                      type='email'
                      placeholder={t('tuEmail')}
                      value={emailData.email}
                      onChange={(e) => setEmailData({ ...emailData, email: e.target.value })}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label htmlFor='static_page_message' className='text-white'>
                      {t('mensaje')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      required
                      size='lg'
                      type='text'
                      placeholder={t('tuMensaje')}
                      value={emailData.message}
                      style={{ minHeight: '130px' }}
                      onChange={(e) => setEmailData({ ...emailData, message: e.target.value })}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-4'>
                <ReCAPTCHA
                  id='captcha'
                  key={key}
                  hl={localStorage.getItem('language')}
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                  onChange={(e) => setCaptcha(e)}
                />
              </Row>
              <Col className='text-center'>
                <Button
                  type='submit'
                  id='sendMessageButton'
                  className='btn dark btn-xl text-uppercase mt-5'
                >
                  {t('enviar')}
                </Button>
              </Col>
            </Form>
            {isPending && (
              <div className='working_apis'>
                <div className='text-working-apis'>{t('comprobando')}...</div>
              </div>
            )}
            {(error && <Col className='text-center alert alert-warning mt-3'>{error}</Col>) ||
              (msg && <Col className='text-center alert alert-success mt-3'>{msg}</Col>)}

            <Row>
              <Col className='text-end mt-3'>
                <a
                  data-toggle='modal'
                  href='#avisolegal'
                  className='text-white text-decoration-none'
                  onClick={() => setAviso(true)}
                >
                  {t('politicaPrivacidad')}
                </a>
                <ModalGenerico title={t('avisoLegal')} onClose={() => setAviso(false)} show={aviso}>
                  <AvisoLegal closeModal={() => setAviso(false)} />
                </ModalGenerico>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
