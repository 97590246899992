/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Prosidenavbar from '../components/srtapp/Prosidenavbar';
import checkNotification from '../support_func/checkNotifications';
import { urlB64ToUint8Array } from '../support_func/urlB64ToUint8Array';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import { USER } from '../redux/constants/idriConstants';
import errorHandler from '../support_func/errorHandler';
import unavailable from '../support_func/unavailable';

const Srtapp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggled, setToggled] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [notifications, setNotifications] = useState({
    modal: false,
    enable: null,
    warning: null,
    data: null,
  });
  const [alerts, setAlerts] = useState([]);
  const token = localStorage.getItem('autoAcceso');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const checkToken = () => {
    let lastToken = localStorage.getItem('autoAcceso');
    return function () {
      let currentToken = localStorage.getItem('autoAcceso');
      if (currentToken !== null && lastToken !== null && currentToken !== lastToken) {
        localStorage.getItem('autoAcceso') && window.location.reload();
      }
    };
  };

  const getNotifications = async () => {
    setIsPending(true);
    localStorage.removeItem(`pushRemind-${localStorage.getItem('id')}`);
    let notification = null;
    if ('Notification' in window) {
      await Notification.requestPermission(function (status) {
        notification = status;
        console.log(t('notificationStatus'), status);
      });

      if (notification === 'granted') {
        const createSubscription = await navigator.serviceWorker
          .getRegistration()
          .then((registration) => {
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
            });
          });
        console.log('created subscription: ', createSubscription);
        window.mySubscription = createSubscription;

        if (!window.mySubscription) {
          setError(t('noSubscription'));
          return;
        }

        const body = {
          endpoint: window.mySubscription.toJSON().endpoint,
          p256dh: window.mySubscription.toJSON().keys.p256dh,
          auth: window.mySubscription.toJSON().keys.auth,
          subscribe: true,

          // expirationTime: window.mySubscription.toJSON().expirationTime,
          // email: localStorage.getItem('email'),
        };
        console.log(body);
        await axios
          .post(
            `${process.env.REACT_APP_API_SRT}/private2/webpushsubs
                  `,
            body,
            config
          )
          .then((res) => {
            console.log(res.data);
            if (!res.data.code) {
              unavailable(true);
            }
            if (res.data.code === '0') {
              setError(t('subscriptionSuccess'));
              setIsPending(false);
            } else {
              setIsPending(false);
              setError(t('problem'));
            }
            setIsPending(false);
          })
          .catch((error) => {
            errorHandler(error, setIsPending, setError);
          });
      } else {
        setIsPending(false);
        setNotifications({ ...notifications, modal: false });
        setError(t('notificationNoActive')); //////
        return;
      }
    } else {
      setError(t('notificationNoSup'));
    }
    checkNotification().then((val) =>
      setNotifications({ ...notifications, data: val, modal: false })
    );
  };

  const subscriptionCheck = async () => {
    console.log('subscribe check');
    const createSubscription = await navigator.serviceWorker.ready.then((reg) => {
      return reg.pushManager.getSubscription();
    });
    window.mySubscription = createSubscription;

    if (
      !(await axios
        .get(
          `${process.env.REACT_APP_API_SRT}/private2/checksubscription?endpoint=${
            window.mySubscription.toJSON().endpoint
          }&p256dh=${window.mySubscription.toJSON().keys.p256dh}&auth=${
            window.mySubscription.toJSON().keys.auth
          }
      `,
          config
        )
        .then((res) => {
          console.log(res.data);
          return res.data.subscription;
        })
        .catch((error) => {
          errorHandler(error, setIsPending, setError);
        }))
    ) {
      await navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager.getSubscription().then((subscription) => {
          subscription
            .unsubscribe()
            .then((successful) => {
              console.log('unsubscribed');
              // window.location.href = '/inicio';
            })
            .catch((e) => {
              console.log(e);
            });
        });
      });
      await setNotifications({ ...notifications, modal: true });
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker
          .register('service-worker.js', { scope: './' })
          .then((registration) => {
            console.log('Worker registration successful', registration.scope);
            registration.update().then(() => {
              registration.active.postMessage({
                type: 'serverUrl',
                payload: process.env.REACT_APP_API_SRT,
              });
            });
          })
          // .then(
          //   function (registration) {
          //     console.log('Worker registration successful', registration.scope);
          //   },
          //   function (err) {
          //     console.log('Worker registration failed', err);
          //   }
          // )
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      console.log('Service Worker is not supported by browser.');
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SRT}/v2/profiles`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          setData(res.data);
          let user = res.data;
          delete user.status;
          delete user.code;
          dispatch({
            type: USER,
            payload: user,
          });

          if (Object.keys(res.data.profiles).length === 0) {
            localStorage.removeItem('profile');
          } else {
            let profs = Object.keys(res.data.profiles).map((p) => {
              return p;
            });

            if (!localStorage.getItem('profile')) {
              localStorage.setItem('profile', profs[0]);
            } else if (!profs.includes(localStorage.getItem('profile'))) {
              localStorage.setItem('profile', profs[0]);
            }
          }
          setIsPending(false);
        } else {
          setIsPending(false);
          setError(t('problem'));
        }
        setIsPending(false);
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
    setInterval(checkToken(), 10000);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SRT}/private2/gwarning`, config)
      .then((res) => {
        console.log(res.data);
        if (!res.data.code) {
          unavailable(true);
        }
        if (res.data.code === '0') {
          let warnings = [];
          res.data.gwarnings.forEach((a) => {
            if (a.active) {
              if (
                !localStorage.getItem(`alertRemind-${a.code}`) ||
                (localStorage.getItem(`alertRemind-${a.code}`) &&
                  moment().toDate() >=
                    moment(localStorage.getItem(`alertRemind-${a.code}`)).toDate())
              ) {
                warnings.push({ modal: true, id: a.id, code: a.code, message: a.message });
              }
              // dispatch(setStatus({ status: 'error', code: null, error: a.message }));
            } else {
              localStorage.removeItem(`alertRemind-${a.code}`);
            }
          });
          setAlerts(warnings);
        } else {
          // dispatch(setStatus/({ status: 'error', code: null, error: t('problem') }));
        }
      })
      .catch((error) => {
        errorHandler(error, setIsPending, setError);
      });
  }, []);

  useEffect(() => {
    data &&
      i18n.getDataByLanguage(localStorage.getItem('language')) &&
      (!localStorage.getItem(`pushRemind-${localStorage.getItem('id')}`) ||
        (localStorage.getItem(`pushRemind-${localStorage.getItem('id')}`) &&
          moment()._d >=
            moment(localStorage.getItem(`pushRemind-${localStorage.getItem('id')}`))._d)) &&
      checkNotification().then((val) => {
        if (!val) {
          setNotifications({ ...notifications, modal: true });
        } else {
          subscriptionCheck();
        }
      });
  }, [data, i18n.getDataByLanguage(localStorage.getItem('language'))]);

  useEffect(() => {
    error !== null && window.alert(error);
    setError(null);
  }, [error]);

  // console.log(notifications);

  return (
    <div id='str_wrapper'>
      {isPending ? (
        <div className='working_apis'>
          <div className='text-working-apis'>{t('comprobando')}...</div>
        </div>
      ) : (
        <>
          {notifications.modal && (
            <Modal
              show={notifications.modal}
              onClose={() =>
                setNotifications({
                  modal: false,
                  enable: null,
                  warning: null,
                  data: null,
                })
              }
              // onHide={() => setNotifications({ ...notifications, modal: false })}
              centered
            >
              <Modal.Header className='d-flex justify-content-center'>
                <Modal.Title className=' text-center'>
                  {notifications.warning
                    ? t('configInProfile')
                    : notifications.enable !== false
                    ? t('wantNotifications')
                    : t('pushRemind')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  {notifications.warning ? (
                    <Col xs={12} className='d-grid gap-2'>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          setNotifications({ ...notifications, modal: false });
                          localStorage.setItem(
                            `pushRemind-${localStorage.getItem('id')}`,
                            moment(moment()).add(6, 'months')
                          );
                        }}
                      >
                        {t('cerrar')}
                      </Button>
                    </Col>
                  ) : notifications.enable === false ? (
                    <>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          size='lg'
                          className='py-md-1'
                          onClick={() => {
                            localStorage.setItem(
                              `pushRemind-${localStorage.getItem('id')}`,
                              moment(moment()).add(3, 'days')
                            );
                            setNotifications({ ...notifications, modal: false });
                          }}
                        >
                          {t('yes')}
                        </Button>
                      </Col>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          size='lg'
                          className='py-md-1'
                          onClick={() => setNotifications({ ...notifications, warning: true })}
                        >
                          {t('no')}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          size='lg'
                          className='py-md-1'
                          onClick={() => getNotifications()}
                        >
                          {t('yes')}
                        </Button>
                      </Col>
                      <Col xs={6} className='d-grid gap-2'>
                        <Button
                          variant='primary'
                          size='lg'
                          className='py-md-1'
                          onClick={() => setNotifications({ ...notifications, enable: false })}
                        >
                          {t('no')}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Modal.Body>
            </Modal>
          )}
          {alerts.map((a) => {
            return (
              <>
                {a.modal && (
                  <Modal key={a.id} show={a.modal} centered>
                    <Modal.Header className='d-flex justify-content-center'>
                      <Modal.Title className='text-center'>
                        {a.warning ? t('noRecieveGlobalAlert') : t('globalAlert')}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        {a.warning ? (
                          <Col xs={12} className='d-grid gap-2'>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                // setAlerts({ ...a, modal: false });
                                setAlerts((prevData) =>
                                  prevData.map((obj) =>
                                    obj.code === a.code ? { ...obj, modal: false } : obj
                                  )
                                );
                                localStorage.setItem(
                                  `alertRemind-${a.code}`,
                                  moment(moment()).add(1, 'week').toString()
                                );
                              }}
                            >
                              {t('cerrar')}
                            </Button>
                          </Col>
                        ) : (
                          <Row className='text'>
                            <Col xs={12} className='d-grid gap-2'>
                              <div
                                className='text-break'
                                dangerouslySetInnerHTML={{ __html: a.message ?? '' }}
                              />
                            </Col>
                            <hr className='mb-2 hr-dark' />
                            <Col xs={12} className='mb-3 text-center'>
                              <strong>{t('doWithMessage')}</strong>
                            </Col>
                            <Col xs={4} className='d-grid gap-2'>
                              <Button
                                variant='primary'
                                onClick={() => {
                                  // setAlerts({ ...a, modal: false });
                                  setAlerts((prevData) =>
                                    prevData.map((obj) =>
                                      obj.code === a.code ? { ...obj, modal: false } : obj
                                    )
                                  );

                                  localStorage.setItem(
                                    `alertRemind-${a.code}`,
                                    moment(moment()).add(1, 'days').toString()
                                  );
                                }}
                              >
                                {t('rememberLater')}
                              </Button>
                            </Col>
                            <Col xs={4} className='d-grid gap-2'>
                              <Button
                                variant='danger'
                                onClick={() => {
                                  // setAlerts({ ...a, modal: false });
                                  setAlerts((prevData) =>
                                    prevData.map((obj) =>
                                      obj.code === a.code ? { ...obj, modal: false } : obj
                                    )
                                  );

                                  localStorage.setItem(
                                    `alertRemind-${a.code}`,
                                    moment(moment()).add(1, 'month').toString()
                                  );
                                }}
                              >
                                {t('discardMessage')}
                              </Button>
                            </Col>
                            <Col xs={4} className='d-grid gap-2'>
                              <Button
                                variant='secondary'
                                onClick={() => {
                                  // setAlerts({ ...a, modal: false });
                                  setAlerts((prevData) =>
                                    prevData.map((obj) =>
                                      obj.code === a.code ? { ...obj, modal: false } : obj
                                    )
                                  );
                                }}
                              >
                                {t('cerrar')}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </Modal.Body>
                  </Modal>
                )}
              </>
            );
          })}

          {data && (
            <Prosidenavbar
              toggled={toggled}
              setToggled={setToggled}
              profiles={data}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Srtapp;
